import { Component, EventEmitter, Input, Output, SimpleChanges } from '@angular/core';
import { User } from '../../../data/models/User';
import { DropdownModule } from 'primeng/dropdown';
import { InputSwitchModule } from 'primeng/inputswitch';
import { FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR } from '@angular/forms';
import { selectGroup, selectGroupById } from '../../../utils/store/admin/admin.selector';
import { Store } from '@ngrx/store';
import { AdminActions } from '../../../utils/store/admin/admin.actions';


@Component({
  selector: 'app-user-detail',
  templateUrl: './user-detail.component.html',
  styleUrls: ['./user-detail.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: UserDetailComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: UserDetailComponent }
  ]
})


export class UserDetailComponent {
  @Input() user: User
  @Input() selectDropdown: string

  //cambiare compliance_user con audit user
  roles: string[] = ['audit_user', 'business_user', 'mooney_admin'];
  group: string[] 
  editUser: FormGroup;
  newrole: string;
  selectedRole: string;
  selecterGroup: string;
  selectedEnable: boolean;
  @Output() selectedRoleChange = new EventEmitter<string>();
  @Output() selectedGroupChange = new EventEmitter<string>();
  @Output() selectedEnableChange = new EventEmitter<boolean>();
  @Input() first:number=0;
  @Input() last:number=0;
  selectedGroup: string;
  @Input() title:string;

  groupUser:any

  constructor(private formBuilder: FormBuilder,private store:Store) {
  }

  ngOnInit() {
    this.store.select(selectGroup).subscribe(data => {
      this.group = data

      console.log(this.group)
    })

    this.store.select(selectGroupById, this.user.id).subscribe(
      (users: any[]) => {
       console.log(users)

       this.groupUser = users[0]
       console.log(this.groupUser)
        // Puoi fare ulteriori operazioni o aggiornare altre parti del tuo componente con i dati selezionati
      }
    );
    

    this.editUser = this.formBuilder.group({
      role: [''],
      group: [''],
      enable: ['']
    });

    console.log(this.selectDropdown)

    // if(this.editUser.get('role').value == 'compliance_user'){
    //   this.editUser.get('role').patchValue('audit_user')

    // }

    this.editUser.get('role').setValue(this.user.roles)
    this.editUser.get('group').setValue(this.user.group)
    this.editUser.get('enable').setValue(this.user.enabled)
    this.selectedRole = this.editUser.controls['role'].value
    this.selecterGroup = this.editUser.controls['group'].value
    this.selectedEnable = this.editUser.controls['enable'].value

  }
  
  onDropdownChange(event: any) {
    if (this.selectDropdown == 'Ruolo') {
      console.log(this.selectedRole);
      // if(this.editUser.get('role').value == 'audit_user'){
      //  event.value = 'compliance_user'
  
      // }
      this.selectedRoleChange.emit(event.value);

    } else if (this.selectDropdown == 'Gruppo') {
      console.log(event.value)
      this.selectedGroupChange.emit(event.value);
    }
  }

  

  onEnableChange(event: any) {
    console.log(this.selectedEnable);
    this.selectedEnableChange.emit(this.selectedEnable);
  }

  // writeValue(value: any) {
  //   if (value) {
  //     this.editUser.setValue(this.roles);
  //   }
  //   console.log(this.editUser.setValue(this.roles))
  // }

  // updateUserGroup() {
  //   // Ottenere il valore selezionato dal dropdown
  //   const selectedRole = this.selectedGroup;

  //   // Emettere l'evento con il valore selezionato
  //   this.selectedRoleChange.emit(selectedRole);
  // }


  delete(id:string,group:string){
    console.log(id,group)
    this.store.dispatch({ type: AdminActions.disconnectGroup, idUser: id, nameGroup: group,first: this.first, last: this.last })
  
    this.user={...this.user}
    console.log("QUI",this.user)
  }


}
