import { UpperCasePipe } from '@angular/common';
import { Pipe, PipeTransform } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';

export enum Stato {
  COMPLETED = 'Completato',
  NEW = 'Nuovo',
  EXPIRED = 'Scaduto',
  DRAFT = 'Bozza',
  SENT = 'Inviato',
  IN_CHARGE = 'In carico',
  REFUSED = 'Rifiutato',
  TO_CHECK = 'Verifica Compliance',
  COMPLIANCE = 'In lavorazione da Compliance',
  PUBLISHED = 'Pubblicato',
  CLOSED = 'Chiuso'

}



@Pipe({
  name: 'statuspipe'
})
export class StatuspipePipe implements PipeTransform {

  transform(value: string, isAdmin?: boolean, isFinding?:boolean, isFaq?:boolean): string {
    if ((value == 'IN_CHARGE' || value == 'NEW') && !isFinding) {
      return isAdmin ? isFaq ? Stato.PUBLISHED : Stato[value] : Stato.COMPLIANCE;
    }else {
      return Stato[value]
    }
  }




}
