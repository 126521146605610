<div class="customContainer mb-2">
  <div [formGroup]="formGroup">
    <div class=" form_new_request border-round-sm ">
      <div class=" header_form border-round-top-sm font-bold flex justify-content-start">
        <p class="m-1 ml-3 ">{{title}}</p>
      </div>
      <!-- <div
        *ngIf="formGroup.controls['title'].invalid && (formGroup.controls['title'].dirty || formGroup.controls['title'].touched)"
        class="alert">
        <div *ngIf="formGroup.controls['title'].errors.required">
          Please enter username
        </div>
      </div> -->
      <div class="p-3">
        <div class="flex flex-row  gap-5 justify-content-between">
          <div class="w-full mt-1 mb-1">
            <p class="font-bold text1 mb-1">Titolo Richiesta</p>
            <input type="text" pInputText class="p-input text-md w-full  " formControlName="title"
              placeholder="Inserisci valore">
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['title'].invalid && formGroup.controls['title'].touched || formGroup.get('title')?.value.length >= 100">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il Titolo ( massimo 100 caratteri
                ). </p>
            </div>
          </div>
          <div class="w-full mt-1 mb-1">
            <p class="font-bold text1 mb-1">Priorità</p>
            <p-dropdown class="" formControlName="priorita" [styleClass]="styleDropDownPrio" [options]="prioselect"
              [(ngModel)]="prio" placeholder="Seleziona Priorità" [showClear]="true"></p-dropdown>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['priorita'].invalid && formGroup.controls['priorita'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la Priorità</p>
            </div>
          </div>
          <div class="w-full mt-1 mb-1 ">
            <p class="font-bold text1 mb-1 ">Scadenza</p>
            <p-calendar [styleClass]="styleCalendar" [style]="{'width':'100%', 'height':'2.9rem'}" [(ngModel)]="date"
              dateFormat="{{'dd-mm-yy'}}" inputId="time" formControlName="dueDate" placeholder="Seleziona una data"
              [minDate]="minDate">
            </p-calendar>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['dueDate'].invalid && formGroup.controls['dueDate'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la Data</p>
            </div>
          </div>
        </div>
        <div class="flex flex-row  gap-5">

        </div>
        <div class="flex flex-row justify-content-between gap-5">
          <div class="w-full mt-3 mb-1">
            <p class="font-bold  text1 mb-1">Ambito</p>
            <!-- <p-dropdown  formControlName="ambito" [styleClass]="styleDropDownScope" [options]="scope"
              [(ngModel)]="selectedScope" placeholder="Seleziona Ambito"  [showClear]="true"></p-dropdown> -->
            <p-dropdown [options]="fieldAOptions" [(ngModel)]="ambito" (onChange)="filterFieldBOptions()"
              [styleClass]="'dropdown w-full'" [placeholder]="'Seleziona Ambito'" formControlName="ambito"></p-dropdown>

            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['ambito'].invalid && formGroup.controls['ambito'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci l' Ambito</p>
            </div>

          </div>
          <div class="w-full mt-3 mb-1">
            <p class="font-bold  text1 mb-1">Perimetro</p>
            <!-- <p-dropdown  formControlName="perimetro" [styleClass]="styleDropDownPerimeter" [options]="perimeter"
              [(ngModel)]="selectedPerimeter" placeholder="Seleziona Perimetro" [showClear]="true"></p-dropdown> -->
            <p-dropdown [options]="fieldBOptions" [(ngModel)]="selectedPerimeter" [styleClass]="'dropdown w-full'"
              [placeholder]="'Seleziona Perimetro'" emptyMessage="Nessun perimetro selezionato"
              formControlName="perimetro"></p-dropdown>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['perimetro'].invalid && formGroup.controls['perimetro'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il Perimetro</p>
            </div>
          </div>
          <div class="w-full mt-3 mb-1">
            <p class="font-bold text1 mb-1">Struttura organizzata</p>
            <input formControlName="struttura" type="text" pInputText class="p-input text-md w-100  " placeholder="...">
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['struttura'].invalid && formGroup.controls['struttura'].touched || formGroup.get('struttura')?.value.length >= 255 ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la Struttura ( massimo 255
                caratteri ).</p>
            </div>
          </div>
        </div>
        <div class="w-full mt-3 mb-1" *ngIf="formGroup.get('dueDate').value != null ">
          <p class="font-bold text1 mb-1">Motivazione in caso di scadenza Definitiva</p>
          <textarea formControlName="motivazione" pInputTextarea [autoResize]="false" class="w-full"
            placeholder="Inserisci la motivazione in caso di selezione della data di Scadenza">
              </textarea>
          <div class="relative m-0 p-0"
            *ngIf="formGroup.controls['motivazione'].invalid && formGroup.controls['motivazione'].touched || formGroup.get('motivazione')?.value.length >= 255">
            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la Motivazione ( massimo 255
              caratteri ).</p>
          </div>
        </div>
        <div class="mt-3 mb-3">
          <p class="font-bold text1 mb-1">Descrivi la tua richiesta</p>
          <textarea formControlName="description" pInputTextarea [autoResize]="false" class="w-full">
          </textarea>
          <div class="relative m-0 p-0"
            *ngIf="formGroup.controls['description'].invalid && formGroup.controls['description'].touched || formGroup.get('description')?.value.length >= 65535">
            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la Descrizione ( massimo 65535
              caratteri ).</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>