import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'linkscopeperimeter'
})

export class LinkscopeperimeterPipe implements PipeTransform {
  transform(item: any, perimeterlist: any): any {

    return perimeterlist.filter(perimeter => item.includes(perimeter.id))
  }
}