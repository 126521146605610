import { Component, Input, OnInit, SimpleChange } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { map, take } from 'rxjs';
import { Priority } from 'src/app/data/models/Request';
import * as jsonambiti from 'src/environments/ambiti.json';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient, HttpResponse } from '@angular/common/http';

@Component({
  selector: 'app-form-new-request',
  templateUrl: './form-new-request.component.html',
  styleUrls: ['./form-new-request.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: FormNewRequestComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: FormNewRequestComponent }
  ]
})
export class FormNewRequestComponent implements ControlValueAccessor, Validator, OnInit {
  @Input() stato: string;
  @Input() title: string = "La tua richiesta";
  @Input() invalidBoolean: boolean = false;

  ambiti: any
  fieldAOptions: any
  ambito: string;
  ambitijson: any;
  prioselect: any
  prio: string;
  fieldPriorityOptions: any
  priorita: string;
  prioritajson: any;
  perimetro: string;
  fieldBOptions: string[] = [];
  formGroup: FormGroup;
  selectedScope: string;
  priority: Priority[];
  selectedPriority: string;
  selectedPerimeter: string;
  reason: string
  date: Date | string
  selectedDate: Date;
  styleDropDownScope: string = "w-full";
  styleDropDownPerimeter: string = "w-full";
  styleDropDownPrio: string = "w-full";
  styleCalendar: string = "";
  errore: string = "errore"
  minDate: Date;



  onChanged!: () => void
  onTouched!: () => void
  onDisabled!: (disable: boolean) => void



  writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
    if (obj.dueDate) {
      this.formGroup.get('motivazione').setValidators([Validators.required, Validators.maxLength(255)])
      if (obj.dueDate !== '') {
        this.date = new Date(obj.dueDate)
      } else {
        this.date = ''
      }
      this.formGroup.get('dueDate').patchValue(this.date)
    }

    if (obj.ambito) {
      console.log(obj.ambito)
      this.filterFieldBOptions()
    }

    // this.selectedPriority = Priority[obj.priorita];

    // switch (obj.priorita) {
    //   case 'Alta':
    //     this.selectedPriority = Priority.HIGH;
    //     break;
    //   case 'Media':
    //     this.selectedPriority = Priority.MEDIUM;
    //     break;
    //   case 'Bassa':
    //     this.selectedPriority = Priority.LOW;
    //     break;
    // }

  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn)

  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      let value = this.formGroup.value
      this.formGroup.disable()
      if (this.ambito) {
        this.fieldBOptions = this.ambitijson[this.ambito];
      }
      this.formGroup.patchValue(value)
    }
  }

  validate(control: AbstractControl): ValidationErrors {

    if (control.valueChanges && this.formGroup.valid) {
      return null
    } else {
      return { required: true }
    }
  }

  constructor(private fb: FormBuilder, private keycloakService: KeycloakService, private http: HttpClient) {
    this.priority = [
      Priority.HIGH,
      Priority.MEDIUM,
      Priority.LOW
    ];


  }

  ngOnInit() {

    this.priorita = localStorage.getItem('Priority')
    console.log(this.priorita)
    this.prioritajson = JSON.parse(this.priorita);
    console.log(this.prioritajson)
    this.fieldPriorityOptions = this.prioritajson.reduce((acc, obj) => {
      acc[obj.id] = obj.name;
      return acc;
    }, {});

    this.prioselect = Object.values(this.fieldPriorityOptions)

    this.ambiti = localStorage.getItem('Ambiti')
    this.ambitijson = JSON.parse(this.ambiti);

    this.fieldAOptions = Object.keys(this.ambitijson).sort()
    console.log(this.fieldAOptions);





    this.minDate = new Date()
    this.formGroup = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      priorita: ['', Validators.required],
      dueDate: [''],
      motivazione: [''],
      ambito: ['', Validators.required],
      perimetro: ['', Validators.required],
      struttura: ['', [Validators.required, Validators.maxLength(255)]],
      description: ['', [Validators.required, Validators.maxLength(65535)]],
    })

    this.formGroup.get("priorita").valueChanges.subscribe(
      res => this.styleDropDownPrio = "w-full"
    )

    this.formGroup.get("ambito").valueChanges.subscribe(
      res => this.styleDropDownScope = "w-full"
    )

    this.formGroup.get("perimetro").valueChanges.subscribe(
      res => this.styleDropDownPerimeter = "w-full"
    )

    this.formGroup.get("dueDate").valueChanges.subscribe(
      res => {
        this.styleCalendar = ""
        if (res) {
          this.formGroup.get('motivazione').setValidators(Validators.required)
          this.formGroup.get('motivazione').updateValueAndValidity()
        } else {
          this.formGroup.get('motivazione').setValue("")
          this.formGroup.get('motivazione').removeValidators(Validators.required)
          this.formGroup.get('motivazione').updateValueAndValidity()
        }
      }

    )


  }

  filterFieldBOptions() {
    console.log("eccomi")
    if (this.ambito) {
      this.fieldBOptions = this.ambitijson[this.ambito].sort();
      console.log(this.fieldBOptions)
    } else {
      this.fieldBOptions = [];
    }

  }




  ngOnChanges() {
    if (this.invalidBoolean == true) {
      if (this.formGroup.get("priorita").invalid) {
        this.styleDropDownPrio = "customdrop w-full";
      }
      if (this.formGroup.get("perimetro").invalid) {
        this.styleDropDownPerimeter = "customdrop w-full";
      }

      if (this.formGroup.get("ambito").invalid) {
        this.styleDropDownScope = "customdrop w-full";
      }

      if (this.formGroup.get("dueDate").invalid) {
        this.styleCalendar = "custom-cal"
      }
      this.formGroup.markAllAsTouched()

    } else {
      this.styleDropDownScope = " w-full";
      this.styleDropDownPerimeter = " w-full";
      this.styleDropDownPrio = " w-full";
      this.styleCalendar = ""

    }


  }
}
