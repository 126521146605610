import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestService } from 'src/app/utils/services/request.service';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/utils/services/user.service';
import { Store } from '@ngrx/store';
import { selectCurrentRequestState, selectCurrentRequestTitle, selectRequestsData, selectCurrentRequestAmbito, selectRequestsTable, selectCurrentRequestId, selectFaqsTable, selectRequestDataLoading } from 'src/app/utils/store/request/request.selector';
import { Observable, Subscription, catchError, concatMap, forkJoin, map, merge, of, switchMap, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DbRequest, Status } from 'src/app/data/models/Request';
import { RequestActions, setCurrentRequest } from 'src/app/utils/store/request/request.action';
import { Attachment } from 'src/app/data/models/Attachment';
import { HttpClient } from '@angular/common/http';
import { selectCurrentIDandRole, selectUserRole } from 'src/app/utils/store/user/user.selector';
import { environment } from 'src/environments/environment';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';

@Component({
  selector: 'app-see-faq',
  templateUrl: './see-faq.component.html',
  styleUrls: ['./see-faq.component.scss']
})
export class SeeFaqComponent {
  @Input() display: boolean = false;
  @Output() newItemEvent = new EventEmitter<string>();
  stato: string;
  formValue: FormGroup;
  tabella: any[] = [];
  isAdmin: boolean = false;
  stateRequest: any[] = [];
  isFilter: { isFilter: boolean, filter: string }
  currentRequest: DbRequest;
  postData: DbRequest;
  attachmentData: Attachment[] = [{
    document_title: "",
    object_name: "",
    description: "",
    uploaded_by: 0
  }];
  tableColumn: string[] = []
  newValue: any
  currentUserId: number;
  currRequestSub: Subscription
  newAttachment: Attachment[] = [];
  attached_doc: any[];
  dataToSend: string;
  isEmpty: boolean = true;
  faqId = this.activateRoute.params['_value']['id'];
  adminSub: Subscription;
  faqSub: Subscription;
  loading: boolean = false;

  url: string = this.activateRoute.queryParams['_value']['par'] == "req" ? "request" : "faq";
  setAdmin = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      let userRole = roles.find(role => role == "compliance_user")
      this.currentUserId = detail['id'];
      if (this.faqSub) {
        this.faqSub.unsubscribe()
      }
      this.faqSub = this.getFaq.subscribe()
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            return this.setAdmin.subscribe(result => {
              this.isAdmin = result;
            })
          })
        )
      )
    })
  )


  getFaq = this.http.get(environment.apiUrl + "/" + this.url + "/" + this.faqId + "/").pipe(
    concatMap(currRequest => {
      this.currentRequest = currRequest
      this.formValue.get("newFaq").patchValue({
        title: this.currentRequest.request_title,
        priorita: this.currentRequest.priority,
        dueDate: this.currentRequest.due_date,
        ambito: this.currentRequest.scope,
        motivazione: this.currentRequest.reason,
        perimetro: this.currentRequest.perimeter,
        struttura: this.currentRequest.structure_request,
        description: this.currentRequest.description
      })

      this.formValue.get("answerFaq").patchValue({
        text: this.currentRequest.compliance_answer,
        legal: this.currentRequest.legal_answer,
        privacy: this.currentRequest.privacy_answer,
        flagPrivacyAnswer: this.currentRequest.privacy_checkbox,
        flagLegalAnswer: this.currentRequest.legal_checkbox,
      })
      this.store.dispatch(setCurrentRequest({ currentRequest: currRequest }))
      this.stato = currRequest['status']
      this.attached_doc = [...this.currentRequest.document_attached]
      if (this.stato == 'NEW') {
        this.formValue.disable()
      }
      const attachmentObservables = currRequest['document_attached'].map(document => {
        return this.http.get<Attachment>(`${environment.apiUrl}/document/` + document + '/').pipe(
          map((result) => {
            const attachment: any = (({
              id,
              attachment,
              object_name,
              description,
              uploaded_by,
              document_title,

            }) => ({
              id,
              attachment,
              object_name,
              description,
              document_title,
              uploaded_by,
            }))(result);
            this.attachmentData.push(attachment)
            if (this.attachmentData.length > 1 && this.attachmentData[0].description == "") {
              this.attachmentData.splice(0, 1)
            }
          }));
      });
      return forkJoin(attachmentObservables);
    }),
    catchError(err => {
      if (this.url !== "faq") {
        this.url = "faq"
        if (this.faqSub) {
          this.faqSub.unsubscribe()
        }

        this.faqSub = this.getFaq.subscribe()
      }
      return of(1)
    })
  )


  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private userService: UserService,
    private store: Store,
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private initService: InitService
  ) {
  }

  //RICOMINCIA DA QUI DESCRIPTION DELL?ALLEGATO E MODIFICA

  ngOnInit() {
    this.store.select(selectRequestDataLoading).pipe(map(value => this.loading = value)).subscribe()

    this.adminSub = this.setAdmin.subscribe(result => {
      this.isAdmin = result;
    });

    this.tableColumn = Object.keys(this.attachmentData[0])
    this.formValue = this.fb.group({
      newFaq: {
        title: '',
        priorita: '',
        dueDate: '',
        ambito: '',
        perimetro: '',
        struttura: '',
        description: ''
      },
      answerFaq: {
        text: ''
      }
    })

    this.formValue.get("newFaq").patchValue({
      title: this.currentRequest.request_title,
      priorita: this.currentRequest.priority,
      dueDate: this.currentRequest.due_date,
      ambito: this.currentRequest.scope,
      perimetro: this.currentRequest.perimeter,
      struttura: this.currentRequest.structure_request,
      description: this.currentRequest.description
    })
    this.formValue.get("answerFaq").patchValue({
      text: this.currentRequest.compliance_answer,
    })

  }



  canAddAllegato() {
    let canAddAllegato: boolean;
    if (this.isAdmin == false) {
      if (this.stato == 'NEW') {
        canAddAllegato = false;
      } else if (this.stato == 'DRAFT') {
        canAddAllegato = false;
      }
    } else if (this.isAdmin == true) {
      if (this.stato == 'DRAFT') {
        canAddAllegato = true;
      } else {
        canAddAllegato = false;

      }
    }
    return canAddAllegato
  }

  addAttachment(attachment) {
    if (this.attachmentData.length > 0 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    //this.addingAttachment = true
    const attToShow: any = (({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }) => ({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }))(attachment);
    if (!this.attachmentData) {
      this.attachmentData = { ...attToShow }
    } else {
      this.attachmentData = [...this.attachmentData, attToShow]
    }
    this.isEmpty = false;
    this.newAttachment = [...this.newAttachment, attToShow]
  }

  removeAttachment(event) {
    if (event == 0 && this.attachmentData.length == 1) {
      this.attachmentData = [{
        document_title: "",
        object_name: "",
        description: "",
        uploaded_by: 0
      }]
      this.isEmpty = true
      this.checkAttachment(event)
    } else {
      this.checkAttachment(event)
    }
  }

  checkAttachment(position) {
    if (this.currentRequest.document_attached.length > 0) {
      let document = this.attachmentData.find(att => att.id == position)
      let index = this.attachmentData.findIndex(att => att.id == position)

      if (document) {
        this.attached_doc.splice(this.attached_doc.findIndex(id => id == document.id), 1)
        this.attachmentData.splice(index, 1)
        this.http.delete(`${environment.apiUrl}/document/${position}/`).pipe(take(1)).subscribe()
      } else {
        this.newAttachment.splice(position, 1)
        this.attachmentData.splice(position, 1)
      }
    } else {
      this.newAttachment.splice(position, 1)
      this.attachmentData.splice(position, 1)
    }
  }

  editAttachmentFromDB(event) {
    event.attachment['attachment'] = this.attachmentData[event.index].attachment
    this.attachmentData[event.index] = { ...event.attachment, id: this.attachmentData[event.index].id }
    this.isEmpty = false
  }


  disabledButton() {
    let disabled: boolean;
    if (this.isAdmin == false) {
      if (this.stato == 'NEW') {
        disabled = true;
      } else {
        disabled = false;
      }
    } else if (this.isAdmin == true) {
      if (this.stato == 'NEW') {
        disabled = true;
      } else {
        disabled = false;
      }
    }
    return disabled
  }


  setFilter(data) {
    this.isFilter = data
  }

  sendToBuisness() {
    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
    } else {
      this.newValue = this.formValue.controls['newFaq'].value
      let answerValue = this.formValue.controls['answerFaq'].value
      this.postData = {
        request_id: this.currentRequest.id,
        request_title: this.newValue['title'],
        priority: this.newValue['priorita'],
        scope: this.newValue['ambito'],
        perimeter: this.newValue['perimetro'],
        structure_request: this.newValue['struttura'],
        description: this.newValue['description'],
        requested_user: this.currentUserId,
        status: Status.NEW,
        document_attached: this.attached_doc,
        is_faq: true,
        comments: [],
        compliance_answer: answerValue['text'],
      };
      let attachmentSent;
      if (this.isEmpty == true) {
        attachmentSent = []
      } else {
        attachmentSent = [...this.attachmentData]
      }
      this.store.dispatch({ type: RequestActions.updateFaq, payload: { request: this.postData, attachments: attachmentSent, id: this.currentRequest.id } })


    }
  }

  sendFaq() {
    this.newValue = this.formValue.controls['newFaq'].value
    let answerValue = this.formValue.controls['answerFaq'].value
    this.postData = {
      request_id: this.currentRequest.id,
      request_title: this.newValue['title'],
      priority: this.newValue['priorita'],
      scope: this.newValue['ambito'],
      perimeter: this.newValue['perimetro'],
      structure_request: this.newValue['struttura'],
      description: this.newValue['description'],
      requested_user: this.currentUserId,
      status: Status.NEW,
      document_attached: this.attached_doc,
      is_faq: true,
      comments: [],
      compliance_answer: answerValue['text'],
    };

    let attachmentSent;

    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }

    this.store.dispatch({
      type: RequestActions.addNewFaq,
      faq: this.postData,
      attachments: attachmentSent

    });
  }

  save() {
    this.newValue = this.formValue.controls['newFaq'].value
    let answerValue = this.formValue.controls['answerFaq'].value
    this.postData = {
      request_id: this.currentRequest.id,
      request_title: this.newValue['title'],
      priority: this.newValue['priorita'],
      scope: this.newValue['ambito'],
      perimeter: this.newValue['perimetro'],
      structure_request: this.newValue['struttura'],
      description: this.newValue['description'],
      requested_user: this.currentUserId,
      status: Status.DRAFT,
      document_attached: this.attached_doc,
      is_faq: true,
      comments: [],
      compliance_answer: answerValue['text'],
    };

    let attachmentSent;

    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }
    this.store.dispatch({
      type: RequestActions.addNewFaq,
      faq: this.postData,
      attachments: attachmentSent

    });
  }

  saveFaqCompliance() {

    this.newValue = this.formValue.controls['newFaq'].value
    let answerValue = this.formValue.controls['answerFaq'].value
    this.postData = {
      request_id: this.currentRequest.id,
      request_title: this.newValue['title'],
      priority: this.newValue['priorita'],
      scope: this.newValue['ambito'],
      perimeter: this.newValue['perimetro'],
      structure_request: this.newValue['struttura'],
      description: this.newValue['description'],
      requested_user: this.currentUserId,
      status: Status.DRAFT,
      document_attached: this.attached_doc,
      is_faq: true,
      comments: [],
      compliance_answer: answerValue['text'],
    };

    let attachmentSent;

    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }
    this.store.dispatch({ type: RequestActions.updateFaq, payload: { request: this.postData, attachments: attachmentSent, id: this.currentRequest.id } })

  }

  ngOnDestroy() {
    if(this.adminSub){
      this.adminSub.unsubscribe()
    }
  }

}


