<div>
  <div>
    <app-flow></app-flow>
    <app-header-tab [isAdmin]="isAdmin" [filters]="filters" [canAddRequest]="isAdmin" [canExport]="isAdmin" [createFinding]="isAdmin"
       [title]="!isAdmin?'Elenco Finding a te assegnati': 'Elenco Finding'" ></app-header-tab>
    <br>

    <app-dynamic-table *ngIf="!(dataLoadedFinding$ | async)" [isAdmin]="isAdmin" [tableColumn]="tableColumn" [currentUserID]="currentUserID"
      [filters]="tableFilters" (loadingRequest)="isLoadingFinding($event)" (setFilters)="setFilters($event)"
      [isLoading]="isLoading" [tableCurrentData]="findingCurrentData" [tableData]="findingData" [isAllegato]="false"
      [size]="totalSize" [canSearch]="true" [isFinding]="true"></app-dynamic-table>
  </div>
</div>


<!--
<div *ngIf="isAdmin">
  <app-flow></app-flow>
  <app-header-tab [isAdmin]="isAdmin" [hasButton]="false" [createFinding]="true" [title]="'Elenco Findings'"></app-header-tab>
  <br>
  <div class="w-100">
    <app-dynamic-table [isAllegato]="false" [isFinding]="true"></app-dynamic-table>
  </div>
</div> -->
