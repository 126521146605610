import { AfterContentInit, Component, OnChanges, inject } from '@angular/core';
import { Observable, Subscription, map, share } from 'rxjs';
import { AdminService } from 'src/app/utils/services/admin.service';

@Component({
  selector: 'app-scope-list',
  templateUrl: './scope-list.component.html',
  styleUrls: ['./scope-list.component.scss']
})
export class ScopeListComponent{

  adminService = inject(AdminService)
  scopes:string[] = []
  scopeSub:Subscription 
  scopeObs:Observable<string[]> = this.adminService.scopes.pipe(
    map(scopes=>{
      console.log("PIPE OPERATION ", scopes);
      this.scopes = scopes
      return this.scopes
    })
  )

  loadItemsLazy(event){
    console.log(event)
  }

  // ngOnDestroy(){
  //   if(this.scopeSub){
  //     console.log("Unsubscribed ! Bye bye !")
  //     this.scopeSub.unsubscribe()
  //   }
  // }
}
