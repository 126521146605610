import { createAction, props } from '@ngrx/store';

export const enum ExportActions {
    exportFile = '[Export] export',
    exportFileSuccess = '[Export] export Success',
    uploadTemplate='[Export] Upload template',
    uploadTemplateSuccess='[Export] Upload template success',
    uploadTemplateFailure='[Export] Upload template failure'
  }

  export const exportFile = createAction(
    ExportActions.exportFile,
    props<{
      responses: any
    }>()
  );
  
  export const exportFileSuccess = createAction(
    ExportActions.exportFileSuccess,
  );
  
  
  export const uploadTemplate = createAction(
    ExportActions.uploadTemplate,
    props<{
      data: any
    }>()
  );

  export const uploadTemplateSuccess = createAction(
    ExportActions.uploadTemplateSuccess,
   
  );

  export const uploadTemplateFailure = createAction(
    ExportActions.uploadTemplateFailure,
   
  );