import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { FindingActions, setFindings } from 'src/app/utils/store/finding/finding.action';
import { FindingService } from 'src/app/utils/services/findings.service';
import { selectFindingDataLoading, selectFindingsData } from 'src/app/utils/store/finding/finding.selector';
import { catchError, debounceTime, forkJoin, map, Observable, of, Subject, Subscription, take } from 'rxjs';
import { DbFinding } from 'src/app/data/models/Finding';
import { selectCurrentIDandRole } from 'src/app/utils/store/user/user.selector';
import { InitService } from 'src/app/utils/services/init.service';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { ExportFilter } from 'src/app/data/models/Filter';
import { UserService } from 'src/app/utils/services/user.service';

export enum TableFinding {
  scope = "Ambito",
  intervention_type = "Tipologia di intervento",
  status = "Stato",
  creation_date = "Data Creazione",
  last_update = "Data Ultimo Aggiornamento",
  due_date = "Data di scadenza",
}
@Component({
  selector: 'app-table-requests-follow-up',
  templateUrl: './table-requests-follow-up.component.html',
  styleUrls: ['./table-requests-follow-up.component.scss']
})
export class TableRequestsFollowUpComponent {

  //commento
  isAdmin: boolean = null;


  findingData: DbFinding[] = [{
    id: null,
    scope: "",
    // user_assigned: null,
    group_assigned:null,
    status: "",
    creation_date: "",
    last_update: "",
    due_date: "",
    due_date_planned:"",
    count_due_date_planned:null,
    expired: false,
  }];

  tableFilters: string[] = [
    "scope",
    "perimeter",
    "intervention_type",
    "status",
    "due_date",
    "due_date_planned",
    "expired"
  ]
  
  tableColumn: string[] = [
    "id",
    "scope",
    "perimeter",
    "intervention_type",
    // "user_assigned",
    "group_assigned",
    "status",
    "creation_date",
    "last_update",
    "due_date",
    "due_date_planned",
    "count_due_date_planned",
    "expired"

  ]
  findingCurrentData: DbFinding[] = [];
  //loadingRequest: Observable<boolean>;
  sub: Observable<any>;
  page: number = 0;
  pageSize: number = 0;
  totalSize: number = 0;
  isLoading: boolean = false;
  filters: ExportFilter = { request:false , filters: undefined};
  subscription: Subscription;
  currentUserID: number
  // user_assigned:any
  gorup_assigned:any


  dataLoadedFinding$ = this.store.select(selectFindingDataLoading).pipe(
    map(loading => {
      this.isLoading = loading
      if (this.isLoading == false) {
        this.dataFinding$.pipe(take(1)).subscribe()
      }
    }));



  dataFinding$ = this.store.select(selectFindingsData).pipe(
    map((findingData) => {
      let findings = findingData?.['findings'] ? findingData['findings'] : []
      this.totalSize = findingData?.['size'] ? findingData['size'] : 0
      let findingsTmp: DbFinding[] = []
      let findingsTmp1: DbFinding[] = []
      if (findings.length == 0) {
        // findingsTmp = []

        findingsTmp.push({
          id: null,
          scope: "",
          // user_assigned: null,
          group_assigned:null,
          status: "",
          creation_date: "",
          last_update: "",
          due_date: "",
          expired: false,
        })

      } else {
        findings.forEach(element => {
          findingsTmp1.push(element)
        })
        findings.forEach(element => {
          // let newDescription = { title: element.request_title, description: element.description.toString() }
          // element.description = { ...newDescription }

          const finding: DbFinding = (({
            id,
            risk_id,
            scope,
            // user_assigned,
            group_assigned,
            email_assigned,
            intervention_type,
            problem_encountered,
            action_required,
            fullname_assigned,
            perimeter,
            status,
            creation_date,
            last_update,
            due_date,
            due_date_planned,
            count_due_date_planned,
            comments,
            expired,
          }) => ({
            id,
            risk_id,
            scope,
            // user_assigned,
            group_assigned,
            email_assigned,
            intervention_type,
            problem_encountered,
            action_required,
            fullname_assigned,
            perimeter,
            status,
            creation_date,
            last_update,
            due_date,
            due_date_planned,
            count_due_date_planned,
            comments,
            expired,
          }))(element);
          findingsTmp.push(finding)
        })
      }
      this.findingData = [...findingsTmp]

      this.isLoading = false
      this.findingCurrentData = [...findingsTmp1]
      return this.findingData
    })
  )

  adminSub$: Observable<any> = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      let userRole = roles.find(role => role == "audit_user")
      this.currentUserID = detail['id']
      this.tableColumn = userRole ? [
        "id",
        "scope",
        "perimeter",
        "intervention_type",
        'action_required',
        'problem_encountered',
        // "user_assigned",
        "group_assigned",
        "status",
        "creation_date",
        "last_update",
        "due_date",
        "due_date_planned",
        "count_due_date_planned",
        "comments",
        "expired",
      ] : [
        "id",
        "scope",
        "perimeter",
        "intervention_type",
        'action_required',
        'problem_encountered',
        "status",
        "creation_date",
        "last_update",
        "due_date",
        "due_date_planned",
        "count_due_date_planned",
        "comments",
        "expired",
      ]
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            this.subscription.unsubscribe()
            this.subscription = this.adminSub$.subscribe(result => {
              this.isAdmin = result;
            })
          })
        )
      )
    })
  )

  constructor(private store: Store, private initService: InitService, private userService:UserService) { }

  ngOnInit() {

    this.subscription = this.adminSub$.subscribe(result => {
      this.isAdmin = result;
   })


  }

  isLoadingFinding(event) {

    let page = (event.first + event.rows) / event.rows
    if (this.findingData.length !== 0) {
      this.isLoading = true;
    }

    if (this.isLoading) {
      this.store.dispatch(setFindings(({ page: page, pageSize: event.rows, filters: event.filters, ordering: { field: event.sortField, sortOrder: event.sortOrder } })))
    }
  }

  setFilters(event) {
    this.filters = {request:false, filters: {...event} }
  }

  ngOnDestroy() {
    this.findingData = []
    this.findingCurrentData = []
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}



