<app-header></app-header>
<div class="main-home flex flex-column ">
    <div class="pt-6 pb-2">
        <app-header-tab [title]="'Ecco le tue Notifiche'"></app-header-tab>
    </div>
    <div class="flex justify-content-center ">
        <div class="home relative z-1 w-full">
            <div class=" z-1">
                <div class="flex flex-row py-2 header">
                    <div class=" flex col-2 p-0 pl-1 text-left ">
                        <p>Titolo</p>
                    </div>
                    <div class=" flex col-5 p-0  text-left">
                        <p>Descrizione</p>
                    </div>
                    <div class=" flex col-2 p-0 justify-content-center">
                        <p class="text-center">Username</p>
                    </div>
                    <div class=" flex col-2 p-0 justify-content-center">
                        <p class="text-center">Data Creazione</p>
                    </div>
                    <div class=" flex col-1 p-0 text-center">
                        <p></p>
                    </div>
                    <!-- <div class="col-2 p-0 text-center">
                        <p>Accetta / Rifiuta</p>
                    </div> -->
                </div>
            </div>
            <div class=" notify-home  z-1">
                <div class=" notify ">
                    <div class="content z-1">
                        <div *ngFor="let notify of notifies" class="m-2">
                            <div class="flex flex-row notifica notify-hover my-2 justify-content-center ">
                                <div class=" flex col-2 p-0  text-left">
                                    <p>{{notify.request_title}}</p>
                                </div>
                                <div class=" flex col-5 p-0  text-left ">
                                    <p>{{notify.message}}</p>
                                </div>
                                <div class=" flex col-2 p-0 justify-content-center h-full">
                                    <p class="">{{notify.username}}</p>
                                </div>
                                <div class=" flex col-2 p-0 justify-content-center">
                                    <p class="text-center"> {{notify.creation_date}}</p>
                                </div>
                                <div class=" flex col-1 p-0 text-center justify-content-center">
                                    <p> <button pButton pRipple type="button" class="apri text-center p-fontMedium "
                                            (click)="openRequest(notify.id_track,notify.flag)">Apri</button></p>
                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex footer justify-content-end align-items-center px-5">
                    <button pButton pRipple type="button" class="return p-fontMedium text1 "
                        [routerLink]="['/home']">Torna
                        Indietro
                    </button>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="flex w-full ">
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
    viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
    <defs>
    <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
    </defs>
    <g class="parallax">
    <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
    <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
    <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
    <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
    </g>
    </svg>
</div>
<!-- <img class="background z-0 w-full h-full fixed bg-auto" src="./assets/images/vector_home.svg"> -->
