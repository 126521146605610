<div class="w-full flex flex-column">
  <app-flow></app-flow>
  <app-header-tab [title]="titleForm"></app-header-tab>
  <form [formGroup]="formValue">
    <app-form-new-faq formControlName="newFaq" [invalidBoolean]="invalidBoolean"></app-form-new-faq>
    <app-answer [isCompliance]='true' formControlName="answerFaq" [isUser]="true"></app-answer>
  </form>
  <app-dynamic-table [currentUserID]="currentUserId" (editAttachment)="editAttachment($event)" [canFilter]="false"
    (attachmentAdded)="addAttachment($event)" [tableColumn]="tableColumn" (removeAttachment)="removeAttachment($event)"
    [tableData]="attachmentData" [canAddAttachment]="canAddAttachment" [isAllegato]="true" [isDisabled]="isDisabled"
    [size]="size"></app-dynamic-table>
  <!-- inserire riga 23,24 in tutti gli allegati sdove stanno i tre button filtri -->
</div>

<div>
  <div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
    <div class="flex justify-content-between gap-3">
      <button pButton pRipple type="button" class="return p-fontMedium "[disabled]="loading" [routerLink]="['../']">Annulla
      </button>
      <button pButton pRipple type="submit" class="save p-fontMedium "[disabled]="loading" (click)="save()">Salva Bozza
      </button>
      <button pButton pRipple type="button" class="send p-fontMedium "[disabled]="loading" (click)="sendFaq()">Pubblica FAQ
      </button>
    </div>
  </div>
</div>
