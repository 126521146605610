<div *ngIf="!isAdmin; else admin">
  <div class="w-full flex flex-column">
    <app-flow></app-flow>
    <div *ngIf="stato=='REFUSED'" class="flex justify-content-center" style="margin: 0 0.5%; padding: 0.5%;">
      <div class="flex flex-column card  p-3 cardRefused ">
        <div class="flex icon justify-content-center ">
          <i class="fa-solid fa-xl fa-circle-exclamation m-1 my-3"></i>
        </div>
        <div>
          <div class="font-bold text-center mb-3">Richiesta Rifiutata da Compliance!</div>
          <div class="Motivazione">
            <div class="text-sm font-bold">Motivazione:</div>
            <div class="text-xs">{{currentRequest.final_answer}}</div>
          </div>
        </div>
      </div>
    </div>
    <!-- <app-follow-timeline *ngIf="stato !== 'REFUSED'" [stato]="stato" [isCompliance]="true"></app-follow-timeline> -->
    <div *ngIf="stato">
      <!-- <app-header-tab [isAdmin]="isAdmin" (response)="respond($event)" [canChat]="canChat()" [stato]="stato"
        [title]="getTitle()"></app-header-tab> -->
      <app-header-tab [isAdmin]="isAdmin" (response)="respond($event)" [canChat]="canChat()" [stato]="stato"
        [title]="getTitle()"></app-header-tab>
    </div>
    <form [formGroup]="formValue">
      <app-form-new-request formControlName="newRequest" [stato]="stato" [isAdmin]="isAdmin"></app-form-new-request>
      <!-- <app-answer [isUser]="true" [isCompliance]="false" formControlName="answer"
        [disable]="disabledAnswer()"></app-answer> -->
      <app-answer *ngIf="stato" [stato]="stato" [isUser]="true" [isCompliance]="false" [isLegal]="legalRole" [isPrivacy]="privacyRole"
        formControlName="answer" [disable]="disabledAnswer()"></app-answer>
    </form>
    <app-dynamic-table [currentUserID]="currentUserId" [isAdmin]="isAdmin" [canFilter]="canFilter"
      (editAttachmentEvent)="editAttachmentFromDB($event)" (removeAttachment)="removeAttachment($event)"
      (attachmentAdded)="addAttachment($event)" [tableData]="attachmentData" [tableColumn]="tableColumn"
      [isAllegato]="true" [isDisabled]="disabledButton()" [canAddAttachment]="canAddAllegato()"></app-dynamic-table>
    <div class="flex justify-content-end gap-5" style=" margin: 0 0.5%;  padding: 0.5%;">
      <div class="flex justify-content-between">
        <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']"
          [disabled]="loading">
          Torna Indietro
        </button>
        <button *ngIf="stato == 'DRAFT'" pButton pRipple type="button" class="save p-fontMedium m-2"
          [disabled]="loading" (click)="saveRequestCompliance()">Salva e Chiudi
        </button>
        <button *ngIf="stato == 'DRAFT'" pButton pRipple type="button" class="send p-fontMedium m-2 mr-0"
          [disabled]="loading" (click)="sendToCompliance()">Conferma e Invia
        </button>
        <button *ngIf="(legalRole == true || privacyRole == true) && stato == 'IN_CHARGE'" pButton pRipple type="button"
          class="send p-fontMedium m-2 mr-0" [disabled]="loading" (click)="saveRequest()">Invia
        </button>
      </div>
    </div>
  </div>
</div>

<ng-template #admin>
  <div class="w-full flex flex-column">
    <app-flow></app-flow>
    <div>
      <div>
        <div *ngIf="stato=='REFUSED'" class="flex justify-content-center" style="margin: 0 0.5%; padding: 0.5%;">
          <div class="flex flex-column card  p-3 cardRefused ">
            <div class="flex icon justify-content-center ">
              <i class="fa-solid fa-xl fa-circle-exclamation m-1 my-3"></i>
            </div>
            <div>
              <div class="font-bold text-center mb-3">Richiesta Rifiutata da Compliance!</div>
              <div class="text-sm font-bold ml-2">Motivazione:</div>
              <div class="Motivazione">
                <div class="text-xs">{{currentRequest.final_answer}}</div>
              </div>
            </div>
          </div>
        </div>
        <app-follow-timeline [timeLineStatus]="timelineStatus" *ngIf="stato;else spinner" [stato]="stato"
          [size]=3></app-follow-timeline>
      </div>
    </div>
    <app-header-tab [isAdmin]="isAdmin" (response)="respond($event)" [title]="getTitle()" [seeRequestCompliance]="true"
      [isFinding]="false" [stato]="stato"></app-header-tab>
    <app-info-request [readOnly]="true"></app-info-request>
    <form [formGroup]="formValue">
      <app-form-new-request formControlName="newRequest" [stato]="stato"></app-form-new-request>
      <!-- <app-answer [isCompliance]="true" [isUser]="false" formControlName="answer"
        [disabled]="disabledAnswer()"></app-answer> -->
      <app-answer *ngIf="stato" [stato]="stato" [isUser]="false" [isCompliance]="true" [isLegal]="legalRole" [isPrivacy]="privacyRole"
        formControlName="answer" [disable]="disabledAnswer()"></app-answer>
    </form>
    <app-dynamic-table [isAdmin]="isAdmin" [currentUserID]="currentUserId" [canFilter]="canFilter"
      (editAttachmentEvent)="editAttachmentFromDB($event)" (removeAttachment)="removeAttachment($event)"
      (attachmentAdded)="addAttachment($event)" [tableData]="attachmentData" [tableColumn]="tableColumn"
      [isAllegato]="true" [isDisabled]="disabledButton()" [stato]="stato" [exteranalFilter]="isFilter"
      [canAddAttachment]="canAddAllegato()"></app-dynamic-table>
    <div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
      <div>
        <button pButton pRipple type="button" class="return p-fontMedium m-2 mr-0 " [routerLink]="['../']"
          [disabled]="loading">Torna
          Indietro
        </button>
        <button pButton pRipple *ngIf="stato == 'IN_CHARGE'" type="button" class="send p-fontMedium m-2"
          [disabled]="loading" (click)="saveRequest()">Salva</button>
      </div>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <div class=" flex w-full timelineCustom justify-content-center align-items-center flex-column">
    <div class="flex w-50 justify-content-between">
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
    </div>
  </div>
</ng-template>