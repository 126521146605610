import { Injectable } from '@angular/core';
import { UserClass } from '../../data/models/User'
import { Store } from '@ngrx/store';
import { of, take } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class UserService {

  constructor(private store: Store, private keycloakService: KeycloakService, private http: HttpClient) { }


  getUserDetail(): Observable<any> {
    return this.http.get<any>(`${environment.apiUrl}/user_details/`, {
      headers: {
        Authorization: `Bearer ${this.keycloakService.getToken()}`
      }
    });
  }


  getBusinessUserFromId(id: number) {
    return this.http.get(`${environment.apiUrl}/business-user/${id}/`)
  }

  updateUserDetail(body: any): Observable<any> {
    return this.http.put<any>(`${environment.apiUrl}/user_details/`, body, {
      headers: {
        'Authorization': `Bearer ${this.keycloakService.getToken()}`,
      }
    });
  }

  getBusinessUserList(searchParam) {
    let search = ""
    if (searchParam) {
      search = "?search=" + searchParam
    }
    return this.http.get(`${environment.apiUrl}/business-users/${search}`)
  }

  //cambiare api
  getGroupList(searchParam) {
    let search = ""
    if (searchParam) {
      search = "?search=" + searchParam
    }
 
    return this.http.get(`${environment.apiUrl}/admin-panel/groups/${search}`)
  }

  

  addUser(name: any, lastname: any, username: any, password: any, email: any, role: any) {
    let postData = {
      email: email,
      first_name: name,
      last_name: lastname,
      username: username,
      password: password,
      role: role
    }

    return this.http.post(`${environment.apiUrl}/admin-panel/users/`, postData);
  }
}
