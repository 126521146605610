<app-header></app-header>
<p-toast [style]="{'z-index':'999999'}" position="top-center"></p-toast>
<div class="home flex align-items-center flex-column  relative z-1">
  <div class="profile-card flex flex-row z-1">
    <div class="info p-5">
      <div class="mb-3 text-center w-full">
        <p class="title">Il tuo Profilo</p>
      </div>
      <div class="flex relative">
        <div class="picture ">
          <div class="image-container">
            <img [src]="'./assets/images/img_avatar.png'"> <br />
          </div>
        </div>
        <!-- <button pButton pRipple type="submit" class="add_img icon-plus" (click)="fileInput.click()"><i
            class="fa-solid fa-circle-plus "></i>
          <input #fileInput type="file" (change)="dropfile($event)" style="display: none" enctype="multipart/form-data" accept=".jpg, .png, .jpeg"/>
        </button> -->
      </div>
      <div class=" flex mt-2 text-center ">
        <p class=" text-desc ">{{username}}</p>
      </div>
      <div class=" p-0 flex flex-column align-items-start mt-5 w-full h-auto">
        <div class="p-0">
          <p class="text1 subtitle p-0">Nome:</p>
          <p class="text-desc">{{firstName}}
        </div>
        <div class="mt-2">
          <p class="text1 subtitle p-0">Cognome:</p>
          <p class="text-desc">{{lastName}}</p>
        </div>
        <div class="mt-2">
          <p class="text1 subtitle p-0">Email:</p>
          <p class="text-desc">{{email}}</p>
        </div>
      </div>
    </div>
    <div class="flex content">
      <div class="flex flex-column h-full  w-full ">
        <form [formGroup]="formValue">
          <p-tabView [(activeIndex)]="varIndex">
            <p-tabPanel header="Profilo" class="p-0">
              <div class="w-full">
                <div class=" formContainer border-round-sm w-full">
                  <div class="formHeader border-round-top-sm font-bold flex justify-content-center">
                    <label class="title text-left">Modifica i tuoi dati</label>
                  </div>
                  <div class="flex flex-column justify-content-center py-4">
                    <div class="form-input">
                      <p class="text1">Nome:</p>
                      <input style="width: 100%; height: 2.1rem;" formControlName="name" pInputText class=" text1"
                        type="text" id="">
                      <div class="relative m-0 p-0"
                        *ngIf="formValue.controls['name'].invalid && formValue.controls['name'].touched || formValue.get('name')?.value.length >= 50">
                        <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci il nome ( massimo 50
                          caratteri ).
                        </p>
                      </div>
                    </div>
                    <div class="form-input">
                      <p class=" text1">Cognome:</p>
                      <input style="width: 100%; height: 2.1rem;" formControlName="surname" pInputText class=""
                        type="text" id="">
                      <div class="relative m-0 p-0"
                        *ngIf="formValue.controls['surname'].invalid && formValue.controls['surname'].touched || formValue.get('surname')?.value.length >= 50">
                        <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci il cognome ( massimo 50
                          caratteri ).
                        </p>
                      </div>
                    </div>
                    <div class="form-input">
                      <p class="text1">Email:</p>
                      <input style="width: 100%; height: 2.1rem;" formControlName="email" pInputText class=""
                        type="email">
                      <div class="relative m-0 p-0"
                        *ngIf="formValue.controls['email'].invalid && formValue.controls['email'].touched || formValue.get('email')?.value.length >= 50">
                        <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci l'email ( massimo 50
                          caratteri ).
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel header="Password" style="min-height: 400px ;">
              <div>
                <div class="border-round-sm w-full">
                  <div class="border-round-top-sm font-bold flex justify-content-center">
                    <label class="title text-left">Cambia Password </label>
                  </div>
                  <div class="flex flex-column justify-content-center py-4">
                    <div class="form-input">
                      <p class=" text1">Nuova password:</p>
                      <p-password class="custom" formControlName="password2" [toggleMask]="true"></p-password>
                      <div class="relative m-0 p-0"
                        *ngIf="formValue.controls['password2'].invalid && formValue.controls['password2'].touched || formValue.get('password2')?.value.length >= 40">
                        <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci la nuova password ( massimo
                          40
                          caratteri ). </p>
                      </div>
                    </div>
                    <div class="form-input">
                      <p class=" text1">Conferma nuova password:</p>
                      <p-password class="custom" formControlName="newPassword" [toggleMask]="true"></p-password>
                      <div class="relative m-0 p-0"
                        *ngIf="formValue.controls['newPassword'].invalid && formValue.controls['newPassword'].touched || formValue.get('newPassword')?.value.length >= 40">
                        <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci la nuova password ( massimo
                          40
                          caratteri ). </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
          <p-dialog [closable]="false" [(visible)]="display" [modal]="true"
            [style]="{'width': '20vw', 'min-width': '20rem', 'height': 'auto' }" [draggable]="false"
            [resizable]="false">
            <ng-template pTemplate="header">
              <div class=" flex justify-content-center  align-item-center  w-full h-full">
                <i class=" text-center  p-2 fa-solid fa-xl fa-circle-exclamation "></i>
              </div>
            </ng-template>
            <ng-template pTemplate="content ">
              <div class=" flex w-full  ">
                <h5 class="w-full  text-center">Per confermare le modifiche inserire la password corrente</h5>
              </div>
              <div class=" flex w-full pt-3">
                <span class="p-float-label w-full">
                  <input style="width: 100%;" formControlName="password" pInputText class="p-input w-full "
                    type="password">
                  <label>Password attuale</label>
                </span>
              </div>

            </ng-template>
            <ng-template pTemplate="footer">
              <div class="flex justify-content-between w-full gap-3">
                <button pButton pRipple type="button" class="return w-full" [disabled]="loadingUpdateDetailUser|async" (click)="return()" label="Annulla">
                </button>
                <button pButton pRipple type="button" class="send m-0 w-full" [disabled]="loadingUpdateDetailUser|async"
                  (click)="updateUserData()" label="Conferma">
                </button>
              </div>
            </ng-template>
          </p-dialog>
        </form>
        <div class="flex  flex-row w-full h-75 p-3 mt-0 gap-2 justify-content-between align-items-end">
          <button pButton pRipple type="button" class=" return w-full text-center fontMedium text1 "
            [routerLink]="['/home']">
            <p class="text-center w-full">Torna indietro</p>
          </button>
          <button pButton pRipple type="button" class="send w-full flex fontMedium text1 " (click)="sendToSuccess()">
            <p class="text-center w-full">Salva Modifiche</p>
          </button>
        </div>
      </div>
    </div>
  </div>
  <div class="flex w-full absolute wave">
    <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
        <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g class="parallax">
        <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
        <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
        <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
        <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
    </svg>
  </div>
</div>