import { Component, inject } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router, UrlSegment } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, Subscription, map, of, take, throwError } from 'rxjs';
import { AdminService } from 'src/app/utils/services/admin.service';
import { DropdownService } from 'src/app/utils/services/dropdown.service';
import { AdminActions } from 'src/app/utils/store/admin/admin.actions';
import { selectBooleanButton, selectInterventionType, selectPerimeters, selectPriority, selectResidualRisks, selectScopes } from 'src/app/utils/store/admin/admin.selector';

export type MenuTabItems = {
  label: string;
  icon?: string;
}

export interface DropDown {
  [id: string]: string[]
}
export interface ListDropDown {
  [id: string]: string
}

@Component({
  selector: 'app-dropdown-selector',
  templateUrl: './dropdown-selector.component.html',
  styleUrls: ['./dropdown-selector.component.scss'],

})
export class DropdownSelectorComponent {

  title: string;
  dropdownOptions: any[];
  dropdown: DropDown;
  isDropdown: boolean = false;
  url: string = "/admin-dash/dropdown-manager/dropdown"
  listDropdown: ListDropDown[] = [];
  perimetro: any[]
  rischio: any[]
  selectedOptions: string
  subscription: Subscription;




  headers: MenuTabItems[];
  activeIndex: MenuTabItems;
  router = inject(Router);
  nomeOk: string;

  value: string
  paths: any[] = []
  listCopy: ListDropDown[];


  constructor(private store: Store, private dropdownService: DropdownService, private activatedRoute: ActivatedRoute) {

    this.dropdownOptions = [
      'Ambito',
      'Perimetro',
      'Rischio',
      'Priorità',
      'Tipologia intervento'
    ];

  }


  ngOnInit() {

    this.activatedRoute.queryParams.subscribe(param => {
      this.selectedOptions = param['id']
      console.log("parametro", param)
    })



    if (this.selectedOptions == 'Ambito') {
      this.store.select(selectScopes).subscribe(data => {
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }

      )
    } else if (this.selectedOptions == 'Perimetro') {
      this.store.select(selectPerimeters).subscribe(data => {
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }
      )
    } else if (this.selectedOptions == 'Rischio') {
      this.store.select(selectResidualRisks).subscribe(data => {
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]

      }
      )
    } else if (this.selectedOptions == 'Priorità') {
      this.store.select(selectPriority).subscribe(data => {
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]

      }
      )
    } else if (this.selectedOptions == 'Tipologia intervento') {
      this.store.select(selectInterventionType).subscribe(data => {
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]

      }
      )
    }

  }





  changeDropdownItems(event) {

    this.listDropdown = []
    this.title = event

    this.router.navigate([this.url, event], { queryParams: { id: event } })

    if (event == 'Ambito') {
      this.value = 'scope'
      //viene lanciato due volte probabile da vedere il ciclo
      this.store.dispatch({ type: AdminActions.setScopes, field: this.value })
      this.store.select(selectScopes).subscribe(data =>{
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }
      )
    } else if (event == 'Perimetro') {
      this.value = 'perimeter'
      this.store.dispatch({ type: AdminActions.setPerimeters, field: this.value })
      this.store.select(selectPerimeters).subscribe(data =>{
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }
      )
    } else if(event == 'Rischio'){
      this.value = 'residualrisk'
      this.store.dispatch({ type: AdminActions.setResidualRisks, field: this.value })
      this.store.select(selectResidualRisks).subscribe(data =>{
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }
      )
    }else if(event == 'Priorità'){
      this.value = 'priority'
      this.store.dispatch({ type: AdminActions.setPriority, field: this.value })
      this.store.select(selectPriority).subscribe(data =>{
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }
      )
    }else if(event == 'Tipologia intervento'){
      this.value = 'interventiontype'
      this.store.dispatch({ type: AdminActions.setInterventionType, field: this.value })
      this.store.select(selectInterventionType).subscribe(data =>{
        (this.listDropdown = data.map(item => ({ id: item['id'], name: item['name'] })))
        this.listCopy = [...this.listDropdown]
      }
      )
    }


    // this.dropdownService.getDropDown(this.value).pipe(take(1)).subscribe(value => {
    //   this.listDropdown = value.map(item => ({ id: item.id, name: item.name }));
    //   console.log("ECCO", this.listDropdown)
    //   console.log("ECCO", this.value)

    // }
    // )


    console.log("Event received 2 ! -> ", this.listDropdown)

  }

  addItem(event) {
    console.log(this.value)
    if (this.value == 'scope') {
      this.store.dispatch({ type: AdminActions.addScope, field: this.value, data: event })
    } else if (this.value == 'perimeter') {
      this.store.dispatch({ type: AdminActions.addPerimeter, field: this.value, data: event })
    } else if(this.value == 'residualrisk') {
      this.store.dispatch({ type: AdminActions.addResidualRisks, field: this.value, data: event })
    }else if(this.value == 'priority') {
      this.store.dispatch({ type: AdminActions.addPriority, field: this.value, data: event })
    }else if(this.value == 'interventiontype') {
      this.store.dispatch({ type: AdminActions.addInterventionType, field: this.value, data: event })
    }
    // this.dropdownService.addField(this.value, event).pipe(take(1)).subscribe()
    console.log("api", event)
  }

  removeItem(event) {
    if (this.value == 'scope') {
      this.store.dispatch({ type: AdminActions.deleteScope, field: this.value, data: event['id'] })
    } else if (this.value == 'perimeter') {
      this.store.dispatch({ type: AdminActions.deletePerimeter, field: this.value, data: event['id'] })
    } else  if (this.value == 'residualrisk')  {
      this.store.dispatch({ type: AdminActions.deleteResidualRisks, field: this.value, data: event['id'] })
    }else  if (this.value == 'priority')  {
      this.store.dispatch({ type: AdminActions.deletePriority, field: this.value, data: event['id'] })
    }else  if (this.value == 'interventiontype')  {
      this.store.dispatch({ type: AdminActions.deleteResidualRisks, field: this.value, data: event['id'] })
    }
  }

  loadEvent(event) {
    if (event.serch !== "") {
      this.listDropdown = this.listCopy.filter(item => item['name'].toLowerCase().includes(event.search.toLowerCase()))
      console.log("dentro if",this.listDropdown)
    } else {
      this.listDropdown = [...this.listCopy]
      console.log(this.listDropdown)
    }
  }

}


