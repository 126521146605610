import { NgModule } from '@angular/core';
import { InputTextModule } from 'primeng/inputtext';
import { ButtonModule } from 'primeng/button';
import { CheckboxModule } from 'primeng/checkbox';
import { CardModule } from 'primeng/card';
import { TableModule } from 'primeng/table';
import { ChipModule } from 'primeng/chip';
import { InputTextareaModule } from 'primeng/inputtextarea';
import { PaginatorModule } from 'primeng/paginator';
import { DropdownModule } from 'primeng/dropdown';
import { OverlayPanelModule } from 'primeng/overlaypanel';
import { SidebarModule } from 'primeng/sidebar';
import { MenubarModule } from 'primeng/menubar';
import { ToastModule } from 'primeng/toast';
import { PanelModule } from 'primeng/panel';
import { StepsModule } from 'primeng/steps';
import { TimelineModule } from 'primeng/timeline';
import { DialogModule } from 'primeng/dialog';
import { TabViewModule } from 'primeng/tabview';
import { TabMenuModule } from 'primeng/tabmenu';
import { ProgressSpinnerModule } from 'primeng/progressspinner';
import { CalendarModule } from 'primeng/calendar';
import { SkeletonModule } from 'primeng/skeleton';
import { BadgeModule } from 'primeng/badge';
import { PasswordModule } from 'primeng/password';
import { AutoCompleteModule } from 'primeng/autocomplete';
import { InputSwitchModule } from 'primeng/inputswitch';
import { TooltipModule } from 'primeng/tooltip';
import { VirtualScrollerModule } from 'primeng/virtualscroller';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AccordionModule } from 'primeng/accordion';
import { MultiSelectModule } from 'primeng/multiselect';
import { MenuModule } from 'primeng/menu';
import { CarouselModule } from 'primeng/carousel';


// import { MenuItem } from 'primeng/api';

//commento
@NgModule({
        exports: [
                InputTextModule,
                ButtonModule,
                CheckboxModule,
                CardModule,
                TableModule,
                ChipModule,
                InputTextareaModule,
                PaginatorModule,
                DropdownModule,
                OverlayPanelModule,
                SidebarModule,
                MenubarModule,
                ToastModule,
                PanelModule,
                StepsModule,
                TimelineModule,
                DialogModule,
                TabViewModule,
                ProgressSpinnerModule,
                CalendarModule,
                SkeletonModule,
                BadgeModule,
                PasswordModule,
                AutoCompleteModule,
                InputSwitchModule,
                TooltipModule,
                TabMenuModule,
                VirtualScrollerModule,
                PanelMenuModule,
                AccordionModule,
                MultiSelectModule,
                MenuModule,
                CarouselModule
        ],
})
export class AppPrimeNGModule { }
