import {KeycloakService} from 'keycloak-angular';
import { environment } from 'src/environments/environment';


export function initializeKeycloak(keycloak: KeycloakService): () => Promise<boolean> {
    return () =>
      keycloak.init({
        config: {
          url: environment.keycloakUrl,
          realm: 'mooney',
          clientId: 'angular-app'
        },
        initOptions: {
            checkLoginIframe: true,
            checkLoginIframeInterval: 30,
            onLoad: 'check-sso',
            silentCheckSsoRedirectUri:
              environment.feUrl + '/assets/silent-check-sso.html'
        },
        loadUserProfileAtStartUp: true,
      });
    }