import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription, catchError, concatMap, debounceTime, forkJoin, interval, map, merge, of, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { RequestService } from 'src/app/utils/services/request.service';
import { selectFaqDataLoading, selectFaqsSize, selectFaqsData } from 'src/app/utils/store/request/request.selector';
import { setFaqs, setRequests } from 'src/app/utils/store/request/request.action';
import { DbRequest } from 'src/app/data/models/Request';
import { selectUserRole } from 'src/app/utils/store/user/user.selector';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';
import { Filter } from 'src/app/data/models/Filter';

export enum TableRequest {
  priority = "Priorita'",
  due_date = "Data di scadenza",
  last_update = "Data Ultimo Aggiornamento",
  creation_date = "Data Creazione",
  status = "Stato",
  perimeter = "Perimetro",
  scope = "Ambito",
  structure_request = "Struttura richiedente",
}



@Component({
  selector: 'app-table-faq',
  templateUrl: './table-faq.component.html',
  styleUrls: ['./table-faq.component.scss']
})
export class TableFaqComponent {
  isAdmin: boolean;
  requestData: DbRequest[] = [{
    scope: "",
    perimeter: "",
    description: "",
    status: "",

  }];
  tableFilters: string[] = [
    "scope",
    "perimeter",
  ]
  tableColumn: string[] = [
    "scope",
    "perimeter",
    "description",]
  requestCurrentData: DbRequest[] = [];
  //loadingRequest: Observable<boolean>;
  sub: Observable<any>;
  page: number = 0;
  pageSize: number = 0;
  totalSize: number = 0;
  isLoading: boolean = false;
  filters: Filter;
  subscription: Subscription

  dataLoaded$ = this.store.select(selectFaqDataLoading).pipe(
    map(loading => {
      this.isLoading = loading
      if (this.isLoading == false) {
        this.data$.pipe(take(1)).subscribe()
      }
    }));

  data$ = this.store.select(selectFaqsData).pipe(
    map((requestData) => {
      let requests = requestData['faqs']
      this.totalSize = requestData['size']
      let requestsTmp: DbRequest[] = []
      let requestsTmp1: DbRequest[] = []
      if (requests.length == 0) {
        requestsTmp.push({
          scope: "",
          perimeter: "",
          description: "",
          structure_request: "",
          status: "",
          priority: "",
          creation_date: "",
          last_update: "",
          due_date: "",
          comments: []
        })

      } else {
        requests.forEach(element => {
          requestsTmp1.push(element)
        })
        requests.forEach(element => {
          // let newDescription = { title: element.request_title, description: element.description.toString() }
          // element.description = { ...newDescription }
          const request: DbRequest = (({
            id,
            requested_user,
            scope,
            perimeter,
            description,
            structure_request,
            status,
            priority,
            creation_date,
            last_update,
            due_date,
            comments,
            expired
          }) => ({
            id,
            requested_user,
            scope,
            perimeter,
            description,
            structure_request,
            status,
            priority,
            creation_date,
            last_update,
            due_date,
            comments,
            expired
          }))(element);
          requestsTmp.push(request)
        })
      }
      this.requestData = [...requestsTmp]

      this.isLoading = false
      this.requestCurrentData = [...requestsTmp1]
    })
  )


  adminSub$: Observable<any> = this.store.select(selectUserRole).pipe(
    take(1),
    map(role => {
      let roles: string[] = role
      let userRole = roles.find(role => role == "compliance_user")
      this.tableFilters = userRole ? [
        "scope",
        "perimeter",
        "status"
      ] : [
        "scope",
        "perimeter",
      ]
      this.tableColumn = userRole ? [
        "scope",
        "perimeter",
        "description",
        "status"
      ] : [
        "scope",
        "perimeter",
        "description",
      ]
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            this.subscription.unsubscribe()
            this.subscription = this.adminSub$.subscribe(result => {
              this.isAdmin = result
            })
          })
        )
      )
    }
    )
  )

  constructor(private store: Store, private requestService: RequestService, private initService: InitService) { }

  ngOnInit() {
    this.subscription = this.adminSub$.subscribe(result => {
      this.isAdmin = result
    })


  }


  isLoadingRequest(event) {
    let page = (event.first + event.rows) / event.rows
    if (this.requestData.length !== 0) {
      this.isLoading = true;
    }

    if (this.isLoading) {
      this.store.dispatch(setFaqs(({ page: page, pageSize: event.rows, filters: event.filters, ordering: { field: event.sortField, sortOrder: event.sortOrder } })))
    }
  }

  setFilters(event) {
    this.filters = { ...event }
  }


  ngOnDestroy() {
    this.requestData = []
    this.requestCurrentData = []
    this.subscription.unsubscribe()
  }
}


