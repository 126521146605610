<div class="flex timelinecustom m-3 p-3">
  <div class="flex  flex-row">
    <p-timeline class="" styleClass="customTimeline" [value]="events" [style]="{'min-height': '4rem' }">
      <ng-template pTemplate="marker" let-event>
        <span class="border-circle-top border-circle-bottom  " [style]="{'background': getBackground()}">
          <i [ngClass]="getIcon()" class="flex-column align-items-center"
            [style]="{'scale':'60%','color': getIconColor()}"></i>
        </span>
        <div class="p-timeline-event-connector p-timeline-event-separator "
          [style]="{'background': getColorBarLast(event),'height': '101%','position':'absolute','width':'2px'}">
          <br>
        </div>
      </ng-template>
      <ng-template pTemplate="content" let-event>
        <div class="flex flex-row">
          <div class="flex flex-column w-14rem h-7rem justify-content-center ">
            <p class="mb-0 text-sm"><b>{{(event['status']| statuspipe:true:true).toUpperCase()}}</b></p>
            <p class="mb-0 text-sm">{{event['username']}}</p>
          </div>
          <div class="ml-5 flex flex-column w-14rem h-7rem justify-content-center">
            <p class="mb-0 text-sm font-bold">Data:</p>
            <p class="mb-0 text-sm">{{event['date_update']}}</p>
          </div>
        </div>
      </ng-template>
    </p-timeline>
  </div>
</div>
