<div *ngIf="isAdmin">
    <div class="w-full flex flex-column">
        <app-flow></app-flow>
        <app-header-tab [isAdmin]="isAdmin" [seeRequestCompliance]="true"></app-header-tab>
        <app-info-request readOnly="true"></app-info-request>
        <app-form-new-request [inputForm]="formvalue" [stato]="stato" [title]="'Dati Richiesta'"></app-form-new-request>
        <div *ngIf="stato=='COMPLETED' || stato=='presa_in_carico'">
            <app-answer [isCompliance]="true" readOnly="true"></app-answer>
        </div>
        <app-header-tab [isAdmin]="isAdmin" [isAllegato]="true"></app-header-tab>
        <app-dynamic-table [isAllegato]="true" [isDisabled]="false"></app-dynamic-table>
        <div class="flex justify-content-end gap-5" style=" margin: 0 0.5%;  padding: 0.5%;">
            <button pButton pRipple type="button" class="return p-fontMedium text1" [routerLink]="['../']">Torna
                Indietro
            </button>
            <button pButton pRipple type="button" class="save p-fontMedium text1" (click)="saveRequestCompliance()"
                [routerLink]="['../']">Salva e Chiudi
            </button>
        </div>
    </div>
</div>

<div *ngIf="!isAdmin">
    <div class="w-full flex flex-column">
        <app-flow></app-flow>
        <div *ngIf="stato=='COMPLETED' || stato=='presa_in_carico'">
            <app-follow-timeline isCompliance="true"></app-follow-timeline>
        </div>
        <app-header-tab [isAdmin]="isAdmin" [statoTitolo]="stato" [title]="'Ecco la tua richiesta'"></app-header-tab>
        <app-form-new-request [inputForm]="formvalue" [stato]="stato"></app-form-new-request>
        <div *ngIf="stato=='COMPLETED' || stato=='presa_in_carico'">
            <app-answer [isCompliance]="true" readOnly="true"></app-answer>
        </div>
        <app-header-tab [isAdmin]="isAdmin" [stato]="stato" [title]="'Allegati'"></app-header-tab>
        <app-dynamic-table [stato]="stato"></app-dynamic-table>
        <div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
            <div *ngIf="stato=='COMPLETED'|| stato=='presa_in_carico'">
                <button pButton pRipple type="button" class="return p-fontMedium text1" [routerLink]="['../']">Torna
                    Indietro
                </button>
            </div>
            <div *ngIf="stato=='DRAFT' || stato=='REFUSED'">
                <div></div>
                <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']">Annulla
                </button>
                <button pButton pRipple type="submit" class="save p-fontMedium m-2" (click)="save()"
                    [routerLink]="['../']">Salva e
                    Chiudi
                </button>
                <button pButton pRipple type="button" class="send p-fontMedium m-2 mr-0" (click)="sendToCompliance()"
                    [routerLink]="['../']">Invia
                </button>
            </div>
            <div *ngIf="stato=='REFUSED'">
                <div class="flex-row card  flex">
                    <div class="flex icon mx-2">
                        <i class="fa-solid fa-circle-exclamation"></i>
                    </div>
                    <div>
                        <div class="font-bold">Richiesta REFUSED da Compliance!</div>
                        <div class="font-semibold">Motivazione:</div>
                        <div class="">Priorita e DueDate non in linea con la motivazione fornita.</div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>