import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Router } from "@angular/router";
import { createEffect, ofType, Actions } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { KeycloakService } from "keycloak-angular";
import { MessageService } from "primeng/api";
import { switchMap, map, catchError, of } from "rxjs";
import { PulsarService } from "../../services/pulsar.service";
import { RequestService } from "../../services/request.service";
import { ExportActions } from "./export.action";
import { DatePipe } from "@angular/common";

@Injectable()
export class ExportEffects {



  exportRequest$ = createEffect(() => {
    const date = new Date();
    const d = this.datePipe.transform(new Date(date), 'dd-MM-yyyy');
    return this.actions$.pipe(
      ofType(ExportActions.exportFile),
      switchMap(({ payload }) => {
        return this.requestService.export(payload['responses']).pipe(
          map((response: Blob) => {
            // const filename = payload['responses']['request'] ? `Esportazione Richieste ${d}.xlsx`:`Esportazione Finding ${d}.xlsx` ;
            const filename = payload['responses']['request'] ? `Esportazione Richieste ${d}.xlsx`: payload['responses']['template'] ? `Template importazione finding ${d}.xlsx` : `Esportazione Finding ${d}.xlsx` ;
            const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            document.body.appendChild(a);
            a.style.display = 'none';
            a.href = url;
            a.download = filename;
            a.click();
            window.URL.revokeObjectURL(url);
            return { type: ExportActions.exportFileSuccess }
          }, (error) => {
            console.error('Download failed:', error);
          })
        )
      })
    )
  })

  uploadTemplate$ = createEffect(() => {
    const date = new Date();
    const d = this.datePipe.transform(new Date(date), 'dd-MM-yyyy');
    return this.actions$.pipe(
      ofType(ExportActions.uploadTemplate),
      switchMap(({ payload }) => {
        console.log(payload)
        return this.requestService.uploadtemplate(payload['data']).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Template inserito' });
            return {
              type: ExportActions.uploadTemplateSuccess
            };
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Inserimento template fallito' });
            // }
            return of({ type: ExportActions.uploadTemplateFailure });
          })
        )
      })
    )
  })



  constructor(
    private actions$: Actions,
    private requestService: RequestService,
    private router: Router,
    private pulsarService: PulsarService,
    private keycloakService: KeycloakService,
    private store: Store,
    private http: HttpClient,
    private messageService: MessageService,
    private datePipe: DatePipe,
  ) { }
}
