import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-flow',
  templateUrl: './flow.component.html',
  styleUrls: ['./flow.component.scss']
})
export class FlowComponent {
  url = this.activatedRoute.url + " "
  paths: string[] = [];
  subject:string
  constructor(private activatedRoute: Router) { }

  ngOnInit() {
    this.url = this.activatedRoute.url + " "
    let count = 0;
    if(this.url.includes('finding')){
      this.subject= 'Finding #'
    }else if(this.url.includes('request')){
      this.subject= 'Richiesta '
    }else if(!this.url.includes('?')){
      this.subject= 'FAQ #'
    }else {
      this.subject = ''
    }
    while (this.url.includes("/", count) && count >= 0) {
      let parola = this.url.slice(count + 1, this.url.indexOf("/", count + 1))
      this.paths.push(parola)
      count = this.url.indexOf("/", count + 1)
    }
  }

  setPath(index,path){
    let ret = '../'
    let tot = this.paths.length
    let diff = tot-(index+1)
    for(let i=0; i< diff ;i++){
      ret+='../'
    }
    return[ret,path]
  }

}
