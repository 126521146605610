import { trigger, state, style, animate, transition, AnimationTriggerMetadata } from '@angular/animations';

export const logoAnimation: AnimationTriggerMetadata =
    trigger('logo', [
        state('normal', style({
            transform: 'translateY(0vh)'
        })),
        state('animated', style({
            transform: 'translateY(-20vh)'
        })),
        transition('normal => animated', animate(8000)),
    ]);

export const formaAnimation: AnimationTriggerMetadata =
    trigger('forma', [
        state('normal', style({
            transform: 'translateY(15vh) scale(1)'
        })),
        state('animated', style({
            transform: 'scale(7)'
        })),
        transition('normal => animated', animate(9000)),
    ])

export const formAnimation: AnimationTriggerMetadata =
    trigger('form', [
        state('normal', style({
            transform: 'translateY(20vh)',
            opacity: 0,
        })),
        state('animated', style({
            opacity: 1,
            transform: 'translateY(-15vh)'
        })),
        transition('normal => animated', animate(8000)),
    ]);

