<app-header></app-header>
<div class="container">
            
    <div class="content">
  
      <!-- top container -->
      <div class="inner-wrapper">
        <div class="top-title">Ouch!</div>
  
        <div class="main-title">          
          <span class="svg-wrap">
            <svg width="80px" height="80px" viewBox="0 0 40 42" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xmlns:sketch="http://www.bohemiancoding.com/sketch/ns">
              <title>Lego head</title>
              <defs>
                <path id="path-1" d="M14.00976,6.04673974 C15.1748273,5.5908863 16,4.45702525 16,3.13043478 C16,1.40154339 14.5984566,0 12.8695652,0 C12.0080647,0 11.2278445,0.348002376 10.6618113,0.911100458 C10.5772973,0.939953572 10.4903214,0.971146886 10.4008152,1.00475543 C5.57957133,2.8150777 4.24944679,0.347826087 3.13043478,0.347826087 C1.40154339,0.347826087 0,1.74936948 0,3.47826087 C0,5.20715226 1.40154339,6.60869565 3.13043478,6.60869565 C4.46967336,6.60869565 12.3802688,6.97312235 14.00976,6.04673974 L14.00976,6.04673974 Z"></path>
              </defs>
              <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" sketch:type="MSPage">
                <g id="Lego-head" sketch:type="MSLayerGroup" transform="translate(1.000000, 1.000000)">
                  <g id="Lego">
                    <g id="404-Page">
                      <g id="Group">
                        <g id="Lego-head">
                          <rect id="head-bottom" stroke="#D8B140" stroke-width="2" fill="#F4CD3B" sketch:type="MSShapeGroup" x="9" y="29" width="20" height="11" rx="2"></rect>
                          <rect id="head-top" stroke="#D8B140" stroke-width="2" fill="#F4CD3B" sketch:type="MSShapeGroup" x="11" y="0" width="16" height="11" rx="2"></rect>
                          <rect id="head" stroke="#D8B140" stroke-width="2" fill="#F4CD3B" sketch:type="MSShapeGroup" x="0" y="4" width="38" height="32" rx="8"></rect>
                          <g id="mouth" transform="translate(11.000000, 23.000000)">
                            <g id="mask-2" fill="#FFFFFF" sketch:type="MSShapeGroup">
                              <path d="M14.00976,6.04673974 C15.1748273,5.5908863 16,4.45702525 16,3.13043478 C16,1.40154339 14.5984566,0 12.8695652,0 C12.0080647,0 11.2278445,0.348002376 10.6618113,0.911100458 C10.5772973,0.939953572 10.4903214,0.971146886 10.4008152,1.00475543 C5.57957133,2.8150777 4.24944679,0.347826087 3.13043478,0.347826087 C1.40154339,0.347826087 0,1.74936948 0,3.47826087 C0,5.20715226 1.40154339,6.60869565 3.13043478,6.60869565 C4.46967336,6.60869565 12.3802688,6.97312235 14.00976,6.04673974 L14.00976,6.04673974 Z" id="path-1"></path>
                            </g>
                            <g id="Mask" fill="#3D3D3D" sketch:type="MSShapeGroup">
                              <path d="M14.00976,6.04673974 C15.1748273,5.5908863 16,4.45702525 16,3.13043478 C16,1.40154339 14.5984566,0 12.8695652,0 C12.0080647,0 11.2278445,0.348002376 10.6618113,0.911100458 C10.5772973,0.939953572 10.4903214,0.971146886 10.4008152,1.00475543 C5.57957133,2.8150777 4.24944679,0.347826087 3.13043478,0.347826087 C1.40154339,0.347826087 0,1.74936948 0,3.47826087 C0,5.20715226 1.40154339,6.60869565 3.13043478,6.60869565 C4.46967336,6.60869565 12.3802688,6.97312235 14.00976,6.04673974 L14.00976,6.04673974 Z" id="path-1"></path>
                            </g>
                            <g id="Oval-25-Clipped">
                              <mask id="mask-2" sketch:name="path-1" fill="white">
                                <use xlink:href="#path-1"></use>
                              </mask>
                              <g id="path-1"></g>
                              <path d="M2.90917969,7.67480469 C5.90917969,9.95019531 8.99755822,7.576474 8.99755822,6.74804688 C8.99755822,5.91961975 6.209139,4 4,4 C1.790861,4 2.8444044e-16,4.67157288 2.8444044e-16,5.5 C2.8444044e-16,6.32842712 -0.0908203125,5.39941406 2.90917969,7.67480469 L2.90917969,7.67480469 Z" id="Oval-25" fill="#EA6767" sketch:type="MSShapeGroup" mask="url(#mask-2)"></path>
                            </g>
                          </g>
                          <circle id="e-r" fill="#3D3D3D" sketch:type="MSShapeGroup" cx="13.5" cy="18.5" r="2.5"></circle>
                          <circle id="e-l" fill="#3D3D3D" sketch:type="MSShapeGroup" cx="22.5" cy="18.5" r="2.5"></circle>
                          <path d="M9,15.7883986 C9,15.7883986 11.0517578,11.8870314 13.6416016,13.3137892" id="e-l" stroke="#3D3D3D" sketch:type="MSShapeGroup" transform="translate(11.320801, 14.394199) scale(-1, -1) translate(-11.320801, -14.394199) "></path>
                          <path d="M26.6416016,15.7883986 C26.6416016,15.7883986 24.5898438,11.8870314 22,13.3137892" id="e-r" stroke="#3D3D3D" sketch:type="MSShapeGroup" transform="translate(24.320801, 14.394199) scale(-1, -1) translate(-24.320801, -14.394199) "></path>
                        </g>
                      </g>
                    </g>
                  </g>
                </g>
              </g>
            </svg>
          </span>
        </div>
      </div>
  
      <!-- bottom-tagline -->
      <p class="blurb">
        la pagina che stai cercando di aprire non esiste o non può essere visualizzato in questo momento.<br/>
      </p>
  
      <button (click)="cancel()" class="lego-btn">Torna alla Home</button>
  
    </div>
  
  </div>
<div class="flex justify-content-center ">
  <app-footer></app-footer>
</div>