<div class="flex pt-5 path" style="margin: 0 0.5%;  padding: 0.5%;">
    <div class="pr-2">
      <i class="fa-solid fa-house flow-icon" routerLink="/home"></i>
    </div>
  <div *ngFor="let path of paths; let i=index">
    <i class="fa-solid fa-angle-right flow-icon pr-2 pl-2"></i>
      <label class="text1"
        [routerLink]="setPath(i,path)">{{ path | flow:subject}}
      </label>
  </div>
</div>
