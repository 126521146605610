<div class="border-round-lg  bg-white  addattachement">
  <form [formGroup]="formGroup" action="submit()" enctype="x-www-urlencoded">
    <div
      class="headerAddAttachment border-round-top-lg flex flex-row align-items-baseline justify-content-between relative p-2 font-bold ">
      <div class="pl-2">
        <p class="">Carica Documento</p>
      </div>
      <div class="">
        <i class="fa-solid pr-2 fa-lg fa-circle-xmark icon" (click)="close()"></i>
      </div>
    </div>
    <div class="">
      <div class="col-12 flex flex-row">
        <div class="col-6 ">
          <p class="ml-1   font-bold">Allegato</p>
          <input formControlName="allegato" type="text" pInputText class="p-input text-sm w-full"
            placeholder="Documento di analisi...">
          <div class="absolute"
            *ngIf="formGroup.controls['allegato'].invalid && formGroup.controls['allegato'].touched || formGroup.get('allegato').value?.length >= 100">
            <p style="color:#e24c4c;font-size:small">Inserisci il nome dell'allegato ( massimo 100 caratteri.)</p>
          </div>

        </div>
        <div class="col-6 ">
          <p class="ml-1  font-bold">Oggetto</p>
          <input formControlName="oggetto" type="text" pInputText class="p-input text-sm w-full"
            placeholder="Contratto">
          <div class="absolute"
            *ngIf="formGroup.controls['oggetto'].invalid && formGroup.controls['oggetto'].touched|| formGroup.get('oggetto').value?.length >= 100">
            <p style="color:#e24c4c;font-size:small">Inserisci l'oggetto ( massimo 100 caratteri)</p>
          </div>
        </div>
        <div class="col-4 ">
        </div>
      </div>
      <div class="col-12 flex flex-column">
        <div class="col-12 w-full ">
          <p class=" mb-2 font-bold">Descrizione documento</p>
          <textarea formControlName="descrizione" pInputTextarea [autoResize]="false"
            class="w-full h-6rem mb-2"></textarea>
          <div class="relative"
            *ngIf="formGroup.controls['descrizione'].invalid && formGroup.controls['descrizione'].touched || formGroup.get('descrizione').value?.length >= 255">
            <p class="absolute" style="color:#e24c4c;font-size:small;top:-0.5rem">Inserisci la descrizione ( massimo 255
              caratteri ).</p>
          </div>
        </div>
        <div class="col-12 ">
          <div id="dropzone-box" appDragDrop (files)="dropfile($event)" style="overflow-y: auto;"
            class="border-round-lg h-9rem border-dashed border-gray-400 border-1 col-12 flex w-full flex-column align-items-center justify-content-center">
            <div *ngIf="!currentFile;else showFile">
              <div id="content"
                class="col-12  flex flex-row text-center text-lg text-dialog justify-content-center align-items-center ">
                <button class="button-upload border-none " style="background-color:transparent" type="submit"
                  (click)="fileInput.click()">
                  <a class="no-underline font-bold ">
                    <span class=" ">Carica un documento</span>
                  </a>
                  <input formControlName="file" #fileInput type="file" (change)="dropfile($event)"
                    style="display: none" />
                </button>
                <span class="text-base text-dialog font-normal"> o trascinalo quì</span>
              </div>
              <div class="col-12 text-center ">
                <span class="text-base text-dialog font-normal">PDF, JPG... massimo 100 MB</span>
              </div>
              <div class="w-full" *ngIf="formGroup.controls['file'].invalid && formGroup.controls['file'].touched ">
                <p class="text-center" style="color:#e24c4c;font-size:small;left:30%">Inserisci il documento</p>
              </div>
            </div>

            <!-- template visualizzazione file drag and drop -->
            <ng-template #showFile>
              <div class="flex flex-row w-full h-full  py-2  justify-content-center align-content-center">
                <div class=" flex flex-row w-full h-full align-items-center justify-content-center ">
                  <div class=" flex flex-row align-items-baseline ">
                    <!--icona file-->
                    <i class="fa fa-file pl-3" aria-hidden="true"></i>
                    <!--Nome file-->
                    <p class="pl-3">
                      {{currentFile.name}}
                    </p>
                    <!-- icona di cancellazione-->
                    <i class="fa fa-close pl-2" (click)="removeFile(index)" aria-hidden="true"></i>
                  </div>
                </div>
              </div>
            </ng-template>
          </div>
        </div>
        <div class="col-12 flex justify-content-end  ">
          <button pButton pRipple type="submit" (click)="showCurrentFile()" class="continue">Continua</button>
        </div>
      </div>
    </div>
  </form>
</div>
