import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';
import { Attachment } from 'src/app/data/models/Attachment';


@Component({
  selector: 'app-add-attachment',
  templateUrl: './add-attachment.component.html',
  styleUrls: ['./add-attachment.component.scss']
})
export class AddAttachmentComponent {
  formGroup: FormGroup
  currentFile: File;
  title: string;
  currentUser: string;
  @Output() addAttachment = new EventEmitter<Attachment>()
  @Output() editAttachment = new EventEmitter<any>()
  @Output() closeAttachment = new EventEmitter<any>()
  @Output() deleteFile = new EventEmitter<any>()
  @Input() attachmentForm: Attachment
  @Input() attachmentIndex: number;
  @Input() currentUserId = 0;
  @Input() canRemove: boolean = false;
  invalidBooleanAttachment: boolean = false;

  isEditing: boolean = false
  //lastReqId: number;

  constructor(private store: Store, private messageService: MessageService) {
    //this.store.select(selectLastRequestId).pipe(take(1)).subscribe(value => this.lastReqId = value + 1)
  }


  ngOnChanges() {
    if (this.attachmentForm) {
      this.formGroup.patchValue({
        //file: this.attachmentForm.attachment['name'],
        descrizione: this.attachmentForm.description,
        oggetto: this.attachmentForm.object_name,
        allegato: this.attachmentForm.document_title
      })
      this.dropfile(this.attachmentForm.attachment)

    } else {
      this.formGroup = new FormGroup({
        allegato: new FormControl('', [Validators.required]),
        oggetto: new FormControl('', [Validators.required]),
        descrizione: new FormControl('', [Validators.required]),
        file: new FormControl('', Validators.required),
      })
    }


  }


  dropfile(file) {
    if (typeof file === 'string') {
      this.isEditing = true
      const fileName = file.substring(file.lastIndexOf('/') + 1, file.indexOf('?'));

      let newfile = new File([''], fileName, {
        type: "text/plain",
      })
      this.currentFile = newfile
    } else {
      this.isEditing = false
      if (file instanceof Event) {
        this.currentFile = file.target['files'][0];
      } else if (file.length > 0) {
        this.currentFile = file[0]['file']
      } else {
        this.currentFile = file
      }
    }
  }

  removeFile(index) {
    this.deleteFile.emit(index)
    this.currentFile = null
  }

  close() {
    this.attachmentForm = undefined
    this.formGroup.reset()
    this.currentFile = null
    this.closeAttachment.emit()
  }

  showCurrentFile() {
    let values = this.formGroup.controls
    this.formGroup.controls['file'].setValue(this.currentFile)
    if (this.formGroup.valid) {
      this.invalidBooleanAttachment = false
      let attachment: Attachment = {
        document_title: values['allegato'].value,
        object_name: values['oggetto'].value,
        description: values['descrizione'].value,
        attachment: values['file'].value,
        uploaded_by: this.currentUserId,
        request_attached: null

      }
      if (this.attachmentForm) {
        let event = {
          attachment: attachment,
          index: this.attachmentIndex,
          isEditingFromDb: this.isEditing
        }
        this.editAttachment.emit(event)
      } else {
        this.addAttachment.emit(attachment)
      }
      this.closeAttachment.emit()
      this.attachmentForm = undefined
      this.formGroup.reset()
      this.currentFile = null
      this.messageService.add({ severity: 'success', summary: 'Allegato caricato con successo' });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Errore nel caricamento del allegato ' });
      this.invalidBooleanAttachment = true
      if (this.invalidBooleanAttachment == true) {
        this.formGroup.markAllAsTouched()

      }
    }
  }

}
