
import { Component } from '@angular/core';

@Component({
  selector: 'app-faq-requests',
  templateUrl: './faq.component.html',
  styleUrls: ['./faq.component.scss']
})
export class FaqComponent {
}
