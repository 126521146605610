<div>
  <app-flow></app-flow>
  <app-header-tab [isAdmin]="isAdmin" [hasButton]="true"  [title]="'FAQ'" [canAddRequest]="false"
    [canFaq]="isAdmin"  ></app-header-tab>
  <br>
  <div>
    <app-dynamic-table *ngIf="!(dataLoaded$ | async)" [isAdmin]="isAdmin" [tableColumn]="tableColumn" [filters]="tableFilters"
      (loadingRequest)="isLoadingRequest($event)" (setFilters)="setFilters($event)" [isLoading]="isLoading"
      [tableCurrentData]="requestCurrentData" [tableData]="requestData" [isAllegato]="false" [size]="totalSize"
      [canSearch]="true" [isFaq]="true"></app-dynamic-table>
    <!-- <app-dynamic-table [canSearch]="true"></app-dynamic-table> -->
  </div>
</div>

<!-- <div>
  <app-flow></app-flow>
  <app-header-tab [isAdmin]="isAdmin" [hasButton]="true" [canAddRequest]="!(adminSub$ | async) ? true : false" [canExport]="true"
  [title]="'FAQ'"></app-header-tab>
  <br>
  <app-dynamic-table *ngIf="!(dataLoaded$ | async)" [tableColumn]="tableColumn" [filters]="tableFilters"
    (loadingRequest)="isLoadingRequest($event)" [isLoading]="isLoading" [tableCurrentData]="requestCurrentData"
    [tableData]="requestData" [isAllegato]="false" [size]="totalSize" [canSearch]="true"></app-dynamic-table>
</div> -->
