import { Component, Input } from '@angular/core';
import { AbstractControl, FormBuilder, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validators } from '@angular/forms';
import { InputSwitchModule } from 'primeng/inputswitch';

@Component({
  selector: 'app-answer',
  templateUrl: './answer.component.html',
  styleUrls: ['./answer.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: AnswerComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: AnswerComponent }
  ]
})
export class AnswerComponent {
  @Input() isCompliance: boolean = false;
  @Input() isUser: boolean = false;
  @Input() isUserFinding: boolean = false;
  @Input() disabled: boolean = false;
  @Input() stato: string;

  isAdmin: boolean;
  privacyAnswer: boolean = false;
  legalAnswer: boolean = false;
  @Input() isLegal: boolean;
  @Input() isPrivacy: boolean;
  checked_privacy: boolean = false;
  checked_legal: boolean = false;
  formGroup: FormGroup;

  onChanged!: () => void
  onTouched!: () => void
  onDisabled!: (disable: boolean) => void



  writeValue(obj: any): void {
    this.formGroup.patchValue(obj)
    if (obj.requested_legal) {
      this.checked_legal = obj.requested_legal
    }
    if (obj.requested_privacy) {
      this.checked_privacy = obj.requested_privacy
    }
  }

  registerOnChange(fn: any): void {

    this.formGroup.valueChanges.subscribe(fn)
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    console.log(this.stato);

    if (isDisabled) {
      const rawValue = this.formGroup.getRawValue()
      console.log("trigger disable state");
      this.formGroup.disable()
      if (this.stato == 'IN_CHARGE') {

        if (this.isLegal == true) {
          this.formGroup.get('legal').enable()
          this.formGroup.get('flagLegalAnswer').enable()
          this.formGroup.get('note').enable()
        } else if (this.isPrivacy == true) {
          this.formGroup.get('note').enable()
          this.formGroup.get('flagPrivacyAnswer').enable()
          this.formGroup.get('privacy').enable()
        } else {
          console.log("qui")
          if (this.formGroup.get('flagLegalAnswer').value == false) {
            this.formGroup.get('requested_legal').enable()
            console.log("qui")

          } else if (this.formGroup.get('flagLegalAnswer').value == true) {
            this.formGroup.get('requested_legal').disable()
            console.log("qui")

          }

          if (this.formGroup.get('flagPrivacyAnswer').value == false) {
            this.formGroup.get('requested_privacy').enable()
            console.log("qui")

          } else if (this.formGroup.get('flagPrivacyAnswer').value == true) {
            this.formGroup.get('requested_privacy').disable()
            console.log("qui")

          }
          this.formGroup.get('note').enable()
          // this.formGroup.get('requested_legal').enable()
          // this.formGroup.get('requested_privacy').enable()
          this.formGroup.get('text').enable()
          console.log(this.formGroup)

        }
      } else {
        console.log(this.stato);
      }
      this.formGroup.patchValue(rawValue)
    }
  }


  validate(control: AbstractControl): ValidationErrors {

    if (control.valueChanges && this.formGroup.valid) {
      return null
    } else {
      return { required: true }
    }
  }

  constructor(private fb: FormBuilder) {

    this.formGroup = this.fb.group({
      note: ['', [Validators.maxLength(2000)]],
      text: ['', [Validators.maxLength(65535)]],
      privacy: ['', [Validators.maxLength(65535)]],
      legal: ['', [Validators.maxLength(65535)]],
      flagPrivacyAnswer: [''],
      flagLegalAnswer: [''],
      requested_legal: [''],
      requested_privacy: ['']
    })
  }

  // ngAfterViewInit() {
  //   const formValue = this.formGroup;
  //   

  // }




  // getStatoRemedation(statoRemedation) {
  //   if (statoRemedation == 'COMPLETED' || statoRemedation == 'REFUSED') {
  //     this.isCompliance = false;
  //     this.readOnly = true;
  //   } else if (statoRemedation == 'DRAFT' || statoRemedation == 'NEW') {
  //     this.isCompliance = false;
  //     this.readOnly = false;
  //   }

  // }
}
