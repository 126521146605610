import { Component, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, concatMap, map, mergeMap, take, takeUntil, tap } from 'rxjs';
import { DbFinding } from 'src/app/data/models/Finding';
import { DbRequest } from 'src/app/data/models/Request';
import { RequestService } from 'src/app/utils/services/request.service';
import { FindingService } from 'src/app/utils/services/findings.service';
import { FindingActions, initFindings } from 'src/app/utils/store/finding/finding.action';
import { RequestActions, initRequests, setRequests } from 'src/app/utils/store/request/request.action';
import { InitService } from 'src/app/utils/services/init.service';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { HeaderComponent } from 'src/app/components/header/header.component';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';

@Component({
  selector: 'app-home-page',
  templateUrl: './home-page.component.html',
  styleUrls: ['./home-page.component.scss']
})
export class HomePageComponent {
  requests: DbRequest[] = [];
  findings: DbFinding[] = [];
  currentUser: any
  displayBadgeRequest: boolean = false;
  displayBadgeFinding: boolean = false;
  isAdmin: boolean;

  @ViewChild(HeaderComponent) header: HeaderComponent

  constructor(private initService: InitService, private store: Store, private keycloakService: KeycloakService) { }

  ngOnInit() {
    
    this.initService.initLookups().pipe(
      map((data) => {
        console.log(data)
        
        //this.initService.setAmbiti()
        this.currentUser = data['user']
        if (this.currentUser?.['new_finding'] == true ) {
          this.displayBadgeFinding = true
        } 

        // if (this.currentUser?.['new_requests'] == true && this.currentUser['roles'].find(role => role == 'compliance_user') !== undefined) {
        //   this.displayBadgeRequest = true
        // } else if (this.currentUser?.['new_finding'] == true && this.currentUser['roles'].find(role => role == 'business_user') !== undefined) {
        //   this.displayBadgeFinding = true
        // } else if (this.currentUser?.['new_finding'] == true && this.currentUser['roles'].find(role => role == 'compliance_user') !== undefined) {
        //   this.displayBadgeFinding = true
        // }

        // const token = this.keycloakService.getKeycloakInstance().token;
        // const roles = this.keycloakService.getKeycloakInstance().tokenParsed.realm_access.roles;
        // this.isAdmin = roles.includes('mooney_admin');
        // console.log(this.isAdmin)
        this.store.dispatch(initUser({ currentUser: this.currentUser }))
      }),
    ).subscribe()
  }

}
