import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'enableUser'
})
export class EnableUserPipe implements PipeTransform {

  transform(value: boolean): any {
    if (value == true) {
      return 'Abilitato';
    } else if (value == false) {
      return 'Disabilitato';
    }
  }

}
