<div class="customContainer">
  <div class="form_info_request border-round-sm ">
    <div class="header_form border-round-top-sm font-bold flex justify-content-start">
      <p class="header m-1 ml-3 ">Alcune informazioni sulla gestione della Richiesta</p>
    </div>
    <div class="p-3">
      <div class="flex flex-column justify-content-between">
        <div class="flex gap-3">
          <div class="w-full mt-2">
            <p class="font-bold text1 mb-1">Utente Presa in carico</p>
            <input type="text" pInputText class="p-input text-sm w-100 " placeholder="" [(ngModel)]="userInCharge"
              [disabled]="readOnly">
          </div>
          <div class="w-full mt-2">
            <p class="font-bold text1 mb-1">Data in carico</p>
            <input type="text" pInputText class="p-input text-sm w-100 " [(ngModel)]="dateInCharge"
              [disabled]="readOnly">
          </div>
        </div>
        <div class=" flex gap-3">
          <div class="w-full mt-2">
            <p class="font-bold text1 mb-1">Utente Risolutore</p>
            <input type="text" pInputText class="p-input text-sm w-100 " [(ngModel)]="userResolution"
              [disabled]="readOnly">
          </div>
          <div class="w-full mt-2">
            <p class="font-bold text1 mb-1">Data risoluzione</p>
            <input type="text" pInputText class="p-input text-sm w-100 " [(ngModel)]="dateResolution"
              [disabled]="readOnly">
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
