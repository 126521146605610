import { createFeatureSelector, createSelector } from '@ngrx/store';
import { UserState } from '../user/user.state';

export const selectUsers = createFeatureSelector<UserState>('user');

export const selectCurrent = createSelector(
  selectUsers,
  (state: UserState) => state.currentUser,
);

export const selectCurrentUserIDKeycloak = createSelector(
  selectUsers,
  (state: UserState) => state.currentUser.keycloak_id,
);

export const selectProfileBool = createSelector(
  selectUsers,
  (state: UserState) => state.updateUser,
);

export const selectCurrentID = createSelector(
  selectCurrent,
  (user: any) => user['id'],
);

export const selectCurrentIDandRole = createSelector(
  selectCurrent,
  (user: any) => ({ id: user.id, roles: user.roles })
);

export const selectUserFullName = createSelector(
  selectUsers,
  (state: UserState) => state.currentUser.username,
);


export const selectUserRole = createSelector(
  selectCurrent,
  (user) => user['roles'],
);

export const selectCurrentUserID = createSelector(
  selectCurrent,
  (currentUser) => currentUser['id']
)

export const selectCurrentUsername = createSelector(
  selectCurrent,
  (currentUser) => currentUser['username']
)

export const selectCurrentUserIDandUsername = createSelector(
  selectCurrent,
  (currentUser) => ({ userId: currentUser['id'], username: currentUser['username'] })
)




