import { DbFinding } from "src/app/data/models/Finding";


export interface FindingsState {

  findings: {
    findings: DbFinding[],
    size: number
  },
  currentFinding: DbFinding,
  loadingFinding: boolean,
  newFinding: DbFinding,
}

export const initialFindingState: FindingsState = {
  findings: null,
  currentFinding: null,
  loadingFinding: false,
  newFinding: null,
};
