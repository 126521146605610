<!-- <div class=" w-full flex justify-content-center " *ngIf="isCompliance==false">
  <p-timeline class="mt-1  " styleClass="customTimeline " [value]="eventsFinding" layout="horizontal">
    <ng-template pTemplate="marker" let-eventFinding>
      <span class="border-circle-top border-circle-bottom justify-content-center"
        [style]="{'background': getBackground(eventFinding.status), 'border-color': '#FFC627'}">
        <i [ngClass]="getIcon(eventFinding.status)" [style]="{'color': getIconColor(eventFinding.status)}"></i>
      </span>
      <div class="p-timeline-event-separator p-timeline-event-connector "
        [style]="{'background': getColorBarLast(eventFinding),'height': '4px','position':'absolute','width':'101%'}">
      </div>
    </ng-template>
    <ng-template pTemplate="content" let-eventsFinding>
      <div class="w-14rem h-7rem text-center">
        <p class="mb-0 font-bold">{{eventsFinding['status'] | data }}</p>

        <p class="mb-0 font-light font-sm" *ngIf="eventsFinding['date_update']">
          {{formatDate(eventsFinding['date_update'])}}</p>
        <p class="mb-0 font-bold" *ngIf="eventsFinding['username']">{{eventsFinding['username']}}</p>
      </div>
    </ng-template>
  </p-timeline>
</div> -->

<div class=" w-full flex justify-content-center timelineCustom my-5">
  <p-timeline class=" mt-1  " styleClass="customTimeline " [value]="events" layout="horizontal">
    <ng-template pTemplate="marker" let-event>
      <span class="border-circle-top border-circle-bottom justify-content-center"
        [style]="{'background': getBackground(event), 'border-color': '#FFC627'}">
        <!-- status == to_check  -->
        <i [ngClass]="getIcon(event)"
          [style]="{'color': getIconColor(event)}"></i>
      </span>
      <div class="p-timeline-event-separator p-timeline-event-connector "
        [style]="{'background': getColorBarLast(event),'height': '4px','position':'absolute','width':'101%'}">
      </div>
    </ng-template>
    <ng-template pTemplate="content" let-event>
      <div class="w-14rem h-7rem text-center ">
        <p class="mb-0 font-bold">{{event['status'] | data }}</p>
        <div class="text-center w-full flex justify-content-center">
          <!-- <p class="mb-0 px-5 text-center" [ngClass]="event['result'] == 'KO' ? 'bedgeKO':'bedgeOK'" *ngIf="event?.['result']">{{event['result']}}</p> -->
          <p class="mb-0 px-5 text-center" *ngIf="event?.['result']">{{event['result']}}</p>
        </div>
        <p class="mb-0 font-light font-sm" *ngIf="event['date_update']">{{event['date_update'] !== '--/--/----' ? formatDate(event['date_update']) : '--/--/----'}}</p>
        <p class="mb-0 font-bold">{{event['username'] !== '' ? event['username'] : 'N/A'}}</p>
      </div>
    </ng-template>
  </p-timeline>
</div>
