import { Pipe, PipeTransform } from '@angular/core';
import { UserService } from '../services/user.service';
import { map, take } from 'rxjs';

@Pipe({
  name: 'fullName'
})
export class UserFullNamePipe implements PipeTransform {
    us:UserService

  transform(value: number ): string {
    let val
    this.us.getBusinessUserFromId(value).pipe(
        take(1),
        map(res => val = res)
      ).subscribe()
      return val
  }
}