import {
  ActionReducerMap,
  MetaReducer
} from '@ngrx/store';
import { userReducer } from './user/user.reducer';
import { requestReducer } from './request/request.reducer';
import { UserState, userFeatureKey } from './user/user.state';
import { RequestsState } from './request/request.state';
import { FindingsState } from './finding/finding.state';
import { findingReducer } from './finding/finding.reducer';
import { ExportState } from './export/export.state';
import { exportReducer } from './export/export.reducer';
import { AdminState } from './admin/admin.state';
import { adminReducer } from './admin/admin.reducer';


export interface State {
  //STATE DI USER 
  [userFeatureKey]: UserState;
  //STATE DI REQUEST
  "request": RequestsState
  //STATE FINDING
  "finding": FindingsState;
  "exportFile":ExportState;
  "admin":AdminState;


}

export const reducers: ActionReducerMap<State> = {
  //REDUCER PER USER
  [userFeatureKey]: userReducer,
  //REDUCER PER REQUEST
  "request": requestReducer,
  "finding": findingReducer,
  "exportFile":exportReducer,
  "admin": adminReducer,
};


export const metaReducers: MetaReducer<State>[] = [];
