import { Component } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { map } from 'rxjs';
import { DropdownService } from 'src/app/utils/services/dropdown.service';
import { HttpClient } from '@angular/common/http';
import { ExportActions } from 'src/app/utils/store/export/export.action';
import { Store } from '@ngrx/store';
import { AdminActions } from 'src/app/utils/store/admin/admin.actions';
import { environment } from 'src/environments/environment';
import { selectBooleanButton } from 'src/app/utils/store/admin/admin.selector';
import { selectExportDataLoading } from 'src/app/utils/store/export/export.selector';

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.scss']
})
export class ImportComponent {
  dropdownOptions: any[];
  formGroup: FormGroup
  loading: boolean = false;

  constructor(private dropdownService:DropdownService,private http: HttpClient, private store: Store ) {

    this.dropdownOptions = [
      'Ambito',
      'Perimetro',
      'Rischio',
      'Finding'
    ];
  }

  selectDropdown:string;
  currentFile: File;

  ngOnInit(){

    this.store.select(selectExportDataLoading).pipe(map(value => this.loading = value)).subscribe()

    
    this.formGroup = new FormGroup({
      // dropdown: new FormControl('', Validators.required),
      file: new FormControl('', Validators.required),
    })

    this.formGroup.get('dropdown').valueChanges.pipe(
      map(event => {
        this.selectDropdown = event
        })
      ).subscribe()
  }

  dropfile(file) {
    if (typeof file === 'string') {
      const fileName = file.substring(file.lastIndexOf('/') + 1, file.indexOf('?'));

      let newfile = new File([''], fileName, {
        type: "text/plain",
      })
      this.currentFile = newfile
    } else {
      if (file instanceof Event) {
        this.currentFile = file.target['files'][0];
      } else if (file.length > 0) {
        this.currentFile = file[0]['file']
      } else {
        this.currentFile = file
      }
    }
    

    console.log("file",this.currentFile)
  }
  
  // import(value:string){
  //   if(this.formGroup.invalid){
  //     this.formGroup.markAllAsTouched()
  //     console.log("invalido")
  //   }else{
  //     console.log('lancio api ',this.currentFile, this.formGroup.get('file').value)
  //     this.dropdownService.import(value,this.currentFile).subscribe()
  //   }
  
  // }

  
  removeFile() {
    this.currentFile = null
    this.formGroup.get('file').setValue('')
    console.log('remove file ',this.currentFile, this.formGroup.get('file').value)
  }
  downloadFile() {
    this.store.dispatch({ type: ExportActions.exportFile, payload: { responses: { filters: {},  request: false, template:true } } })
  }
  
  saveImport(){
    const formData = new FormData();
    formData.append('upload', this.currentFile, this.currentFile.name);
  

    this.store.dispatch({ type: ExportActions.uploadTemplate, payload: { data: formData } })

    this.currentFile = null
    this.formGroup.get('file').setValue('')
    // // Effettua la richiesta POST all'API
    // this.http.post(`${environment.apiUrl}/admin-panel/upload/`, formData).subscribe(
    //   (response) => {
    //     // Gestisci la risposta dell'API
    //     console.log('Upload completato con successo', response);
    //   },
    //   (error) => {
    //     // Gestisci l'errore dell'API
    //     console.error('Errore durante l\'upload', error);
    //     console.log( this.http.post(`${environment.apiUrl}/admin-panel/upload/`, formData))
    //   }
    // );

  }
  }



