import { Component, OnInit, Input, Output, EventEmitter, ViewChild, ElementRef } from '@angular/core';
import { RequestService } from 'src/app/utils/services/request.service';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/utils/services/user.service';
import { Store } from '@ngrx/store';
import { selectCurrentRequestState, selectCurrentRequestTitle, selectRequestsData, selectCurrentRequestAmbito, selectRequestsTable, selectCurrentRequestId, selectRequestDataLoading } from 'src/app/utils/store/request/request.selector';
import { Observable, Subject, Subscription, catchError, concatMap, forkJoin, map, merge, of, switchMap, take } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { DbRequest, Status } from 'src/app/data/models/Request';
import { RequestActions, setCurrentRequest } from 'src/app/utils/store/request/request.action';
import { Attachment } from 'src/app/data/models/Attachment';
import { HttpClient } from '@angular/common/http';
import { selectCurrentIDandRole, selectUserRole } from 'src/app/utils/store/user/user.selector';
import { environment } from 'src/environments/environment';
import { InitService } from 'src/app/utils/services/init.service';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { DatePipe } from '@angular/common';



@Component({
  selector: 'app-see-request',
  templateUrl: './see-request.component.html',
  styleUrls: ['./see-request.component.scss']
})
export class SeeRequestComponent implements OnInit {
  @Input() display: boolean = false;
  @Output() newItemEvent = new EventEmitter<string>();
  stato: string;
  tabella: any[] = [];
  isAdmin: boolean = false;
  stateRequest: any[] = [];
  isFilter: { isFilter: boolean, filter: string }
  currentRequest: DbRequest;
  postData: DbRequest;
  timelineStatus: any = [
    "NEW",
    "IN_CHARGE",
    "COMPLETED",
  ]

  attachmentData: Attachment[] = [{
    document_title: "",
    object_name: "",
    description: "",
    uploaded_by: 0
  }];
  tableColumn: string[] = []
  newValue: any
  answerValue: any
  currentUserId: number;
  currRequestSub: any
  requestSub: Subscription
  newAttachment: Attachment[] = [];
  attached_doc: any[];
  dataToSend: string;
  isEmpty: boolean = true;
  requestId = this.activateRoute.params['_value']['id'];
  adminSub: Subscription
  @ViewChild('hea') value: ElementRef<HTMLElement>;
  canFilter: boolean
  loading: boolean = false;
  legalRole: boolean;
  privacyRole: boolean


  //settare legal e privacy
  setAdmin: Observable<any> = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      let userRole = roles.find(role => role == "compliance_user")

      let legalTemp = roles.find(role => role == "legal_user")
      this.legalRole = legalTemp !== undefined ? true : false;
      let privacyTemp = roles.find(role => role == "privacy_user")
      this.privacyRole = privacyTemp !== undefined ? true : false;


      this.currentUserId = detail['id'];
      this.requestSub = this.getRequest.subscribe()
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {

              this.isAdmin = result;
            })
          })
        )
      )
    })
  )

  getRequest = this.http.get(environment.apiUrl + "/request/" + this.requestId + "/").pipe(
    concatMap(currRequest => {
      // console.log("comment -> ", currRequest['comments'][currRequest['comments'].length-1]['user'])
      this.currentRequest = currRequest
      this.canFilter = this.currentRequest.status !== 'NEW' ? true : false
      this.formValue.get("newRequest").patchValue({
        title: this.currentRequest.request_title,
        priorita: this.currentRequest.priority,
        dueDate: this.currentRequest.due_date !== null ? this.currentRequest.due_date : '',
        ambito: this.currentRequest.scope,
        motivazione: this.currentRequest.reason,
        perimetro: this.currentRequest.perimeter,
        struttura: this.currentRequest.structure_request,
        description: this.currentRequest.description,
      })

      this.formValue.get("answer").patchValue({
        text: this.currentRequest.compliance_answer,
        legal: this.currentRequest.legal_answer,
        privacy: this.currentRequest.privacy_answer,
        flagPrivacyAnswer: this.currentRequest.privacy_checkbox,
        flagLegalAnswer: this.currentRequest.legal_checkbox,
        note: this.currentRequest.note,
        requested_legal: this.currentRequest.requested_legal,
        requested_privacy: this.currentRequest.requested_privacy
      })
      console.log(this.currentRequest.requested_privacy)
      console.log(this.currentRequest.privacy_checkbox)



      this.store.dispatch(setCurrentRequest({ currentRequest: currRequest }))
      this.stato = currRequest['status']
      console.log(this.stato)
      this.attached_doc = [...this.currentRequest.document_attached]
      if (this.isAdmin == false) {
        this.formValue.get("answer").disable()
        if (this.stato !== 'DRAFT') {
          this.formValue.get("newRequest").disable()
        }
        if (this.legalRole) {
          console.log(this.formValue.get("answer"))
        }
      }
      if (this.isAdmin == true) {
        this.formValue.get("newRequest").disable()
        this.formValue.get("answer").disable()
      }
      if (currRequest['document_attached'].length > 0) {
      }
      const attachmentObservables = currRequest['document_attached'].map(document => {
        return this.http.get<Attachment>(`${environment.apiUrl}/document/` + document + '/').pipe(
          map((result) => {
            const attachment: any = (({
              id,
              attachment,
              object_name,
              description,
              uploaded_by,
              document_title,
              username,
            }) => ({
              id,
              attachment,
              object_name,
              description,
              document_title,
              uploaded_by,
              username,
            }))(result);
            this.attachmentData.push(attachment)
            if (this.attachmentData.length > 1 && this.attachmentData[0].description == "") {
              this.attachmentData.splice(0, 1)
            }
          }));
      });
      return forkJoin(attachmentObservables);
    }),
    catchError(err => {
      this.store.dispatch({ type: RequestActions.getRequestFailure })
      return of(1)
    })
  )

  formValue: FormGroup





  constructor(
    private http: HttpClient,
    private messageService: MessageService,
    private store: Store,
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private initService: InitService,
    private datePipe: DatePipe,
  ) {


  }

  //RICOMINCIA DA QUI DESCRIPTION DELL?ALLEGATO E MODIFICA
  ngAfterViewInit() {

  }
  ngOnInit() {
    this.store.select(selectRequestDataLoading).pipe(map(value => this.loading = value)).subscribe()

    this.adminSub = this.setAdmin.subscribe(result => {

      this.isAdmin = result;
    });

    this.tableColumn = Object.keys(this.attachmentData[0])
    this.formValue = this.fb.group({
      newRequest: {
        title: '',
        priorita: '',
        dueDate: '',
        ambito: '',
        perimetro: '',
        struttura: '',
        description: '',
      },
      answer: {
        text: '',
        legal: '',
        privacy: '',
        flagPrivacyAnswer: '',
        flagLegalAnswer: '',
        note: '',
        requested_legal: '',
        requested_privacy: ''
      }
    })

  }


  disableForm() {
    this.formValue.get("newRequest").disable()
    // this.formValue.get("answer").disable()
  }

  getTitle() {
    let title = ""
    if (this.stato == 'COMPLETED' || this.stato == 'IN_CHARGE') {
      title = "Dati Richiesta"
    } else if (this.stato == 'DRAFT' || this.stato == 'REFUSED' || this.stato == 'NEW') {
      title = "Ecco la tua richiesta"
    }
    return title
  }

  canAddAllegato() {
    let canAddAllegato: boolean;
    if (this.isAdmin == false && this.legalRole == false && this.privacyRole == false) {
      if (this.stato == 'COMPLETED' || this.stato == 'IN_CHARGE' || this.stato == 'REFUSED') {
        canAddAllegato = false;
      } else if (this.stato == 'DRAFT') {
        canAddAllegato = true;
      }
    } else if (this.isAdmin == true || this.legalRole == true || this.privacyRole == true) {
      if (this.stato == 'IN_CHARGE') {
        canAddAllegato = true;
      } else {
        canAddAllegato = false;
      }
    }
    return canAddAllegato
  }

  addAttachment(attachment) {
    if (this.attachmentData.length > 0 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    //this.addingAttachment = true
    const attToShow: any = (({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }) => ({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }))(attachment);
    if (!this.attachmentData) {
      this.attachmentData = { ...attToShow }
    } else {
      this.attachmentData = [...this.attachmentData, attToShow]
    }
    this.isEmpty = false;
    this.newAttachment = [...this.newAttachment, attToShow]
  }

  removeAttachment(event) {
    if (event == 0 && this.attachmentData.length == 1) {
      this.attachmentData = [{
        document_title: "",
        object_name: "",
        description: "",
        uploaded_by: 0
      }]
      this.isEmpty = true
      this.checkAttachment(event)
    } else {
      this.checkAttachment(event)
    }
  }

  checkAttachment(position) {
    if (this.currentRequest.document_attached.length > 0) {
      let document = this.attachmentData.find(att => att.id == position)
      let index = this.attachmentData.findIndex(att => att.id == position)

      if (document) {
        this.attached_doc.splice(this.attached_doc.findIndex(id => id == document.id), 1)
        this.attachmentData.splice(index, 1)
        this.http.delete(`${environment.apiUrl}/document/${position}/`).pipe(take(1)).subscribe()
      } else {
        this.newAttachment.splice(position, 1)
        this.attachmentData.splice(position, 1)
      }
    } else {
      this.newAttachment.splice(position, 1)
      this.attachmentData.splice(position, 1)
    }
  }

  editAttachmentFromDB(event) {
    event.attachment['attachment'] = this.attachmentData[event.index].attachment
    this.attachmentData[event.index] = { ...event.attachment, id: this.attachmentData[event.index].id }
    this.isEmpty = false
  }

  disabledButton() {
    let disabled: boolean;
    if (this.isAdmin == false) {
      if (this.stato == 'COMPLETED' || this.stato == 'IN_CHARGE' || this.stato == 'NEW' || this.stato == 'REFUSED') {
        disabled = true;
      } else {
        disabled = false;
      }
    } else if (this.isAdmin == true) {
      if (this.stato == 'COMPLETED' || this.stato == 'REFUSED' || this.stato == "NEW") {
        disabled = true;
      } else {
        disabled = false;
      }
    }
    return disabled
  }

  canChat(stato) {
    let canChat: boolean;
    if (!(this.legalRole || this.privacyRole)) {
      if (this.stato == 'COMPLETED' || this.stato == 'IN_CHARGE') {
        canChat = true;
      } else if (this.stato == 'DRAFT' || this.stato == 'REFUSED') {
        canChat = false;

      }
    } else {
      canChat = false
    }
    return canChat
  }

  setFilter(data) {
    this.isFilter = data
  }

  infoRequest() {
    let infoRequest: boolean;
    return infoRequest = true
  }

  disabledAnswer() {
    let disabledAnswer: boolean;
    if (this.isAdmin == false) {
      disabledAnswer = true;
    } else if (this.isAdmin == true) {
      if (this.stato == 'COMPLETED' || this.stato == 'REFUSED' || this.stato == 'EXPIRED' || this.stato == 'NEW' || this.stato == 'EXPIRED') {
        disabledAnswer = true;
      } else {
        disabledAnswer = false;
      }
    }
    return disabledAnswer
  }



  sendToCompliance() {
    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
    } else {
      this.newValue = this.formValue.controls['newRequest'].value
      this.answerValue = this.formValue.controls['answer'].value
      if (this.newValue['dueDate'] !== '') {
        let data = new Date(this.newValue['dueDate'])
        this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
      } else {
        this.dataToSend = null
      }
      this.postData = {
        request_title: this.newValue['title'],
        priority: this.newValue['priorita'],
        due_date: this.dataToSend,
        reason: this.newValue['motivazione'],
        scope: this.newValue['ambito'],
        perimeter: this.newValue['perimetro'],
        structure_request: this.newValue['struttura'],
        description: this.newValue['description'],
        requested_user: this.currentRequest.requested_user,
        status: Status.NEW,
        document_attached: this.attached_doc,
        is_faq: false,
        comments: [],
        compliance_answer: this.answerValue['text'],
        legal_answer: this.answerValue['legal'],
        privacy_answer: this.answerValue['privacy'],
        privacy_checkbox: this.answerValue['flagPrivacyAnswer'],
        legal_checkbox: this.answerValue['flagLegalAnswer'],
        note: this.answerValue['note'],
        requested_legal: this.answerValue['requested_legal'],
        requested_privacy: this.answerValue['requested_privacy'],
      };
    }

    let attachmentSent;
    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }
    this.store.dispatch({ type: RequestActions.updateRequest, payload: { request: this.postData, attachments: attachmentSent, id: this.currentRequest.id } })

  }


  saveRequestCompliance() {

    this.newValue = this.formValue.controls['newRequest'].value
    this.answerValue = this.formValue.controls['answer'].value
    console.log("SAVEREQUEST COMP", this.answerValue)

    if (this.newValue['dueDate'] !== '') {
      let data = new Date(this.newValue['dueDate'])
      this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
    } else {
      this.dataToSend = null
    }
    this.postData = {
      request_title: this.newValue['title'],
      priority: this.newValue['priorita'],
      due_date: this.dataToSend,
      reason: this.newValue['motivazione'],
      scope: this.newValue['ambito'],
      perimeter: this.newValue['perimetro'],
      structure_request: this.newValue['struttura'],
      description: this.newValue['description'],
      requested_user: this.currentRequest.requested_user,
      status: Status.DRAFT,
      document_attached: this.attached_doc,
      is_faq: false,
      comments: [],
      compliance_answer: this.answerValue['text'],
      legal_answer: this.answerValue['legal'],
      privacy_answer: this.answerValue['privacy'],
      privacy_checkbox: this.answerValue['flagPrivacyAnswer'],
      legal_checkbox: this.answerValue['flagLegalAnswer'],
      note: this.answerValue['note'],
      requested_legal: this.answerValue['requested_legal'],
      requested_privacy: this.answerValue['requested_privacy'],
    };

    let attachmentSent;
    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }
    this.store.dispatch({ type: RequestActions.updateRequest, payload: { request: this.postData, attachments: attachmentSent, id: this.currentRequest.id } })
  }

  saveRequest() {
    this.newValue = this.formValue.controls['newRequest'].value
    this.answerValue = this.formValue.controls['answer'].value
    console.log("SAVEREQUEST", this.answerValue)
    if (this.newValue['dueDate'] !== '') {
      let data = new Date(this.newValue['dueDate'])
      this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
    } else {
      this.dataToSend = null
    }
    this.postData = {
      request_title: this.newValue['title'],
      priority: this.newValue['priorita'],
      due_date: this.dataToSend,
      reason: this.newValue['motivazione'],
      scope: this.newValue['ambito'],
      perimeter: this.newValue['perimetro'],
      structure_request: this.newValue['struttura'],
      description: this.newValue['description'],
      requested_user: this.currentRequest.requested_user,
      status: this.currentRequest.status,
      document_attached: this.attached_doc,
      is_faq: false,
      comments: [],
      compliance_answer: this.answerValue['text'],
      legal_answer: this.answerValue['legal'],
      privacy_answer: this.answerValue['privacy'],
      privacy_checkbox: this.answerValue['flagPrivacyAnswer'],
      legal_checkbox: this.answerValue['flagLegalAnswer'],
      note: this.answerValue['note'],
      requested_legal: this.answerValue['requested_legal'],
      requested_privacy: this.answerValue['requested_privacy'],
    };

    let attachmentSent;
    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }
    // path('api/request/<int:pk>/update-status/', RequestViewSet.as_view({'patch': 'update_status'}), name='my_model_update_status'),
//prova

    if (this.legalRole == true && this.answerValue['flagLegalAnswer'] == false ) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi inserire il flag per concludere l'intervento Legal" });
    }else if(this.privacyRole == true && this.answerValue['flagLegalAnswer'] == true && (this.answerValue['legal'] == null ||  this.answerValue['legal'] == "")){
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi inserire la risposta Legal" });
    }else if(this.privacyRole == true && this.answerValue['flagPrivacyAnswer'] == false){ 
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi inserire il flag per concludere l'intervento Privacy" });
    }else if(this.privacyRole == true && this.answerValue['flagPrivacyAnswer'] == true && (this.answerValue['privacy'] == null ||  this.answerValue['privacy'] == "")){
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi inserire la risposta Privacy" });
    }else {
      this.store.dispatch({ type: RequestActions.updateRequest, payload: { request: this.postData, attachments: attachmentSent, id: this.currentRequest.id } })
    }

  }

  respond(event) {
    let responses: {
      final_answer: string
      legal_answer: string,
      privacy_answer: string,
      compliance_answer: string,
      legal_checkbox: boolean;
      privacy_checkbox: boolean;
      note: string;
    }

    let status: string = event['status'] ? event['status'] : event;
    if (status !== "IN_CHARGE") {
      this.answerValue = this.formValue.controls['answer'].value
      responses = {
        compliance_answer: this.answerValue['text'],
        legal_answer: this.answerValue['legal'],
        privacy_answer: this.answerValue['privacy'],
        final_answer: event['reason'] ? event['reason'] : "",
        legal_checkbox: this.currentRequest.legal_checkbox,
        privacy_checkbox: this.currentRequest.legal_checkbox,
        note: this.answerValue['note']
      }
    }

    console.log("responbse", responses)


    if (status == "COMPLETED" && (responses.compliance_answer == null || responses.compliance_answer == '')) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi prima rispondere all'opinion" });
    } else if (this.currentRequest.legal_checkbox == false && this.currentRequest.requested_legal == true) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi prima aspettare la conclusione dell'intervento Legal" });
    } else if (this.currentRequest.privacy_checkbox == false && this.currentRequest.requested_privacy == true) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Per confermare, devi prima aspettare la conclusione dell'intervento Privacy" });
    } else {
      this.store.dispatch({ type: RequestActions.updateRequestStatus, payload: { status: status.toUpperCase(), id: this.currentRequest.id, responses: responses } })
    }
  }

  ngOnDestroy() {
    if (this.adminSub) {
      this.adminSub.unsubscribe()
    }
    if (this.requestSub) {
      this.requestSub.unsubscribe()
    }
  }


}

