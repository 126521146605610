import { Scope } from "src/app/data/models/DropDown";
import { User, UserList } from "src/app/data/models/User";

export interface AdminState {

    scopes?: string[];
    perimeters?: string[];
    residualRisks?:string[];
    priority?:string[];
    intervention_type?:string[];
    group?:string[];
    // oldgroup?:any
    users_paginated?: UserList;
    loading_users:boolean;
    roles?:string[];
    booleanbutton:boolean;
    error:string;
    
}

export const initialAdminState:AdminState = {
    scopes:[""],
    perimeters: [""],
    residualRisks:[""],
    priority:[""],
    intervention_type:[""],
    group:[""],
    // oldgroup:null,
    users_paginated: { users: [], count:0},
    loading_users:false,
    roles:[""],
    booleanbutton:false,
    error:""

};
