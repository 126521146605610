import { createAction, props } from '@ngrx/store';
import { User, UserList } from 'src/app/data/models/User';

export const enum AdminActions {
    addUser = '[ADMIN add user',
    addUserSuccess = '[ADMIN] add user success',
    addUserFailure = '[ADMIN] add user failure',

    patchUser = '[ADMIN] patch user',
    patchUserSuccess = '[ADMIN] patch user success',
    patchUserFailure = '[ADMIN] patch user failure',

    patchUserGroup = '[ADMIN] patch userGroup',
    patchUserGroupSuccess = '[ADMIN] patch user Group success',
    patchUserGroupFailure = '[ADMIN] patch user Group failure',

    patchUserEnable = '[ADMIN] patch user Enable',
    patchUserEnableSuccess = '[ADMIN] patch user Enable success',
    patchUserEnableFailure = '[ADMIN] patch user Enable failure',

    setScopes = '[ADMIN] setScopes',
    setScopesSuccess = '[ADMIN] setScopesSuccess',
    setScopesFailure = '[ADMIN] setScopesFailure',
    addScope = '[ADMIN add scope',
    addScopeSuccess = '[ADMIN] add scope success',
    addScopeFailure = '[ADMIN] add scope failure',
    deleteScope = '[ADMIN delete scope',
    deleteScopeSuccess = '[ADMIN] delete scope success',
    setPerimeters = '[ADMIN] setPerimeters',
    setPerimetersSuccess = '[ADMIN] setPerimetersSuccess',
    setPerimetersFailure = '[ADMIN] setPerimetersFailure',
    addPerimeter = '[ADMIN add perimeter',
    addPerimeterSuccess = '[ADMIN] add perimeter success',
    addPerimeterFailure = '[ADMIN] add perimeter failure',
    deletePerimeter = '[ADMIN delete perimeter',
    deletePerimeterSuccess = '[ADMIN] delete perimeter success',
    setResidualRisks = '[ADMIN] setResidualRisks',
    setResidualRisksSuccess = '[ADMIN] setResidualRisksSuccess',
    setResidualRisksFailure = '[ADMIN] setResidualRisksFailure',
    addResidualRisks = '[ADMIN add ResidualRisks',
    addResidualRisksSuccess = '[ADMIN] add ResidualRisks success',
    addResidualRisksFailure = '[ADMIN] add ResidualRisks failure',
    deleteResidualRisks = '[ADMIN delete ResidualRisks',
    deleteResidualRisksSuccess = '[ADMIN] delete ResidualRisks success',
    setPriority = '[ADMIN] setPriority',
    setPrioritySuccess = '[ADMIN] setPrioritySuccess',
    setPriorityFailure = '[ADMIN] setPriorityFailure',
    addPriority = '[ADMIN add Priority',
    addPrioritySuccess = '[ADMIN] add Priority success',
    addPriorityFailure = '[ADMIN] add Priority failure',
    deletePriority = '[ADMIN delete Priority',
    deletePrioritySuccess = '[ADMIN] delete Priority success',
    setInterventionType = '[ADMIN] setInterventionType',
    setInterventionTypeSuccess = '[ADMIN] setInterventionTypeSuccess',
    setInterventionTypeFailure = '[ADMIN] setInterventionTypeFailure',
    addInterventionType = '[ADMIN add InterventionType',
    addInterventionTypeSuccess = '[ADMIN] add InterventionType success',
    addInterventionTypeFailure = '[ADMIN] add InterventionType failure',
    deleteInterventionType = '[ADMIN delete InterventionType',
    deleteInterventionTypeSuccess = '[ADMIN] delete InterventionType success',
    deleteInterventionTypeSuccessFailure = '[ADMIN] delete InterventionType failure',

    setGroup = '[ADMIN] setGroup',
    setGroupSuccess = '[ADMIN] setGroupSuccess',
    setGroupFailure = '[ADMIN] setGroupFailure',
    addGroup = '[ADMIN add Group',
    addGroupSuccess = '[ADMIN] add Group success',
    addGroupFailure = '[ADMIN] add Group failure',
    deleteGroup = '[ADMIN delete Group',
    deleteGroupSuccess = '[ADMIN] delete Group success',
    deleteGroupFailure = '[ADMIN] delete Group success',

    connectPerimeter = '[ADMIN] connect PerimeterScope',
    connectPerimeterSuccess = '[ADMIN] connect PerimeterSuccess',
    disconnectPerimeter = '[ADMIN] disconnect Perimeter',
    disconnectPerimeterSuccess = '[ADMIN] disconnect PerimeterSuccess',
    setUserList = '[ADMIN] set UserList',
    setUserListSuccess = '[ADMIN] set UserList Success',
    getRoles = '[ADMIN] get Roles',
    getRolesSuccess = '[ADMIN] get Roles Success',
    exportTemplate = '[ADMIN] import Finding ',
    exportTemplateSuccess = '[ADMIN] import Finding Success',
    exportTemplateFailure = '[ADMIN] import Finding Failure',
    disconnectGroup = '[ADMIN] disconnect group',
    disconnectGroupSuccess = '[ADMIN] disconnect group success',
    disconnectGroupFailure = '[ADMIN] disconnect group failure'

}

export const patchUser = createAction(
    AdminActions.patchUser,
    props<{
        id: string,
        role: string,
        start: number,
        end: number,
    }>()
);

export const patchUserSuccess = createAction(
    AdminActions.patchUserSuccess,
    props<{
        start: number,
        end: number,
    }>()
);

export const exportTemplate = createAction(
    AdminActions.exportTemplate,
);
export const exportTemplateSuccess = createAction(
    AdminActions.exportTemplateSuccess,
);
export const exportTemplateFailure = createAction(
    AdminActions.exportTemplateFailure,
);


export const patchUserFailure = createAction(
    AdminActions.patchUserFailure,

);

export const patchUserGroup = createAction(
    AdminActions.patchUserGroup,
    props<{
        id: string,
        booleangroup:any
        oldgroup:any,
        group: string,
        start: number,
        end: number,
    }>()
);


export const patchUserEnable = createAction(
    AdminActions.patchUserEnable,
    props<{
        id: string,
        enable: string,
        start: number,
        end: number,
    }>()
);

export const patchUserEnableSuccess = createAction(
    AdminActions.patchUserEnableSuccess,
    props<{
        start: number,
        end: number,
    }>()
);


export const patchUserEnableFailure = createAction(
    AdminActions.patchUserEnableFailure,

);

export const patchUserGroupSuccess = createAction(
    AdminActions.patchUserGroupSuccess,
    props<{
        start: number,
        end: number,
    }>()
);


export const patchUserGroupFailure = createAction(
    AdminActions.patchUserGroupFailure,

);


export const addUser = createAction(
    AdminActions.addUser,
    props<{
        name: string,
        lastname: string,
        username: string,
        password: string,
        email: string,
        role: string,
    }>()
);

export const addUserSuccess = createAction(
    AdminActions.addUserSuccess,

);

export const addUserFailure = createAction(
    AdminActions.addUserFailure,
    props<{
        error: string
    }>()
);

export const connectPerimeter = createAction(
    AdminActions.connectPerimeter,
    props<{
        idScope: string,
        idPerimeter: any[]
    }>()
);
export const connectPerimeterSuccess = createAction(
    AdminActions.connectPerimeterSuccess,
    props<{ field: string }>()
);

export const disconnectPerimeter = createAction(
    AdminActions.disconnectPerimeter,
    props<{
        idScope: string,
        idPerimeter: any[]
    }>()
);
export const disconnectPerimeterSuccess = createAction(
    AdminActions.disconnectPerimeterSuccess,
    props<{ field: string }>()
);

export const disconnectGroup = createAction(
    AdminActions.disconnectGroup,
    props<{
        idUser: string,
        nameGroup: any[]
    }>()
);
export const disconnectGroupSuccess = createAction(
    AdminActions.disconnectGroupSuccess,
    props<{
        start: number,
        end: number,
    }>()
);

export const disconnectGroupFailure = createAction(
    AdminActions.disconnectGroupFailure,
);

export const setScopes = createAction(
    AdminActions.setScopes,
    props<{
        field: string
    }>()
);
export const setScopesSuccess = createAction(
    AdminActions.setScopesSuccess,
    props<{
        scopes: string[]
    }>()
);

export const addScope = createAction(
    AdminActions.addScope,
    props<{
        field: string,
        data: string
    }>()
);

export const addScopeSuccess = createAction(
    AdminActions.addScopeSuccess,
    props<{
        field: string,
    }>()
);

export const addScopeFailure = createAction(
    AdminActions.addScopeFailure,

);

export const deleteScope = createAction(
    AdminActions.deleteScope,
    props<{
        field: string,
        data: string
    }>()
);

export const deleteScopeSuccess = createAction(
    AdminActions.deleteScopeSuccess,
    props<{
        field: string,
    }>()
);

export const setPerimeters = createAction(
    AdminActions.setPerimeters,
    props<{
        field: string
    }>()
);
export const setPerimetersSuccess = createAction(
    AdminActions.setPerimetersSuccess,
    props<{
        perimeters: string[]
    }>()
);

export const addPerimeter = createAction(
    AdminActions.addScope,
    props<{
        field: string,
        data: string
    }>()
);

export const addPerimeterSuccess = createAction(
    AdminActions.addScopeSuccess,
    props<{
        field: string,
    }>()
);

export const addPerimeterFailure = createAction(
    AdminActions.addPerimeterFailure,

);

export const deletePerimeter = createAction(
    AdminActions.deletePerimeter,
    props<{
        field: string,
        data: string
    }>()
);

export const deletePerimeterSuccess = createAction(
    AdminActions.deletePerimeterSuccess,
    props<{
        field: string,
    }>()
);

//
export const setResidualRisks = createAction(
    AdminActions.setResidualRisks,
    props<{
        field: string
    }>()
);
export const setResidualRisksSuccess = createAction(
    AdminActions.setResidualRisksSuccess,
    props<{
        residualRisks: string[]
    }>()
);

export const addResidualRisks = createAction(
    AdminActions.addResidualRisks,
    props<{
        field: string,
        data: string
    }>()
);

export const addResidualRisksSuccess = createAction(
    AdminActions.addResidualRisksSuccess,
    props<{
        field: string,
    }>()
);

export const addResidualRisksFailure = createAction(
    AdminActions.addResidualRisksFailure,

);

export const deleteResidualRisks = createAction(
    AdminActions.deleteResidualRisks,
    props<{
        field: string,
        data: string
    }>()
);

export const deleteResidualRisksSuccess = createAction(
    AdminActions.deleteResidualRisksSuccess,
    props<{
        field: string,
    }>()
);


export const setPriority = createAction(
    AdminActions.setPriority,
    props<{
        field: string
    }>()
);
export const setPrioritySuccess = createAction(
    AdminActions.setPrioritySuccess,
    props<{
        priority: string[]
    }>()
);

export const addPriority = createAction(
    AdminActions.addPriority,
    props<{
        field: string,
        data: string
    }>()
);

export const addPrioritySuccess = createAction(
    AdminActions.addPrioritySuccess,
    props<{
        field: string,
    }>()
);

export const addPriorityFailure = createAction(
    AdminActions.addPriorityFailure,

);

export const deletePriority = createAction(
    AdminActions.deletePriority,
    props<{
        field: string,
        data: string
    }>()
);

export const deletePrioritySuccess = createAction(
    AdminActions.deletePrioritySuccess,
    props<{
        field: string,
    }>()
);


export const setInterventionType = createAction(
    AdminActions.setInterventionType,
    props<{
        field: string
    }>()
);
export const setInterventionTypeSuccess = createAction(
    AdminActions.setInterventionTypeSuccess,
    props<{
        intervention_type: string[]
    }>()
);

export const addInterventionType = createAction(
    AdminActions.addInterventionType,
    props<{
        field: string,
        data: string
    }>()
);

export const addInterventionTypeSuccess = createAction(
    AdminActions.addInterventionTypeSuccess,
    props<{
        field: string,
    }>()
);

export const addInterventionTypeFailure = createAction(
    AdminActions.addInterventionTypeFailure,

);

export const deleteInterventionType = createAction(
    AdminActions.deleteInterventionType,
    props<{
        field: string,
        data: string
    }>()
);

export const deleteInterventionTypeSuccess = createAction(
    AdminActions.deleteInterventionTypeSuccess,
    props<{
        field: string,
    }>()
);


//

export const setGroup = createAction(
    AdminActions.setGroup,

);
export const setGroupSuccess = createAction(
    AdminActions.setGroupSuccess,
    props<{
        group: string[]
    }>()
);

export const addGroup = createAction(
    AdminActions.addGroup,
    props<{

        data: string
    }>()
);

export const addGroupSuccess = createAction(
    AdminActions.addGroupSuccess,

);

export const addGroupFailure = createAction(
    AdminActions.addGroupFailure,

);

export const deleteGroup = createAction(
    AdminActions.deleteGroup,
    props<{

        data: string
    }>()
);

export const deleteGroupSuccess = createAction(
    AdminActions.deleteGroupSuccess,

);

export const deleteGroupFailure = createAction(
    AdminActions.deleteGroupFailure,
    props<{
        field: string,
    }>()
);




export const getRoles = createAction(
    AdminActions.getRoles,
);

export const getRolesSuccess = createAction(
    AdminActions.getRolesSuccess,
    props<{ roles: string[] }>()
);

export const setUserList = createAction(
    AdminActions.setUserList,
    props<{
        start: number,
        end: number,
        filters?: string
    }>()
);

export const setUserListSuccess = createAction(
    AdminActions.setUserListSuccess,
    props<{ users_paginated: UserList }>()
);
