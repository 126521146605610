import { DbRequest } from "src/app/data/models/Request";


export interface RequestsState {
    requests: {
        requests: DbRequest[],
        size: number
    },
    faqs: {
        faqs: DbRequest[],
        size: number
    },
    currentRequest: DbRequest,
    loadingRequest: boolean,
    newRequest: DbRequest,
}

export const initialRequestState: RequestsState = {
    requests: null,
    faqs: null,
    currentRequest: null,
    loadingRequest: false,
    newRequest: null,
};


