<div class=" blocks p-3 w-full h-full">
    <div class="border bg-white p-2 h-full w-full  ">
        <div class="flex flex-column p-3 pb-0 h-10">
            <h1 class="title m-0">
                Homepage 
            </h1>
            <hr>
        </div>
        <div class="flex flex-column w-full h-full align-items-center justify-content-center">
            <p-carousel class="mt-4 w-full" [value]="blocks" [numVisible]="3" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" >
                <ng-template  let-block pTemplate="item">
                    <div class="block" (click)="block.items ? assignLittleBlock(block) : null" [routerLink]="block.items ? null : '../' + block.routerLink" [queryParams]="block?.queryParams">
                        <div class="prova flex flex-column h-full justify-content-center align-items-center">
                            <i [ngClass]="block.icon" class="icon flex"></i>
                            <p class="text-block flex m-0 mt-3">{{ block.label }}</p>
                        </div>
                    </div>
                </ng-template>
            </p-carousel>
        
           <div class="flex flex-column w-full " *ngIf="littleblocks.length > 0 ">
               <p-carousel class="hide-page-links " [value]="littleblocks" [numVisible]="5" [numScroll]="1" [circular]="false" [responsiveOptions]="responsiveOptions" >
                   <ng-template let-block pTemplate="item">
                    <div class="little-block" [routerLink]="'../' + block.routerLink" [queryParams]="block?.queryParams">
                           <div class="prova flex flex-column h-full justify-content-center align-items-center">
                            <div class="flex">
                                <i [ngClass]="block.icon" class="little-icon flex"></i>
                            </div>
                            <div class="flex ">
                                <p class="little-text-block flex m-0">{{ block.label }}</p>
                            </div>
                           </div>
                       </div>
                   </ng-template>
               </p-carousel>
        </div>
    </div>
      
    </div>
</div>

<!-- <div *ngFor="let block of blocks" class="block "  [routerLink]="'../' + block.routerLink" [queryParams]="block?.queryParams">
    <div  class="prova flex flex-column h-full justify-content-center align-items-center">
        <i [ngClass]="block.icon" class="icon flex"></i>
        <p  class="text-block flex">{{block.label}}</p>
    </div>
</div> -->
<!-- <p-carousel [numVisible]="3" [numScroll]="1">
    <ng-template pTemplate let-block="item">
      <div class="block" [routerLink]="'../' + block.routerLink" [queryParams]="block?.queryParams">
        <div class="prova flex flex-column h-full justify-content-center align-items-center">
          <i [ngClass]="block.icon" class="icon flex"></i>
          <p class="text-block flex">{{ block.label }}</p>
        </div>
      </div>
    </ng-template>
  </p-carousel> -->