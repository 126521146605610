import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree, Router } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class NotAdminGuard implements CanActivate {
  constructor(private keycloakService: KeycloakService, private router: Router) { }
  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    // Verifica se l'utente è un amministratore
    const isAdmin = this.keycloakService.getUserRoles().includes('mooney_admin');

    // Se l'utente non è un amministratore, reindirizza alla pagina di accesso negato o a un'altra pagina di tua scelta
    if (isAdmin) {
      // Reindirizza a una pagina di accesso negato o a un'altra pagina
      return true;
    }else {
      this.router.navigate(['home']);
      return false
      
    }
    // L'utente è un amministratore e può accedere alla rotta
  }
  
}
