import { EventEmitter, Input, Output, SimpleChange, SimpleChanges, inject, } from '@angular/core';
import { FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { LazyLoadEvent, MessageService } from 'primeng/api';
import { debounceTime, forkJoin, from, map, merge, mergeAll } from 'rxjs';
import { User } from 'src/app/data/models/User';

import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { selectBooleanButton } from 'src/app/utils/store/admin/admin.selector';
import { Store } from '@ngrx/store';


@Component({
  selector: 'app-dynamic-list',
  templateUrl: './dynamic-list.component.html',
  styleUrls: ['./dynamic-list.component.scss']

})

export class DynamicListComponent implements OnInit {
  fb = inject(FormBuilder);
  addField: string;
  option: string;
  listItems: any[] = [];
  addingItem: string;
  message: string;
  first: number;
  last: number;
  searchParams: string | undefined;
  disabled: boolean
  buttonload: boolean = false
  Dialog: boolean = false;
  DialogEdit: boolean;
  userToShow: any;
  
  @Input() isDropdownManage: boolean = false;
  @Input() seeAll: boolean = true;
  @Input() selectedDropDown: string
  @Input() values: any[];
  @Input() isLoading: boolean = false
  @Input() dropDownOptions: { [id: string]: string[] }
  @Input() lazy: boolean = true;
  @Input() title: string | string[] = "";
  @Input() itemSize: number = 75;
  @Input() canAddItems: boolean = false;
  @Input() canRemoveItems: boolean = false;
  @Input() canEditItems: boolean = false;
  @Input() canRemoveGroup: boolean = false;
  @Input() manageGroup: boolean = false;
  @Output() selectedDropDownChange: EventEmitter<string> = new EventEmitter<string>()
  @Output() loadEvent: EventEmitter<any> = new EventEmitter<any>
  @Output() changeDropdownTitle: EventEmitter<any> = new EventEmitter<string>
  @Output() changeDropdown: EventEmitter<any> = new EventEmitter<string>
  @Output() apiAddDropDown: EventEmitter<string> = new EventEmitter<string>
  @Output() apiRemoveDropDown: EventEmitter<string> = new EventEmitter<string>
  @Output() editUser : EventEmitter<any> = new EventEmitter<any>

  formGroupDropDown: FormGroup = this.fb.group({
    selectDropDown: [''],
    search: [''],
    add: ['', Validators.maxLength(255)],
    items: this.fb.array([])
  });
  
  


  get items(): FormArray {
    return this.formGroupDropDown.get('items') as FormArray
  }
  get dropdowns(): FormArray {
    return this.formGroupDropDown.get('dropdowns') as FormArray
  }

  constructor(private router: Router, private store: Store, private messageService: MessageService) {
    this.values?.forEach((item) => {
      console.log("Guardo questo campo ---->", item);
      this.items.push(this.fb.group({
        dropdowns: this.fb.array([])
      }))
      let keys = Object.keys(item)
      keys.forEach(element => {
        if (typeof item[element] === 'object') {

          this.dropdowns?.push(this.fb.group({
            [element]: [""]
          }))
        }
      });

    })
  }

  ngOnInit() {
    this.store.select(selectBooleanButton).pipe(map(value => this.buttonload = value)).subscribe()
    console.log(this.buttonload)
    merge(
      this.formGroupDropDown.get('search').valueChanges.pipe(
        debounceTime(500),
        map(event => {
          this.searchParams = event
          this.loadEvent.emit({ first: this.first, last: this.last, search: event })
        })
      ),
      this.formGroupDropDown.get('selectDropDown').valueChanges.pipe(
        map(event => {
          this.changeDropdownTitle.emit(event)
          console.log("Event emitted --> ", event)
        })
      )
    ).subscribe()
    this.formGroupDropDown.get('selectDropDown').patchValue(this.selectedDropDown)
    this.disabled = true

  }



  ngOnChanges(changes: SimpleChanges) {
    //console.log("CHANGES",this.selectedDropDown,changes)
    if (Object.keys(changes).includes('selectedDropDown')) {
      this.formGroupDropDown.get('selectDropDown').patchValue(this.selectedDropDown)
    }
  }

  getItems(item) {
    if (typeof item === 'object') {
      this.listItems = [...Object.keys(item)];
      const id = this.listItems.indexOf('id')
      const usernameIndex = this.listItems.indexOf('username')
      if (id > -1) {
        this.listItems.splice(id, 1)
      }
      if (usernameIndex > -1) {
        
        this.listItems = [
          "email",
          "username",
          "roles",
          "groups",
          "enabled"
        ]
      }
    }
    return this.listItems
  }

  printFor(event, item, field?) {
    const i = this.values.findIndex(value => value.id == item.id)
    this.changeDropdown.emit({ index: i, value: event.value })
    console.log(event.value, item)
  }

  isObject(item) {
    return typeof item === 'object' ? true : false
  }

  loadCarsLazy(event) {
    this.first = event.first;
    this.last = event.last;
    event = { ...event, search: this.searchParams }
    this.loadEvent.emit(event)
    console.log("loading event", event)
  }

  addItem(value: string) {
    // console.log(this.formGroupDropDown.get('add').value==undefined)
    if (this.formGroupDropDown.get('add').value) {

      if (this.formGroupDropDown.get('add').value.length >= 255) {
        this.messageService.add({ severity: 'error', summary: 'Massimo 255 carattesi' })
      } else {
        this.apiAddDropDown.emit(value)
        console.log("api add dropdown", value)
      }
    }else{
      this.messageService.add({ severity: 'error', summary: 'Riempi il campo!' })

    }
  }
  showDialog() {
    this.Dialog = true;
  }
  removeItem(index) {
    this.apiRemoveDropDown.emit(index)
    console.log("api remove dropdown", index)
  }
  showDialogEdit(event){
    this.userToShow = event
    this.DialogEdit = true;
  }
  editItem(item) {
    this.editUser.emit(item)
    console.log("api edit dropdown", item)
  }


  
}
