import { createReducer, on } from "@ngrx/store";
import { initialAdminState } from "./admin.state";
import { addInterventionType, addInterventionTypeFailure, addInterventionTypeSuccess, addPerimeter, addPerimeterFailure, addPerimeterSuccess, addPriority, addPriorityFailure, addPrioritySuccess, addResidualRisks, addResidualRisksFailure, addResidualRisksSuccess, addScope, addScopeFailure, addScopeSuccess, addUser, addUserFailure, addUserSuccess, connectPerimeterSuccess, getRolesSuccess, patchUser, patchUserEnable, patchUserEnableFailure, patchUserEnableSuccess, patchUserFailure, patchUserGroup, patchUserGroupFailure, patchUserGroupSuccess, patchUserSuccess, setGroup, setGroupSuccess, setInterventionTypeSuccess, setPerimeters, setPerimetersSuccess, setPrioritySuccess, setResidualRisksSuccess, setScopesSuccess, setUserList, setUserListSuccess } from "./admin.actions";

export const adminReducer = createReducer(
    initialAdminState,
    on(getRolesSuccess, (state, { roles }) => ({ ...state, roles })),
    on(setUserList, (state,) => ({ ...state, loading_users: true })),
    on(setUserListSuccess, (state, { users_paginated }) => ({ ...state, users_paginated, loading_users: false })),
    on(setScopesSuccess, (state, { scopes }) => ({ ...state, scopes, booleanbutton: false })),
    on(setPerimetersSuccess, (state, { perimeters }) => ({ ...state, perimeters, booleanbutton: false })),
    on(setResidualRisksSuccess, (state, { residualRisks }) => ({ ...state, residualRisks, booleanbutton: false })),
    on(setPrioritySuccess, (state, { priority }) => ({ ...state, priority, booleanbutton: false })),
    on(setGroupSuccess, (state, { group }) => ({ ...state, group, booleanbutton: false })),
    on(setInterventionTypeSuccess, (state, { intervention_type }) => ({ ...state, intervention_type, booleanbutton: false })),
    on(connectPerimeterSuccess, (state) => ({ ...state, booleanbutton: true })),
    on(addScope, (state) => ({ ...state, booleanbutton: true })),
    on(addScopeSuccess, (state) => ({ ...state, booleanbutton: true })),
    on(addScopeFailure, (state) => ({ ...state, booleanbutton: false })),
    on(addPerimeter, (state) => ({ ...state, booleanbutton: true })),
    on(addPerimeterSuccess, (state) => ({ ...state, booleanbutton: true })),
    on(addPerimeterFailure, (state) => ({ ...state, booleanbutton: false })),
    on(addResidualRisks, (state) => ({ ...state, booleanbutton: true })),
    on(addResidualRisksSuccess, (state) => ({ ...state, booleanbutton: true })),
    on(addResidualRisksFailure, (state) => ({ ...state, booleanbutton: false })),
    on(addPriority, (state) => ({ ...state, booleanbutton: true })),
    on(addPrioritySuccess, (state) => ({ ...state, booleanbutton: true })),
    on(addPriorityFailure, (state) => ({ ...state, booleanbutton: false })),
    on(addInterventionType, (state) => ({ ...state, booleanbutton: true })),
    on(addInterventionTypeSuccess, (state) => ({ ...state, booleanbutton: true })),
    on(addInterventionTypeFailure, (state) => ({ ...state, booleanbutton: false })),
    on(addUser, (state) => ({ ...state, booleanbutton: true })),
    on(addUserSuccess, (state) => ({ ...state, booleanbutton: false })),
    on(addUserFailure, (state, { error }) => ({ ...state, booleanbutton: false, error })),
    on(patchUser, (state) => ({ ...state, booleanbutton: true })),
    on(patchUserSuccess, (state) => ({ ...state, booleanbutton: false })),
    on(patchUserFailure, (state) => ({ ...state, booleanbutton: false })),
    on(patchUserGroup, (state) => ({ ...state, booleanbutton: true })),
    on(patchUserGroupSuccess, (state) => ({ ...state, booleanbutton: false })),
    on(patchUserGroupFailure, (state) => ({ ...state, booleanbutton: false })),
    on(patchUserEnable, (state) => ({ ...state, booleanbutton: true })),
    on(patchUserEnableSuccess, (state) => ({ ...state, booleanbutton: false })),
    on(patchUserEnableFailure, (state) => ({ ...state, booleanbutton: false })),
);