import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { catchError, Observable, of, throwError } from 'rxjs';
import { ActivatedRoute, Route, Router } from '@angular/router';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';

@Injectable()
export class CustomHttpInterceptor implements HttpInterceptor {

  constructor( private router: Router, private activatedRouter:ActivatedRoute) { }

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(catchError((error: HttpErrorResponse)=>{
      if (error.status == 404 || error.status == 401) {
        if(!this.router.url.includes('profile')){
          this.router.navigate([ROUTES_STRINGS.NOTFOUND])

        }
        
      }

      return throwError(error)
    }));


  }
}
