import { Component, Input, OnInit } from '@angular/core';
import { UserService } from 'src/app/utils/services/user.service';
import { CheckboxModule } from 'primeng/checkbox';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Operation, Perimeter, Risk, Scope } from 'src/app/data/models/Finding';
import { environment } from 'src/environments/environment';
import { Observable, Subscription, distinctUntilChanged, take } from 'rxjs';
import * as jsonambiti from 'src/environments/ambiti.json';
import { HttpClient, HttpResponse } from '@angular/common/http';


@Component({
  selector: 'app-form-attributes',
  templateUrl: './form-attributes.component.html',
  styleUrls: ['./form-attributes.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: FormAttributesComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: FormAttributesComponent }
  ]
})
export class FormAttributesComponent implements ControlValueAccessor, Validator, OnInit {


  ambiti: any
  fieldAOptions: any
  ambito: string;
  ambitijson:any;
  riskselect: any
  interventionselect:any;
  rischio: string;
  fieldRiskOptions: any
  residualrisk:string;
  residualriskjson:any
  intervento:string;
  interventiontype: string;
  interventiontypejson: any;
  fieldInterventionOptions:any
  perimetro: string;
  fieldBOptions: string[] = [];
  emptyMessage: string[] = [];




  @Input() invalidBoolean: boolean = false;
  value: number = 0;
  formGroup: FormGroup;

  scope: Scope[];
  selectedScope: string;

  users: any[] = [""]
  //{[id:string]:{full_name:string, username:string}}
  selectedUser: string;

  operation: Operation[];
  selectedOperation: string;

  perimeter: Perimeter[];
  selectedPerimeter: string;

  risk: Risk[];
  selectedRisk: string;

  preavviso: boolean;
  scadenza: boolean;

  date: Date | string
  dateMinutes: string = "00"
  dateHours: string = "00"
  selectedDate: Date;
  checkedReminderDay: boolean = false;
  checkedExpirtDay: boolean = false;
  disabilita: boolean = false;
  minDate: Date;


  styleDropDownScope: string = "w-full";
  styleDropDownPerimeter: string = "w-full";
  styleDropDownOperation: string = "w-full";
  styleDropDownRisk: string = "w-full";
  styleCalendar: string = "";
  styleCalendarRescheduled: string = "";
  styleNumber: string = "";
  styleUserAssigned: string = "customUser w-full"
  errore: string = "errore"
  reminderday: number
  sub: Subscription;
  @Input() stato: string;
  @Input() isAdmin: boolean;
  userSub: Observable<any>
  dateRescheduling: Date | string
  counterRescheduling: number
  @Input() isNewFinding: boolean = false;



  onChanged!: () => void
  onTouched!: () => void
  onDisabled!: (disable: boolean) => void


  writeValue(obj: any): void {
    this.formGroup.patchValue(obj);
    if (obj.dataVincolante) {
      this.date = new Date(obj.dataVincolante)
      this.formGroup.get('dataVincolante').patchValue(this.date)
    }

    if (obj.dataRescheduling) {
      this.date = new Date(obj.dataRescheduling)
      this.formGroup.get('dataRescheduling').patchValue(this.date)
    }

    if (obj.utenteAssegnatario) {
      this.selectedUser = obj.utenteAssegnatario
    }
    

    if (obj.ambito) {
      this.filterFieldBOptions()
    }

    // switch (obj.tipologiaIntervento) {
    //   case 'Normativa Interna':
    //     this.selectedOperation = Operation.normativa;
    //     break;
    //   case 'Strumenti e tecnicalità':
    //     this.selectedOperation = Operation.strumenti;
    //     break;
    //   case 'Processi e procedure':
    //     this.selectedOperation = Operation.processi;
    //     break;
    // }

    // switch (obj.scenarioResiduo) {
    //   case 'Alto':
    //     this.selectedRisk = Risk.alto;
    //     break;
    //   case 'Medio Alto':
    //     this.selectedRisk = Risk.medioalto;
    //     break;
    //   case 'Medio Basso':
    //     this.selectedRisk = Risk.mediobasso;
    //     break;
    //   case 'Basso':
    //     this.selectedRisk = Risk.basso;
    //     break;
    // }
  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn)
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      let value = this.formGroup.value
      console.log(value.counter)
      this.formGroup.disable()
      console.log(this.formGroup)

      if (this.ambito) {
        this.fieldBOptions = this.ambitijson[this.ambito];
      }
      this.formGroup.patchValue(value)
      if (this.isAdmin && this.stato !== 'NEW' && this.stato !== 'CLOSED' && this.stato !== 'DRAFT') {
        this.formGroup.get('problemaRiscontrato').enable()
        this.formGroup.get('azioneNecessaria').enable()
        this.formGroup.get('utenteAssegnatario').enable()
        this.formGroup.get('dataRescheduling').enable()

      }

      if(this.isAdmin && this.stato == 'DRAFT') {
       
        this.formGroup.enable()
        // this.formGroup.get('utenteAssegnatario').disable()

        this.formGroup.get('idFinding').disable()
        this.formGroup.get('dataRescheduling').disable()
        this.formGroup.get('counter').disable()


      }
    }
  }

  validate(control: AbstractControl): ValidationErrors {

    if (control.valueChanges && this.formGroup.valid) {
      return null
    } else {
      return { required: true }
    }
  }


  constructor(private userService: UserService, private fb: FormBuilder, private http: HttpClient) {

    this.risk = [
      Risk.alto,
      Risk.medioalto,
      Risk.mediobasso,
      Risk.basso,
    ]

    this.operation = [
      Operation.normativa,
      Operation.strumenti,
      Operation.processi,
    ];
  }


  ngOnInit() {
    // console.log(this.stato)
    // this.ambiti = jsonambiti
    // this.fieldAOptions = Object.keys(this.ambiti).sort()

    this.residualrisk = localStorage.getItem('Residualrisk')
    this.residualriskjson = JSON.parse(this.residualrisk);
    this.fieldRiskOptions = this.residualriskjson.reduce((acc, obj) => {
      acc[obj.id] = obj.name;
      return acc;
    }, {});

    this.riskselect = Object.values(this.fieldRiskOptions)

    this.interventiontype = localStorage.getItem('InterventionType')
    this.interventiontypejson = JSON.parse(this.interventiontype);
    this.fieldInterventionOptions = this.interventiontypejson.reduce((acc, obj) => {
      acc[obj.id] = obj.name;
      return acc;
    }, {});

    this.interventionselect = Object.values(this.fieldInterventionOptions)

    this.ambiti = localStorage.getItem('Ambiti')
    this.ambitijson = JSON.parse(this.ambiti);

    this.fieldAOptions = Object.keys(this.ambitijson).sort()


    this.minDate = new Date()
    this.checkedReminderDay = false;
    this.formGroup = this.fb.group({
      idFinding: [''],
      utenteAssegnatario: ['', Validators.required],
      idScenario: ['', [Validators.required, Validators.maxLength(255)]],
      ambito: ['', Validators.required],
      scenarioResiduo: ['', Validators.required],
      perimetro: ['', Validators.required],
      tipologiaIntervento: ['', Validators.required],
      dataVincolante: ['', Validators.required],
      problemaRiscontrato: ['', [Validators.required, Validators.maxLength(65535)]],
      azioneNecessaria: ['', [Validators.required, Validators.maxLength(65535)]],
      flag_reminder: [''],
      reminder_days: ['',],
      flag_expiry_date: [''],
      riutata: [''],
      dataRescheduling: [''],
      counter: ['']
    })

    this.formGroup.get("riutata").disable()
    this.formGroup.get("idFinding").disable()
    //this.formGroup.get("utenteAssegnatorio").disable()

    this.formGroup.get("tipologiaIntervento").valueChanges.subscribe(
      res => this.styleDropDownOperation = "w-full"
    )

    this.formGroup.get("ambito").valueChanges.subscribe(
      res => this.styleDropDownScope = "w-full"
    )
    this.formGroup.get("scenarioResiduo").valueChanges.subscribe(
      res => this.styleDropDownRisk = "w-full"
    )

    this.formGroup.get("perimetro").valueChanges.subscribe(
      res => this.styleDropDownPerimeter = "w-full"
    )

    this.formGroup.get("dataVincolante").valueChanges.subscribe(
      res => this.styleCalendar = ""
    )
    this.formGroup.get("dataRescheduling").valueChanges.subscribe(
      res => this.styleCalendarRescheduled = ""
    )
    this.formGroup.get("utenteAssegnatario").valueChanges.subscribe(
      res => this.styleUserAssigned = "customUser w-full"
    )




    this.formGroup.get("flag_reminder").valueChanges.subscribe(
      res => {
        let reminder = this.formGroup.get('reminder_days')
        if (res && !this.formGroup.get("flag_reminder").disabled) {
          reminder.setValidators([Validators.required, Validators.min(1)])
          reminder.updateValueAndValidity()
          reminder.enable()
          reminder.markAsTouched()
        } else {
          reminder.disable()
        }
      }

    )

    this.formGroup.get("reminder_days").valueChanges.subscribe(
      res => this.styleNumber = ""
    )



  }

  // onFilterDropdown($event){
  //   if (this.perimetro.length === 0) {
  //     this.fieldBOptions.emptyMessage = 'Nessun risultato trovato';
  //   }
  // }


  filterFieldBOptions() {

    if (this.ambito) {
      this.fieldBOptions = this.ambitijson[this.ambito].sort();
    } else {
      this.fieldBOptions = [];
    }

  }


  ngOnChanges() {
    if (this.invalidBoolean == true) {
      if (this.formGroup.get("tipologiaIntervento").invalid) {
        this.styleDropDownOperation = "customdrop w-full";
      }
      if (this.formGroup.get("perimetro").invalid) {
        this.styleDropDownPerimeter = "customdrop w-full";
      }
      if (this.formGroup.get("ambito").invalid) {
        this.styleDropDownScope = "customdrop w-full";
      }
      if (this.formGroup.get("scenarioResiduo").invalid) {
        this.styleDropDownRisk = "customdrop w-full";
      }
      if (this.formGroup.get("dataVincolante").invalid) {
        this.styleCalendar = "custom-cal"
      }
      if (this.formGroup.get("dataRescheduling").invalid) {
        this.styleCalendarRescheduled = "custom-cal"
      }
      if (this.formGroup.get("reminder_days").invalid) {
        this.styleNumber = "customnumber"
      }
      if (this.formGroup.get("utenteAssegnatario").invalid) {
        this.styleUserAssigned = "customAutocomplete w-full"
      }
      this.formGroup.markAllAsTouched()

    } else {
      this.styleDropDownScope = " w-full";
      this.styleDropDownPerimeter = " w-full";
      this.styleDropDownOperation = " w-full";
      this.styleDropDownRisk = "w-full";
      this.styleCalendar = "";
      this.styleCalendarRescheduled = "";
      this.styleNumber = "";
      this.styleUserAssigned = "customUser w-full";
    }


  }

  search(event) {
    this.users = [""]
    // this.userSub = this.userService.getBusinessUserList(event.query)
    this.userSub = this.userService.getGroupList(event.query)

    if (this.sub) {
      this.sub.unsubscribe()
    }
    this.sub = this.userSub.subscribe((res: any[]) => {
      console.log(res)
      this.users = [...res]
      console.log(this.users)
      

      // this.users.pop()
      // let keys = Object.keys(res)

      // if (keys.length > 0) {
      //   keys.forEach(key => {
          // this.users = [...this.users]

          // this.users = [...this.users, { id: key, full_name: res[key].full_name, username: res[key].username, email: res[key].email }]
      //   })
      // }
    })
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }
}
