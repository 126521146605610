import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from '../components/login/login.component';
import { ComplianceOpinionComponent } from '../features/compliance-opinion/compliance-opinion.component';
import { FollowUpRemedationPlanComponent } from '../features/follow-up-remedation-plan/follow-up-remedation-plan.component';
import { HomePageComponent } from '../features/home-page/home-page.component';
import { RequestsComponent } from '../features/compliance-opinion/requests/requests.component';
import { DynamicTable } from '../components/dynamic-table/dynamic-table.component';
import { FaqComponent } from '../features/compliance-opinion/faq/faq.component';
import { FormNewRequestComponent } from '../features/compliance-opinion/new-request/form-new-request/form-new-request.component'
import { NewRequestComponent } from '../features/compliance-opinion/new-request/new-request.component';
import { SeeRequestComponent } from '../features/compliance-opinion/see-request/see-request.component';
import { TableRequestsComponent } from '../features/compliance-opinion/table-requests/table-requests.component';
import { FindingAssignedComponent } from '../features/follow-up-remedation-plan/finding-assigned/finding-assigned.component';
import { TableRequestsFollowUpComponent } from '../features/follow-up-remedation-plan/table-requests-follow-up/table-requests-follow-up.component';
import { DetailFindingComponent } from '../features/follow-up-remedation-plan/detail-finding/detail-finding.component';
import { NewFindingComponent } from '../features/follow-up-remedation-plan/new-finding/new-finding.component';
import { FullnotifyComponent } from '../components/fullnotify/fullnotify.component';
import { ProfileComponent } from '../components/profile/profile.component';
import { AuthGuard } from '../utilities/keycloak_auth_guard';
import { NewFaqComponent } from '../features/compliance-opinion/new-faq/new-faq.component';
import { TableFaqComponent } from '../features/compliance-opinion/table-faq/table-faq.component';
import { SeeFaqComponent } from '../features/compliance-opinion/see-faq/see-faq.component';
import { NotfoundComponent } from '../components/notfound/notfound.component';
import { AdminDashComponent } from '../admin/admin-dash/admin-dash.component';
import { ManageUserComponent } from '../admin/admin-dash-tabs/manage-user/manage-user.component';
import { DropdownSelectorComponent } from '../admin/admin-dash-tabs/dropdown-selector/dropdown-selector.component';
import { ScopeListComponent } from '../admin/admin-dash-tabs/dropdown-selector/scope-list/scope-list.component';
import { AdminHomeComponent } from '../admin-home/admin-home.component';
import { CreateUserComponent } from '../admin/admin-dash-tabs/create-user/create-user.component';
import { ScopePerimeterComponent } from '../admin/admin-dash-tabs/scope-perimeter/scope-perimeter.component';
import { ImportComponent } from '../admin/admin-dash-tabs/import/import.component';
import { AdminGuard } from '../utilities/admin.guard';
import { NotAdminGuard } from '../utilities/not-admin.guard';
import { MakeGroupComponent } from '../admin/admin-dash-tabs/make-group/make-group.component';

export const enum ROUTES_STRINGS {
  LOGIN = 'login',
  HOME = 'home',
  COMPLIANCE_OPINION = 'compliance-opinion',
  FOLLOW_UP_REMEDATION_PLAN = 'follow-up-remedation-plan',
  FINDING_ASSIGNED = 'finding-assigned',
  MYREQUEST = 'my-request',
  REQUEST = 'requests',
  FAQ = 'faq',
  ADDREQUEST = 'add-request',
  NEW_REQUEST = 'new-request',
  SEE_REQUEST = 'see-request',
  SEE_FAQ = 'see-FAQ',
  NEW_FINDING = 'new-finding',
  NOTIFY = 'notify',
  PROFILE = 'profile',
  NEW_FAQ = 'new-faq',
  NOTFOUND = 'not-found',
  ADMINDASH = 'admin-dash',
  USERDASH = 'user-manager',
  DROPDOWNDASH = 'dropdown-manager',
  SCOPERPERIMETER = 'scope-perimeter',
  ADMINHOME = 'admin-home',
  CREATEUSER = 'create-user',
  IMPORT = 'import',
  CREATEGROUP= 'create-group'
}

// const publicRoutes: Routes = [
//   {
//     path: ROUTES_STRINGS.LOGIN,
//     component: LoginComponent,
//     // canActivate: [LoggedInAuthGuardService],
//   },
// ];

const routes: Routes = [
  {
    path: '',
    component: HomePageComponent,
    canActivate: [AuthGuard, AdminGuard],
    // canActivate: [AuthGuardService],
  },
  {
    path: ROUTES_STRINGS.LOGIN,
    component: LoginComponent,
    canActivate: [AuthGuard,],
    // canActivate: [LoggedInAuthGuardService],
  },
  {
    path: ROUTES_STRINGS.HOME,
    component: HomePageComponent,
    canActivate: [AuthGuard, AdminGuard],
    // canActivate: [AuthGuardService],
  },
  {
    path: ROUTES_STRINGS.NOTIFY,
    component: FullnotifyComponent,
    canActivate: [AuthGuard, AdminGuard],
    // canActivate: [AuthGuardService],
  },
  {
    path: ROUTES_STRINGS.PROFILE,
    component: ProfileComponent,
    canActivate: [AuthGuard, AdminGuard],
    // canActivate: [AuthGuardService],
  },
  // {
  //   path: ROUTES_STRINGS.NOTFOUND,
  //   component: NotfoundComponent,
  //   // canActivate: [AuthGuardService],
  // },
  {
    path: ROUTES_STRINGS.ADMINDASH,
    component: AdminDashComponent,
    canActivate: [AuthGuard, NotAdminGuard],
    children: [
      {
        path: '',
        redirectTo: ROUTES_STRINGS.ADMINHOME,
        pathMatch: 'full'
      },
      {
        path: ROUTES_STRINGS.USERDASH,
        children: [
          {
            path: '',
            redirectTo: 'user',
            pathMatch: "full"
            //outlet:"ciao"
          },
          {
            path: 'user',
            children: [
              {
                path: ':id',
                component: ManageUserComponent,
              }
            ]
          },
        ]
      },
      {
        path: ROUTES_STRINGS.CREATEGROUP,
        component: MakeGroupComponent
      },
      {
        path: ROUTES_STRINGS.CREATEUSER,
        component: CreateUserComponent
      },
      {
        path: ROUTES_STRINGS.ADMINHOME,
        component: AdminHomeComponent
      },
      {
        path: ROUTES_STRINGS.DROPDOWNDASH,
        children: [
          {
            path: '',
            redirectTo: 'dropdown',
            pathMatch: "full"
            //outlet:"ciao"
          },
          {
            path: 'dropdown',
            children: [
              {
                path: ':id',
                component: DropdownSelectorComponent,
              }
            ]
          },
        ]
      },
      {
        path: ROUTES_STRINGS.SCOPERPERIMETER,
        component: ScopePerimeterComponent,
      },
      {
        path: ROUTES_STRINGS.IMPORT,
        component: ImportComponent,
      }

    ]
    // canActivate: [AuthGuardService],
  },
  {
    path: ROUTES_STRINGS.COMPLIANCE_OPINION,
    component: ComplianceOpinionComponent,
    canActivate: [AuthGuard, AdminGuard],
    children: [
      {
        path: '',
        redirectTo: 'requests', pathMatch: 'full',

        // canActivate: [AuthGuardService],
      },
      {
        path: ROUTES_STRINGS.REQUEST,
        component: RequestsComponent,
        children: [
          {
            path: '',
            component: TableRequestsComponent
          },
          {
            path: ':id',
            component: SeeRequestComponent,
            // canActivate: [AuthGuardService],
          }
        ]
        // canActivate: [AuthGuardService],
      },
      {
        path: ROUTES_STRINGS.FAQ,
        component: FaqComponent,
        children: [
          {
            path: '',
            component: TableFaqComponent
          },
          {
            path: ':id',
            component: SeeFaqComponent,
            // canActivate: [AuthGuardService],
          }
        ]
        // canActivate: [AuthGuardService],
      },
      {
        path: ROUTES_STRINGS.NEW_REQUEST,
        component: NewRequestComponent,
        // canActivate: [AuthGuardService],
      },
      {
        path: ROUTES_STRINGS.NEW_FAQ,
        component: NewFaqComponent,
        // canActivate: [AuthGuardService],
      },
    ]
    // canActivate: [AuthGuardService],
  },
  {
    path: ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN,
    canActivate: [AuthGuard, AdminGuard],
    component: FollowUpRemedationPlanComponent,
    children: [{
      path: '',
      redirectTo: 'finding-assigned', pathMatch: 'full'
      // canActivate: [AuthGuardService],
    },
    {
      path: ROUTES_STRINGS.FINDING_ASSIGNED,
      component: FindingAssignedComponent,
      children: [
        {
          path: '',
          component: TableRequestsFollowUpComponent
        },
        {
          path: ':id',
          component: DetailFindingComponent,
          // canActivate: [AuthGuardService],
        }
      ]
    },
    {
      path: ROUTES_STRINGS.NEW_FINDING,
      component: NewFindingComponent,
      // canActivate: [AuthGuardService],
    },
    ]
  },


  {
    path: '**',
    redirectTo: ROUTES_STRINGS.NOTFOUND, pathMatch: 'full',
  },

];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule]
})
export class AppRoutingModule { }
