import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { map } from 'rxjs';
import { TrackingRequest } from 'src/app/data/models/Request';
import { selectTrackings } from 'src/app/utils/store/request/request.selector';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-info-request',
  templateUrl: './info-request.component.html',
  styleUrls: ['./info-request.component.scss']
})

export class InfoRequestComponent {

  @Input() readOnly: boolean = false;

  userInCharge:string
  dateInCharge:string
  userResolution:string
  dateResolution:string
  tracking: TrackingRequest[];

  constructor(private store:Store, private datePipe: DatePipe) {

    this.store.select(selectTrackings).pipe(
      map(trackings => {
    

        this.tracking = []
        trackings.forEach(element => {
          if(element.status == "IN_CHARGE"){
            this.userInCharge = element.username
            this.dateInCharge = this.formatDate(element.date_update)
          }

          if(element.status == "COMPLETED"){
            this.userResolution = element.username
            this.dateResolution = this.formatDate(element.date_update)
          }
        })
      })
    ).subscribe()


  

  }


   formatDate(date) {
    return this.datePipe.transform(new Date(date), 'dd-MM-yyyy');
  }
}




