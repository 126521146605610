import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeIcons } from 'primeng/api';
import { TrackingRequest } from 'src/app/data/models/Request';
import { selectTrackings } from 'src/app/utils/store/request/request.selector';
import { map, Subscription } from 'rxjs';
import { DatePipe } from '@angular/common';
import { selectTrackingsFinding } from 'src/app/utils/store/finding/finding.selector';
import { TrackingFinding } from 'src/app/data/models/Finding';
@Component({
  selector: 'app-follow-timeline',
  templateUrl: './follow-timeline.component.html',
  styleUrls: ['./follow-timeline.component.scss']
})
export class FollowTimelineComponent {


  @Input() isCompliance: boolean = false;
  @Input() stato: string;
  @Input() size: number;
  @Input() timeLineStatus: string[] = []
  tracking: TrackingRequest[];
  events: TrackingRequest[] = [];
  @Input() isFinding: boolean = false;
  sub: Subscription


  trackingEvent: TrackingRequest[] = [];
  emptyEvent: TrackingRequest = {
    status: '',
    result: '',
    date_update: '',
    username: '',
    flagTimeLine: false
  }


  formatDate(date) {
    return this.datePipe.transform(new Date(date), 'dd-MM-yyyy');
  }


  getIconColor(event) {
    if(event.date_update=='--/--/----'){
      return '#FFC627';
    }
    if(this.events.indexOf(event)== 3 && this.stato == 'TO_CHECK' ){
      return '#FFC627';
    }else if (event == '') {
      return '#FFC627';
    } else {
      return '#ffffff';
    }
  }

  getBackground(event) {
    if(event.date_update=='--/--/----'){
      return '#ffffff';
    }
    if(this.events.indexOf(event)== 3 && this.stato == 'TO_CHECK' ){
      return '#ffffff';
    }else if (event == '') {
      return '#ffffff';
    } else {
      return '#FFC627';
    }
  }

  getIcon(event) {


    if(event.date_update=='--/--/----'){
      return 'pi pi-circle-fill';
    }
    if(this.events.indexOf(event)== 3 && this.stato == 'TO_CHECK'){
      return 'pi pi-circle-fill';
    }else if(event==''){
      return 'pi pi-circle-fill';
    }else{
        return 'fa-solid fa-check';

    }
    // if (event == '') {
    //   return 'pi pi-circle-fill';
    // } else {
    //   return 'fa-solid fa-check';
    // }
  }

  getColorBarLast(event) {

    if (event.flagTimeLine == false) {
      return '#FFC627';
    } else {
      return 'transparent';
    }
  }

  complianceTracking$ = this.store.select(selectTrackings).pipe(
    map(trackings => {
      this.tracking = []
      trackings.forEach(element => {
        this.tracking.push(element)
      })
    })
  )


  findingTracking$ = this.store.select(selectTrackingsFinding).pipe(
    map(trackings => {
      this.tracking = []
      trackings.forEach(element => {
        this.tracking.push(element)
      })
    })
  )





  constructor(private store: Store, private datePipe: DatePipe) { }


  ngOnInit() {
    if (!this.isFinding) {
      this.sub = this.complianceTracking$.subscribe()
    } else {
      this.sub = this.findingTracking$.subscribe()
    }
    let trackingToSearch;
    let prevStatus = '';
    let trackingtemp = [];
    this.tracking.forEach(element => {

      if (element.status !== 'DRAFT') {
        trackingToSearch = this.tracking.filter(t => t.status == element.status)
        if (trackingToSearch.length > 1 && prevStatus !== element.status) {
          prevStatus = element.status
          if (trackingtemp.includes(trackingToSearch.findLast(t => t.id >= element.id)) && trackingToSearch.findLast(t => t.id >= element.id) !== undefined) {
          } else {
            trackingtemp.push(trackingToSearch.findLast(t => t.id >= element.id))
          }
        } else if (trackingToSearch.length == 1) {
          trackingtemp.push(element)
        }
      }
    })
    for (let x = 0; x < this.size; x++) {

      if (trackingtemp[x]) {
        this.events = [...this.events, {
          status: x == 3 ? this.timeLineStatus[x] : trackingtemp[x]['status'],
          result: x == 3 ? (trackingtemp[x]['status'] == 'REFUSED' ? trackingtemp[x + 1]?.['status'] == 'CLOSED' ? 'OK' : 'KO' : trackingtemp[x]['status'] == 'CLOSED' ? 'OK' : '') : '',
          date_update: trackingtemp[x]['date_update'],
          username: trackingtemp[x]['username'],
          flagTimeLine: false
        }]
      } else if (trackingtemp[x - 1]?.['status'] == "CLOSED") {
        this.events = [...this.events, {
          status: trackingtemp[x - 1]['status'],
          date_update: trackingtemp[x - 1]['date_update'],
          username: trackingtemp[x - 1]['username'],
          flagTimeLine: false
        }]
      } else {
        this.events = [...this.events, {
          status: this.timeLineStatus[x],
          date_update: '--/--/----',
          username: '',
          flagTimeLine: false
        }]

      }
    }
    this.events[this.events.length - 1] = { ...this.events[this.events.length - 1], flagTimeLine: true }
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }
}
