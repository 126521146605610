import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { MenuItem } from 'primeng/api';
import { PanelMenuModule } from 'primeng/panelmenu';
import { AdminActions } from 'src/app/utils/store/admin/admin.actions';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-admin-dash',
  templateUrl: './admin-dash.component.html',
  styleUrls: ['./admin-dash.component.scss']
})
export class AdminDashComponent {

  items: MenuItem[];
  backhome: MenuItem[];


  constructor(private store: Store) { }

  ngOnInit() {
    this.items = environment.adminOptions
    console.log(environment.adminOptions.length)
    this.backhome = [
      {
        label: 'Torna Alla Home',
        icon: 'pi pi-fw pi-home',
        routerLink: 'admin-home'
      }
    ]

    this.store.dispatch({ type: AdminActions.setScopes, field: 'scope' })
    this.store.dispatch({ type: AdminActions.setPerimeters, field: 'perimeter' })
    this.store.dispatch({ type: AdminActions.setGroup })
  }

}
