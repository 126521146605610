import { Attachment } from "./Attachment";
import { Comment } from 'src/app/data/models/Comment';

export class DbFinding {

  id?: number;
  scope?: string;
  perimeter?: string;
  intervention_type?: string;
  expired?: boolean;
  due_date?: string;
  flag_expiry_date?: boolean;
  problem_encountered?: string;
  action_required?: string;
  solution_description?: string;
  status?: string;
  flag_reminder?: boolean;
  reminder_days?: number;
  last_update?: string;
  creation_date?: string;
  risk_id?: string;
  residual_risk?: string;
  user_assigned?: number;
  group_assigned?: string;
  fdocument_attached?: number[] | Attachment[];
  comments?: Comment[] | number[];
  trackings?: TrackingFinding[];
  username_assigned?:string;
  fullname_assigned?:string;
  email_assigned?:string;
  refused_reasons?:string;
  due_date_planned?: string;
  count_due_date_planned?: number;
}

export class TrackingFinding {
  id?: number | string;
  id_track?: number;
  status?: string;
  date_update?: string;
  user?: number
  flagTimeLine?: boolean
  username?: string
}
export class TableFinding {
  richiesta: {
    titolo: string;
    descrizione: string;
  }
  priorita: string;
  due_date: string;
  ambito: string;
  perimetro: string;
  struttura_richiedente: string;
  status: string;
  creation_date?: string;
  last_update?: string;
  requested_user?: number;
  fdocument_attached?: number[] | Attachment[];
  is_faq?: boolean;
}


export enum Scope {
  trasparenza = 'Trasparenza',
  tutela = 'Tutela dei fondi',
  antitrust = 'Antitrust',
  conflittiCollegate = 'Conflitti d interesse e parti collegate',
  politiche = 'Politiche retributive e incentivanti',
  conflittiCorrelate = 'Conflitti d interesse e parti correlate',
  altro = 'Altro'
};

export enum IdFinding {
  titolo = 'ID FINLDING',
  descrizione = 'Descrizione Finding(primi 30 caratteri...)'
}

export enum FindingsStatus {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  REFUSED = 'REFUSED',
  IN_CHARGE = 'IN_CHARGE',
  TO_CHECK = 'TO_CHECK'
}

export const defPerimetroFinding = [
  'Modifica foglio informativo',
  'Approvazione nuovo prodotto',
  'Approvazione campagnia publicitaria',
  'Approvazione messaggio promozionale',
  'Altro'
]
export enum Perimeter {
  modifica = 'Modifica foglio informativo',
  approvazioneProdotto = 'Approvazione nuovo prodotto',
  approvazioneCampagna = 'Approvazione campagnia publicitaria',
  approvazioneMessaggio = 'Approvazione messaggio promozionale',
  altro = 'Altro'
}

export const defOperation = [
  'Normativa Interna',
  'Strumenti e tecnicalità',
  'Processi e procedure'

]
export enum Operation {
  normativa = 'Normativa Interna',
  strumenti = 'Strumenti e tecnicalità',
  processi = 'Processi e procedure',

}

export const defRisk = [
  'Alto',
  'Medio Alto',
  'Medio basso',
  'Basso'
]

export const defStatusFinding = [
  'NEW',
  'IN_CHARGE',
  'TO_CHECK',
  'REFUSED',
  'CLOSED',
]

export const defStatusFindingComp = [
  'NEW',
  'IN_CHARGE',
  'TO_CHECK',
  'REFUSED',
  'CLOSED',
  'DRAFT'
]

export enum Risk {
  alto = 'Alto',
  medioalto = 'Medio Alto',
  mediobasso = 'Medio Basso',
  basso = 'Basso',

}

