import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { UserService } from 'src/app/utils/services/user.service';

import { User } from 'src/app/data/models/User';
import { Observable, Subscription, catchError, forkJoin, map, of, take } from 'rxjs';

import { DomSanitizer } from '@angular/platform-browser';
import { selectCurrent, selectProfileBool } from 'src/app/utils/store/user/user.selector';
import { initUser, updateUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';
import { Router } from '@angular/router';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';
@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: ProfileComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: ProfileComponent }
  ]
})
export class ProfileComponent implements OnInit, ControlValueAccessor, Validator {

  profile_id: number
  postData: User;

  formValue: FormGroup;

  password: string;
  display: boolean = false;
  username: string;
  firstName: string;
  lastName: string;
  email: string
  varIndex: number

  currentFile: File;
  url: any
  adminSub: Subscription
  userTemp: any
  loading: boolean = false;

  loadingUpdateDetailUser: Observable<boolean> = this.store.select(selectProfileBool).pipe(map(value => this.loading = value))

  onChanged!: () => void
  onTouched!: () => void
  onDisabled!: (disable: boolean) => void

  writeValue(obj: any): void {
    this.formValue.patchValue(obj);
  }

  registerOnChange(fn: any): void {
    this.formValue.valueChanges.subscribe(fn)
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {
      let value = this.formValue.value
      this.formValue.disable()
      this.formValue.patchValue(value)
    }
  }

  validate(control: AbstractControl): ValidationErrors {

    if (control.valueChanges && (this.formValue.valid)) {
      return null
    } else {
      return { required: true }
    }
  }

  setAdmin: Observable<any> = this.store.select(selectCurrent).pipe(
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {


            })
          })
        )
      )
    })
  )



  constructor(private router: Router, private initService: InitService, private sanitizer: DomSanitizer, private http: HttpClient, private keycloak: KeycloakService, private store: Store, private userService: UserService, private messageService: MessageService, private fb: FormBuilder, private keycloakService: KeycloakService) {
  }

  ngOnInit() {

    this.store.select(selectProfileBool).pipe(map(value => this.loading = value)).subscribe()


    this.formValue = this.fb.group({
      name: ['', Validators.required],
      surname: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern("^[a-z0-9._%+-]+@[a-z0-9.-]+\\.[a-z]{2,4}$")]],
      file: [''],
      fileSource: [''],
      password: ['', Validators.required],
      password2: [''],
      newPassword: ['']
    })

    this.formValue.get('password').disable()

    this.adminSub = this.setAdmin.pipe(map(userProfile => {

      this.userTemp = userProfile
      if (userProfile !== null) {
        this.firstName = userProfile.first_name;
        this.lastName = userProfile.last_name;
        this.email = userProfile.email;
        this.username = userProfile.username;

        this.formValue.patchValue({
          name: userProfile.first_name,
          surname: userProfile.last_name,
          email: userProfile.email,
        })

        // this.url = userProfile.profile_photo
      } else {
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {


            })
          })

        ).subscribe()
      }


    })).subscribe()

    this.formValue.get("password2").valueChanges.subscribe(
      res => {
        if (res) {
          this.formValue.get('newPassword').setValidators(Validators.required)
          this.formValue.get('newPassword').updateValueAndValidity()
        } else {
          this.formValue.get('newPassword').setValue("")
          this.formValue.get('newPassword').removeValidators(Validators.required)
          this.formValue.get('newPassword').updateValueAndValidity()
        }
      }

    )

  }


  return() {
    this.formValue.get('password').disable()
    this.display = false
  }

  // dropfile(event) {
  //   const fileTemp: File = event.target.files[0]
  //   const maxSize = 10 * (1024 * 1024);
  //   if (event.target.files[0].type === 'image/png' || event.target.files[0].type === 'image/jpeg' || event.target.files[0].type === 'image/jpg') {

  //     if (event.size > maxSize) {
  //       this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Dimensione massima 10MB' })

  //     } else {

  //       var reader = new FileReader();

  //       reader.readAsDataURL(fileTemp); // read file as data url
  //       reader.onload = (event) => { // called once readAsDataURL is completed
  //         this.url = event.target.result;
  //       }
  //       this.currentFile = fileTemp
  //     }
  //   } else {
  //     this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserire file di tipo png,jpeg o jpg' })
  //   }

  // }

  sendToSuccess() {

    if (this.formValue.get('password2').value !== this.formValue.get('newPassword').value) {
      this.formValue.get('newPassword').setErrors({
        password: false
      })

      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Le due password inserite non sono identiche' })
      this.formValue.get('password2').markAsTouched()
      this.formValue.get('newPassword').markAsTouched()
      this.formValue.get('password').disable()
      this.display = false
    } else if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
      this.formValue.markAllAsTouched()
    } else if (this.varIndex == 1 && this.formValue.get('password2').value == '') {
      this.messageService.add({ severity: 'error', summary: 'Le password non possono essere vuote' });
    } else {
      this.formValue.get('password').enable()
      this.display = true;
    }
  }

  updateUserData() {


    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci la password corretta' })
      this.loading = false
    } else {
      const formData: FormData = new FormData();


      formData.append('firstName', this.formValue.get('name').value);
      formData.append('lastName', this.formValue.get('surname').value);
      formData.append('email', this.formValue.get('email').value);
      formData.append('password', this.formValue.get('password').value);
      formData.append('new_password', this.formValue.get('newPassword').value);
      // if (this.currentFile !== undefined) {
      //   formData.append('profile_photo', this.currentFile);
      // }
      // console.log(this.currentFile)
      this.store.dispatch(updateUser())

      this.userService.updateUserDetail(formData).pipe(
        map(res => {
          this.messageService.add({ severity: 'success', summary: 'Modifiche effettuate con successo' });
          this.formValue.get('password').disable()
          this.display = false;
          this.firstName = this.formValue.get('name').value;
          this.lastName = this.formValue.get('surname').value;
          this.email = this.formValue.get('email').value;
          this.userTemp = { ...this.userTemp, firstName: this.firstName, lastName: this.lastName, email: this.email }
          // if (res.profile_photo !== null) {
          //   this.userTemp = { ...this.userTemp, profile_photo: res.profile_photo }
          // }
          this.store.dispatch(initUser({ currentUser: this.userTemp }))
          this.router.navigate(['../', ROUTES_STRINGS.HOME])
          // this.loading = true
        }
        ),
        catchError((error: HttpErrorResponse) => {
          if (error.status == 401) {
            this.formValue.get('password').setErrors({
              password: false
            })
            this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci la password attuale corretta' });

          }
          return of(1)
        })
      ).subscribe();
      this.loading = false
    }



  }

}






