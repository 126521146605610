import { Component, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';


@Component({
  selector: 'app-notfound',
  templateUrl: './notfound.component.html',
  styleUrls: ['./notfound.component.scss']
})
export class NotfoundComponent implements OnInit {
  @Output() onCancel: EventEmitter<void> = new EventEmitter();

  constructor(private router: Router) { }

  ngOnInit(): void {
  }

  cancel() {
    this.onCancel.emit();
    this.router.navigateByUrl('').then(() => {
      this.router.navigate(["../"])
    })
  }

}
