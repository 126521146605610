<div class="home relative">
  <div class="content ">
    <p-toast [style]="{'z-index':'999999'}" position="top-center"></p-toast>
    <div>
      <app-header></app-header>
    </div>
    <div class="p-5 content2 ">
      <div>
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>
