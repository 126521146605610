import { Action, createReducer, on } from '@ngrx/store';
import { initialRequestState } from './request.state'
import { setRequests, setCurrentRequest, addNewRequest, setRequestsSuccess, updateRequest, initRequests, addComment, addCommentSuccess, setComments, addNewFaqs, setFaqs, setFaqsSuccess, updateFaq, setCurrentFaq, setCommentsSuccess, updateRequestStatus, updateRequestStatusSuccess} from './request.action';
import { state } from '@angular/animations';



export const requestReducer = createReducer(
  initialRequestState,
  on(setRequests, state => ({ ...state, loadingRequest: true })),
  on(setFaqs, state => ({ ...state, loadingRequest: true })),
  on(setRequestsSuccess, (state, { requests, size }) => ({ ...state, requests: { requests, size }, loadingRequest: false})),
  on(setFaqsSuccess, (state, { faqs, size }) => ({ ...state, faqs: { faqs, size }, loadingRequest: false })),
  on(addNewRequest, (state) => ({ ...state, loadingRequest: true })),
  on(addNewFaqs, (state) => ({ ...state, loadingRequest: true })),
  on(setCurrentRequest, (state, { currentRequest }) => ({ ...state, currentRequest })),
  on(setCurrentFaq, (state, { currentRequest }) => ({ ...state, currentRequest })),
  on(updateRequest, (state) => ({ ...state, loadingRequest: true })),
  on(updateFaq, (state) => ({ ...state, loadingRequest: true })),
  on(setCommentsSuccess, (state, { comments }) => ({ ...state, currentRequest: { ...state.currentRequest, comments: comments } })),
  on(updateRequestStatus, (state) => ({ ...state, loadingRequest: true })),
  on(updateRequestStatusSuccess, (state) => ({ ...state, loadingRequest: false })),
);
