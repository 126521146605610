import { AfterViewInit, Component, Input, OnChanges } from '@angular/core';
import { UserService } from 'src/app/utils/services/user.service';

@Component({
  selector: 'app-compliance-opinion',
  templateUrl: './compliance-opinion.component.html',
  styleUrls: ['./compliance-opinion.component.scss']
})
export class ComplianceOpinionComponent {

}
