import { Component, ElementRef, EventEmitter, Input, Output } from '@angular/core';
import { KeycloakService } from 'keycloak-angular';
import { Store } from '@ngrx/store';
import { Subscription, take, map, Observable, catchError, forkJoin, from, tap } from 'rxjs';
import { selectCurrent, selectCurrentUserIDKeycloak, selectUserFullName } from 'src/app/utils/store/user/user.selector';
import { PulsarService } from 'src/app/utils/services/pulsar.service';
import { NotifyComponent } from '../notify/notify.component';
// import { selectUserData } from 'src/app/utils/store/user/user.selector';
import { HostListener } from '@angular/core';
import { environment } from 'src/environments/environment';
import { InitService } from 'src/app/utils/services/init.service';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { DropdownService } from 'src/app/utils/services/dropdown.service';
import { AdminService } from 'src/app/utils/services/admin.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent {

  fullName = '';
  notify: boolean;
  profile: boolean;
  @Input() displayNotify: boolean;
  @Output() displayNotifyChange = new EventEmitter<boolean>();

  firstName: string;
  lastName: string;
  id = '';
  topic: string;
  topic2: string
  pulsarSub: string;
  queryParam: { [id: string]: string };
  message: any;
  isAdmin: boolean;
  title = 'socketrv';
  content = '';
  received = [];
  sent = [];
  sub: Subscription;
  sub2: Subscription;
  @Output() emitMessageRequest: EventEmitter<string> = new EventEmitter<string>()
  showAnimation: boolean = false
  count = 0;
  group: any
  url: string;
  adminSub: Subscription
  logo: string = environment.logo_header


  fetchedData: any;

  groupLength: number;
  firstGroup: any;

  setAdmin: Observable<any> = this.store.select(selectCurrent).pipe(

    catchError(err => {
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {


            })
          })
        )
      )
    })

  )



  constructor(private keycloakService: KeycloakService, private pulsarService: PulsarService, private store: Store, private elementRef: ElementRef, private initService: InitService, private adminService: AdminService) {

    this.InitializeUserOptions();

    this.adminSub = this.setAdmin.subscribe(userProfile => {

      if (userProfile !== null) {
        this.fullName = userProfile.username
        // this.url = userProfile.profile_photo
      } else {
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            console.log(data)
            this.store.dispatch(initUser({ currentUser: data['user'] }))

            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {


            })
          })

        ).subscribe()
      }


    })
  }



  ngOnInit() {

    // this.store.select(selectCurrentUserIDKeycloak).subscribe(data => {
    //   this.id = data
    // })

    this.notify = false;
    this.isAdmin = this.keycloakService.isUserInRole("audit_user")
    console.log("quii",this.isAdmin)
    let username = this.keycloakService.getUsername()

    // this.topic = this.isAdmin ? 'compliance' : this.firstGroup
    // this.pulsarSub = username
    // this.queryParam = this.isAdmin ? { subscriptionType: "Exclusive" } : null

    let getID = this.keycloakService.getKeycloakInstance()
    this.id = getID.profile.id
    console.log(this.id)

    console.log(this.keycloakService.getKeycloakInstance())

    // this.adminService.getUserGroup(this.id).pipe(map(data => {
    //   console.log(data)

    //   this.group = data['groups']
    //   console.log(this.group)
    //   console.log(this.group.length)
    //   console.log(this.group[0])
    // })).subscribe()


    this.adminService.getUserGroup(this.id).pipe(
      tap(data => {
        this.fetchedData = data;
        console.log(this.fetchedData);
      }),
      map(data => data['groups']),
      tap(groups => {
        this.group = groups;
        console.log(this.group);
        this.groupLength = this.group.length;
        console.log(this.groupLength);
        this.firstGroup = this.group[0];
        console.log(this.firstGroup);

        this.topic = this.isAdmin ? 'audit' : this.firstGroup
        this.pulsarSub = username
        this.queryParam = this.isAdmin ? { subscriptionType: "Exclusive" } : null

        this.sub = this.pulsarService.initConnection(this.topic, this.pulsarSub, this.queryParam).subscribe(msg => {
          this.message = msg
          let decodedMsg = decodeURIComponent(escape(atob(msg.payload)))
          let jsonData = JSON.parse(decodedMsg)
          msg = { ...msg, payload: jsonData }
          this.received.push(msg);
          if (this.received.length > 1 && this.count == 1) {
            this.count = 0
          }
          if (this.count == 0) {
            setTimeout(() => {
              if (this.showAnimation) {
                this.showAnimation = false
                this.count++
              }
            }, 3000)
            this.showAnimation = true;
          }
        });
      })
    ).subscribe();

    // if(this.group!==undefined){
    //   console.log(this.group[1])
    //   console.log(this.group.length)
    //   for(let i=0; i<this.group.length; i++){

    //     this.sub2[i] = this.pulsarService.initConnection(this.group[i], this.pulsarSub, this.queryParam).subscribe(msg => {
    //       this.message = msg
    //       let decodedMsg = decodeURIComponent(escape(atob(msg.payload)))
    //       let jsonData = JSON.parse(decodedMsg)
    //       msg = { ...msg, payload: jsonData }
    //       this.received.push(msg);
    //       if (this.received.length > 1 && this.count == 1) {
    //         this.count = 0
    //       }
    //       if (this.count == 0) {
    //         setTimeout(() => {
    //           if (this.showAnimation) {
    //             this.showAnimation = false
    //             this.count++
    //           }
    //         }, 3000)
    //         this.showAnimation = true;
    //       }
    //     });
    //   }
    // }

    // this.topic2 = this.group[1]
    // console.log(this.group[1])



    if (!this.isAdmin && this.topic !== username) {
      this.sub2 = this.pulsarService.initConnection(username, this.pulsarSub, this.queryParam).subscribe(msg => {
        this.message = msg
        let decodedMsg = decodeURIComponent(escape(atob(msg.payload)))
        let jsonData = JSON.parse(decodedMsg)
        msg = { ...msg, payload: jsonData }
        this.received.push(msg);
        if (this.received.length > 1 && this.count == 1) {
          this.count = 0
        }
        if (this.count == 0) {
          setTimeout(() => {
            if (this.showAnimation) {
              this.showAnimation = false
              this.count++
            }
          }, 3000)
          this.showAnimation = true;
        }
      });


    }







  }
  //prendere il gruppo dell'utente corrente e assegnarlo al topic
  // http://localhost:8000/api/admin-panel/groups/

  // this.topic = this.isAdmin ? 'compliance' : this.keycloakService.isUserInRole("privacy_user") ? 'privacy' : this.keycloakService.isUserInRole("legal_user") ? 'legal' : username
  // this.topic = this.isAdmin ? 'compliance' : this.group? username  : username 






  @HostListener('window:click', ['$event'])
  onClick(event: MouseEvent) {
    if (!this.elementRef.nativeElement.contains(event.target)) {
      if (this.notify) {
        this.notify = false;
        this.profile = false;
      } else if (this.profile) {
        this.profile = false;
      }
    }
  }


  visiblenotify() {
    this.notify = !this.notify;
    this.profile ? this.profile = !this.profile : null;
    this.displayNotify = false;
    // this.displayNotifyChange.emit(this.displayNotify);
  }

  visibleprofile() {
    this.profile = !this.profile;
    this.notify ? this.notify = !this.notify : null;
  }

  private InitializeUserOptions(): void {
    this.fullName = this.keycloakService.getUsername();

  }


  logout(): void {
    this.keycloakService.logout(environment.feUrl);
  }

  removeNotification(event) {
    if (event == " ") {
      this.received = []
    } else {
      this.received.splice(this.received.findIndex(msg => msg.messageId == event), 1)
    }
    if (this.received.length == 0) {
      this.emitMessageRequest.emit("empty")
    }
  }


  sendMsg(messageID) {

    let message = {
      messageId: messageID

    }
    this.sent.push(message);
    this.pulsarService.messages.next(message);
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
    if (this.sub2) {
      this.sub2.unsubscribe()
    }
  }


}

