<div class="chat-container border-round-lg mt-3 justify-content-between">
  <div class="chat-header border-round-top-lg flex flex-row justify-content-between  pl-3  relative mt-2 ">
    <h4 class=" ">Sezione Commenti</h4>
    <i class="fa-solid   fa-lg fa-circle-xmark icon mr-1 mt-2" (click)="close()"></i>
  </div>
  <div class="chat-messages  px-3" #chatContainer>
    <div>
      <div *ngFor="let comment of (commentSub | async)">
        <div class="relative" [ngClass]="userId == comment['user']? 'message-sender' : 'message-received '">
          <div class="msg-tmp" [ngClass]="userId == comment['user']? ' sender' : ' received'">
            <div class="status " style="font-weight: bold; color: black;">
              {{comment['username']}}
            </div>
            <p class="mb-0">{{comment['message']}}</p>
          </div>
          <div class="flex flex-column">
            <div *ngIf="userId == comment['user']" class="flex" style="font-size:12px" class="flex align-items-baseline">
              <div class=" p-0">
                <i [ngClass]="comment.seen ? 'fa-solid fa-eye' : 'fa-solid fa-paper-plane'"></i>
              </div>
              <div class=" px-1"> 
                <p style="font-size: 10px; margin-bottom: 0px;" class="">{{comment.seen ? 'Letto' : 'Inviato'}}</p> 
              </div>
              <div class="pr-2">              
                <p style="font-size: 10px; margin-bottom: 0px; margin-right: 0px;" >{{formatDate(comment['send_time'])}}</p>
              </div> 
            </div>
          </div>
          <div [ngClass]="userId == comment['user'] ?  'right':'left' "></div>
        </div>
      </div>
    </div>
  </div>

  <div class=" justify-content-end">
    <div class="chat-input px-3 w-full justify-content-end">
      <div class="relative">
        <h6 class="absolute mb-0 caratteri" [ngClass]="message.length >= 255 ? 'text-red':''">{{message.length}}/255
        </h6>
      </div>
    </div>
    <div class="chat-input px-3 w-full justify-content-between">
      <textarea (keyup.enter)="addComment(message)" style="min-height: 4rem;max-height: 4rem;" class="w-full" [autoResize]="false" pInputTextarea rows="2"
        cols="81" [(ngModel)]="message" placeholder="Scrivi un commento" [disabled]="disabledButton">
      </textarea>
      <button [ngClass]="disabledButton?'buttonDisabled':'buttonActive'" class=" h-4rem " (click)="addComment(message)"
        [disabled]="disabledButton">Invia</button>
      <!-- <input type="text" placeholder="Scrivi un commento" class="bginput" [(ngModel)]="message"> -->
    </div>
    <div class="px-3 w-full">
      <div class="relative " *ngIf="message.length >= 255">
        <p class="absolute error ml-2" style="color:#e24c4c; font-size: small;">Inserisci massimo 255 caratteri</p>
      </div>
    </div>
  </div>
</div>


<!-- <textarea style="min-height: 3rem;max-height: fit-content;" [disabled]="true" [autoResize]="false" rows="5" cols="50" pInputTextarea [ngClass]="userId == comment['user']? ' sender' : ' received'" >  {{comment['message']}}</textarea>
</div>
<div class="status">{{comment['username']}} - {{formatDate(comment['send_time'])}}</div>
</div>
</div>
</div> -->

<!-- <div class="chat-input px-3 w-full justify-content-between">
<textarea style="min-height: 4rem;max-height: 4rem;" class="w-full" [autoResize]="false" pInputTextarea rows="2" cols="81" [(ngModel)]="message" placeholder="Scrivi un commento"></textarea>
<button class=" h-4rem " (click)="addComment(message)">Invia</button>
<input type="text" placeholder="Scrivi un commento" class="bginput" [(ngModel)]="message">
</div>
</div> -->