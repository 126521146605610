import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { FormGroup, FormControl, FormBuilder, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { catchError, map, of } from 'rxjs';
import { addUser } from 'src/app/data/models/User';
import { UserService } from 'src/app/utils/services/user.service';
import { AdminActions } from 'src/app/utils/store/admin/admin.actions';
import { selectBooleanButton, selectError } from 'src/app/utils/store/admin/admin.selector';


@Component({
  selector: 'app-create-user',
  templateUrl: './create-user.component.html',
  styleUrls: ['./create-user.component.scss']
})

export class CreateUserComponent {



  profileForm: FormGroup;
  postData: addUser;
  //cambiare qui il ruolo compliance_user con audit_user
  roles: string[] = ['audit_user', 'business_user', 'mooney_admin'];
  selectedRole: string;
  loading: boolean = false;
  error: string;

  constructor(private store: Store, private fb: FormBuilder, private userService: UserService, private messageService: MessageService) {
    console.log(this.roles)

  }

  ngOnInit() {
    this.store.select(selectBooleanButton).pipe(map(value => this.loading = value)).subscribe()

    this.store.select(selectError).pipe(map(value => this.error = value)).pipe(
      map(value => {
        if (value.includes('username')) {
          this.profileForm.get('username').setErrors({ 'usernameExist': 'username già esistente' })
        } else if (value.includes('email')) {
          this.profileForm.get('email').setErrors({ 'emailExist': 'email già esistente' })
        }
      })
    ).subscribe()

 



    this.profileForm = this.fb.group({
      name: ['', [Validators.required, Validators.maxLength(150)]],
      surname: ['', [Validators.required, Validators.maxLength(150)]],
      email: ['', [Validators.required, Validators.email, Validators.maxLength(254)]],
      username: ['', [Validators.required, Validators.maxLength(20)]],
      password: ['', [Validators.required, Validators.maxLength(128)]],
      role: ['', [Validators.required]],
    })


  }


  addUser() {

    if (this.profileForm.invalid) {
      this.profileForm.markAllAsTouched()
    } else {
      // this.postData = {
      //   first_name: this.profileForm.get('name').value,
      //   last_name: this.profileForm.get('surname').value,
      //   username: this.profileForm.get('username').value,
      //   password: this.profileForm.get('password').value,
      //   email: this.profileForm.get('email').value,
      //   role: this.profileForm.get('role').value,

      // }

      // if(this.profileForm.get('role').value == 'audit_user'){
      //   console.log("sono qui")
      //   console.log( this.profileForm.get('role').value)

      //   this.profileForm.get('role').patchValue('compliance_user') 
      //   console.log( this.profileForm.get('role').value)

      // }


      this.store.dispatch({
        type: AdminActions.addUser,
        name: this.profileForm.get('name').value,
        lastname: this.profileForm.get('surname').value,
        username: this.profileForm.get('username').value,
        password: this.profileForm.get('password').value,
        email: this.profileForm.get('email').value,
        role: this.profileForm.get('role').value,
      })

      // this.profileForm.get('role').patchValue('audit_user') 



      //   this.userService.addUser(this.postData).pipe(catchError((errorResponse: HttpErrorResponse) => {
      //     const errorObj = JSON.parse(errorResponse.error);
      //     const errorMessage = errorObj.errorMessage;
      //     console.log(errorMessage)

      //     if (errorMessage.includes('username')) {

      //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'username già esistente' });
      //       this.profileForm.get('username').setErrors({ 'usernameExist': 'username già esistente' })
      //     } else if (errorMessage.includes('email')) {
      //       this.messageService.add({ severity: 'error', summary: 'Error', detail: 'email già esistente' });
      //       this.profileForm.get('email').setErrors({ 'emailExist': 'email già esistente' })
      //     }
      //     return of(1)
      //   })).subscribe()

      //   this.messageService.add({ severity: 'success', summary: 'Utente aggiunto' })

    }

  }
}
