<div>
  <app-flow></app-flow>
  <app-header-tab [isAdmin]="isAdmin" [filters]="filters" [hasButton]="true" [canAddRequest]="!isAdmin" [canFaq]="false"
    [hasAction]="hasAction" [canExport]="true"
    [title]="headerTabTitle"></app-header-tab>
  <br>

  <app-dynamic-table *ngIf="!(dataLoaded$ | async)" [isAdmin]="isAdmin" [tableColumn]="tableColumn"
    [currentUserID]="currentUserID" [filters]="tableFilters" (loadingRequest)="isLoadingRequest($event)"
    (setFilters)="setFilters($event)" [isLoading]="isLoading" [tableCurrentData]="requestCurrentData"
    [tableData]="requestData" [isAllegato]="false" [size]="totalSize" [canSearch]="true"
    [makeFaq]="isAdmin"></app-dynamic-table>
</div>