import { createReducer, on } from '@ngrx/store';
import { exportFile, exportFileSuccess } from './export.action';
import { initialExportState } from './export.state';



export const exportReducer = createReducer(
    initialExportState,

    on(exportFile, (state) => ({ ...state, exportLoading: true })),
    on(exportFileSuccess, (state) => ({ ...state, exportLoading: false })),
);
