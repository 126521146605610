<div class="flex  h-full w-full " >
    <div [formGroup]="editUser" class="border flex w-full h-full  flex-column align-item-stretch">
        <div class="flex flex-column w-full ">
            <p class="title name">{{user.firstName}}</p>
            <p class="text1 email">{{user.email}}</p>
        </div>
        <div class="flex flex-column w-full">
            <p class="text1 subtitle mb-0"> Modifica: {{title}}</p>
            <p-dropdown *ngIf="selectDropdown=='Ruolo'" placeholder="Seleziona il ruolo" [options]="roles" [(ngModel)]="selectedRole"  (onChange)="onDropdownChange($event)" formControlName="role"   [style]="{'width':'100%', 'z-index':'999'}"></p-dropdown>
            <p-dropdown *ngIf="selectDropdown=='Gruppo'" placeholder="Seleziona il gruppo" [options]="group" [(ngModel)]="selecterGroup"  (onChange)="onDropdownChange($event)" formControlName="group"   [style]="{'width':'100%'}"></p-dropdown>

        </div>
        <div *ngIf="selectDropdown=='Abilitazione'" class="flex flex-row gap-3  h-full w-full mt-4">
            <p-inputSwitch  [disabled]="false"
                    [style]="{'height':'10px'}" [(ngModel)]="selectedEnable"  (onChange)="onEnableChange($event)" formControlName="enable"></p-inputSwitch>
            <p > Disabilita/Abilita Utente</p>
        </div>
        <div *ngIf="selectDropdown=='Gruppo'" class="flex w-full flex-wrap">
            <div class="flex flex-row w-full h-full align-items-center" *ngFor="let item of groupUser.groups">
                <p-chip
                    class="mt-2 flex max-height-3rem  white-space-nowrap max-h-2rem">{{item}} 
                    <i class="ml-3 fa-solid  fa-sm fa-circle-xmark icon custom " (click)="delete(user.id,item)"></i>
                </p-chip>
            </div>
        </div>
    </div>
</div>