import { Component, OnInit} from '@angular/core';
import { environment } from 'src/environments/environment';
import { Router, RouterLink } from '@angular/router';
import { User } from '../data/models/User';
import { Location } from '@angular/common';
import { ROUTES_STRINGS } from '../modules/app-routing.module';


@Component({
  selector: 'app-admin-home',
  templateUrl: './admin-home.component.html',
  styleUrls: ['./admin-home.component.scss']
})

export class AdminHomeComponent implements OnInit {
  blocks: any[];
  littleblocks: any[];
  responsiveOptions: any[] | undefined;




  constructor(private router: Router,private location:Location) { }

  ngOnInit() {
    this.blocks = environment.adminOptions;
    this.littleblocks = []
    this.responsiveOptions = [
      {
          breakpoint: '1400px',
          numVisible: 3,
          numScroll: 3
      },
      {
          breakpoint: '1220px',
          numVisible: 2,
          numScroll: 2
      },
      {
          breakpoint: '1100px',
          numVisible: 1,
          numScroll: 1
      }
  ];
    
  }
  
  navigateToRoute(route: string) {
    this.router.navigateByUrl(route);
   console.log(this.router.navigateByUrl(route))
  }

  assignLittleBlock(block){
    console.log(block);
    this.littleblocks = [...block.items]
    
  }
  



}
