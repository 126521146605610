<div>
  <app-flow></app-flow>
  <app-header-tab [isAdmin]="isAdmin" [title]="titleForm"></app-header-tab>
  <form [formGroup]="formValue">
    <app-form-attributes formControlName="newFinding" [invalidBoolean]="invalidBoolean"
      [isNewFinding]="true"></app-form-attributes>
  </form>
  <app-dynamic-table [currentUserID]="currentUserId" [isAdmin]="isAdmin" (editAttachment)="editAttachment($event)"
    [canFilter]="false" (attachmentAdded)="addAttachment($event)" [tableColumn]="tableColumn"
    (removeAttachment)="removeAttachment($event)" [tableData]="attachmentData" [canAddAttachment]="canAddAttachment"
    [isAllegato]="true" [isDisabled]="isDisabled" [size]="size"></app-dynamic-table>
</div>

<div *ngIf="titleForm=='Crea nuovo finding'">
  <div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
    <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']"
      [disabled]="loading">Annulla
    </button>
    <button pButton pRipple type="submit" class="save p-fontMedium m-2" [disabled]="loading" (click)="save()">Salva e
      Chiudi
    </button>
    <button pButton pRipple type="button" class="send p-fontMedium m-2 mr-0" [disabled]="loading"
      (click)="sendFinding()">Conferma e Invia
    </button>
  </div>
</div>

<!-- <div *ngIf="titleForm=='Crea nuovo finding'">
  <div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
    <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']">Annulla
    </button>
    <button pButton pRipple type="submit" class="send p-fontMedium m-2 mr-0" (click)="sendToBuisnessUser()">Continua
    </button>
  </div>
</div> -->