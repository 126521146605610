import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { RequestService } from '../../services/request.service';
import { MessageService } from 'primeng/api';
import { Observable, concatMap, from, map, mergeAll, mergeMap, of, switchMap, take, tap } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { DbRequest } from 'src/app/data/models/Request';
import {
  RequestActions,
  addNewAttachmentSuccess,
  addNewRequestSuccess,
  setCurrentRequest,
  setRequestsSuccess,
  setComments,
  addCommentSuccess,
  updateRequestSuccess,
  updateAttachment,
  updateAttachmentSuccess,
  setFaqsSuccess,
  updateFaqSuccess,
} from './request.action';
import { Router } from '@angular/router';
import { selectCurrentID, selectCurrentIDandRole } from '../user/user.selector';
import { environment } from 'src/environments/environment';
import { PulsarService } from '../../services/pulsar.service';
import { KeycloakService } from 'keycloak-angular';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';

export enum Summary {
  COMPLETED = "Completata",
  IN_CHARGE = "Presa in carico",
  REFUSED = "Richiesta rifiutata!",
};

export enum Detail {
  COMPLETED = "Richiesta finalizzata con successo.",
  IN_CHARGE = "Richiesta presa in carico",
  REFUSED = "Richiesta rifiutata con successo.",
}

@Injectable()
export class RequestEffects {
  userId: number;
  filter: boolean;
  page: number;
  pageSize: number

  setRequests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.setRequest, RequestActions.addNewRequestSuccess, RequestActions.updateRequestSuccess, RequestActions.updateRequestStatusSuccess),
      concatMap((value) => {
        return this.requestService.getRequests(value['page'], value['pageSize'], value['filters'], value['ordering']).pipe(
          map(data => {
            return setRequestsSuccess({ requests: data['results'], size: data['count'] })
          })
        )
      })
    );
  });

  setFaqs$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.setFaq, RequestActions.addNewFaqSuccess, RequestActions.updateFaqSuccess),
      concatMap((value) => {
        return this.requestService.getFaqs(value['page'], value['pageSize'], value['filters'], value['ordering']).pipe(
          map(data => {
            return setFaqsSuccess({ faqs: data['results'], size: data['count'] })
          })
        )
      })
    );
  });


  addRequests$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.addNewRequest),
      concatMap(({ request, attachments }) => {
        return this.requestService.addNewRequest(request).pipe(
          map(data => {
            let array: any[] = attachments
            if (array.length > 0) {
              return {
                type: RequestActions.addNewAttachment,
                attachments: attachments,
                newRequestId: (data.body)['id']
              }
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION])
              this.messageService.add({ severity: 'success', summary: 'Richiesta creata con successo!' });
              return {
                type: RequestActions.addNewRequestSuccess
              }
            };
          }))
      }
      )
    )
  });

  addFaq$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.addNewFaq),
      concatMap(({ faq, attachments }) => {
        return this.requestService.addNewFaq(faq).pipe(
          map(data => {
            let array: any[] = attachments
            if (array.length > 0) {
              return {
                type: RequestActions.addNewAttachment,
                attachments: attachments,
                newRequestId: (data.body)['id']
              }
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION, ROUTES_STRINGS.FAQ])
              this.messageService.add({ severity: 'success', summary: 'FAQ creata con successo' });
              return {
                type: RequestActions.addNewFaqSuccess
              }
            };
          }))
      }
      )
    )
  });

  addComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.addComment),
      concatMap(({ payload }) => {
        return this.requestService.addComment(payload['id'], payload['comment']).pipe(
          map(data => {
            return { type: RequestActions.addCommentSuccess, requestID: payload['id'] }
          })
        );
      })
    )
  });

  setComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.addCommentSuccess, RequestActions.setComments),
      concatMap(({ requestID }) => {
        return this.http.get(`${environment.apiUrl}/request/${requestID}/comments/`).pipe(
          map((data) => {
            return { type: RequestActions.setCommentsSuccess, comments: data }
          })
        )
      })
    )
  })




  updateRequest$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.updateRequest),
      concatMap(({ payload }) => {
        return this.requestService.updateRequest(payload['request'], payload['id']).pipe(
          mergeMap(() => {
            const attachments: any[] = payload['attachments'];
            const updates = [];
            const adds = [];
            attachments.forEach(att => {
              if (att.id) {
                updates.push(att);
              } else {
                adds.push(att);
              }
            });
            const updateActions = updates.map(att => {
              return { type: RequestActions.updateAttachment, attachment: att };
            })
            const addActions = adds.map(att => {
              return { type: RequestActions.addNewAttachment, attachments: att, newRequestId: payload['id'] };
            })
            if (updateActions.length > 0 || addActions.length > 0) {

              return from([
                ...updateActions,
                ...addActions,
              ])
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION])
              return of({ type: RequestActions.updateRequestSuccess })
            }
          })
        )
      }
      )
    )
  });

  updateFaq$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.updateFaq),
      concatMap(({ payload }) => {
        return this.requestService.updateFaq(payload['request'], payload['id']).pipe(
          mergeMap(() => {
            const attachmentsFaq: any[] = payload['attachments'];
            const updatesFaq = [];
            const addsFaq = [];
            attachmentsFaq.forEach(att => {
              if (att.id) {
                updatesFaq.push(att);
              } else {
                addsFaq.push(att);
              }
            });
            const updateActionsFaq = updatesFaq.map(att => {
              return { type: RequestActions.updateAttachment, attachment: att };
            })
            const addActionsFaq = addsFaq.map(att => {
              return { type: RequestActions.addNewAttachment, attachments: att, newRequestId: payload['id'] };
            })
            if (updateActionsFaq.length > 0 || addActionsFaq.length > 0) {
              return from([
                ...updateActionsFaq,
                ...addActionsFaq,
              ])
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION])
              return of({ type: RequestActions.updateFaqSuccess })
            }
          })
        )
      }
      ),
    )
  });

  updateRequestStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.updateRequestStatus),
      switchMap(({ payload }) => {
        return this.requestService.updateRequestStatus(payload['status'], payload['id'], payload['responses']).pipe(
          map((data) => {
            this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION])
            let message = this.getMessageFromStatus(payload['status'])
            this.messageService.add(message);
            return { type: RequestActions.updateRequestStatusSuccess }
          })
        )
      })
    )
  })


  updateAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.updateAttachment),
      concatMap(data => {
        return this.requestService.updateAttachment(data['attachment']).pipe(
          map(dataRes => {
            this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION])
            return updateAttachmentSuccess()

          })
        )
      })
    )
  })

  addAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(RequestActions.addNewAttachment),
      concatMap(({ attachments, newRequestId }) => {
        let att = attachments[0] !== undefined ? attachments[0] : attachments
        return this.requestService.addNewAttachment(att, newRequestId).pipe(
          map(() => {
            let att: any[] = attachments
            if (att.length > 1) {
              return {
                type: RequestActions.addNewAttachment,
                attachments: att.slice(1),
                newRequestId: newRequestId
              }
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.COMPLIANCE_OPINION])
              return addNewAttachmentSuccess()
            }
          })
        )
      })
    );
  });

  // getRequestFailure$ = createEffect(() =>
  //   this.actions$.pipe(
  //     ofType(RequestActions.getRequestFailure),
  //     map(() => {
  //       this.router.navigate(["../not-found"])
  //     })
  //   ),
  //   { dispatch: false }
  // )

  getMessageFromStatus(status) {
    let summary = Summary[status]
    let detail = Detail[status]
    let message = { severity: (status == 'REFUSED' ? 'error' : 'success'), summary: summary, detail: detail }
    return message
  }
  constructor(
    private actions$: Actions,
    private requestService: RequestService,
    private router: Router,
    private pulsarService: PulsarService,
    private keycloakService: KeycloakService,
    private store: Store,
    private http: HttpClient,
    private messageService: MessageService
  ) { }
}
