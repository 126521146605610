<div class="p-3 flex layout justify-content-stretch flex-column">
  <div class="border bg-white p-2">
    <div class="flex flex-column w-full  p-3 pb-0 justify-content-between ">
      <h1 class="title">Modifica Domini DropDown</h1>
      <p class="subtitle ">{{title}}</p>
    </div>


    <app-dynamic-list 
      [(selectedDropDown)]="selectedOptions" 
      [itemSize]="50" 
      [values]="listDropdown" 
      [title]="dropdownOptions" 
      [canAddItems]="true"
      (apiAddDropDown)="addItem($event)" 
      (apiRemoveDropDown)="removeItem($event)"
      (changeDropdownTitle)="changeDropdownItems($event)" 
      [lazy]="false" 
      [canRemoveItems]="true" 
      (loadEvent)="loadEvent($event)"
      [isDropdownManage]="true"

      />
  </div>
</div>


<!-- <p-tabMenu [model]="headers" [activeItem]="activeIndex" (activeItemChange)="onActiveItemChange($event)">
</p-tabMenu> -->

<!-- <div>
    <p> {{ nomeOk }}</p>
    <button type="submit" class="btn btn-primary" (click)="cambiaNome()"></button>
</div> -->

<!-- <div class="form_attributes border-round-sm ">
    <div class="header_attributes border-round-top-sm font-bold flex justify-content-start">
      <p class="header m-1 ml-3 ">Attributi dell'area d'intervento</p>
    </div>
</div> -->
<!-- <router-outlet></router-outlet> -->