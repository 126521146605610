<p-menubar styleClass="  border-noround border-0 flex flex-row fixed w-full  px-6"
  [style]="{'background-color': ' #4F4F4F', 'z-index':'999'}">
  <ng-template pTemplate="start">
    <div class="flex align-items-center">
      <img class="mx-1 " src="./assets/images/deloitte_logo.png" alt="..." height="15" routerLink="/home">
      <img class="mx-1" src="./assets/images/Line 20.svg" alt="..." height="20">
      <img src="data:image/png;base64, {{logo}}"height="20"/>

    </div>
  </ng-template>
  <ng-template pTemplate="end" styleClass="flex flex-row flex-wrap">
    <div class=" flex align-items-center">
      <a class="mx-2 no-underline text-white text-sm" routerLink="../profile">{{keycloakService.getUsername() ? fullName
        :
        "Esegui l'accesso"}}</a>
      <!-- <div class="picture ">
        <div class="image-container ">
          <img [src]="url ? url : '../../assets/images/img_avatar.png'" class="">
        </div>
      </div> -->
      <div class="mx-2 picture" style="border-radius: 50%;" alt="" height="25" (click)="visibleprofile()">
        <div class="image-container ">
          <img [src]="'./assets/images/img_avatar.png'" class="image ">
        </div>
        <!-- <i class="fa-solid fa-user fa-md justify-content-center"></i> -->
      </div>
      <div [ngClass]="showAnimation == true ? 'animation' : ''">
        <i class="fa-regular fa-bell fa-xl fa-s notification mx-2 relative" (click)="visiblenotify()">
          <p-badge *ngIf="received.length>0" styleClass="newMessage" [value]="received.length"></p-badge>
        </i>
      </div>
      <i (click)='logout()' class="fa  fa-sign-out mx-2" style="color: #FFC627; background-color: transparent;">
      </i>
    </div>
  </ng-template>
</p-menubar>

<div class="z-5" *ngIf="notify">
  <app-notify (removeNotification)="removeNotification($event)" [notifies]="received"></app-notify>
</div>

<div class="z-5" *ngIf="profile">
  <app-profile-dashb></app-profile-dashb>
</div>