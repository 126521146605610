import { createFeatureSelector, createSelector } from "@ngrx/store";
import { FindingsState } from "./finding.state";
import { DbFinding } from "src/app/data/models/Finding"




export const selectFindings = createFeatureSelector<FindingsState>('finding');

export const selectFindingsData = createSelector(
  selectFindings,
  (state: FindingsState) => state.findings
);

export const selectFindingsList = createSelector(
  selectFindings,
  (state: FindingsState) => state.findings.findings
);


export const selectFindingsize = createSelector(
  selectFindings,
  (state: FindingsState) => state.findings.size
);

export const selectFindingsTable = (id: number) => createSelector(
  selectFindingsList,
  (findings: DbFinding[]) => findings.find(finding => finding.id == id)
);

export const selectFindingDataLoading = createSelector(
  selectFindings,
  (state: FindingsState) => state.loadingFinding);


export const selectCurrentFinding = createSelector(
  selectFindings,
  (state: FindingsState) => state.currentFinding
);
export const selectCurrentFindingId = createSelector(
  selectCurrentFinding,
  (Finding: DbFinding) => Finding.id
);

//richiama l'altro selector per prendere il valore preciso
export const selectCurrentfindingstate = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.status
);

export const selectCurrentFindingAmbito = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.scope
);

export const selectCurrentFindingPerimetro = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.perimeter
);


// export const selectCurrentFindingRichiesta = createSelector(
//   selectCurrentFinding,
//   (currentFinding: DbFinding) => currentFinding.reason
// );

// export const selectCurrentfindingstruttura = createSelector(
//   selectCurrentFinding,
//   (currentFinding: DbFinding) => currentFinding.structure_finding
// );

// export const selectCurrentFindingPriorita = createSelector(
//   selectCurrentFinding,
//   (currentFinding: DbFinding) => currentFinding.priority
// );

// export const selectCurrentFindingDataCreazione = createSelector(
//   selectCurrentFinding,
//   (currentFinding: DbFinding) => currentFinding.creation_date
// );

export const selectCurrentFindingDataAggiornamento = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.last_update
);

// export const selectCurrentFindingDataFine = createSelector(
//   selectCurrentFinding,
//   (currentFinding: DbFinding) => currentFinding.due_date
// );

// export const selectCurrentFindingTitle = createSelector(
//   selectCurrentFinding,
//   (currentFinding: DbFinding) => currentFinding.finding_title
// );

export const selectCurrentFindingID = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.id
);

export const selectCommentIdUser = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.comments['user']
);

export const selectCommentsFinding = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.comments
);

export const selectTrackingsFinding = createSelector(
  selectCurrentFinding,
  (currentFinding: DbFinding) => currentFinding.trackings
);
