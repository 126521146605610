import { HttpClient } from '@angular/common/http';
import { ChangeDetectorRef, Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { map, take } from 'rxjs';
import { Perimeter, Scope } from 'src/app/data/models/DropDown';
import { DropdownService } from 'src/app/utils/services/dropdown.service';
import { AdminActions } from 'src/app/utils/store/admin/admin.actions';
import { selectBooleanButton, selectPerimeters, selectScopes } from 'src/app/utils/store/admin/admin.selector';
import { SelectItem } from 'primeng/api';
import { FormBuilder, FormGroup } from '@angular/forms';

@Component({
  selector: 'app-scope-perimeter',
  templateUrl: './scope-perimeter.component.html',
  styleUrls: ['./scope-perimeter.component.scss']
})


export class ScopePerimeterComponent {

  listScope: string[]
  perimeter: any[] = []
  selectedPerimeter: string[];
  perimetri: any
  loading: boolean = false;
  prova:any[]=[]

  options: SelectItem[];
  selectedItems: any[];
  selectedItemsLabel: string;
  values: string[] = ['a', 'b'];

  selectedDefault: any

  

  constructor(private fb: FormBuilder, private dropDownService: DropdownService, private http: HttpClient, private store: Store) {

  
  }
  
  ngOnInit() {
    this.store.select(selectBooleanButton).pipe(map(value => this.loading = value)).subscribe()
    
    this.store.dispatch({ type: AdminActions.setScopes, field: 'scope' })
    this.store.dispatch({ type: AdminActions.setPerimeters, field: 'perimeter' })

    this.store.select(selectScopes).subscribe(data => {
      this.listScope = [...data]
      console.log(this.listScope)
    })


    this.store.select(selectPerimeters).subscribe(data => {
      this.perimeter = [...data]
      console.log( this.perimeter )
    })

    

  }


  getPerimeterName(value: any) {
    if (value.id !== undefined) {
      return (this.perimeter.find(p => p.id == value.id))?.name
    } else {
      return (this.perimeter.find(p => p.id == value))?.name
    }
  }

  addPerimeter(scope: number, perimetri: any) {

    let perimetriID: number[] = [];
    console.log(scope)
    console.log( perimetri)

    perimetri.forEach((perimeter, index) => {
      perimetriID[index] = perimeter.id !== undefined ? perimeter.id : perimeter
    })
    console.log(scope, perimetriID)

    this.store.dispatch({ type: AdminActions.connectPerimeter, idScope: scope, idPerimeter: perimetriID })

  }

  delete(scopeId: any, item: any, listitem: any) {

    listitem = listitem.filter(function (valore) {
      return valore !== item;
    });   

    this.store.dispatch({ type: AdminActions.disconnectPerimeter, idScope: scopeId, idPerimeter: listitem })

  }

  pick(perimetri:any){
    this.prova=[]
    console.log(perimetri)
    
    perimetri.forEach(item=>{
      this.prova.push(item.id)
    })



    console.log(this.prova)
    return this.prova
  }

}
