import { HttpClient, HttpErrorResponse } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Actions, createEffect, ofType } from "@ngrx/effects";
import { Store } from "@ngrx/store";
import { MessageService } from "primeng/api";
import { AdminActions } from "./admin.actions";
import { catchError, concatMap, map, of } from "rxjs";
import { AdminService } from "../../services/admin.service";
import { DropDown } from "src/app/admin/admin-dash-tabs/dropdown-selector/dropdown-selector.component";
import { DropdownService } from "../../services/dropdown.service";
import { UserService } from "../../services/user.service";

@Injectable()
export class AdminEffects {
  userId: number;
  filter: boolean;
  page: number;
  pageSize: number;

  //   setScopes$ = createEffect(() => {
  //     return this.actions$.pipe(
  //         ofType(AdminActions.setScopes),
  //         map
  //     )
  //   })

  getRoles$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.getRoles),
      concatMap(() => {
        return this.adminService.getRoles().pipe(
          map(roles => {
            return { type: AdminActions.getRolesSuccess, roles: roles }
          })
        )
      })
    )
  })


  setUserList$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setUserList, AdminActions.patchUserSuccess, AdminActions.patchUserEnableSuccess, AdminActions.patchUserGroupSuccess,AdminActions.disconnectGroupSuccess),
      concatMap(({ start, end, filters }) => {
        return this.adminService.getUsersList(start, end, filters).pipe(
          map(result => {
            result = { users: [...result['users']], count: result['numbers of users'] }
            return {
              type: AdminActions.setUserListSuccess,
              users_paginated: result
            }
          }),
          catchError(err => {
            console.log(err)
            return of(err)
          }
          )
        )
      })
    )
  })

  setScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setScopes, AdminActions.addScopeSuccess, AdminActions.deleteScopeSuccess, AdminActions.connectPerimeterSuccess, AdminActions.disconnectPerimeterSuccess),
      concatMap(({ field }) => {
        return this.dropdownService.getDropDown(field).pipe(
          map(data => {
            return {
              type: AdminActions.setScopesSuccess,
              scopes: data
            }
          })
        )
      }
      )
    )
  })

  addScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addScope),
      concatMap(({ field, data }) => {
        return this.dropdownService.addField(field, data).pipe(
          map(data => {
            console.log("dato", data)
            this.messageService.add({ severity: 'warn', summary: 'Ambito aggiunto, Ricordati di collegare almeno un perimetro  così da poterlo visualizzare in app' })

            return {
              type: AdminActions.addScopeSuccess,
              field: field
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ambito già esistente' });

            return of({ type: AdminActions.addScopeFailure });
          })
        )
      }
      )
    )
  })

  deleteScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.deleteScope),
      concatMap(({ field, data }) => {
        return this.dropdownService.deleteField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Ambito eliminato' });

            console.log(field)
            return {
              type: AdminActions.deleteScopeSuccess,
              field: field
            }
          })
        )
      }
      )
    )
  })

  //
  setGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setGroup, AdminActions.addGroupSuccess, AdminActions.deleteGroupSuccess),
      concatMap(({  }) => {
        return this.dropdownService.getGroup().pipe(
          map(data => {
            console.log(data)
            return {
              type: AdminActions.setGroupSuccess,
              group: data
            }
          })
        )
      }
      )
    )
  })

  addGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addGroup),
      concatMap(({ data }) => {
        return this.dropdownService.addGroup(data).pipe(
          map(data => {
            console.log("dato", data)
            this.messageService.add({ severity: 'success', summary: 'Gruppo' })

            return {
              type: AdminActions.addGroupSuccess,
             
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Gruppo già esistente' });

            return of({ type: AdminActions.addGroupFailure });
          })
        )
      }
      )
    )
  })

  deleteGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.deleteGroup),
      concatMap(({ data }) => {
        return this.adminService.deleteGroup( data).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Gruppo', detail: 'Gruppo eliminato' });

        
            return {
              type: AdminActions.deleteGroupSuccess,
              
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore nella eliminazione del gruppo' });

            return of({ type: AdminActions.deleteGroupFailure });
          })
        )
      }
      )
    )
  })


  setPerimeter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setPerimeters, AdminActions.addPerimeterSuccess, AdminActions.deletePerimeterSuccess),
      concatMap(({ field }) => {
        return this.dropdownService.getDropDown(field).pipe(
          map(data => {
            return {
              type: AdminActions.setPerimetersSuccess,
              perimeters: data
            }
          })
        )
      }
      )
    )
  })

  addPerimeter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addPerimeter),
      concatMap(({ field, data }) => {
        return this.dropdownService.addField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'warn', summary: 'Perimetro aggiunto ricordarsi di collegarlo ad almeno un ambito così da poterlo visualizzare in app' })

            return {
              type: AdminActions.addPerimeterSuccess,
              field: field
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'perimetro già esistente' });

            return of({ type: AdminActions.addPerimeterFailure });
          })
        )
      }
      )
    )
  })

  deletePerimeter$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.deletePerimeter),
      concatMap(({ field, data }) => {
        return this.dropdownService.deleteField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Perimetro eliminato' });

            console.log(field)
            return {
              type: AdminActions.deletePerimeterSuccess,
              field: field
            }
          })
        )
      }
      )
    )
  })

  setResidualRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setResidualRisks, AdminActions.addResidualRisksSuccess, AdminActions.deleteResidualRisksSuccess),
      concatMap(({ field }) => {
        return this.dropdownService.getDropDown(field).pipe(
          map(data => {
            return {
              type: AdminActions.setResidualRisksSuccess,
              residualRisks: data
            }
          })
        )
      }
      )
    )
  })

  addResidualRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addResidualRisks),
      concatMap(({ field, data }) => {
        return this.dropdownService.addField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Rischio aggiunto' })
            return {
              type: AdminActions.addResidualRisksSuccess,
              field: field
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Rischio Residuo già esistente' });

            return of({ type: AdminActions.addResidualRisksFailure });
          })
        )
      }
      )
    )
  })

  deleteResidualRisk$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.deleteResidualRisks),
      concatMap(({ field, data }) => {
        return this.dropdownService.deleteField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'error', summary: 'Rischio eliminato' })

            return {
              type: AdminActions.deleteResidualRisksSuccess,
              field: field
            }
          })
        )
      }
      )
    )
  })

  //
  setPriority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setPriority, AdminActions.addPrioritySuccess, AdminActions.deletePrioritySuccess),
      concatMap(({ field }) => {
        return this.dropdownService.getDropDown(field).pipe(
          map(data => {
            return {
              type: AdminActions.setPrioritySuccess,
              priority: data
            }
          })
        )
      }
      )
    )
  })

  addPriority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addPriority),
      concatMap(({ field, data }) => {
        return this.dropdownService.addField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Priorita aggiunta' })
            return {
              type: AdminActions.addPrioritySuccess,
              field: field
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Priorita già esistente' });

            return of({ type: AdminActions.addPriorityFailure });
          })
        )
      }
      )
    )
  })

  deletePriority$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.deletePriority),
      concatMap(({ field, data }) => {
        return this.dropdownService.deleteField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'error', summary: 'Priorita eliminata' })

            return {
              type: AdminActions.deletePrioritySuccess,
              field: field
            }
          })
        )
      }
      )
    )
  })

  //
  setInterventionType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.setInterventionType, AdminActions.addInterventionTypeSuccess, AdminActions.deleteInterventionTypeSuccess),
      concatMap(({ field }) => {
        return this.dropdownService.getDropDown(field).pipe(
          map(data => {
            return {
              type: AdminActions.setInterventionTypeSuccess,
              intervention_type: data
            }
          })
        )
      }
      )
    )
  })

  addInterventionType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addInterventionType),
      concatMap(({ field, data }) => {
        return this.dropdownService.addField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Tipologia intervento aggiunta' })
            return {
              type: AdminActions.addInterventionTypeSuccess,
              field: field
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Tipologia intervento già esistente' });

            return of({ type: AdminActions.addInterventionTypeFailure });
          })
        )
      }
      )
    )
  })

  deleteInterventionType$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.deleteInterventionType),
      concatMap(({ field, data }) => {
        return this.dropdownService.deleteField(field, data).pipe(
          map(data => {
            this.messageService.add({ severity: 'error', summary: 'Tipologia intervento eliminato' })

            return {
              type: AdminActions.deleteInterventionTypeSuccess,
              field: field
            }
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore durante la eliminazione della tipologia  intervento' });

            return of({ type: AdminActions.deleteInterventionTypeSuccessFailure });
          })
        )
      }
      )
    )
  })

  connectPerimeterScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.connectPerimeter),
      concatMap(({ idScope, idPerimeter }) => {
        console.log(idScope)
        return this.dropdownService.addPerimeter(idScope, idPerimeter).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Perimetri collegati' })
            return {
              type: AdminActions.connectPerimeterSuccess,
              field: 'scope'
            }
          })
        )
      })
    )
  })


  disconnectPerimeterScope$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.disconnectPerimeter),
      concatMap(({ idScope, idPerimeter }) => {
        console.log(idScope)
        return this.dropdownService.addPerimeter(idScope, idPerimeter).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Perimetro scollegato' })
            return {
              type: AdminActions.disconnectPerimeterSuccess,
              field: 'scope'
            }
          })
        )
      })
    )
  })

  disconnectGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.disconnectGroup),
      concatMap(({ idUser, nameGroup,first, last }) => {
        console.log(idUser)
        return this.adminService.disconnectGroup(idUser, nameGroup).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Gruppo scollegato' })
            return {
              type: AdminActions.disconnectGroupSuccess,
              start: first,
              end: last
            }
          })
        )
      })
    )
  })


  addUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.addUser),
      concatMap(({ name, lastname, username, password, email, role }) => {
        return this.userService.addUser(name, lastname, username, password, email, role).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Utente aggiunto' });
            return {
              type: AdminActions.addUserSuccess,
              payload: data
            };
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            const errorObj = JSON.parse(errorResponse.error);
            const errorMessage = errorObj.errorMessage;
            console.log("eccomi")
            if (errorMessage.includes('username')) {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'username già esistente' });
            } else if (errorMessage.includes('email')) {
              this.messageService.add({ severity: 'error', summary: 'Error', detail: 'email già esistente' });
            }
            return of({ type: AdminActions.addUserFailure, error: errorMessage });
          })
        );
      })
    );
  });


  patchUser$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.patchUser),
      concatMap(({ role, id, first, last }) => {
        return this.adminService.patchUser(role, id).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Ruolo aggiornato' });
            return {
              type: AdminActions.patchUserSuccess,
              start: first,
              end: last
            };
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore durante la modifica' });
            // }
            return of({ type: AdminActions.patchUserFailure });
          })
        );
      })
    );
  });



  patchUserGroup$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.patchUserGroup),
      concatMap(({ booleangroup,oldgroup, group, id, first, last }) => {

        console.log(booleangroup)
        if(booleangroup==true){
        return this.adminService.patchUserGroup(oldgroup,group, id).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Gruppo aggiornato' });
            return {
              type: AdminActions.patchUserGroupSuccess,
              start: first,
              end: last
            };
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore durante la modifica' });
            // }
            return of({ type: AdminActions.patchUserGroupFailure });
          })
        );
      }else{
        return this.adminService.addUserGroup(group, id).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Gruppo Aggiunto' });
            return {
              type: AdminActions.patchUserGroupSuccess,
              start: first,
              end: last
            };
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore durante la modifica' });
            // }
            return of({ type: AdminActions.patchUserGroupFailure });
          })
        );
      }
      })
    );
  });

  patchUserEnable$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(AdminActions.patchUserEnable),
      concatMap(({ enable, id, first, last }) => {
        return this.adminService.patchUserEnable(enable, id).pipe(
          map(data => {
            this.messageService.add({ severity: 'success', summary: 'Abilitazione aggiornata' });
            return {
              type: AdminActions.patchUserEnableSuccess,
              start: first,
              end: last
            };
          }),
          catchError((errorResponse: HttpErrorResponse) => {
            this.messageService.add({ severity: 'error', summary: 'Error', detail: 'Errore durante la modifica' });
            // }
            return of({ type: AdminActions.patchUserEnableFailure });
          })
        );
      })
    );
  });




  constructor(
    private actions$: Actions,
    private store: Store,
    private http: HttpClient,
    private messageService: MessageService,
    private adminService: AdminService,
    private dropdownService: DropdownService,
    private userService: UserService) { }
}