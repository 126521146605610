import { Component } from '@angular/core';
import { InitService } from './utils/services/init.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent {
  title = 'mooney';
  admin = 'false';
  priorita: any;
  prioritajson: any;
  key:any

  // this.localStorage.setLocal('myKey', myObject);
  constructor(initService: InitService) {
    initService.getAmbiti().subscribe((data: any) => {
      localStorage.setItem('Ambiti', JSON.stringify(data))
    })
    initService.getPriority().subscribe((data: any) => {
      localStorage.setItem('Priority', JSON.stringify(data))
    })
    initService.getResidualRisk().subscribe((data: any) => {
      localStorage.setItem('Residualrisk', JSON.stringify(data))
    })
    initService.getInterventionType().subscribe((data: any) => {
      localStorage.setItem('InterventionType', JSON.stringify(data))
    })


  }

  ngOnInit(){

  }
}
