import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Perimeter, ResidualRisk, Scope } from 'src/app/data/models/DropDown';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class DropdownService {

  constructor(private http: HttpClient) { }

  getDropDown(value:string) {
    return this.http.get<any>(`${environment.apiUrl}/${value}/`)
  }

  addField(value: string, field:string) {
    let postData:any = {
      name:field
    } 

    console.log(postData)
    return this.http.post(`${environment.apiUrl}/${value}/`, postData);
  }

  deleteField(value:string,field:string){
    return this.http.delete(`${environment.apiUrl}/${value}/${field}/`);
 
  }

  getGroup() {
    return this.http.get<any>(`${environment.apiUrl}/admin-panel/groups/`)
  }

  addGroup(value: string) {
    let postData:any = {
      group:value
    } 

    console.log(postData)
    return this.http.post(`${environment.apiUrl}/admin-panel/create-group/`, postData);
  }

 
  addPerimeter(idScope:number, idPerimeter:any[]){
    console.log(idScope,idPerimeter)
   
    let postData:any = {
      id:idScope,
      perimeter:idPerimeter
    } 
    console.log(postData)
    return this.http.patch(`${environment.apiUrl}/scope/${idScope}/`, postData);
 
  }

  //import prende l'id che sarebbe il dropdown selezionato e il file
  import(id:string, file:File){
    return this.http.patch(`${environment.apiUrl}/admin-panel/upload/`, file);
  }
 
}
