<p-virtualScroller [value]="[1,2,2]" scrollHeight="450px" [itemSize]="100">
    <ng-template pTemplate="header"> Ambiti </ng-template>
    <ng-template let-item pTemplate="item">
        <div class="flex align-items-center p-3 w-full flex-wrap">
            <div class="flex-1">
                <h5 class="mb-2 text-base">AAAAA</h5>
                {{ scopeObs | async }}
                <i class="pi pi-tag hidden md:inline vertical-align-middle mr-2"></i>
                <span class="hidden md:inline-flex vertical-align-middle mr-2">{{ item }}</span>
            </div>
            <div class="flex flex-column align-items-end">
                <h6 style="width:25px; height:14px;" class="mb-2">bbbb</h6>
                <!-- <p-tag [value]="product.inventoryStatus" [severity]="getSeverity(product.inventoryStatus)"></p-tag> -->
            </div>
        </div>
    </ng-template>
    <ng-template let-product pTemplate="loadingItem">
        <div class="flex align-items-center p-3 w-full flex-wrap loading-item">
            <div class="mr-3" style="width:100px; height:66px;"></div>
            <div class="flex-1">
                <h5 class="mb-2 text-base"></h5>
                <i class="hidden md:inline vertical-align-middle mr-2"></i>
                <span class="hidden md:inline-flex vertical-align-middle mr-2"></span>
            </div>
            <div class="flex flex-column align-items-end">
                <h6 style="width:25px; height:14px;" class="mb-2"></h6>
                <span class="block h-2rem" style="width:100px"></span>
            </div>
        </div>
    </ng-template>
</p-virtualScroller>
<!-- <div>
    <div class="flex">
        <input type="text" pInputText >
    </div>
</div> -->