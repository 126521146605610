import { Pipe, PipeTransform } from '@angular/core';
import { Router } from '@angular/router'

export enum PATHS {
  compliance_opinion = 'Compliance opinion',
  requests = 'Elenco Richieste',
  faq = 'FAQ',
  new_request = 'Crea nuova richiesta',
  new_finding = 'Crea nuovo finding',
  new_faq = 'Crea FAQ',
  see_request = 'Visualizza richiesta',
  see_faq = 'Visualizza FAQ',
  finding_assigned = 'Elenco Findings',
  follow_up_remedation_plan = 'Follow up remedation plan',
}


@Pipe({
  name: 'flow'
})
export class FlowPipe implements PipeTransform {
  transform(path: string, subject:string ): string {
    if (path.includes("-")) {
      path = path.replace(/-/g, "_");
    } else if ((+path).toString() !== "NaN" || path.includes('?')) {
      if(subject !== ''){
        path = subject +path
      }else if(path.includes('?')){
        path = PATHS['new_faq']
      }
    }
    return PATHS[path] ? PATHS[path] : path;
  }
}
