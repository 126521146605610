<form [formGroup]="formGroup">
  <div *ngIf="isUser == true && !isLegal && !isPrivacy">
    <div class="customContainer pt-5 mb-2 ">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex justify-content-start">
          <p class=" m-1 ml-3 ">Risposta</p>
        </div>
        <div class="p-4">
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="text" placeholder="">Risposta relativa alla richiesta...
              </textarea>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="isUserFinding == true">
    <div class="customContainer pt-2 mb-2 ">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex justify-content-start">
          <p class=" m-1 ml-3 ">Descrivi l'intervento di risoluzione eseguita</p>
        </div>
        <div class="p-4">
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="text">Risposta relativa al finding...
              </textarea>
              <div class="relative m-0 p-0" *ngIf="formGroup.get('text').value?.length >= 65535">
                <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci massimo 65535 caratteri.</p>
              </div>
        </div>
      </div>
    </div>
  </div>

  <div *ngIf="(isUser == false && isUserFinding == false )|| isLegal || isPrivacy">
    <!-- <div *ngIf="isUser == false && isUserFinding == false"> -->
    <div class="customContainer pt-5 ">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex justify-content-start">
          <p class=" m-1 ml-3 ">Risposta finale Opinion</p>
        </div>
        <div class="p-4">
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="text">Risposta relativa alla richiesta...
              </textarea>
        </div>
      </div>
    </div>

    <div class="customContainer pt-5 ">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex ">
          <div class="flex flex-row w-full active align-items-baseline pt-2">
            <div class="flex flex-start w-full">
              <p class=" m-1  ml-3 ">Risposta Privacy</p>
            </div>
            <!-- <input type="checkbox" formControlName="requested_privacy"> -->
            <div class="flex justify-content-end w-full pr-5  gap-3">
              <p [ngClass]="formGroup.get('requested_privacy').value ? 'text-disable':''">Disattiva</p>
              <p-inputSwitch formControlName="requested_privacy" [(ngModel)]="checked_privacy" [disabled]="false"
                [style]="{'height':'10px'}"></p-inputSwitch>
              <p [ngClass]="formGroup.get('requested_privacy').value == false ? 'text-disable':''">Attiva</p>
            </div>
          </div>
          <!-- <div class="container">
            <input type="checkbox" class="checkbox" id="checkbox">
            <label class="switch" for="checkbox">
              <span class="slider"></span>
            </label>
          </div> -->
        </div>
        <div class="p-4">
              <div class="flex flex-row check gap-3 ">
                <div class="flex flex-row">
                  <p class=" mr-2">L'intervento del gruppo Privacy può ritenersi concluso?</p>
                </div>
                <div class="flex flex-row align-items-baseline gap-2">
                  <p>Si/No</p>
                  <input type="checkbox" formControlName="flagPrivacyAnswer">

                </div>

              </div>
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="privacy">Risposta relativa alla richiesta...
              </textarea>
              <div class="relative m-0 p-0" *ngIf="formGroup.get('privacy').value?.length >= 65535">
                <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci massimo 65535 caratteri.</p>
              </div>
        </div>
      </div>
    </div>

    <div class="customContainer pt-5 ">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex justify-content-start">
          <div class="flex flex-row w-full active align-items-baseline pt-2">
            <div class="flex flex-start w-full">
              <p class=" m-1  ml-3 ">Risposta Legal</p>
            </div>
            <div class="flex justify-content-end w-full gap-3 pr-5 pt-2">
              <p [ngClass]="formGroup.get('requested_legal').value ? 'text-disable':''">Disattiva</p>
              <p-inputSwitch formControlName="requested_legal" [(ngModel)]="checked_legal" [disabled]="false"
                [style]="{'height':'10px'}"></p-inputSwitch>
              <p [ngClass]="formGroup.get('requested_legal').value == false ? 'text-disable':''">Attiva</p>
            </div>
          </div>
        </div>
        <div class="p-4">
              <div class="flex flex-row align-items-baseline gap-3 check">
                <div class="flex flex-row">
                  <p class="  mr-2">L'intervento del gruppo Legal può ritenersi concluso?</p>
                </div>
                <div class="flex flex-row align-items-baseline gap-2">
                  <p>Si/No</p>
                  <input type="checkbox" formControlName="flagLegalAnswer">
                </div>
              </div>
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="legal">Risposta relativa alla richiesta...
              </textarea>
              <div class="relative m-0 p-0" *ngIf="formGroup.get('legal').value?.length >= 65535">
                <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci massimo 65535 caratteri.</p>
              </div>
        </div>
      </div>
    </div>

    <div class="customContainer pt-5 ">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex justify-content-start">
          <p class=" m-1 ml-3 ">Note Interne Complieance/Legal/Privacy</p>
        </div>
        <div class="p-4">
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="note">Risposta relativa alla richiesta...
              </textarea>
              <div class="relative m-0 p-0" *ngIf="formGroup.get('legal').value?.length >= 65535">
                <p class="absolute" style="color:#e24c4c;font-size:small">Inserisci massimo 65535 caratteri.</p>
              </div>
        </div>
      </div>
    </div>


    <!-- <div class="customContainer pt-5 mb-2">
      <div class="form_answer  border-round-sm ">
        <div class="header_answer border-round-top-sm font-bold flex justify-content-start">
          <p class=" m-1 ml-3 ">Note interne Compliance/Legal/Privacy</p>
        </div>
        <div class="p-4">
            <textarea pInputTextarea [autoResize]="false"  class="w-full h-15rem"
              formControlName="text">...
              </textarea>
        </div>
      </div>
    </div> -->
  </div>
</form>