import { createFeatureSelector, createSelector } from '@ngrx/store';
import { RequestsState } from './request.state';
import { DbRequest } from 'src/app/data/models/Request';

export const selectRequests = createFeatureSelector<RequestsState>('request');

export const selectRequestsData = createSelector(
  selectRequests,
  (state: RequestsState) => state.requests
);
export const selectFaqsData = createSelector(
  selectRequests,
  (state: RequestsState) => state.faqs
);

export const selectRequestsList = createSelector(
  selectRequests,
  (state: RequestsState) => state.requests.requests
);

export const selectFaqsList = createSelector(
  selectRequests,
  (state: RequestsState) => state.faqs.faqs
);


export const selectRequestSize = createSelector(
  selectRequests,
  (state: RequestsState) => state.requests.size
);

export const selectFaqsSize = createSelector(
  selectRequests,
  (state: RequestsState) => state.faqs.size
);

export const selectRequestsTable = (id: number) => createSelector(
  selectRequestsList,
  (requests: DbRequest[]) => requests.find(request => request.id == id)
);

export const selectFaqsTable = (id: number) => createSelector(
  selectFaqsList,
  (faqs: DbRequest[]) => faqs.find(faq => faq.id == id)
);

export const selectRequestDataLoading = createSelector(
  selectRequests,
  (state: RequestsState) => state.loadingRequest);

export const selectFaqDataLoading = createSelector(
  selectRequests,
  (state: RequestsState) => state.loadingRequest);

export const selectCurrentRequest = createSelector(
  selectRequests,
  (state: RequestsState) => state.currentRequest
);
export const selectCurrentRequestId = createSelector(
  selectCurrentRequest,
  (request: DbRequest) => request.id
);

//richiama l'altro selector per prendere il valore preciso
export const selectCurrentRequestState = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.status
);

export const selectCurrentRequestAmbito = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.scope
);

export const selectCurrentRequestPerimetro = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.perimeter
);


export const selectCurrentRequestRichiesta = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.reason
);

export const selectCurrentRequestStruttura = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.structure_request
);

export const selectCurrentRequestPriorita = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.priority
);

export const selectCurrentRequestDataCreazione = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.creation_date
);

export const selectCurrentRequestDataAggiornamento = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.last_update
);

export const selectCurrentRequestDataFine = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.due_date
);

export const selectCurrentRequestTitle = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.request_title
);

export const selectCurrentRequestID = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.id
);

export const selectCurrentRequestStatus = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.status
);

export const selectCommentIdUser = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.comments['user']
);

export const selectComments = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.comments
);

export const selectTrackings = createSelector(
  selectCurrentRequest,
  (currentRequest: DbRequest) => currentRequest.trackings
);
