<div class="p-3 flex layout justify-content-stretch flex-column">
    <div class="border bg-white flex flex-column w-full">
        <div class=" flex flex-column w-full  p-3 pb-0  h-10">
            <div class="flex flex-row  justify-content-between">
                <p class="title pb-0">Elenco Ambiti e Perimetri</p>
                <button pButton pRipple type="button" class="button-add"
                    [routerLink]="['../dropdown-manager/dropdown','Ambito']" [queryParams]="{ id: 'Ambito'}"
                    [replaceUrl]="true" [disabled]="loading">Aggiungi Ambito
                </button>

            </div>
            <hr>
        </div>
        <div class="list-accrodin p-3 gap-2 h-full scroller">
            <p-accordion class="" *ngFor="let item of listScope; let i = index">
                <p-accordionTab class="" header="{{item.name}}">
                    <div class="flex justify-content-between mb-3">
                        <p-multiSelect class="customDropSelector" [style]="{'width':'30rem'}" optionLabel="name"
                            [options]="perimeter" [ngModel]="item.perimeter|linkscopeperimeter:perimeter" 
                            (ngModelChange)="pick($event)"></p-multiSelect>
                        <button pButton pRipple type="button" (click)="addPerimeter(item.id,prova)"
                            class="button-add p-fontMedium " [disabled]="loading">Salva</button>
                    </div>
                    <div class="flex w-full flex-wrap">
                        <div class="flex flex-row w-full h-full" *ngFor="let perimeter of item.perimeter">
                            <div class="w-full">
                              <p-chip class="m-2 flex max-height-3rem">{{getPerimeterName(perimeter)}} 
                                <i class="ml-3 fa-solid  fa-sm fa-circle-xmark icon custom" (click)="delete(item.id,perimeter,item.perimeter)"></i>
                              </p-chip>
                            </div>
                          </div>
                    </div>
                </p-accordionTab>
            </p-accordion>
        </div>
        <div class="flex justify-content-end">
            <div class="flex justify-content-end">
                <div class="flex justify-content-end h-4rem align-items-center p-3">
                    <button pButton pRipple type="button" class="button-decline p-fontMedium " [routerLink]="['../']"
                        [disabled]="loading">Torna Indietro
                    </button>
                </div>
            </div>
        </div>
    </div>

</div>