import { createAction, props } from '@ngrx/store';
import { User } from 'src/app/data/models/User';


export const enum UserActions {

  initUser = '[USER] init user',
  setUser = '[USER] set user',
  setUserSuccess = '[USER] set user success',
  getUserFullName = '[USER] get User full name',
  getUserFullNameSuccess = '[USER] get User full name success',
  getUserFullNameFaillure = '[USER] get User full name faillure',
  updateUser = '[USER] update User',
  updateUserSuccess = '[USER] update User Success',
  // setUserList = '[USER] set UserList',
  // setUserListSuccess = '[USER] set UserList Success',
}

// export const loginUser = createAction(UserActions.loginUser, props<{ email: string, password: string }>());
// export const loginUserSuccess = createAction(UserActions.loginUserSuccess, props<{ email: string, password: string }>());
// export const loginUserFaillure = createAction(UserActions.loginUserFaillure);

export const initUser = createAction(
  UserActions.initUser,
  props<{ currentUser: any }>()
)

export const setUser = createAction(
  UserActions.setUser,
);

export const setUserSuccess = createAction(
  UserActions.setUserSuccess,
  props<{ currentUser: any }>()
);

export const updateUser = createAction(
  UserActions.updateUser,
  // props<{
  //   form: any,
  // }>()
);

export const updateUserSuccess = createAction(
  UserActions.updateUserSuccess,
  props<{ currentUser: any }>()
);

// export const setUserList = createAction(
//   UserActions.setUserList,
//   props<{ start: number, end:number}>()
// );

// export const setUserListSuccess = createAction(
//   UserActions.setUserListSuccess,
//   props<{ users: User[] }>()
// );











