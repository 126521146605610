import { Component } from '@angular/core';
import { RequestService } from 'src/app/utils/services/request.service';
import { OnInit, Input, Output, EventEmitter } from '@angular/core';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/utils/services/user.service';
import { Store } from '@ngrx/store';
import { Observable, Subscription, catchError, concatMap, forkJoin, map, of, take } from 'rxjs';
import { selectCurrentfindingstate, selectFindingDataLoading, selectFindingsData } from 'src/app/utils/store/finding/finding.selector';
import { FindingService } from 'src/app/utils/services/findings.service';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { DbFinding } from 'src/app/data/models/Finding';
import { selectCurrentIDandRole } from 'src/app/utils/store/user/user.selector';
import { Attachment } from 'src/app/data/models/Attachment';
import { ActivatedRoute } from '@angular/router';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';
import { FindingActions, setCurrentFinding } from 'src/app/utils/store/finding/finding.action';
import { setCurrentRequest } from 'src/app/utils/store/request/request.action';
import { environment } from 'src/environments/environment';
import { Status } from 'src/app/data/models/Faq';
import { TrackingRequest } from 'src/app/data/models/Request'

@Component({
  selector: 'app-detail-finding',
  templateUrl: './detail-finding.component.html',
  styleUrls: ['./detail-finding.component.scss']
})
export class DetailFindingComponent implements OnInit {
  @Input() display: boolean = false;
  @Output() newItemEvent = new EventEmitter<string>();
  timelineStatus: any = [
    "NEW",
    "IN_CHARGE",
    "TO_CHECK",
    "ULT. ESITO COMPLIANCE",
    "CLOSED"
  ]
  stato: string;
  canFilter: boolean
  formValue: FormGroup;
  tabella: any[] = [];
  isEmpty: boolean = true;
  isAdmin: boolean = false;
  stateRequest: any[] = [];
  isFilter: { isFilter: boolean, filter: string }
  currentFinding: DbFinding;
  postData: DbFinding;
  attachmentData: Attachment[] = [{
    document_title: "",
    object_name: "",
    description: "",
    uploaded_by: 0
  }];
  tableColumn: string[] = []
  newValue: any
  answerValue: any
  newAttachment: Attachment[] = [];
  attached_doc: any[];
  dataToSend: string
  dataToSendRescheduled: string
  currentUserId: number;
  findingSub: Subscription;
  adminSub: Subscription;
  userSub: Subscription;
  userAssigned: any;
  canAddAttachment: boolean;
  loading: boolean = false;
  trackings: TrackingRequest[] = []
  findingId = this.activateRoute.params['_value']['id'];
  refused_reasons: string;

  getFinding = this.findingService.getFinding(this.findingId).pipe(
    concatMap(currFinding => {
      console.log(currFinding)

      this.currentFinding = currFinding
      this.trackings = [...this.currentFinding.trackings]
      this.refused_reasons = this.currentFinding.refused_reasons
      this.stato = this.currentFinding.status
      if (this.userSub) {
        this.userSub.unsubscribe()
      }

      this.userSub = this.userService.getUserDetail().pipe(
        map(res => {
          console.log(res)

          this.userAssigned = res

          let findingControl = this.formValue.get("newFinding");
          this.formValue.get("newFinding").patchValue({
            idFinding: this.currentFinding.id,
            idScenario: this.currentFinding.risk_id,
            ambito: this.currentFinding.scope,
            scenarioResiduo: this.currentFinding.residual_risk,
            perimetro: this.currentFinding.perimeter,
            tipologiaIntervento: this.currentFinding.intervention_type,
            dataVincolante: this.currentFinding.due_date,
            problemaRiscontrato: this.currentFinding.problem_encountered,
            azioneNecessaria: this.currentFinding.action_required,
            flag_reminder: this.currentFinding.flag_reminder,
            reminder_days: this.currentFinding.reminder_days,
            flag_expiry_date: this.currentFinding.flag_expiry_date,
            utenteAssegnatario: this.currentFinding.group_assigned,
            riutata: this.currentFinding.refused_reasons,
            dataRescheduling: this.currentFinding.due_date_planned,
            counter: this.currentFinding.count_due_date_planned,
          })

          console.log(this.formValue)
          this.formValue.get("answer").patchValue({
            text: this.currentFinding.solution_description,
          })
          if (this.stato !== 'DRAFT') {
            console.log(this.stato)
            this.newValue = this.formValue.controls["newFinding"].value
            this.formValue.get("newFinding").disable()
          } else {
            this.formValue.get("newFinding").disable()
            console.log(this.stato)
            console.log(this.formValue.controls["newFinding"])
            console.log(this.currentFinding.due_date)

          }
        })
        ).subscribe()

      this.store.dispatch(setCurrentFinding({ currentFinding: this.currentFinding }))
      this.attached_doc = [...this.currentFinding.fdocument_attached]
      if (this.isAdmin) {
        this.formValue.get("answer").disable()
      } else if (this.currentFinding.status !== 'REFUSED' && this.currentFinding.status !== 'IN_CHARGE') {
        this.formValue.get("answer").disable()
      }

      this.canFilter = this.currentFinding.status !== 'NEW' ? true : false

      const attachmentObservables = currFinding['fdocument_attached'].map(document => {
        return this.http.get<Attachment>(`${environment.apiUrl}/document/` + document + '/').pipe(
          map((result) => {
            const attachment: any = (({
              id,
              attachment,
              object_name,
              description,
              uploaded_by,
              document_title,
              username,
            }) => ({
              id,
              attachment,
              object_name,
              description,
              document_title,
              uploaded_by,
              username,
            }))(result);
            this.attachmentData.push(attachment)
            if (this.attachmentData.length > 1 && this.attachmentData[0].description == "") {
              this.attachmentData.splice(0, 1)
            }
          }));
      });
      return forkJoin(attachmentObservables);
    }),
  )

  setAdmin: Observable<boolean | [Subscription]> = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      let userRole = roles.find(role => role == "audit_user")
      this.currentUserId = detail['id'];
      this.findingSub = this.getFinding.subscribe(currentFinding => {
      })
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            return this.adminSub = this.setAdmin.subscribe((result: boolean) => {
              this.isAdmin = result;
            })
          })
        )
      )
    })
  );

  constructor(
    private userService: UserService,
    private http: HttpClient,
    private messageService: MessageService,
    private initService: InitService,
    private store: Store,
    private fb: FormBuilder,
    private activateRoute: ActivatedRoute,
    private findingService: FindingService,
  ) { }

  ngOnInit() {
    this.store.select(selectFindingDataLoading).pipe(map(value => this.loading = value)).subscribe()


    this.adminSub = this.setAdmin.subscribe((result: boolean) => {
      this.isAdmin = result;
    })
  

    this.tableColumn = Object.keys(this.attachmentData[0])
    this.formValue = this.fb.group({
      newFinding: {
        idFinding: '',
        idScenario: '',
        ambito: '',
        scenarioResiduo: '',
        perimetro: '',
        tipologiaIntervento: '',
        dataVincolante: '',
        problemaRiscontrato: '',
        azioneNecessaria: '',
        flag_reminder: '',
        reminder_days: '',
        flag_expiry_date: '',
        utenteAssegnatario: '',
        dataRescheduling: '',
        counter: '',
      },
      answer: {
        text: '',
      }
    })
  }

  //DA COLLEGARE ALLO STORE

  saveRequestCompliance() {

    this.newValue = this.formValue.controls['newFinding'].value
    this.answerValue = this.formValue.controls['answer'].value
    if (this.newValue['dataVincolante'] !== '') {
      let data = new Date(this.newValue['dataVincolante'])
      this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
    } else {
      this.dataToSend = null
    }

    if (this.newValue['dataRescheduling']) {
      console.log("entro")
      let dataRescheduled = new Date(this.newValue['dataRescheduling'])
      this.dataToSendRescheduled = dataRescheduled.toJSON().slice(0, dataRescheduled.toJSON().indexOf("T"));
    } else {
      this.dataToSendRescheduled = null
    }

    console.log("dataResacheduling", this.newValue['dataRescheduling'])



    this.postData = {
      scope: this.newValue['ambito'],
      perimeter: this.newValue['perimetro'],
      intervention_type: this.newValue['tipologiaIntervento'],
      flag_expiry_date: this.newValue['flag_expiry_date'],
      due_date: this.dataToSend,
      problem_encountered: this.newValue['problemaRiscontrato'],
      action_required: this.newValue['azioneNecessaria'],
      status: this.currentFinding.status,
      flag_reminder: this.newValue['flag_reminder'],
      reminder_days: this.newValue['reminder_days'],
      fdocument_attached: this.attached_doc,
      risk_id: this.newValue['idScenario'],
      residual_risk: this.newValue['scenarioResiduo'],
      group_assigned: this.newValue['utenteAssegnatario'],
      comments: [],
      solution_description: this.answerValue['text'],
      due_date_planned: this.dataToSendRescheduled,
      count_due_date_planned: this.newValue['counter'],

    };

    console.log("postData", this.postData)

    let attachmentSent;
    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }
    this.store.dispatch({ type: FindingActions.updateFinding, payload: { finding: this.postData, attachments: attachmentSent, id: this.currentFinding.id } })
  }

  saveFindingCompliance() {
    this.newValue = this.formValue.controls['newFinding'].value
    this.answerValue = this.formValue.controls['answer'].value

    //quando è in draft
    if (this.currentFinding.status == 'DRAFT') {
      if (this.newValue['dataVincolante'] !== undefined) {
        console.log(this.newValue['dataVincolante'])
        let data = new Date(this.newValue['dataVincolante'])
        console.log(data)
        this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
      } else {
        this.dataToSend = null
      }
    }

  

    if (this.newValue['dataRescheduling']) {
      let dataRescheduled = new Date(this.newValue['dataRescheduling'])
      this.dataToSendRescheduled = dataRescheduled.toJSON().slice(0, dataRescheduled.toJSON().indexOf("T"));
    } else {
      this.dataToSendRescheduled = null
    }


    this.postData = {
      scope: this.currentFinding.status == 'DRAFT' ? this.currentFinding.scope : this.currentFinding.scope,
      perimeter: this.currentFinding.status == 'DRAFT' ? this.currentFinding.perimeter : this.currentFinding.perimeter,
      intervention_type: this.newValue['tipologiaIntervento'],
      flag_expiry_date: this.newValue['flag_expiry_date'],
      due_date: this.currentFinding.status == 'DRAFT' ? this.currentFinding.due_date : this.newValue['dataVincolante'],
      problem_encountered: this.newValue['problemaRiscontrato'],
      action_required: this.newValue['azioneNecessaria'],
      solution_description: null,
      status: this.currentFinding.status,
      flag_reminder: this.newValue['flag_reminder'],
      reminder_days: this.newValue['reminder_days'],
      fdocument_attached: this.attached_doc,
      risk_id: this.newValue['idScenario'],
      residual_risk: this.newValue['scenarioResiduo'],
      group_assigned: this.currentFinding.status == 'DRAFT' ? this.newValue['utenteAssegnatario'] : this.newValue['utenteAssegnatario'],
      comments: [],
      due_date_planned: this.dataToSendRescheduled,
      count_due_date_planned: this.currentFinding.count_due_date_planned,

    };

    console.log(this.postData)

    let attachmentSent;
    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }

    // path('api/request/<int:pk>/update-status/', RequestViewSet.as_view({'patch': 'update_status'}), name='my_model_update_status'),

    this.store.dispatch({ type: FindingActions.updateFinding, payload: { finding: this.postData, attachments: attachmentSent, id: this.currentFinding.id } })
  }


  getTitle() {
    let title = ""
    if (this.stato == 'COMPLETED' || this.stato == 'IN_CHARGE') {
      title = "Dati Richiesta"
    } else if (this.stato == 'DRAFT' || this.stato == 'REFUSED') {
      title = "Ecco la tua richiesta"
    }
    return title
  }

  canAddAllegato() {
    if (this.stato == 'NEW' || this.stato == 'CLOSED' || (this.stato == 'REFUSED' && this.isAdmin) || (this.stato == 'TO_CHECK' && !this.isAdmin)) {
      this.canAddAttachment = false
      return this.canAddAttachment;
    } else if (this.stato == 'IN_CHARGE' && this.isAdmin) {
      this.canAddAttachment = false
      return this.canAddAttachment;
    } else {
      this.canAddAttachment = true
      return this.canAddAttachment;
    }
  }

  respond(event) {

    this.newValue = this.formValue.controls['newFinding'].value

    let responses: {
      refused_reasons: string
      problem_encountered: string
      action_required: string,
      solution_description: string,
      due_date_planned: string
      group_assigned:string,
      closed_date?:any
    }

    let status: string = event['status'] ? event['status'] : event;
    let dataRespond
    if (this.formValue.controls['newFinding'].value['dataRescheduling'] !== null) {
      dataRespond = new Date(this.formValue.controls['newFinding'].value['dataRescheduling'])

    } else {
      dataRespond = null
    }

    console.log("qui",this.newValue['utenteAssegnatario'] )
    if (status == "TO_CHECK") {
      responses = {
        refused_reasons: this.currentFinding.refused_reasons,
        problem_encountered: this.formValue.controls['newFinding'].value['problemaRiscontrato'],
        action_required: this.formValue.controls['newFinding'].value['azioneNecessaria'],
        solution_description: this.formValue.controls['answer'].value['text'],
        due_date_planned: dataRespond !== null ? dataRespond.toJSON().slice(0, dataRespond.toJSON().indexOf("T")) : dataRespond,
        group_assigned: this.newValue['utenteAssegnatario'] 

      }
      console.log("entro", responses)

    }else if(status == "CLOSED"){
      let closedData = new Date()
      let DataToSendClosed:any
      DataToSendClosed= closedData.toJSON().slice(0, closedData.toJSON().indexOf("T"));

      console.log(DataToSendClosed)
      responses = {
        refused_reasons: event['reason'] ? event['reason'] : '',
        problem_encountered: this.formValue.controls['newFinding'].value['problemaRiscontrato'],
        action_required: this.formValue.controls['newFinding'].value['azioneNecessaria'],
        solution_description: this.formValue.controls['answer'].value['text'],
        due_date_planned: dataRespond !== null ? dataRespond.toJSON().slice(0, dataRespond.toJSON().indexOf("T")) : dataRespond,
        group_assigned: this.newValue['utenteAssegnatario'],
        closed_date:DataToSendClosed
      }
    }else {

      console.log(this.formValue.controls['newFinding'].value['dataRescheduling'])
      responses = {
        refused_reasons: event['reason'] ? event['reason'] : '',
        problem_encountered: this.formValue.controls['newFinding'].value['problemaRiscontrato'],
        action_required: this.formValue.controls['newFinding'].value['azioneNecessaria'],
        solution_description: this.formValue.controls['answer'].value['text'],
        due_date_planned: dataRespond !== null ? dataRespond.toJSON().slice(0, dataRespond.toJSON().indexOf("T")) : dataRespond,
        group_assigned: this.newValue['utenteAssegnatario'] 

      }
    }

    console.log("entro", responses)


    if (status == "TO_CHECK" && (responses.solution_description == "" || responses.solution_description == null)) {
      console.log("entro", responses)
      this.formValue.controls['answer'].setErrors({ required: true })
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "Descrivi l'intervento di risoluzione eseguito" });
    } else if (status == "TO_CHECK" && responses.problem_encountered == "" || responses.problem_encountered == null || responses.action_required == "" || responses.action_required == null) {
      console.log("entro", responses)
      console.log("entro", this.formValue.controls['newFinding'], this.formValue.controls['newFinding'].getError)
      this.messageService.add({ severity: 'error', summary: 'Attenzione!!', detail: "I campi Problema riscontrato e Azione necessaria non devono essere vuoti!" });
    } else {
      let attachmentSent;
      if (this.isEmpty == true) {
        attachmentSent = []
      } else {
        attachmentSent = [...this.attachmentData]
      }
      this.store.dispatch({ type: FindingActions.updateFindingStatus, payload: { status: status.toUpperCase(), id: this.currentFinding.id, responses: responses, attachments: attachmentSent } })
    }

  }

  sendToCompliance() {
    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
    } else {
      this.newValue = this.formValue.controls['newFinding'].value
      this.answerValue = this.formValue.controls['answer'].value      
      if (this.newValue['dataVincolante'] !== undefined) {
        console.log("qui")
        let data = new Date(this.newValue['dataVincolante'])
        this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
      } else {
        this.dataToSend = null
      }

      if (this.newValue['dataRescheduling']) {
        let dataRescheduled = new Date(this.newValue['dataRescheduling'])
        this.dataToSendRescheduled = dataRescheduled.toJSON().slice(0, dataRescheduled.toJSON().indexOf("T"));
      } else {
        this.dataToSendRescheduled = null
      }

      this.postData = {
        scope: this.currentFinding.scope,
        perimeter: this.currentFinding.perimeter,
        intervention_type: this.newValue['tipologiaIntervento'],
        flag_expiry_date: this.newValue['flag_expiry_date'],
        due_date: this.currentFinding.due_date,
        problem_encountered: this.newValue['problemaRiscontrato'],
        action_required: this.newValue['azioneNecessaria'],
        solution_description: null,
        status: Status.NEW,
        flag_reminder: this.newValue['flag_reminder'],
        reminder_days: this.newValue['reminder_days'],
        fdocument_attached: this.attached_doc,
        risk_id: this.newValue['idScenario'],
        residual_risk: this.newValue['scenarioResiduo'],
        group_assigned: this.newValue['utenteAssegnatario'],
        comments: [],
        due_date_planned: this.dataToSendRescheduled,
        count_due_date_planned: this.currentFinding.count_due_date_planned,

      };

      console.log(this.postData)
    }


    let attachmentSent;
    if (this.isEmpty == true) {
      attachmentSent = []
    } else {
      attachmentSent = [...this.attachmentData]
    }

    // path('api/request/<int:pk>/update-status/', RequestViewSet.as_view({'patch': 'update_status'}), name='my_model_update_status'),

    this.store.dispatch({ type: FindingActions.updateFinding, payload: { finding: this.postData, attachments: attachmentSent, id: this.currentFinding.id } })
  }


  canChat() {
    let canChat: boolean;
    if (this.stato == 'REFUSED' || this.stato == 'IN_CHARGE') {
      canChat = true;
    } else {
      canChat = false;

    }
    return canChat
  }

  disabledForm() {
    let disabledForm: boolean;
    if (this.isAdmin == false) {
      if (this.stato == 'COMPLETED' || this.stato == 'NEW' || this.stato == 'REFUSED' || this.stato == 'DRAFT' || this.stato == 'IN_CHARGE') {
        disabledForm = true;
      } else {
        disabledForm = false;
      }
    } else if (this.isAdmin == true) {
      if (this.stato == 'COMPLETED' || this.stato == 'IN_CHARGE' || this.stato == 'SENT') {
        disabledForm = true;
      } else {
        disabledForm = false
      }
    }
    return disabledForm;
  }

  disabledAnswer() {
    let disabledAnswer: boolean;
    if (this.isAdmin == false) {
      if (this.stato == 'COMPLETED' || this.stato == 'REFUSED' || this.stato == 'NEW') {
        disabledAnswer = true;
      } else {
        disabledAnswer = false;
      }
    } else if (this.isAdmin == true) {
      if (this.stato == 'COMPLETED' || this.stato == 'NEW' || this.stato == 'IN_CHARGE' || this.stato == 'SENT') {
        disabledAnswer = true;
      } else {
        disabledAnswer = false;
      }
    }


    return disabledAnswer;
  }

  enableButton() {
    let enableButton;
    if (this.isAdmin == false) {
      if (this.stato == 'NEW') {
        enableButton = true;
      } else {
        enableButton = false;
      }
    }

    return enableButton
  }

  approvalButton() {
    let approvalButton;
    if (this.isAdmin == false) {
      if (this.stato == 'IN_CHARGE' || this.stato == 'DRAFT') {
        approvalButton = true
      } else {
        approvalButton = false;
      }
    }

    return approvalButton
  }


  disabledButton() {
    return this.canAddAttachment ? false : true
  }

  setFilter(data) {
    this.isFilter = data;
  }

  editAttachmentFromDB(event) {
    event.attachment['attachment'] = this.attachmentData[event.index].attachment
    this.attachmentData[event.index] = { ...event.attachment, id: this.attachmentData[event.index].id }
    this.isEmpty = false
  }

  removeAttachment(event) {
    if (event == 0 && this.attachmentData.length == 1) {
      this.attachmentData = [{
        document_title: "",
        object_name: "",
        description: "",
        uploaded_by: 0
      }]
      this.isEmpty = true
      this.checkAttachment(event)
    } else {
      this.checkAttachment(event)
    }
  }

  checkAttachment(position) {
    if (this.currentFinding.fdocument_attached.length > 0) {
      let document = this.attachmentData.find(att => att.id == position)
      let index = this.attachmentData.findIndex(att => att.id == position)

      if (document) {
        this.attached_doc.splice(this.attached_doc.findIndex(id => id == document.id), 1)
        this.attachmentData.splice(index, 1)
        this.http.delete(`${environment.apiUrl}/document/${position}/`).pipe(take(1)).subscribe()
      } else {
        this.newAttachment.splice(position, 1)
        this.attachmentData.splice(position, 1)
      }
    } else {
      this.newAttachment.splice(position, 1)
      this.attachmentData.splice(position, 1)
    }

  }

  addAttachment(attachment) {
    if (this.attachmentData.length > 0 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    const attToShow: any = (({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }) => ({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }))(attachment);
    if (!this.attachmentData) {
      this.attachmentData = { ...attToShow }
    } else {
      this.attachmentData = [...this.attachmentData, attToShow]
    }
    this.isEmpty = false;
    this.newAttachment = [...this.newAttachment, attToShow]
  }

  ngOnDestroy() {
    if (this.adminSub) {
      this.adminSub.unsubscribe()
    }
  }

}

