<div class="customContainer mb-3 mt-0">
  <div [formGroup]="formGroup">
    <div class="form_attributes border-round-sm ">
      <div class="header_attributes border-round-top-sm font-bold flex justify-content-start">
        <p class="header m-1 ml-3 ">Attributi dell'area d'intervento</p>
      </div>
      <div class="p-3">
        <div class="flex flex-row justify-content-between gap-1 mr-3">
          <div class="col-2">
            <p class="font-bold text1 mb-1">ID Finding</p>
            <span class="p-input-icon-left ">
              <i class="pi pi-hashtag font-bold" style="color:black"></i>
              <input type="text" pInputText class="p-input w-full"
                style="padding: 0.63rem 1.8rem;font-size:14pt; max-height: inherit;" formControlName="idFinding">
            </span>
          </div>
          <div class="col-4">
            <div [style]="{'width': '100%' }">
              <p class="font-bold text1 mb-1">Gruppo assegnatario:</p>
              <span class="p-input-icon-right w-full">
                <i class="pi pi-user font-bold " style="color:black"></i>
                <p-autoComplete [forceSelection]="true" showEmptyMessage="true" [(ngModel)]="selectedUser"
                  [styleClass]="styleUserAssigned" formControlName="utenteAssegnatario"
                  (completeMethod)="search($event)" [suggestions]="users">
                  <ng-template let-user pTemplate="item">
                    <div class="flex align-items-center gap-2" *ngIf="user.length > 0; else empty">
                      <div class="img">
                        <!-- foto profilo -->
                        <div class="image-container ">
                          <img [src]="'./assets/images/img_avatar.png'" class="image ">
                        </div>
                        <!-- <i class="fa fa-user fa-2xl"></i> -->
                      </div>
                      <div class="flex flex-column ml-2">
                        <!-- <p class="mb-0 font-bold" style="font-size: 8pt;">{{ user.username}}</p>
                        <p class="mb-0 font-bold capitalize">{{ user.full_name }}</p> -->
                        <p class="mb-0 font-bold" style="font-size: 8pt;">{{user}} </p>
                      </div>
                    </div>
                    <ng-template #empty>
                      <div>Non ci sono riscontri</div>
                    </ng-template>
                  </ng-template>
                </p-autoComplete>
              </span>
              <div class="relative m-0 p-0"
                *ngIf="formGroup.controls['utenteAssegnatario'].invalid && formGroup.controls['utenteAssegnatario'].touched">
                <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci l'utente</p>
              </div>
            </div>
            <!-- <p-dropdown styleClass="dropdown w-full" [styleClass]="styleDropDownScope" [options]="users" formControlName="utenteAssegnatario"
                [(ngModel)]="selectedUser" placeholder="Seleziona Utente">
              <ng-template pTemplate="selectedItem">
                <div class="flex align-items-center gap-2" *ngIf="selectedUser">
                    <div>{{ selectedUser.full_name }}</div>
                </div>
              </ng-template>

          </p-dropdown> -->
          </div>
          <div class=" col-6 flex flex-column ">
            <p class="font-bold  text1 mb-1">Titolo Rilievo</p>
            <input type="text" pInputText class="p-input text1 w-full mr-4" formControlName="idScenario">
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['idScenario'].invalid && formGroup.controls['idScenario'].touched || formGroup.get('description')?.value.length >= 255">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci l'id Scenario di rischio (
                massimo 255 caratteri ).</p>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-content-start">
          <div class=" col-6 mt-2">
            <p class="font-bold  text1 mb-1">Ambito</p>
            <!-- <p-dropdown styleClass="dropdown w-full" [styleClass]="styleDropDownScope" [options]="scope"
              formControlName="ambito" [(ngModel)]="selectedScope" placeholder="Seleziona Ambito"></p-dropdown>
          </div> -->
            <p-dropdown [options]="fieldAOptions" [(ngModel)]="ambito" (onChange)="filterFieldBOptions()"
              styleClass="dropdown w-full" [styleClass]="styleDropDownScope" [placeholder]="'Seleziona Ambito'"
              formControlName="ambito" [emptyFilterMessage]="'Nessun ambito trovato'"></p-dropdown>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['ambito'].invalid && formGroup.controls['ambito'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci l' Ambito</p>
            </div>
          </div>
          <div class="col-6 mt-2">
            <p class="font-bold  text1 mb-1">Scenario di rischio residuo attribuito all'ambito</p>
            <p-dropdown styleClass="dropdown w-full" [styleClass]="styleDropDownScope" [options]="riskselect"
              [(ngModel)]="rischio" placeholder="Seleziona Scenario" formControlName="scenarioResiduo">
            </p-dropdown>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['scenarioResiduo'].invalid && formGroup.controls['scenarioResiduo'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci lo scenario di rischio residuo
                attribuito all'ambito</p>
            </div>
          </div>
        </div>
        <div class="flex flex-row justify-content-start">
          <div class="col-6 mt-2">
            <p class="font-bold  text1 mb-1">Processo</p>
            <!-- <p-dropdown styleClass="dropdown w-full" [styleClass]="styleDropDownPerimeter" [options]="perimeter"
              formControlName="perimetro" [(ngModel)]="selectedPerimeter"
              placeholder="Seleziona Perimetro"></p-dropdown> -->
            <p-dropdown [options]="fieldBOptions" [(ngModel)]="perimetro" styleClass="dropdown w-full"
              [styleClass]="styleDropDownPerimeter" [placeholder]="'Seleziona Perimetro'" formControlName="perimetro"
              emptyMessage="Nessun perimetro selezionato">
            </p-dropdown>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['perimetro'].invalid && formGroup.controls['perimetro'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il Processo</p>
            </div>
          </div>
          <div class="col-6 mt-2">
            <p class="font-bold text1 mb-1">Tipologia di intervento</p>
            <p-dropdown styleClass="dropdown w-full" [styleClass]="styleDropDownOperation" [options]="interventionselect"
              formControlName="tipologiaIntervento" [(ngModel)]="intervento" placeholder="Seleziona Intervento">
            </p-dropdown>
            <div class="relative m-0 p-0"
              *ngIf="formGroup.controls['tipologiaIntervento'].invalid && formGroup.controls['tipologiaIntervento'].touched ">
              <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la tipologia d'intervento</p>
            </div>
          </div>
        </div>
        <div class="flex col-12 flex-row    border-round-md border-dashed p-1 checkbox mt-3">
          <div class="flex col-2  flex-column ">
            <p class="font-bold mb-1  text1">Da risolvere entro il:</p>
            <p-calendar [styleClass]="styleCalendar" [style]="{'height': ' 2.1rem'}" class="date" [(ngModel)]="date"
              dateFormat="{{'dd-mm-yy'}}" inputId="time" formControlName="dataVincolante" class="calendar"
              [minDate]="minDate" placeholder="Seleziona una data">
            </p-calendar>
          </div>
          <div class="flex col-6  mt-3 flex-row justify-content-around gap-3  ">
            <div class="flex  flex-row align-items-center">
              <p-checkbox styleClass="customCheck" class="pl-5 pr-2" [(ngModel)]="checkedReminderDay"
                formControlName="flag_reminder" [binary]="true" inputId="binary"></p-checkbox>
              <p class="font-bold  text1 pr-2">Invia mail con i seguenti giorni di preavviso:</p>
              <p-inputNumber inputId="integeronly" formControlName="reminder_days" class="w-0"
                [styleClass]="styleNumber" [(ngModel)]="reminderday"></p-inputNumber>
            </div>
            <div class="flex  flex-row align-items-center">
              <p-checkbox styleClass="customCheck" class="pl-5 pr-2" [(ngModel)]="checkedExpirtDay"
                formControlName="flag_expiry_date" [binary]="true" inputId="binary"></p-checkbox>
              <p class="font-bold text1  ">Invia mail il giorno della scadenza</p>
            </div>
          </div>
          <div *ngIf="!isNewFinding" class="flex flex-row justify-content-start">
            <div class="flex col-5 flex-column ">
              <p class="font-bold mb-1  text1">Data Ripianificazione:</p>
              <p-calendar [styleClass]="styleCalendar" [style]="{'height': ' 2.1rem'}" class="date"
                [(ngModel)]="dateRescheduling" dateFormat="{{'dd-mm-yy'}}" inputId="time"
                formControlName="dataRescheduling" class="calendar" [minDate]="date" placeholder="Seleziona una data">
              </p-calendar>
            </div>
            <div class="flex col-7  mt-3 flex-row gap-3 justify-content-start">
              <div class="flex  flex-row align-items-center  pl-7">
                <p class="font-bold  text1 pr-2">N° Ripianificazione</p>
                <p-inputNumber inputId="integeronly" formControlName="counter" class="w-0" [styleClass]="styleNumber"
                  [(ngModel)]="counterRescheduling"></p-inputNumber>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-2 col-12 ">
          <p class="font-bold text1 mb-1">Problema Riscontrato</p>
          <textarea pInputTextarea [autoResize]="false" class="w-full p-3"  formControlName="problemaRiscontrato">Mooney non formalizza le modalità di determinazione (nei casi previsti dalla normativa) della percentuale da applicare ai fondi ricevuti dalla clientela al fine di determinare l'ammontare dei fondi destinati a future operazioni di pagamento, ovvero future operazioni connesse con la moneta elettronica.
          </textarea>
          <div class="relative m-0 p-0"
            *ngIf="formGroup.controls['problemaRiscontrato'].invalid && formGroup.controls['problemaRiscontrato'].touched || formGroup.get('problemaRiscontrato')?.value.length >= 65535">
            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il problema riscontrato ( massimo
              65535 caratteri ).</p>
          </div>
        </div>
        <div class="mt-2 col-12 ">
          <p class="font-bold text1 mb-1">Azione Necessaria richiesta</p>
          <textarea pInputTextarea [autoResize]="false" class="w-full p-3 " formControlName="azioneNecessaria">Al fine di garantire un maggior presidio in relazione ai fondi ricevuti dagli utenti a fronte della prestazione di servizi di pagamento, si suggerisce di aggiornare la Procedura “Gestione Flussi Finanziari” in modo da allineare le denominazioni societarie delle Legal Entities del Gruppo a quelle attuali, nonché definire le modalità di calcolo (nei casi previsti dalla normativa) della percentuale da applicare ai fondi ricevuti dalla clientela in modo da determinare l'ammontare dei fondi destinati a future operazioni di pagamento
          </textarea>
          <div class="relative m-0 p-0"
            *ngIf="formGroup.controls['azioneNecessaria'].invalid && formGroup.controls['azioneNecessaria'].touched || formGroup.get('azioneNecessaria')?.value.length >= 65535">
            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci l'azione necessaria( massimo 65535
              caratteri ).</p>
          </div>
        </div>
        <div class="mt-2 col-12 " *ngIf="stato == 'REFUSED' || stato == 'TO_CHECK'">
          <p class="font-bold text1 mb-1">Motivazione Rifiutata</p>
          <textarea pInputTextarea [autoResize]="false" class="w-full p-3 h-6rem" formControlName="riutata"></textarea>
        </div>
      </div>
    </div>




  </div>