import { Injectable } from '@angular/core';
import { Attachment } from 'src/app/data/models/Attachment';
import { Filter, SortField } from 'src/app/data/models/Filter';
import { HttpClient } from '@angular/common/http';
import { DbRequest } from 'src/app/data/models/Request';
import { Store } from '@ngrx/store';
import { selectRequestDataLoading } from '../store/request/request.selector';
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';

@Injectable({
  providedIn: 'root'
})
export class RequestService {

  private serviceData: any[] = [];
  private request: DbRequest;
  private finding: any;


  constructor(private http: HttpClient, private store: Store, private keycloackservice: KeycloakService) { }

  //funzione della select loading
  getLoading() {
    return this.store.select(selectRequestDataLoading)
  }

  addNewRequest(newRequest: DbRequest) {
    return this.http.post(`${environment.apiUrl}/request/`, newRequest, { observe: 'response' });
  }

  addNewFaq(newFaq: DbRequest) {
    return this.http.post(`${environment.apiUrl}/faq/`, newFaq, { observe: 'response' });
  }

  updateRequest(request: DbRequest, id: number) {
    return this.http.put(`${environment.apiUrl}/request/${id}/`, request)
  }

  updateFaq(faq: DbRequest, id: number) {
    return this.http.put(`${environment.apiUrl}/faq/${id}/`, faq)
  }

  updateRequestStatus(status, id, answer) {
    status = answer !== undefined ? {
      status: status,
      final_answer: answer.final_answer,
      legal_answer: answer.legal_answer,
      privacy_answer: answer.privacy_answer,
      compliance_answer: answer.compliance_answer,
      legal_checkbox: answer.legal_checkbox,
      privacy_checkbox: answer.privacy_checkbox,
      note: answer.note
    } : {
      status: status,
      legal_checkbox: false,
      privacy_checkbox: false,

    }
    return this.http.patch(`${environment.apiUrl}/request/${id}/update-status/`, status)
  }

  export(data) {
    return this.http.post(`${environment.apiUrl}/download-excel/`, data, {
      headers: {
        'Authorization': `Bearer ${this.keycloackservice.getToken()}`
      },
      responseType: 'blob'
    })
  }

  uploadtemplate(data) {
    return this.http.post(`${environment.apiUrl}/admin-panel/upload/`, data, {
      headers: {
        'Authorization': `Bearer ${this.keycloackservice.getToken()}`
      },
      responseType: 'blob'
    })
  }

  addNewAttachment(attachment: any, id: number) {
    let tmp = { ...attachment, request_attached: id }
    const formData: FormData = new FormData();

    formData.append('document_title', tmp.document_title);
    formData.append('object_name', tmp.object_name);
    formData.append('description', tmp.description);
    formData.append('attachment', tmp.attachment, tmp.attachment.name);
    formData.append('uploaded_by', tmp.uploaded_by.toString());
    formData.append('request_attached', tmp.request_attached.toString());
    return this.http.post(`${environment.apiUrl}/document/`, formData);
  }

  addComment(requestId: number, comment: Comment) {
    return this.http.post(`${environment.apiUrl}/request/${requestId}/comment/`, comment)
  }

  getRequests(page: number, pageSize: number, filters?: Filter, order?: SortField) {
    let filtersToApply = ""
    let orderToApply = ""
    page = page == undefined ? 1 : page;
    pageSize = pageSize == undefined ? 5 : pageSize
    if (filters) {
      let keys = Object.keys(filters);
      keys.forEach((key, i) => {
        if (key == 'due_date') {
          filtersToApply += `&${key}__gte=${filters[key][0]}&due_date__lte=${filters[key][1]}`
        } else if (key == "search" || key == "expired" || key == "requested_legal" || key == "requested_privacy") {
          filtersToApply += "&" + key + "="
        } else {
          filtersToApply += "&" + key + "__in="
        }
        if (key !== "due_date") {
          filters[key].forEach((filter, index) => {
            if (index > 0) {
              filtersToApply += ","
            }
            filtersToApply += filter;
          })
        }
      })
    }
    if (order?.['field'] !== undefined) {
      orderToApply = "&ordering=" + (order.sortOrder > 0 ? '' : '-') + (order.field == 'description' ? 'request_title' : order.field)
    }
    return this.http.get<DbRequest[]>(`${environment.apiUrl}/request/?page=${page}&page_size=${pageSize}${filtersToApply}${orderToApply}`)
  }

  getAttachment() {
    return this.http.get<Attachment>(`${environment.apiUrl}/document/`)
  }

  getFaqs(page: number, pageSize: number, filters?: Filter, order?: SortField) {
    let filtersToApply = ""
    let orderToApply = ""
    page = page == undefined ? 1 : page;
    pageSize = pageSize == undefined ? 5 : pageSize
    if (filters) {
      let keys = Object.keys(filters);
      keys.forEach((key, i) => {
        if (key == 'due_date') {
          filtersToApply += `&${key}__gte=${filters[key][0]}&due_date__lte=${filters[key][1]}`
        } else if (key == "search" || key == "expired") {
          filtersToApply += "&" + key + "="
        } else {
          filtersToApply += "&" + key + "__in="
        }
        if (key !== "due_date") {
          filters[key].forEach((filter, index) => {
            if (filter == 'PUBLISHED') {
              filter = 'NEW'
            }
            if (index > 0) {
              filtersToApply += ","
            }
            filtersToApply += filter;
          })
        }
      })
    }
    if (order) {
      orderToApply = "&ordering=" + (order.sortOrder > 0 ? '' : '-') + (order.field == 'description' ? 'request_title' : order.field)
    }
    return this.http.get<DbRequest[]>(`${environment.apiUrl}/faq/?page=${page}&page_size=${pageSize}${filtersToApply}${orderToApply}`)
  }

  updateAttachment(attachment) {
    const id = attachment['id']

    let att = {
      document_title: attachment['document_title'],
      object_name: attachment['object_name'],
      description: attachment['description']
    }
    return this.http.patch(`${environment.apiUrl}/document/${id}/`, att)
  }


}
