<div class="container-profile">
  <div class="profile-home  ">
    <div class="triangle"></div>
    <div class="flex mt-2 ">
      <div class="flex mx-3 flex-row ">
        <div class="flex  test  ">
          <div class="picture ">
            <div class="image-container ">
              <img [src]="'./assets/images/img_avatar.png'" class="">
            </div>
          </div>
          <div class="flex-row ml-5  pt-3">
            <div class="flex align-items-baseline">
              <p class="text2 subtitle">Nome:</p>
              <p class="text-desc">{{firstName}}</p>
            </div>
            <div class=" flex align-items-baseline">
              <p class="text2 subtitle">Cognome:</p>
              <p class="text-desc">{{lastName}}</p>
            </div>
            <div class=" flex align-items-baseline">
              <p class="text2 subtitle">Email:</p>
              <p class="text-desc">{{email}}</p>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="flex w-full justify-content-center buttoncustom">
      <button class="customButton" [routerLink]="['/profile']">Modifica
        informazioni</button>
      <i class="fa-sharp fa-solid fa-pencil iconpencil"></i>
    </div>
  </div>