<div class="p-3 w-full">
    <p-virtualScroller styleClass="customScroller" [value]="values" [itemSize]="itemSize" [lazy]="lazy"
        (onLazyLoad)="loadCarsLazy($event)" showLoader="true">
        <ng-template pTemplate="header">
            <form [formGroup]="formGroupDropDown">
                <div class=" flex flex-column gap-3">
                    <div class="flex flex-row justify-content-between ">
                        <div class="flex-grow-0 flex mr-3" *ngIf="isObject(title)  else simpleTitle">
                            <div *ngIf="isDropdownManage" class="flex " style="justify-content: start;">
                                <p-dropdown [(ngModel)]="selectedDropDown" [options]="title" placeholder="Seleziona"
                                    formControlName="selectDropDown"></p-dropdown>
                            </div>
                        </div>
                        <div class="p-input-icon-right flex-grow-1 flex   ">
                            <i class="fa fa-search pr-2"></i>
                            <input type="text" placeholder="Cerca" pInputText class="p-input w-100 pl-3"
                                style="font-size:16px; " formControlName="search" />
                        </div>
                        <div class=" flex-grow-1 flex ml-3" *ngIf="canAddItems">
                            <div class="flex flex-row w-100 gap-2 relative">
                                <input type="text" pInputText placeholder="Aggiungi" [(ngModel)]="addField"
                                    class="p-input w-full pl-3 " [style]="{'width':'100%'}"
                                    style="font-size:16px; width: 100%;" formControlName="add" />
                                <button pButton pRipple type="button"
                                    class="button-add-item h-75 m-0 w-3rem justify-content-center"
                                    [disabled]="buttonload" (click)="addItem(addField)"><i
                                        class="fa-solid fa-circle-plus fa-md "></i></button>
                                <!-- <i class="fa-solid fa-circle-plus fa-md h-75 m-0 w-3rem button-add-item justify-content-center" [ariaDisabled]="true" (click)="addItem(addField)"></i> -->
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </ng-template>
        <ng-template pTemplate="item" let-value let-index let-options="options">
            <div *ngIf="!isLoading;else loading"
                class="flex prova w-100 h-100 px-3 align-items-center gap-2 border-bottom-2 border-300">
                <div *ngFor="let item of getItems(value);let i = index" class="flex fs-xs w-full">
                    <div >
                        <div class="flex ">
                            <div class="flex flex-column" class="text "
                                [ngClass]=" value['enabled'] == true && item == 'enabled'?  ' badgeactive':value['enabled']==false && item == 'enabled'?'badgedecline':'' ">
                                <p [style]="{'margin-bottom': '0px','font-weight':'bold'}" *ngIf="item == 'email'"
                                    class="text">{{value['firstName'] + ' ' + value['lastName']}}</p>
                                <p [style]="{'margin-bottom': '0px'}" *ngIf="item == 'groups'"  class="text ">
                                    {{value['groups']==''?'Nessun gruppo': '' }}</p>
                                    <p [style]="{'margin-bottom': '0px'}" *ngIf="item == 'roles'" class="text">
                                        {{value['roles']==undefined?'Nessun ruolo': '' }}</p>
                                <p [style]="{'margin-bottom': '0px'} " [ngClass]="value[item].length > 0 && manageGroup == true ? 'crop-text':''">
                                    {{ item == 'enabled'? (value[item]|enableUser) : value[item]}}
                                </p>
                            </div>
                        </div>
                    </div>
                    <!-- <p [style]="{'margin-bottom': '0px'}" *ngIf="item == 'groups'" class="text crop-text">
                        {{ value['groups'] == '' ? 'Nessun gruppo' : (value['groups'].length > 20 ? (value['groups'] | slice:0:20) + '...' : value['groups']) }}
                      </p> -->
                    <!-- <div *ngIf="isObject(value[item])  else simpleField">
                        <div *ngIf="selectedDropDown == item || seeAll == true">
                            <p-dropdown [options]="dropDownOptions[item]" (onChange)="printFor($event,index)"
                                [placeholder]="value[item].length > 0 ? value[item] : 'Nessun '+item"></p-dropdown>
                        </div>
                    </div>
                    <ng-template #simpleField>
                        <div class="flex">
                            <div class="flex flex-column" >
                                <p [style]="{'margin-bottom': '0px','font-weight':'bold'}" *ngIf="item == 'email'"
                                    class="text">{{value['firstName'] + ' ' + value['lastName']}}</p>
                                <p [style]="{'margin-bottom': '0px'}"> 
                                    {{ item == 'enabled'? (value[item]|enableUser) : value[item]}}
                                </p>
                            </div>
                        </div>
                    </ng-template> -->
                </div>
                <div class="flex gap-3 align-items-baseline">
                    <i *ngIf="(value['id']!==undefined) && canRemoveItems" class="pi pi-times icon"
                        (click)="removeItem(index)"></i>
                    <i *ngIf="canRemoveGroup" class="pi pi-times icon"
                        (click)="removeItem(index)"></i>

                    <i *ngIf="canEditItems" class="pi pi-pencil icon" (click)="editItem(value)"></i>
                </div>
            </div>
        </ng-template>
        <ng-template #simpleTitle>
            <div class="flex flex-grow-2 text1">
                {{title}}
            </div>
        </ng-template>
        <ng-template #loading>
            <div class="flex prova w-100 py-1 px-3 align-items-center gap-2">
                <div *ngFor="let item of getItems(value)" class="flex fs-xs w-full">
                    <p-skeleton width="10rem" height="3rem"></p-skeleton>
                </div>
            </div>
        </ng-template>
    </p-virtualScroller>
</div>






<p-dialog [closable]="true" [(visible)]="Dialog" [modal]="true"
    [style]="{'width': '30vw', 'min-width': '20rem', 'height': 'auto' }" [draggable]="false" [resizable]="false">
    <ng-template pTemplate="header">
        <div class=" flex justify-content-center  align-item-center  w-full h-full">
            <i class=" text-center  p-3 fa-solid fa-xl fa-circle-exclamation "></i>
        </div>
    </ng-template>
    <ng-template pTemplate="content ">
        <div class=" flex w-full  ">
            <h5 class="w-full  text-center">{{isDropdown? 'Vuoi rimuovere questo elemento?':'Vuoi disabilitare questo
                Utente?'}}</h5>
        </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <div class="flex justify-content-between w-full">
            <button pButton pRipple type="button" class="customButton   w-full" [disabled]="loading"
                (click)="displayAcept=false" label="Annulla">
            </button>
            <button pButton pRipple type="button" class="customButtonActive  m-0 w-full" [disabled]="loading"
                (click)="removeItem(index)" label="Conferma">
            </button>
        </div>
    </ng-template>
</p-dialog>