
import { Component } from '@angular/core';
import { Store, select } from '@ngrx/store';
import { Observable, Subject, Subscription, catchError, concatMap, debounceTime, forkJoin, interval, map, merge, mergeMap, of, switchMap, take, takeUntil, tap, withLatestFrom } from 'rxjs';
import { RequestService } from 'src/app/utils/services/request.service';
import { selectRequestDataLoading, selectRequestSize, selectRequestsData } from 'src/app/utils/store/request/request.selector';
import { setRequests } from 'src/app/utils/store/request/request.action';
import { DbRequest } from 'src/app/data/models/Request';
import { selectCurrentIDandRole, selectUserRole } from 'src/app/utils/store/user/user.selector';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';
import { environment } from 'src/environments/environment';
import { ExportFilter, Filter } from 'src/app/data/models/Filter';
export enum TableRequest {
  priority = "Priorita'",
  due_date = "Data di scadenza",
  last_update = "Data Ultimo Aggiornamento",
  creation_date = "Data Creazione",
  status = "Stato",
  perimeter = "Perimetro",
  scope = "Ambito",
  structure_request = "Struttura richiedente",
}




@Component({
  selector: 'app-table-requests',
  templateUrl: './table-requests.component.html',
  styleUrls: ['./table-requests.component.scss']
})
export class TableRequestsComponent {
  isAdmin: boolean = null;
  requestData: DbRequest[] = [{
    scope: "",
    perimeter: "",
    description: "",
    structure_request: "",
    status: "",
    priority: "",
    creation_date: "",
    last_update: "",
    due_date: "",
    comments: [],
    expired: false,
    // privacy_answer: "",
    // legal_answer: "",
    legal_checkbox: false,
    privacy_checkbox: false,



  }];

  tableFilters: string[] = [
    "scope",
    "perimeter",
    "status",
    "priority",
    "expired",
    "due_date"
  ]

  tableColumn: string[] = [
    "scope",
    "perimeter",
    "description",
    "structure_request",
    "status",
    "priority",
    "creation_date",
    "last_update",
    "due_date",
    "comments",
    "expired",
  ]
  requestCurrentData: DbRequest[] = [];
  //loadingRequest: Observable<boolean>;
  sub: Observable<any>;
  page: number = 0;
  pageSize: number = 0;
  totalSize: number = 0;
  isLoading: boolean = false;
  filters: ExportFilter = { request: true, filters: undefined };
  subscription: Subscription;
  currentUserID: number
  legalRole: boolean = false;
  privacyRole: boolean = false
  hasAction: boolean = true;
  headerTabTitle: string;
  dataLoaded$ = this.store.select(selectRequestDataLoading).pipe(
    map(loading => {
      this.isLoading = loading
      if (this.isLoading == false) {
        this.data$.pipe(take(1)).subscribe()
      }
    }));

  data$ = this.store.select(selectRequestsData).pipe(
    map((requestData) => {
      let requests = requestData['requests']
      this.totalSize = requestData['size']
      let requestsTmp: DbRequest[] = []
      let requestsTmp1: DbRequest[] = []
      if (requests.length == 0) {
        requestsTmp.push({
          scope: "",
          perimeter: "",
          description: "",
          structure_request: "",
          status: "",
          priority: "",
          creation_date: "",
          last_update: "",
          due_date: "",
          comments: [],
          // legal_answer: "",
          // privacy_answer: "",
          compliance_answer: "",
          legal_checkbox: false,
          privacy_checkbox: false,
        })

      } else {
        requests.forEach(element => {
          requestsTmp1.push(element)
        })
        requests.forEach(element => {
          // if(element.comments.length>0){
          //   const tmpComments:any[] = element.comments
          //   const unseenComment = (tmpComments.filter(comment => comment.seen == false)).filter(comment=>comment.user !== this.currentUserID)
          //     const unreaded:boolean = unseenComment.length>0 ? true : false
          // }

          const request: DbRequest = (({
            id,
            requested_user,
            scope,
            perimeter,
            description,
            structure_request,
            status,
            priority,
            creation_date,
            last_update,
            due_date,
            comments,
            expired,
            // legal_answer,
            // privacy_answer,
            compliance_answer,
            legal_checkbox,
            privacy_checkbox,
            requested_legal,
            requested_privacy,

          }) => ({
            id,
            requested_user,
            scope,
            perimeter,
            description,
            structure_request,
            status,
            priority,
            creation_date,
            last_update,
            due_date,
            comments,
            expired,
            // legal_answer,
            // privacy_answer,
            compliance_answer,
            legal_checkbox,
            privacy_checkbox,
            requested_legal,
            requested_privacy,
          }))(element);
          requestsTmp.push(request)
        })
      }
      this.requestData = [...requestsTmp]

      this.isLoading = false
      this.requestCurrentData = [...requestsTmp1]
      return this.requestData
    })
  )


  adminSub$: Observable<any> = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      const userRole = roles.find(role => role == "compliance_user")
      this.headerTabTitle = userRole !== undefined ? "Elenco richieste" : "Le mie richieste"
      const legalTemp = roles.find(role => role == "legal_user")
      this.legalRole = legalTemp !== undefined ? true : false;
      const privacyTemp = roles.find(role => role == "privacy_user")
      this.privacyRole = privacyTemp !== undefined ? true : false;

      this.currentUserID = detail['id']

      if (!privacyTemp && !legalTemp) {
  

      
      this.tableColumn = userRole||legalTemp||privacyTemp ? [
          "scope",
          "perimeter",
          "description",
          "structure_request",
          "status",
          "priority",
          "creation_date",
          "last_update",
          "due_date",
          "comments",
          "expired",
          "legal_checkbox",
          "privacy_checkbox",
          "requested_legal",
          "requested_privacy",
        ] : [
          "scope",
          "perimeter",
          "description",
          "structure_request",
          "status",
          "priority",
          "creation_date",
          "last_update",
          "due_date",
          "comments",
          "expired",
        ]
        this.tableFilters = userRole ? [
          "scope",
          "perimeter",
          "status",
          "priority",
          "expired",
          "due_date",
          "legal_checkbox",
          "privacy_checkbox",
          "requested_legal",
          "requested_privacy",
        ] : [
          "scope",
          "perimeter",
          "status",
          "priority",
          "expired",
          "due_date"
        ]
      } else if (privacyTemp && !legalTemp) {
        this.tableColumn = [
          "scope",
          "perimeter",
          "description",
          "structure_request",
          "status",
          "priority",
          "creation_date",
          "last_update",
          "due_date",
          "comments",
          "expired",
          "privacy_checkbox",

        ]
        this.tableFilters = [
          "scope",
          "perimeter",
          "status",
          "priority",
          "expired",
          "due_date",
          "privacy_checkbox",
        ]
      }else if(!privacyTemp && legalTemp){
        this.tableColumn = [
          "scope",
          "perimeter",
          "description",
          "structure_request",
          "status",
          "priority",
          "creation_date",
          "last_update",
          "due_date",
          "comments",
          "expired",
          "legal_checkbox",

        ]
        this.tableFilters = [
          "scope",
          "perimeter",
          "status",
          "priority",
          "expired",
          "due_date",
          "legal_checkbox",
        ]
      }


      if (this.legalRole || this.privacyRole) {
        this.hasAction = false;
        this.headerTabTitle = "Elenco richieste"
      }
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            this.subscription.unsubscribe()
            this.subscription = this.adminSub$.subscribe(result => {
              this.isAdmin = result;
            })
          })
        )
      )
    })
  )

  constructor(private store: Store, private initService: InitService) {




  }

  ngOnInit() {

    this.subscription = this.adminSub$.subscribe(result => {
      this.isAdmin = result;
    })


  }


  isLoadingRequest(event) {

    let page = (event.first + event.rows) / event.rows
    if (this.requestData.length !== 0) {
      this.isLoading = true;
    }

    if (this.isLoading) {
      this.store.dispatch(setRequests(({ page: page, pageSize: event.rows, filters: event.filters, ordering: { field: event.sortField, sortOrder: event.sortOrder } })))
    }
  }

  setFilters(event) {
    this.filters = { request: true, filters: { ...event } }
  }


  ngOnDestroy() {
    this.requestData = []
    this.requestCurrentData = []
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }

}
