import { createReducer, on } from '@ngrx/store';
import * as UserActions from './user.actions';
import { initialUserState } from './user.state';


export const userReducer = createReducer(
  initialUserState,
  on(UserActions.initUser, (state, { currentUser }) => ({ ...state, currentUser, updateUser: false })),
  // on(UserActions.loginUser, (state, { email, password }) => ({ ...state, email, password })),
  // on(UserActions.getUserFullName, (state) => ({ ...state})),
  // on(UserActions.getUserFullNameSuccess, (state, { name, surname }) => ({ ...state, currentUserFullName: name + " " + surname })),
  on(UserActions.setUserSuccess, (state, { currentUser }) => ({ ...state, currentUser })),
  on(UserActions.updateUser, (state) => ({ ...state, updateUser: true })),
  on(UserActions.updateUserSuccess, (state, { currentUser }) => ({ ...state, currentUser, updateUser: false })),
  // on(UserActions.setUserList, (state) => ({ ...state, loadingUsers:true })),
  // on(UserActions.setUserListSuccess, (state, { users }) => ({ ...state, users, loadingUsers: false })),
);
