import { APP_INITIALIZER, NgModule, isDevMode } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { AppPrimeNGModule } from 'src/app/modules/app-primeNG.module';
import { AppRoutingModule } from './modules/app-routing.module';
import { AppComponent } from './app.component';
import { ReactiveFormsModule } from '@angular/forms';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { KeycloakAngularModule, KeycloakService } from 'keycloak-angular';

import { LoginComponent } from './components/login/login.component';
import { FooterComponent } from './components/footer/footer.component';
import { HeaderComponent } from './components/header/header.component';
import { HomePageComponent } from './features/home-page/home-page.component';
import { FollowUpRemedationPlanComponent } from './features/follow-up-remedation-plan/follow-up-remedation-plan.component';
import { ComplianceOpinionComponent } from './features/compliance-opinion/compliance-opinion.component';
import { StoreModule } from '@ngrx/store';
import { DynamicTable } from './components/dynamic-table/dynamic-table.component';
import { DragndropDirective } from './utils/directive/dragndrop.directive';
import { FaqComponent } from './features/compliance-opinion/faq/faq.component';
import { FormNewRequestComponent } from './features/compliance-opinion/new-request/form-new-request/form-new-request.component';
import { NewRequestComponent } from './features/compliance-opinion/new-request/new-request.component';
import { AddAttachmentComponent } from './features/compliance-opinion/new-request/add-attachment/add-attachment.component';
import { RequestService } from './utils/services/request.service';
import { MessageService } from 'primeng/api';
import { HeaderTabComponent } from './components/header-tab/header-tab.component';
import { SeeRequestComponent } from './features/compliance-opinion/see-request/see-request.component';
import { TableRequestsComponent } from './features/compliance-opinion/table-requests/table-requests.component';
import { FlowComponent } from './features/compliance-opinion/flow/flow.component';
import { FindingAssignedComponent } from './features/follow-up-remedation-plan/finding-assigned/finding-assigned.component';
import { AnswerComponent } from './features/compliance-opinion/see-request/answer/answer.component';
import { TableRequestsFollowUpComponent } from './features/follow-up-remedation-plan/table-requests-follow-up/table-requests-follow-up.component';
import { FollowTimelineComponent } from './features/follow-up-remedation-plan/detail-finding/follow-timeline/follow-timeline.component';
import { DetailFindingComponent } from './features/follow-up-remedation-plan/detail-finding/detail-finding.component';
import { FormAttributesComponent } from './features/follow-up-remedation-plan/detail-finding/form-attributes/form-attributes.component';
import { InfoRequestComponent } from './features/compliance-opinion/info-request/info-request.component';
import { UserService } from './utils/services/user.service';
import { reducers, metaReducers } from './utils/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { ViewRequestTestComponent } from './view-request-test/view-request-test.component';
import { ChatComponent } from './components/chat/chat.component';
import { TrackingComponent } from './features/compliance-opinion/tracking/tracking.component';
import { NewFindingComponent } from './features/follow-up-remedation-plan/new-finding/new-finding.component';
import { NotifyComponent } from './components/notify/notify.component';
import { FullnotifyComponent } from './components/fullnotify/fullnotify.component';
import { ProfileComponent } from './components/profile/profile.component';
import { ProfileDashbComponent } from './components/profile-dashb/profile-dashb.component';
import { RequestsComponent } from './features/compliance-opinion/requests/requests.component';
import { initializeKeycloak } from './utilities/keycloak-init';
import { NotfoundComponent } from './components/notfound/notfound.component';

import { HTTP_INTERCEPTORS, HttpClientModule, HttpClientXsrfModule } from '@angular/common/http';
import { CustomHttpInterceptor } from './utils/interceptor/http.interceptor';
import { EffectsModule } from '@ngrx/effects';
import { UserEffects } from './utils/store/user/user.effects';
import { RequestEffects } from './utils/store/request/request.effects';
import { FindingEffects } from './utils/store/finding/finding.effects';
import { DatePipe } from '@angular/common';
import { NewFaqComponent } from './features/compliance-opinion/new-faq/new-faq.component';
import { AddAttachmentFaqComponent } from './features/compliance-opinion/new-faq/add-attachment-faq/add-attachment-faq.component';
import { FormNewFaqComponent } from './features/compliance-opinion/new-faq/form-new-faq/form-new-faq.component';
import { TableFaqComponent } from './features/compliance-opinion/table-faq/table-faq.component';
import { SeeFaqComponent } from './features/compliance-opinion/see-faq/see-faq.component';
import { StatuspipePipe } from './utils/pipe/statuspipe.pipe';
import { DataPipe } from './utils/pipe/datapipe/data.pipe';
import { PulsarService } from './utils/services/pulsar.service';
import { TruncatePipe } from './utils/pipe/truncate.pipe';
import { FilterstatusPipe } from './utils/pipe/filterstatus.pipe';
import { FlowPipe } from './utils/pipe/flow.pipe';
import { UserFullNamePipe } from './utils/pipe/userFulName.pipe';
import { CommentEffects } from './utils/store/comment/comment.effect';
import { CommentService } from './utils/services/comment.service';
import { ExportEffects } from './utils/store/export/export.effects';
import { AdminDashComponent } from './admin/admin-dash/admin-dash.component';
import { ManageUserComponent } from './admin/admin-dash-tabs/manage-user/manage-user.component';
import { DropdownSelectorComponent } from './admin/admin-dash-tabs/dropdown-selector/dropdown-selector.component';
import { ScopeListComponent } from './admin/admin-dash-tabs/dropdown-selector/scope-list/scope-list.component';
import { AdminService } from './utils/services/admin.service';
import { DynamicListComponent } from './components/dynamic-list/dynamic-list.component';
import { AdminHomeComponent } from './admin-home/admin-home.component';
import { ScopePerimeterComponent } from './admin/admin-dash-tabs/scope-perimeter/scope-perimeter.component';
import { DropdownService } from './utils/services/dropdown.service';
import { AdminEffects } from './utils/store/admin/admin.effects';
import { CreateUserComponent } from './admin/admin-dash-tabs/create-user/create-user.component';
import { ImportComponent } from './admin/admin-dash-tabs/import/import.component';
import { AdminGuard } from './utilities/admin.guard';
import { NotAdminGuard } from './utilities/not-admin.guard';
import { UserDetailComponent } from './admin/admin-dash-tabs/user-detail/user-detail.component';
import { LinkscopeperimeterPipe } from './utils/pipe/linkscopeperimeter.pipe';
import { MakeGroupComponent } from './admin/admin-dash-tabs/make-group/make-group.component';
import { EnableUserPipe } from './utils/pipe/enable-user.pipe';


// import { AddAtt}
// import { AddAttachmentFaqComponent } from './add-attachment-faq/add-attachment-faq.component';
// import { FormNewFaqComponent } from './form-new-faq/form-new-faq.component'



@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    FooterComponent,
    HeaderComponent,
    HomePageComponent,
    FollowUpRemedationPlanComponent,
    ComplianceOpinionComponent,
    RequestsComponent,
    DynamicTable,
    DragndropDirective,
    FormNewRequestComponent,
    NewRequestComponent,
    AddAttachmentComponent,
    FaqComponent,
    HeaderTabComponent,
    SeeRequestComponent,
    FindingAssignedComponent,
    TableRequestsComponent,
    FlowComponent,
    AnswerComponent,
    TableRequestsFollowUpComponent,
    FollowTimelineComponent,
    DetailFindingComponent,
    FormAttributesComponent,
    InfoRequestComponent,
    ViewRequestTestComponent,
    ChatComponent,
    TrackingComponent,
    NewFindingComponent,
    NotifyComponent,
    FullnotifyComponent,
    ProfileComponent,
    ProfileDashbComponent,
    NewFaqComponent,
    AddAttachmentFaqComponent,
    FormNewFaqComponent,
    TableFaqComponent,
    SeeFaqComponent,
    StatuspipePipe,
    UserFullNamePipe,
    DataPipe,
    TruncatePipe,
    FilterstatusPipe,
    FlowPipe,
    NotfoundComponent,
    AdminDashComponent,
    ManageUserComponent,
    DropdownSelectorComponent,
    ScopeListComponent,
    DynamicListComponent,
    AdminHomeComponent,
    ScopePerimeterComponent,
    CreateUserComponent,
    ImportComponent,
    UserDetailComponent,
    LinkscopeperimeterPipe,
    MakeGroupComponent,
    EnableUserPipe,
   
  
    
    


  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    StoreModule.forRoot(reducers, { metaReducers }),
    ReactiveFormsModule,
    AppPrimeNGModule,
    KeycloakAngularModule,
    HttpClientModule,
    BrowserAnimationsModule,
    StoreDevtoolsModule.instrument(),
    EffectsModule.forRoot([
      UserEffects,
      RequestEffects,
      FindingEffects,
      CommentEffects,
      ExportEffects,
      AdminEffects
    ]),
    HttpClientXsrfModule.withOptions({
      cookieName: 'XSRF-TOKEN',
      headerName: 'X-CSRF-TOKEN'
    }),
  ],
  exports: [AppPrimeNGModule],
  providers: [
    DatePipe,
    {
      provide: APP_INITIALIZER,
      useFactory: initializeKeycloak,
      multi: true,
      deps: [KeycloakService]
    },
    { provide: HTTP_INTERCEPTORS, useClass: CustomHttpInterceptor, multi: true },
    RequestService,
    CommentService,
    MessageService,
    UserService,
    DropdownService,
    PulsarService,
    AdminService,
    AdminGuard,
    NotAdminGuard
  ],
  bootstrap: [AppComponent]
})
export class AppModule {

}
