import { createAction, props } from '@ngrx/store';
import { Attachment } from 'src/app/data/models/Attachment';
import { DbRequest } from 'src/app/data/models/Request';
import { Comment } from 'src/app/data/models/Comment';
import { Filter, SortField } from 'src/app/data/models/Filter';

export const enum RequestActions {
  setRequest = '[REQUEST] set request',
  getRequestFailure = '[REQUEST] get request failure',
  initRequest = '[REQUEST] init request',
  setFaq = '[REQUEST] set Faq',
  setRequestSuccess = '[REQUEST] set request Success',
  setFaqSuccess = '[REQUEST] set Faq Success',
  setRequestFailure = '[REQUEST] set request Failure',
  setFaqFailure = '[REQUEST] set faq Failure',
  addNewRequest = '[REQUEST] add new request',
  addNewRequestSuccess = '[REQUEST] add new request success',
  addNewAttachment = '[REQUEST] add new attachment',
  addNewAttachmentSuccess = '[REQUEST] add new attachment Success',
  setCurrentRequest = '[REQUEST] set current request',
  setCurrentFaq = '[FAQ] set current faq',
  updateRequest = '[REQUEST] update request',
  updateRequestStatus = '[REQUEST] update request status',
  updateRequestStatusSuccess = '[REQUEST] update request status Success',
  updateRequestSuccess = '[REQUEST] update request Success',
  updateFaq = '[REQUEST] update FAQ',
  updateFaqSuccess = '[REQUEST] update request FAQ Success',
  addComment = '[REQUEST] add comment',
  addCommentSuccess = '[REQUEST] add comment success',
  addCommentFailure = '[REQUEST] add comment failure',
  setComments = '[REQUEST] set comments',
  setCommentsSuccess = '[REQUEST] set comments success',
  addNewFaq = '[REQUEST] add new faq',
  addNewFaqSuccess = '[REQUEST] add new faq success',
  updateAttachment = '[REQUEST] update attachment',
  updateAttachmentSuccess = '[REQUEST] update attachment success',

}

export const initRequests = createAction(
  RequestActions.initRequest,
  props<{ requests: DbRequest[] }>()
)



export const setRequests = createAction(
  RequestActions.setRequest,
  props<{
    page: number,
    pageSize: number,
    filters?: Filter,
    ordering?: SortField
  }>()
);
export const setRequestsSuccess = createAction(
  RequestActions.setRequestSuccess,
  props<{
    requests: DbRequest[]
    size: number
  }>()
);

export const setFaqs = createAction(
  RequestActions.setFaq,
  props<{
    page: number,
    pageSize: number,
    filters?: Filter,
    ordering?: SortField
  }>()
);
export const setFaqsSuccess = createAction(
  RequestActions.setFaqSuccess,
  props<{
    faqs: DbRequest[]
    size: number
  }>()
);

export const setRequestsFailure = createAction(
  RequestActions.setRequestFailure
);

export const getRequestsFailure = createAction(
  RequestActions.getRequestFailure
);

export const setFaqsFailure = createAction(
  RequestActions.setFaqFailure
);

export const addNewRequest = createAction(
  RequestActions.addNewRequest,
  props<{
    request: DbRequest,
    attachments?: Attachment
  }>()
);

export const addNewFaqs = createAction(
  RequestActions.addNewFaq,
  props<{
    faq: DbRequest,
    attachments?: Attachment
  }>()
);

export const updateRequest = createAction(
  RequestActions.updateRequest,
  props<{
    request: DbRequest,
    id: number,
    attachments?: Attachment[]
  }>()
);

export const updateRequestStatus = createAction(
  RequestActions.updateRequestStatus,
  props<{
    status: string,
    id: number,
    responses: any
  }>()
);

export const updateAttachment = createAction(
  RequestActions.updateAttachment,
  props<{
    attachment: any
  }>()
);

export const updateAttachmentSuccess = createAction(
  RequestActions.updateAttachmentSuccess,
);

export const updateRequestSuccess = createAction(
  RequestActions.updateRequestSuccess,
);

export const updateRequestStatusSuccess = createAction(
  RequestActions.updateRequestStatusSuccess,
);

export const updateFaq = createAction(
  RequestActions.updateFaq,
  props<{
    request: DbRequest,
    id: number,
    attachments?: Attachment[]
  }>()
);

export const updateFaqSuccess = createAction(
  RequestActions.updateFaqSuccess,
);

export const addNewRequestSuccess = createAction(
  RequestActions.addNewRequestSuccess,
);
export const addNewFaqsSuccess = createAction(
  RequestActions.addNewFaqSuccess,
);

export const addNewAttachments = createAction(
  RequestActions.addNewAttachment,
  props<{
    attachments: Attachment[],
    newRequestId: number
  }>()
);
export const addNewAttachmentSuccess = createAction(
  RequestActions.addNewAttachmentSuccess,
);

export const setCurrentRequest = createAction(
  RequestActions.setCurrentRequest,
  props<{ currentRequest: DbRequest }>()
);

export const setCurrentFaq = createAction(
  RequestActions.setCurrentFaq,
  props<{ currentRequest: DbRequest, id: number }>()
);

export const addComment = createAction(
  RequestActions.addComment,
  props<{
    comment: Comment,
    id: number
  }>()
);

export const addCommentSuccess = createAction(
  RequestActions.addCommentSuccess,
  props<{
    id: number
  }>()
);

export const setComments = createAction(
  RequestActions.setComments,
  props<{
    requestID: number
  }>()
)

export const setCommentsSuccess = createAction(
  RequestActions.setCommentsSuccess,
  props<{
    comments: Comment[]
  }>()
)



