import { Component } from '@angular/core';
import { RequestService } from 'src/app/utils/services/request.service';

@Component({
  selector: 'app-finding-assigned',
  templateUrl: './finding-assigned.component.html',
  styleUrls: ['./finding-assigned.component.scss']
})
export class FindingAssignedComponent {
  requestData: any[] = [];
  constructor(private requestService: RequestService) { }
  ngOnInit() {
    //this.requestData = this.requestService.getRequests();
  }
}
