<app-header ></app-header>
<p-toast [style]="{'z-index':'999999'}" position="top-center"></p-toast>

<div class="body overflow-hidden ">
    <div id="dash" class="dashboard gap-3">
        <nav class="flex flex-column  ">
            <div class="navigation flex  p-3 h-full  " style="background-color: transparent;border: none;" >
                <p-panelMenu  styleClass="p-panelcustom " [model]="items" [style]="{'width':'300px'}"></p-panelMenu>
            </div>
            <div class="p-3">
                <p-panelMenu styleClass="p-panelcustom"  class="text" [model]="backhome" [style]="{'width':'300px'}"></p-panelMenu>
            </div>
        </nav>
        <main>
            <router-outlet></router-outlet>
        </main>
    </div>
    <div class="bg">
        <div class="flex w-full ">
            <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
            viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
            <defs>
            <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
            </defs>
            <g class="parallax">
            <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
            <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
            <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
            <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
            </g>
            </svg>
        </div>
    </div>
</div>