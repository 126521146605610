import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { take } from 'rxjs';
import { Attachment } from 'src/app/data/models/Attachment';


@Component({
  selector: 'app-add-attachment-faq',
  templateUrl: './add-attachment-faq.component.html',
  styleUrls: ['./add-attachment-faq.component.scss']
})
export class AddAttachmentFaqComponent {
  formGroup: FormGroup
  currentFile: File;
  title: string;
  currentUser: string;
  @Output() addAttachment = new EventEmitter<Attachment>()
  @Output() closeAttachment = new EventEmitter<any>()
  @Input() currentUserID = 0;
  //lastReqId: number;

  constructor(private store: Store, private messageService: MessageService) {
    //this.store.select(selectLastRequestId).pipe(take(1)).subscribe(value => this.lastReqId = value + 1)
  }

  ngOnInit() {
    //this.store.select(selectUserUsername).pipe(take(1)).subscribe(username => this.currentUser = username)
    this.formGroup = new FormGroup({
      allegato: new FormControl('', [Validators.required ]),
      oggetto: new FormControl('', [Validators.required ]),
      descrizione: new FormControl('', [Validators.required ]),
      file: new FormControl('', [Validators.required]),
    })
  }

  dropfile(file) {
    this.formGroup.get('file')
    if (file instanceof Event) {
      this.currentFile = file.target['files'][0];
      
    } else {
      this.currentFile = file
    }
  }

  removeFile(index) {
    this.currentFile = null
   
  }

  close() {
    this.closeAttachment.emit()
  }

  showCurrentFile() {
    let values = this.formGroup.controls
    this.formGroup.controls['file'].setValue(this.currentFile)
    if (this.formGroup.valid) {
     
      let attachment: Attachment = {
        document_title: values['allegato'].value,
        object_name: values['oggetto'].value,
        description: values['descrizione'].value,
        attachment: values['file'].value,
        uploaded_by: this.currentUserID,
        request_attached: null
      }
      this.addAttachment.emit(attachment)
      this.closeAttachment.emit()
      this.formGroup.reset()
      this.currentFile = null
      this.messageService.add({ severity: 'success', summary: 'Allegato caricato con successo' });
    } else {
      this.messageService.add({ severity: 'error', summary: 'Errore nel caricamento del allegato' });
    
    }
  }

}
