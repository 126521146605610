import { DatePipe } from '@angular/common';
import { Component, Input } from '@angular/core';
import { Store } from '@ngrx/store';
import { PrimeIcons } from 'primeng/api';
import { Subscription, map } from 'rxjs';
import { TrackingRequest } from 'src/app/data/models/Request';
import { selectTrackingsFinding } from 'src/app/utils/store/finding/finding.selector';
import { selectTrackings } from 'src/app/utils/store/request/request.selector';


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html',
  styleUrls: ['./tracking.component.scss']
})
export class TrackingComponent {


  @Input() stato: string;
  @Input() trackings: TrackingRequest[];
  events: TrackingRequest[] = [];
  findingTrack: Subscription;


  formatDate(date) {
    return this.datePipe.transform(new Date(date), 'dd-MM-yyyy');
  }

  getIconColor() {
    return '#FFC627';
  }

  getBackground() {
    return '#FFF49F';
  }

  getIcon() {
    return PrimeIcons.CIRCLE_FILL;
  }

  getColorBarLast(event) {

    if (event.flagTimeLine == false) {
      return '#light-grey';
    } else {
      return 'transparent';
    }
  }


  // findingTracking$ = this.store.select(selectTrackingsFinding).pipe(
  //   map(trackings => {
  //     this.tracking = []
  //     trackings.forEach(element => {
  //       this.tracking.push(element)
  //     })
  //   })
  // ).subscribe()

  // findingTracking$ = this.store.select(selectTrackingsFinding).pipe(
  //   map(trackings => {
  //     console.log("[TIMELINE COMPONENT ]",trackings)
  //     this.tracking = []
 //   })
 // )

  constructor(private store: Store, private datePipe: DatePipe) {

  }


  ngOnChanges() {

//    this.findingTrack = this.findingTracking$.subscribe()
    this.trackings.forEach(element => {
      if (element.status !== 'DRAFT') {
        this.events = [...this.events, {
          status: element['status'],
          date_update: element['date_update'],
          username: element['username'],
          flagTimeLine: false
        }]
      }
    })
    this.events[this.events.length - 1] = { ...this.events[this.events.length - 1], flagTimeLine: true }

  }

  ngOnDestroy() {
    if (this.findingTrack) {
      this.findingTrack.unsubscribe()
    }
  }
}
