import { Component } from '@angular/core';
import { Store } from '@ngrx/store';
import { AdminActions } from 'src/app/utils/store/admin/admin.actions';
import { selectGroup, selectScopes } from 'src/app/utils/store/admin/admin.selector';

export interface ListDropDown {
  [id: string]: string
}

@Component({
  selector: 'app-make-group',
  templateUrl: './make-group.component.html',
  styleUrls: ['./make-group.component.scss']
})
export class MakeGroupComponent {

  listDropdown: ListDropDown[] = [];
  listCopy: ListDropDown[];

  constructor(private store: Store) { }

  ngOnInit(){
    this.store.select(selectGroup).subscribe(data => {
      (this.listDropdown = data.map(item => ({  name: item})))
      // (this.listDropdown = data.map(item => ({ id: item['id'], name: item['group'] })))

      this.listCopy = [...this.listDropdown]
    })
  }


  addItem(event) {


    this.store.dispatch({ type: AdminActions.addGroup, data: event })

    // this.dropdownService.addField(this.value, event).pipe(take(1)).subscribe()
    console.log("api", event)
  }

  removeItem(event) {
    console.log(event['name'])

    this.store.dispatch({ type: AdminActions.deleteGroup, data: event['name'] })

  }

  loadEvent(event) {
    if (event.serch !== "") {
      this.listDropdown = this.listCopy.filter(item => item['name'].toLowerCase().includes(event.search.toLowerCase()))
      console.log("dentro if",this.listDropdown)
    } else {
      this.listDropdown = [...this.listCopy]
      console.log(this.listDropdown)
    }
  }
}
