
import { createAction, props } from '@ngrx/store';
import { Attachment } from 'src/app/data/models/Attachment';
import { Filter, SortField } from 'src/app/data/models/Filter';
import { DbFinding } from 'src/app/data/models/Finding';
import { Comment } from 'src/app/data/models/Comment';

export const enum FindingActions {
  setFinding = '[Finding] set Finding',
  initFinding = '[Finding] init Finding',
  setFindingSuccess = '[Finding] set Finding Success',
  setFindingFailure = '[Finding] set Finding Failure',
  addNewFinding = '[Finding] add new Finding',
  addNewFindingSuccess = '[Finding] add new Finding success',
  addNewAttachment = '[Finding] add new attachment',
  addNewAttachmentSuccess = '[Finding] add new attachment Success',
  setCurrentFinding = '[Finding] set current Finding',
  updateFinding = '[Finding] update Finding',
  updateFindingSuccess = '[Finding] update Finding Success',
  updateFindingStatus = '[Finding] update Finding status',
  updateFindingStatusSuccess = '[Finding] update Finding status Success',
  addComment = '[Finding] add comment',
  addCommentSuccess = '[Finding] add comment success',
  addCommentFailure = '[Finding] add comment failure',
  setComments = '[Finding] set comments',
  setCommentsSuccess = '[Finding] set comments success',
  updateAttachment = '[Finding] update attachment',
  updateAttachmentSuccess = '[Finding] update attachment success'

}

export const initFindings = createAction(
  FindingActions.initFinding,
  props<{ findings: DbFinding[] }>()
)



export const setFindings = createAction(
  FindingActions.setFinding,
  props<{
    page: number,
    pageSize: number,
    filters?: Filter,
    ordering?: SortField
  }>()
);
export const setFindingsSuccess = createAction(
  FindingActions.setFindingSuccess,
  props<{
    findings: DbFinding[]
    size: number
  }>()
);


export const setFindingsFailure = createAction(
  FindingActions.setFindingFailure
);

export const addNewFinding = createAction(
  FindingActions.addNewFinding,
  props<{
    finding: DbFinding,
    attachments?: Attachment
  }>()
);

export const updateFinding = createAction(
  FindingActions.updateFinding,
  props<{
    finding: DbFinding,
    id: number,
    attachments?: Attachment[]
  }>()
);
export const updateFindingStatus = createAction(
  FindingActions.updateFindingStatus,
  props<{
    status: string,
    id: number,
    responses: any,
    attachments?: Attachment[],
  }>()
);

export const updateFindingStatusSuccess = createAction(
  FindingActions.updateFindingStatusSuccess,
);



export const updateAttachment = createAction(
  FindingActions.updateAttachment,
  props<{
    attachment: any
  }>()
);

export const updateAttachmentSuccess = createAction(
  FindingActions.updateAttachmentSuccess,
);

export const updateFindingSuccess = createAction(
  FindingActions.updateFindingSuccess,
);

export const addNewFindingSuccess = createAction(
  FindingActions.addNewFindingSuccess,
);

export const addNewAttachments = createAction(
  FindingActions.addNewAttachment,
  props<{
    attachments: Attachment[],
    newFindingId: number
  }>()
);
export const addNewAttachmentSuccess = createAction(
  FindingActions.addNewAttachmentSuccess,
);

export const setCurrentFinding = createAction(
  FindingActions.setCurrentFinding,
  props<{ currentFinding: DbFinding }>()
);

export const addComment = createAction(
  FindingActions.addComment,
  props<{
    comment: Comment,
    id: number
  }>()
);
export const addCommentSuccess = createAction(
  FindingActions.addCommentSuccess,
  props<{
    id: number
  }>()
);
export const setComments = createAction(
  FindingActions.setComments,
  props<{
    findingID: number
  }>()
)

export const setCommentsSuccess = createAction(
  FindingActions.setCommentsSuccess,
  props<{
    comments: Comment[]
  }>()
)
