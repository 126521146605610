<div>
  <app-header [(displayNotify)]="displayBadgeRequest"></app-header>
</div>
<p-toast position="top-center"></p-toast>
<div class="home z-1 overflow-hidden flex flex-column align-items-center">
  <div class="flex flex-column pt-8 justify-content-center  w-full  flexhome  z-2">
    <div class="flex flex-column col-12 w-full align-items-center p-0">
      <div class="col-12 my-5 z-1 w-full  p-0">
        <div class="flex pb-4 flex-column ">
          <div class="title text-7xl w-full">
            <p >Seleziona Processo</p>
          </div>
        </div>
      </div>
      <div class="flex  w-full gap-8 ">
        <div class="flex flex-order-0  col-6 col-lg-12 col-md-12     mb-5 p-0  ">
          <div class="card w-full  z-1  p-0 pt-2 border-round-xl border-none flex-column" style="height: 13.5rem;">
            <div class="card-disabled"><i class="fa-solid fa-lock icon-lock "></i></div>
            <div class="flex flex-row" style="height:10%">
            <div class="-mt-5 ml-3 icon-disabled-layer"></div>
              <div class="-mt-5 ml-3 icon-disabled">
                <!-- <i class="fa-solid fa-list-check fa-xl iconimg" ></i> -->               
                <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #fff;
                      }
                
                      .cls-2 {
                        fill: #4f4f4f;
                      }
                    </style>
                  </defs>
                  <ellipse class="cls-1" cx="196" cy="134.5" rx="31" ry="27.5"/>
                  <g>
                    <path class="cls-2" d="m198.16,289.11c6.95-1.33,9.32,3.41,11.5,8.39.24.11.49.22.73.32.31-.58.52-1.23.93-1.72,3.89-4.7,7.79-9.38,11.72-14.04.75-.89,1.53-1.83,2.48-2.47.71-.47,1.75-.68,2.59-.58.27.03.39,1.4.58,2.15.47-.59.92-1.52,1.66-1.95.63-.37,1.6-.17,2.42-.22-.07.65-.01,1.36-.25,1.95-.37.94-.94,1.79-1.15,2.89.74-1.05,1.4-2.18,2.26-3.12.4-.43,1.2-.5,1.81-.73.15.69.62,1.53.38,2.06-.74,1.65-1.74,3.18-2.31,4.99,1.13-1.58,2.26-3.17,3.56-5,2.08,5.03-2.02,8.03-3.08,11.91,1.09-1.56,2.17-3.13,3.26-4.69.2.07.4.14.6.21.04.51.25,1.09.08,1.53-.8,2.11-1.37,4.41-2.61,6.24-14.2,20.98-28.47,41.92-42.81,62.81-3.9,5.68-10.2,7.45-16.98,5.03-10.44-3.73-20.86-7.5-31.29-11.25-.67-.24-1.36-.44-2.21-.72-.42,3.25,2.79,2.09,3.72,3.63-.18.31-.36.82-.7,1.18-2.81,2.9-3.26,6.38-3.2,10.29.19,13.74.08,27.48.08,41.22,0,.73,0,1.45,0,2.35H55.5c0-.77,0-1.55,0-2.33,0-28.58-.01-57.17,0-85.75.01-25.65,20.9-45.34,46.45-43.52,13.27.95,24.09,6.98,31.74,17.87,2.97,4.22,6.45,7.74,10.23,11.1,9.49,8.46,18.95,16.94,28.43,25.4,3.2,2.86,4.67,2.83,7.63-.2,6.72-6.89,13.44-13.78,20.12-20.7,3.69-3.82,4.55-8.25,2.56-13.19-1.24-3.06-2.52-6.1-3.77-9.16-.27-.66-.46-1.34-.73-2.16Z"/>
                    <path class="cls-2" d="m444.43,411.82h-86.25c-.04-.73-.11-1.37-.12-2,0-14.62-.06-29.24.05-43.86.02-2.56-.47-4.72-2.18-6.64-.72-.81-1.23-1.8-1.73-2.56.95-1.6,4.19-.4,3.73-3.68-2.57.9-4.99,1.72-7.39,2.58-8.63,3.1-17.25,6.24-25.89,9.32-7.27,2.6-13.32.69-17.69-5.69-13.82-20.17-27.64-40.34-41.35-60.58-1.53-2.26-2.4-4.98-3.51-7.52-.2-.47-.08-1.08-.11-1.62.22-.11.43-.23.65-.34.97,1.32,1.95,2.64,2.94,3.98-1.11-2.77-2.24-5.48-3.26-8.23-.19-.5,0-1.17.09-1.74.05-.33.27-.63.55-1.23,1.24,1.81,2.35,3.43,3.46,5.04.13-.07.26-.15.39-.22-.83-1.47-1.77-2.9-2.44-4.44-.28-.65,0-1.55.01-2.33.76.32,1.68.48,2.23,1.01.8.77,1.33,1.83,2.28,2.58-.47-.87-1.03-1.71-1.36-2.63-.24-.65-.17-1.42-.24-2.13.88.15,1.93.06,2.59.52.79.56,1.24,1.59,2.08,2.31-.14-.36-.37-.72-.39-1.09-.04-.52-.11-1.27.18-1.52.33-.29,1.22-.39,1.55-.15,1.29.94,2.6,1.91,3.63,3.11,4.02,4.68,7.92,9.46,11.89,14.18.36.43.82.77,1.48,1.39,2.22-5.12,4.61-9.85,11.67-8.52-.96,2.39-1.87,4.68-2.8,6.96-.66,1.63-1.4,3.24-2.01,4.88-1.73,4.65-.87,8.86,2.53,12.42,6.9,7.22,13.89,14.36,20.86,21.52,2.17,2.23,4.14,2.29,6.52.16,11.72-10.47,23.44-20.95,35.13-31.45.69-.62,1.28-1.41,1.76-2.22,9.42-15.69,27.08-23.92,44.7-20.79,17.74,3.15,31.45,16.54,35.04,34.36.46,2.29.73,4.66.74,6.99.05,29.46.04,58.92.04,88.38,0,.43-.04.86-.07,1.49Z"/>
                    <path class="cls-2" d="m235.75,118.72c-6.97-20.78-28.12-33.55-49.56-29.92-32.04,5.42-47.77,41.92-29.69,68.92,3.78,5.64,8.67,10.1,14.53,13.67-4.85,9.15-9.64,18.21-14.44,27.27.1.08.21.17.31.25.25-.14.5-.28.75-.43,10.78-6.71,21.54-13.45,32.36-20.11.98-.61,2.3-.95,3.46-.97,7.33-.08,14.23-1.77,20.73-5.17,19.25-10.05,28.46-32.89,21.55-53.51Zm-63.04,9.04-.1,9.35,41.58-9.33.07,9.33"/>
                    <path class="cls-2" d="m72.75,235.5c.03-19.05,15.44-34.35,34.56-34.31,18.84.04,34.25,15.6,34.18,34.52-.07,18.99-15.52,34.22-34.7,34.19-18.72-.03-34.07-15.53-34.04-34.39Z"/>
                    <path class="cls-2" d="m435.66,235.72c0,18.66-15.43,34.15-34.04,34.17-19.19.02-34.81-15.36-34.71-34.19.1-19.26,15.47-34.6,34.62-34.54,18.85.06,34.12,15.52,34.13,34.55Z"/>
                    <path class="cls-2" d="m331.79,176.81c3.1,5.88,6.1,11.56,9.09,17.24-.1.09-.19.19-.29.28-4.33-2.69-8.66-5.39-12.99-8.08-.81-.5-1.63-.99-2.43-1.52-3.67-2.41-7.37-4.05-12.02-4.6-14.18-1.67-24.43-13.93-24.44-28.12,0-14.26,10.22-26.23,24.29-28.42,13.18-2.06,26.14,5.46,31.04,18.01,4.84,12.39.37,26.69-10.64,34.07-.48.32-.95.67-1.61,1.13Zm-1.63-21.5c1.65-.14,2.68-.95,2.67-2.61,0-1.64-1.13-2.81-2.61-2.54-.97.18-2.22,1.44-2.46,2.43-.37,1.53.84,2.48,2.39,2.71Zm-23.17-2.65c-.13-1.66-1.06-2.61-2.67-2.57-1.49.04-2.47.98-2.5,2.51-.03,1.62.9,2.63,2.55,2.66,1.68.03,2.47-1,2.62-2.6Zm10.36,2.66c1.69-.18,2.8-1.25,2.49-2.71-.2-.98-1.49-2.24-2.47-2.43-1.47-.28-2.59.82-2.6,2.51,0,1.69,1.03,2.45,2.57,2.63Z"/>
                    <path class="cls-2" d="m166.58,326.83c-5.14-4.58-10.12-9.01-15.06-13.48-.34-.3-.68-.88-.64-1.28.42-3.56-1.17-6.59-2.43-9.71-1.01-2.5-2.05-5-3.16-7.7,7.11-1.54,9.38,3.44,11.78,8.65,2.49-2.91,4.75-5.49,6.94-8.12,2.31-2.76,4.52-5.6,6.85-8.34.74-.87,1.68-1.64,2.67-2.22.52-.3,1.33-.1,2.02-.12-.02.66.05,1.34-.1,1.98-.12.54-.5,1.02-.5,1.71.57-.83,1-1.85,1.76-2.46.86-.68,2.31-1.63,2.98-1.32,1.58.71.01,1.79-.27,2.72-.1.33-.31.62-.16,1.17.49-.69.88-1.51,1.51-2.04.52-.44,1.32-.54,2-.79.1.71.48,1.52.25,2.1-.49,1.26-1.28,2.41-1.58,3.87.92-1.26,1.85-2.52,2.89-3.95,1.9,4.09-.92,6.83-1.97,10.11.36-.5.79-.97,1.07-1.52.3-.58.46-1.23.68-1.84.37.3.73.6,1.1.9.13,2.9-1.15,5.39-2.69,7.75-3.37,5.16-6.82,10.27-10.24,15.4-1.87,2.8-3.74,5.59-5.7,8.52Z"/>
                    <path class="cls-2" d="m323.9,286.28c.51-2.3,1.13-2.65,2.93-1.48,1.02.66,1.92,1.59,2.71,2.52,3.89,4.59,7.73,9.24,11.59,13.86.51.61,1.06,1.18,1.85,2.05,2.36-5.08,4.62-10.08,11.77-8.57-1.55,3.78-3.1,7.33-4.45,10.95-.6,1.62-1.05,3.39-1.09,5.11-.04,1.54-.44,2.53-1.61,3.54-4.73,4.07-9.35,8.27-14.14,12.53-3.89-5.8-7.73-11.52-11.54-17.26-1.87-2.81-3.82-5.58-5.44-8.53-.96-1.76-1.35-3.83-2-5.76.33-.16.67-.33,1-.49l3.01,4.15c-1.17-3.66-4.76-6.63-2.79-11.47,1.33,1.93,2.48,3.59,3.62,5.25.11-.06.23-.11.34-.17-.76-1.38-1.54-2.75-2.28-4.14-.5-.93-1.53-2.08-.06-2.76.5-.23,1.67.84,2.45,1.42.39.29.59.82,1.26,1.1-.27-.55-.67-1.07-.79-1.65-.14-.68-.05-1.41-.06-2.11.75.08,1.58,0,2.23.29.54.24.87.94,1.48,1.65Z"/>
                  </g>
                </svg>
              </div>
              <div *ngIf="displayBadgeRequest">
                <p-chip styleClass="badge ml-3 text-xs text-red-700" label="Hai delle richieste da lavorare!"
                  icon="fa-solid fa-circle fa-xs"></p-chip>
              </div>
            </div>
            <div style="height: 80%;">
              <div class="m-3 pt-2">
                <div class="title text-2xl mb-1">Compliance Opinion</div>
                <div class="text1 text-base">
                  <p>Modulo dedicato alle richieste di supporto verso ufficio Compliance.</p>
                </div>
              </div>
            </div>
            <div>
              <a [routerLink]="'/compliance-opinion'" pButton class="bottone w-full border-round-bottom-xl">
                Entra<i class="fa-solid fa-arrow-right m-2"></i></a>
            </div>
          </div>
        </div>


        <div class="flex flex-order-1 col-6 col-lg-12 col-md-12    mb-5 p-0  ">
          <div class="  card  w-full  z-1  p-0 pt-2 border-round-xl border-none flex-column " style="height: 13.5rem;">
            <div class="flex flex-row" style="height:10%">
              <div class="-mt-5 ml-3 icon">
                <!-- <i class="fa-solid  fa-magnifying-glass fa-xl iconimg"></i> -->

                <svg id="Livello_1" data-name="Livello 1" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 500 500">
                  <defs>
                    <style>
                      .cls-1 {
                        fill: #4f4f4f;
                      }
                    </style>
                  </defs>
                  <path class="cls-1" d="m322.86,268.42c4.66,4.61,9.35,9.01,13.72,13.7,2.17,2.34,4.33,3.41,7.59,3.24,5.81-.3,10.68,1.89,14.89,6.14,29.75,29.99,59.6,59.89,89.47,89.78,2.89,2.9,5.17,6.08,6.22,10.06,1.98,7.5-1,14.77-7.93,19.47-6.46,4.37-15.33,4.25-21.39-.36-1.31-1-2.53-2.15-3.7-3.32-29.4-29.38-58.74-58.83-88.22-88.13-5.05-5.02-7.87-10.57-6.93-17.75.29-2.2-.39-3.74-2.1-5.32-4.37-4.03-8.53-8.3-12.62-12.62-1.46-1.54-2.43-1.68-4.06-.25-14.84,12.95-32.17,20.71-51.42,24.31-16.89,3.17-33.57,2.37-49.95-3.04-41.18-13.6-66.47-41.55-74.78-84.14-5.99-30.7.38-59.2,19.43-83.98,23.15-30.13,54.1-45.32,92.43-43.34,27.06,1.4,50.57,11.54,70.09,30.78,17.63,17.37,27.84,38.38,31.5,62.57,2.69,17.77.16,35.16-5.8,52.09-3.39,9.64-8.1,18.55-14.15,26.77-.74,1.01-1.42,2.06-2.3,3.33Zm-136.79,9.92c0-15.26,0-30.18,0-45.11,0-14.56,8.27-22.93,22.84-22.96,22.93-.04,45.86-.04,68.8,0,13.62.02,21.71,7.95,21.89,21.58.16,11.71.09,23.42.12,35.13,0,.93,0,1.86,0,2.79.24.11.48.22.72.32,8.33-7.87,15.59-16.59,20.71-27.01,9.61-19.58,12.57-40.19,8.18-61.47-8.79-42.55-46.12-73.32-89.41-74.14-43.09-.81-81.41,27.45-92.46,68.83-11.52,43.11,9.02,83.06,38.63,102.03Z"/>
                  <path class="cls-1" d="m117.84,203.72c.83,7,1.33,13.42,2.4,19.73,3.11,18.24,11,34.43,22.02,49.12,3.56,4.75,7.52,9.22,11.56,13.57,1.72,1.86,1.84,3.32.69,5.38-1.83,3.3-4.31,5.92-7.97,6.86-2.74.71-3.3,2.17-3.29,4.76.09,22.15.06,44.29.05,66.44,0,11.39-7.18,20.88-18.03,24.28-14.76,4.62-32.44-6.07-32.22-25.04.24-20.39.08-40.78.1-61.17,0-.49-.02-.98.03-1.46.46-4.17.09-7.19-5.1-8.75-5.01-1.5-7.36-6.27-7.38-11.57-.08-22.44-.07-44.88-.01-67.32.02-8.98,5.94-14.77,14.9-14.81,7.4-.03,14.81,0,22.25,0Z"/>
                  <path class="cls-1" d="m392.2,299.25v8.43c-.82-.65-1.32-.98-1.75-1.4-8.49-8.33-16.94-16.71-25.47-25-4.97-4.83-11.08-7.13-17.93-7.49-4.44-.23-6.16-4.06-8.75-6.68-.34-.34.51-2.11,1.08-3.05,6.57-10.71,11.64-22.08,14.37-34.33,1.85-8.31,2.92-16.8,4.4-25.61.19-.02,1.14-.23,2.09-.24,10.34-.08,20.68-.32,31.01-.12,7.65.15,13,5.53,13.18,13.28.3,12.87.24,25.75.3,38.62.04,9.17.01,18.34.05,27.51.03,9.06-3.41,13.58-12.59,16.07Z"/>
                  <path class="cls-1" d="m217.85,318.79c16.99,2.62,33.53,1.72,50.07-1.87.05.86.12,1.52.12,2.19,0,21.46.05,42.92-.02,64.38-.03,9.3-3.68,16.97-11.57,22.06-7.98,5.15-16.57,5.66-25.01,1.19-8.69-4.6-13.51-12.02-13.59-21.96-.15-18.53-.02-37.07-.01-55.6,0-3.3,0-6.59,0-10.4Z"/>
                  <path class="cls-1" d="m393.54,162.42c.23,17.15-16.97,30.22-33.35,25.39-2.31-.68-3.53-1.84-3.86-4.4-1.5-11.57-4.88-22.62-9.87-33.15-1.16-2.44-.74-4.05,1.08-5.92,8.18-8.39,18.81-10.99,29.09-6.93,10.43,4.12,16.76,13.48,16.92,25.02Z"/>
                  <path class="cls-1" d="m133.94,140.89c-2.22,4.53-4.47,8.73-6.38,13.08-4.45,10.14-7.32,20.73-8.44,31.75-.26,2.53-1.1,3.23-3.71,2.86-13.43-1.88-23.26-12.28-23.61-25.25-.37-13.71,8.74-25.03,22.22-27.36,7.17-1.24,13.65.82,19.91,4.92Z"/>
                  <path class="cls-1" d="m341.79,344.05c14.5,14.25,28.86,28.37,43.41,42.66-6.93,6.39-14.72,9.7-23.9,7.48-9.76-2.35-16.15-8.65-18.84-18.43-.43-1.58-.63-3.27-.64-4.9-.06-9.02-.03-18.04-.03-26.81Z"/>
                  <path class="cls-1" d="m243.07,193.79c22.16.25,40.17-17.21,40.36-39.14.2-22.28-17.12-40.4-38.89-40.67-22.6-.28-40.74,17.09-40.94,39.2-.21,22.57,17.08,40.36,39.47,40.61Z"/>
                  <path class="cls-1" d="m243.07,193.79c-22.39-.25-39.68-18.04-39.47-40.61.2-22.11,18.35-39.48,40.94-39.2,21.77.27,39.09,18.39,38.89,40.67-.2,21.93-18.2,39.39-40.36,39.14Z"/>
                </svg>
              </div>
              <div *ngIf="displayBadgeFinding">
                <p-chip styleClass="badge ml-3 text-xs text-red-700" label="Hai dei finding da lavorare!"
                  icon="fa-solid fa-circle fa-xs"></p-chip>
              </div>
            </div>
            <div style="height:80%">
              <div class="m-3 pt-2">
                <div class="title text-2xl mb-1">Follow Up Remedation Plan</div>
                <div class="text1 text-base">
                  <p>Modulo dedicato ai findings rilevati dall'ufficio Audit.</p>
                </div>
              </div>
            </div>
            <div>
              <a [routerLink]="'/follow-up-remedation-plan'" pButton class="bottone w-full border-round-bottom-xl">
                Entra<i class="fa-solid fa-arrow-right m-2"></i></a>
            </div>
          </div>
        </div>
        
      </div>
    </div>
  </div>
  <div class="footer  ">
    <app-footer isHome="false"></app-footer>
  </div>
  
  <!-- push -->

  <!-- <img class="background z-0 w-full h-full fixed bg-auto" src="../../assets/images/vector_home.svg"> -->
  <div class="flex w-full ">
      <svg class="waves" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
      viewBox="0 24 150 28" preserveAspectRatio="none" shape-rendering="auto">
      <defs>
      <path id="gentle-wave" d="M-160 44c30 0 58-18 88-18s 58 18 88 18 58-18 88-18 58 18 88 18 v44h-352z" />
      </defs>
      <g class="parallax">
      <use xlink:href="#gentle-wave" x="48" y="0" fill="rgba(255,255,255,0.7" />
      <use xlink:href="#gentle-wave" x="48" y="3" fill="rgba(255,255,255,0.5)" />
      <use xlink:href="#gentle-wave" x="48" y="5" fill="rgba(255,255,255,0.3)" />
      <use xlink:href="#gentle-wave" x="48" y="7" fill="#fff" />
      </g>
      </svg>
    </div>
</div>



