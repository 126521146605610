import { Component } from '@angular/core';
import { OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription, catchError, forkJoin, map, take } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { HttpClient } from '@angular/common/http';
import { UserService } from 'src/app/utils/services/user.service';
import { InitService } from 'src/app/utils/services/init.service';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { selectCurrent } from 'src/app/utils/store/user/user.selector';

@Component({
  selector: 'app-profile-dashb',
  templateUrl: './profile-dashb.component.html',
  styleUrls: ['./profile-dashb.component.scss']
})
export class ProfileDashbComponent {

  // userName: string;
  // name: string;
  // surname: string;
  userName: string;
  userProfile: any;
  firstName: string;
  lastName: string;
  email: string;
  url: string;
  adminSub: Subscription

  setAdmin: Observable<any> = this.store.select(selectCurrent).pipe(
    catchError(err => {
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {


            })
          })
        )
      )
    })
  )

  constructor(private initService: InitService, private store: Store, private keycloakService: KeycloakService, private http: HttpClient) { }

  ngOnInit() {

    this.adminSub = this.setAdmin.subscribe(userProfile => {

      if (userProfile !== null) {
        this.firstName = userProfile.first_name
        this.lastName = userProfile.last_name
        this.email = userProfile.email
        // this.url = userProfile.profile_photo
      } else {
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.adminSub) {
              this.adminSub.unsubscribe()
            }
            this.adminSub = this.setAdmin.subscribe(result => {


            })
          })

        ).subscribe()
      }


    })

  }


}
