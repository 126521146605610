import { Component, Input, SimpleChange } from '@angular/core';
import { AbstractControl, ControlValueAccessor, FormBuilder, FormControl, FormGroup, NG_VALIDATORS, NG_VALUE_ACCESSOR, ValidationErrors, Validator, Validators } from '@angular/forms';
import { from, map } from 'rxjs';
import { Scope, Priority, Perimeter } from 'src/app/data/models/Faq';
import * as jsonambiti from 'src/environments/ambiti.json';

@Component({
  selector: 'app-form-new-faq',
  templateUrl: './form-new-faq.component.html',
  styleUrls: ['./form-new-faq.component.scss'],
  providers: [
    { provide: NG_VALUE_ACCESSOR, multi: true, useExisting: FormNewFaqComponent },
    { provide: NG_VALIDATORS, multi: true, useExisting: FormNewFaqComponent }
  ]
})
export class FormNewFaqComponent implements ControlValueAccessor, Validator {
  @Input() stato: string;
  @Input() title: string = "Dettaglio FAQ";
  @Input() invalidBoolean: boolean = false;

  styleDropDownScope: string = "w-full";
  styleDropDownPerimeter: string = "w-full";
  styleDropDownPrio: string = "w-full";
  formGroup: FormGroup;

  ambiti: any;
  ambitijson: any;
  prioselect: any
  prio: string;
  fieldPriorityOptions: any
  priorita: string;
  prioritajson: any;

  priority: string[]
  fieldAOptions: any
  ambito: string;
  perimetro: string;
  fieldBOptions: string[] = [];
  selectedPriority: string;

  reason: string
  // date: Date
  // dateMinutes: string = "00"
  // dateHours: string = "00"
  // selectedDate: Date;

  onChanged!: () => void
  onTouched!: () => void
  onDisabled!: (disable: boolean) => void



  writeValue(obj: any): void {

    this.formGroup.patchValue(obj);

    switch (obj.priorita) {
      case 'Alta':
        this.selectedPriority = Priority.HIGH;
        break;
      case 'Media':
        this.selectedPriority = Priority.MEDIUM;
        break;
      case 'Bassa':
        this.selectedPriority = Priority.LOW;
        break;
    }
    //FUTURO BUG DEL PERIMETRO
    if (obj.ambito) {
      this.filterFieldBOptions()
    }

    if (obj.ambito) {
      this.filterFieldBOptions()
    }


  }

  registerOnChange(fn: any): void {
    this.formGroup.valueChanges.subscribe(fn)
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn
  }

  setDisabledState(isDisabled: boolean): void {
    if (isDisabled) {

      let value = this.formGroup.value

      this.formGroup.disable()

      this.formGroup.patchValue(value)

    }
  }

  validate(control: AbstractControl): ValidationErrors {

    if (control.valueChanges && this.formGroup.valid) {
      return null
    } else {
      return { required: true }
    }
  }

  filterFieldBOptions() {

    if (this.ambito) {
      this.fieldBOptions = this.ambitijson[this.ambito].sort();
    } else {
      this.fieldBOptions = [];
    }

  }

  constructor(private fb: FormBuilder) {
    this.priority = [
      Priority.HIGH,
      Priority.MEDIUM,
      Priority.LOW
    ];

  }

  ngOnInit() {
    // this.ambiti = jsonambiti
    // this.fieldAOptions = Object.keys(this.ambiti).sort()

    this.priorita = localStorage.getItem('Priority')
    this.prioritajson = JSON.parse(this.priorita);
    this.fieldPriorityOptions = this.prioritajson.reduce((acc, obj) => {
      acc[obj.id] = obj.name;
      return acc;
    }, {});

    this.prioselect = Object.values(this.fieldPriorityOptions)

    this.ambiti = localStorage.getItem('Ambiti')
    this.ambitijson = JSON.parse(this.ambiti);

    this.fieldAOptions = Object.keys(this.ambitijson).sort()


    this.formGroup = this.fb.group({
      title: ['', [Validators.required, Validators.maxLength(100)]],
      priorita: ['', Validators.required],
      // dueDate: ['', Validators.required],
      // motivazione: [''],
      ambito: ['', Validators.required],
      perimetro: ['', Validators.required],
      // struttura: ['', Validators.required],
      description: ['', [Validators.required, Validators.maxLength(65535)]]
    })

    this.formGroup.get("priorita").valueChanges.subscribe(
      res => this.styleDropDownPrio = "w-full"
    )

    this.formGroup.get("ambito").valueChanges.subscribe(
      res => this.styleDropDownScope = "w-full"
    )

    this.formGroup.get("perimetro").valueChanges.subscribe(
      res => this.styleDropDownPerimeter = "w-full"
    )


  }

  ngOnChanges() {

    if (this.invalidBoolean == true) {
      if (this.formGroup.get("priorita").invalid) {
        this.styleDropDownPrio = "customdrop w-full";
      }
      if (this.formGroup.get("perimetro").invalid) {
        this.styleDropDownPerimeter = "customdrop w-full";
      }

      if (this.formGroup.get("ambito").invalid) {
        this.styleDropDownScope = "customdrop w-full";
      }


      this.formGroup.markAllAsTouched()

    } else {
      this.styleDropDownScope = " w-full";
      this.styleDropDownPerimeter = " w-full";
      this.styleDropDownPrio = " w-full";


    }



  }

}
