import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'data'
})
export class DataPipe implements PipeTransform {

  transform( value: any): string {

    // if (value == '' && items.indexOf(value) == 1) {
    //   return "IN CARICO".toUpperCase();
    // } else if (value == '' && items.indexOf(value) == 2) {
    //   return "COMPLETATO".toUpperCase();
 if (value == 'NEW') {
      return "NUOVO".toUpperCase();
    } else if (value == 'IN_CHARGE') {
      return "IN CARICO".toUpperCase();
    } else if (value == 'REFUSED') {
      return "RIFIUTATO".toUpperCase();
    } else if (value == 'COMPLETED') {
      return "COMPLETATO".toUpperCase();
    } else if (value == 'TO_CHECK') {
      return "ULT. INVIO. APPR".toUpperCase();
    }else if (value == 'CLOSED') {
      return "CHIUSO".toUpperCase();
    } else {
      return value;
    }
  }

}
