import { Component, EventEmitter, Input, Output } from '@angular/core';
import { OnInit } from '@angular/core';
import { FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { RequestService } from 'src/app/utils/services/request.service';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, catchError, concatMap, forkJoin, map, merge, mergeAll, switchMap, take } from 'rxjs';
import { Store } from '@ngrx/store';
import { RequestActions, addNewRequest, addNewRequestSuccess, setCurrentRequest, setRequests, setRequestsSuccess } from 'src/app/utils/store/request/request.action';
import { selectRequestDataLoading, selectRequestsData } from 'src/app/utils/store/request/request.selector';
import { Attachment } from 'src/app/data/models/Attachment';
import { DbRequest, Status } from 'src/app/data/models/Request';
import { HttpClient } from '@angular/common/http';
import { selectCurrentID, selectCurrentIDandRole, selectUserFullName } from 'src/app/utils/store/user/user.selector';
import { ITcolumn } from 'src/app/components/dynamic-table/dynamic-table.component';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';

@Component({
  selector: 'app-new-faq',
  templateUrl: './new-faq.component.html',
  styleUrls: ['./new-faq.component.scss']
})
export class NewFaqComponent implements OnInit {

  @Input() titleForm: string = 'Crea nuova FAQ';
  @Input() addAllegato: boolean = true;
  @Input() isAllegato: boolean;
  @Input() isDisabled: boolean = false;
  canAddAttachment: boolean = true;
  addingAttachment: boolean = false;
  size: number = 0;
  isFilter: { isFilter: boolean, filter: string }
  formValue: FormGroup;
  requestData = [];
  attachmentData: Attachment[] = [{
    document_title: "",
    object_name: "",
    description: "",
    uploaded_by: 0
  }]
  tableColumn: string[] = []
  requestToSend: DbRequest
  inputvalidator: boolean = false;
  postData: DbRequest;
  lastReqId = 0;
  currentUserId;
  loading: boolean = false;
  sub: Subscription
  invalidBoolean: boolean = false;
  adminSub$: Observable<any> = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      let userRole = roles.find(role => role == "compliance_user")
      this.currentUserId = detail['id']
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.sub) {
              this.sub.unsubscribe()
            }
            this.sub = this.adminSub$.subscribe()
          })
        )
      )
    })
  )

  constructor(private initService: InitService, private requestService: RequestService, private messageService: MessageService, private store: Store, private fb: FormBuilder, private http: HttpClient) { }

  ngOnInit() {
    this.store.select(selectRequestDataLoading).pipe(map(value => this.loading = value)).subscribe()

    this.sub = this.adminSub$.subscribe()

    this.tableColumn = Object.keys(this.attachmentData[0])

    this.formValue = this.fb.group({
      newFaq: {
        title: '',
        priorita: '',
        ambito: '',
        perimetro: '',
        // struttura: '',
        description: ''
      },
      answerFaq: {
        text: '',
        privacy: '',
        legal: '',
        note: ''
      }
    })

  }



  disableForm() {
    this.formValue.get("newFaq").disable()
    this.formValue.get("answerFaq").disable()
  }

  sendFaq() {
    let newValue = this.formValue.controls['newFaq'].value
    let answerValue = this.formValue.controls['answerFaq'].value

    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
      this.invalidBoolean = true
    } else {
      this.isDisabled = true;
      this.invalidBoolean = false
      this.canAddAttachment = false;

      this.titleForm = 'Ecco la tua FAQ';

      this.postData = {
        request_title: newValue['title'],
        priority: newValue['priorita'],
        scope: newValue['ambito'],
        perimeter: newValue['perimetro'],
        // structure_request: 'null',
        description: newValue['description'],
        status: Status.NEW,
        requested_user: this.currentUserId, //this.store.select(selectUserFullName).pipe(take(1)).subscribe(),
        document_attached: [],
        comments: [],
        compliance_answer: answerValue['text'],
      };


    }
    if (this.attachmentData.length == 1 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    this.store.dispatch({
      type: RequestActions.addNewFaq,
      faq: this.postData,
      attachments: this.attachmentData

    });
  }

  save() {
    let newValue = this.formValue.controls['newFaq'].value
    let answerValue = this.formValue.controls['answerFaq'].value

    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
      this.invalidBoolean = true
    } else {
      this.isDisabled = true;
      this.invalidBoolean = false
      this.canAddAttachment = false;

      this.titleForm = 'Ecco la tua FAQ';

      this.postData = {
        request_title: newValue['title'],
        priority: newValue['priorita'],
        scope: newValue['ambito'],
        perimeter: newValue['perimetro'],
        // structure_request: 'null',
        description: newValue['description'],
        status: Status.DRAFT,
        requested_user: this.currentUserId, //this.store.select(selectUserFullName).pipe(take(1)).subscribe(),
        document_attached: [],
        comments: [],
        compliance_answer: answerValue['text'],
      };


    }
    if (this.attachmentData.length == 1 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    this.store.dispatch({
      type: RequestActions.addNewFaq,
      faq: this.postData,
      attachments: this.attachmentData

    });
  }

  setFilter(data) {
    this.isFilter = data
  }

  addAttachment(attachment) {
    if (this.attachmentData.length > 0 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    this.addingAttachment = true
    const attToShow: any = (({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }) => ({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }))(attachment);
    if (!this.attachmentData) {
      this.attachmentData = { ...attToShow }
    } else {
      this.attachmentData = [...this.attachmentData, attToShow]
    }
    this.size = this.attachmentData.length
  }

  removeAttachment(event) {


    if (event == 0) {
      this.attachmentData = [{
        document_title: "",
        object_name: "",
        description: "",
        uploaded_by: 0
      }]
    } else {
      this.attachmentData.splice(event, 1)
    }

  }

  editAttachment(event) {
    this.removeAttachment(event.index);
    this.addAttachment(event.attachment)
  }


  ngOnDestroy() {
    this.sub.unsubscribe()
  }

}
