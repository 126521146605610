import { User, UserClass } from "src/app/data/models/User";

export const userFeatureKey = 'user';

export interface UserState {
  currentUser: any,
  updateUser: boolean,
  users: User[],
  loadingUsers:boolean
}

export const initialUserState: UserState = {
  currentUser: null,
  updateUser: false,
  users : [],
  loadingUsers:false

}
