<div *ngIf="!isAdmin; else admin">
  <div class="w-full flex flex-column">
    <app-flow></app-flow>
    <div *ngIf="stato">
      <app-header-tab [isAdmin]="isAdmin" [title]="'FAQ'"></app-header-tab>
    </div>
    <form [formGroup]="formValue">
      <app-form-new-faq formControlName="newFaq" [stato]="stato" [title]="'Dettaglio FAQ'"
        [isAdmin]="isAdmin"></app-form-new-faq>
      <app-answer [isCompliance]="true" formControlName="answerFaq" [isUser]="true"></app-answer>
    </form>
    <app-dynamic-table [isAdmin]="isAdmin" [currentUserID]="currentUserId" (removeAttachment)="removeAttachment($event)"
      (attachmentAdded)="addAttachment($event)" [tableData]="attachmentData" [tableColumn]="tableColumn"
      [isAllegato]="true" [isDisabled]="disabledButton()" [exteranalFilter]="isFilter"
      [canAddAttachment]="canAddAllegato()"></app-dynamic-table>
    <div class="flex justify-content-end gap-5" style=" margin: 0 0.5%;  padding: 0.5%;">
      <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']">Torna
        Indietro
      </button>
    </div>
  </div>
</div>

<ng-template #admin>
  <div class="w-full flex flex-column">
    <app-flow></app-flow>
    <app-header-tab [title]="'FAQ'"></app-header-tab>
    <form [formGroup]="formValue">
      <app-form-new-faq formControlName="newFaq" [title]="'Dettaglio Faq'"></app-form-new-faq>
      <app-answer [isCompliance]="true" formControlName="answerFaq" [isUser]="true"></app-answer>
    </form>
    <app-dynamic-table [isAdmin]="isAdmin" [currentUserID]="currentUserId" [canFilter]="true"
      (editAttachmentEvent)="editAttachmentFromDB($event)" (removeAttachment)="removeAttachment($event)"
      (attachmentAdded)="addAttachment($event)" [tableData]="attachmentData" [tableColumn]="tableColumn"
      [isAllegato]="true" [isDisabled]="disabledButton()" [stato]="stato" [exteranalFilter]="isFilter"
      [canAddAttachment]="canAddAllegato()"></app-dynamic-table>
    <div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
      <div *ngIf="stato == 'NEW'">
        <button pButton pRipple type="button" class="return p-fontMedium m-2 mr-0 " [routerLink]="['../']" [disabled]="loading">Torna
          Indietro
        </button>
      </div>
      <div *ngIf="stato == 'DRAFT'">
        <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']" [disabled]="loading">Annulla
        </button>
        <button pButton pRipple type="submit" class="save p-fontMedium m-2" [disabled]="loading" (click)="saveFaqCompliance()">Salva Bozza
        </button>
        <button pButton pRipple type="button" class="send p-fontMedium m-2 mr-0" [disabled]="loading" (click)="sendToBuisness()">Pubblica FAQ
        </button>
      </div>
      <div *ngIf="stato == 'COMPLETED'">
        <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']" [disabled]="loading">Annulla
        </button>
        <button pButton pRipple type="submit" class="save p-fontMedium m-2" [disabled]="loading" (click)="save()">Salva Bozza
        </button>
        <button pButton pRipple type="button" class="send p-fontMedium m-2 mr-0" [disabled]="loading" (click)="sendFaq()">Pubblica FAQ
        </button>
      </div>
    </div>
  </div>
</ng-template>
