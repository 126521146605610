
import { DatePipe } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Attachment } from 'src/app/data/models/Attachment';
import { DbRequest, TableRequest, defPriorita, defStato, Status, defStatoComp, defStatoFaq } from 'src/app/data/models/Request';
import { RequestService } from 'src/app/utils/services/request.service';
import { setCurrentFinding, setFindings } from 'src/app/utils/store/finding/finding.action';
import { RequestActions, setCurrentRequest, setFaqs, setRequests } from 'src/app/utils/store/request/request.action';
import { PipeTransform } from '@angular/core';
import { StatuspipePipe } from 'src/app/utils/pipe/statuspipe.pipe';
import { selectCurrentIDandRole, selectUserRole } from 'src/app/utils/store/user/user.selector';
import { Observable, Subscription } from 'rxjs';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { ViewChild } from '@angular/core';
import { Calendar } from 'primeng/calendar';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';
import { SkeletonModule } from 'primeng/skeleton';
import { TruncatePipe } from 'src/app/utils/pipe/truncate.pipe';
import { environment } from 'src/environments/environment';
import { Filter } from 'src/app/data/models/Filter';
import { UserService } from 'src/app/utils/services/user.service';
import { defOperation, defPerimetroFinding, defStatusFinding, defStatusFindingComp } from 'src/app/data/models/Finding';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module'
import * as jsonambiti from 'src/environments/ambiti.json';
export enum ITcolumn {
  // request / finding
  scope = "ambito",
  perimeter = "perimetro",
  description = "richiesta",
  structure_request = "struttura richiedente",
  status = "stato",
  priority = "priorità",
  creation_date = "data creazione",
  last_update = "ultima data aggiornamento",
  due_date = "data fine",
  due_date_planned = "data ripianificata",
  count_due_date_planned = "N. ripianificazione",
  comments = "messaggi",
  // attachment
  document_title = "allegato",
  object_name = "oggetto",
  uploaded_by = "allegato caricato da",
  //request response
  expired = "scaduta",
  legal_answer = "legal",
  privacy_answer = "privacy",
  //finding
  expiry_date = "data scadenza",
  intervention_type = "tipologia di intervento",
  id = "id",
  flag_expiry_date = "scaduta",
  compliance_answer = "Mex",
  legal_checkbox = "Concluso  Legal",
  privacy_checkbox = "Concluso  Privacy",
  user_assigned = "utente assegnato",
  group_assigned = "gruppo assegnato",
  requested_legal = "Rich. Legal",
  requested_privacy = "Rich. Privacy",
  problem_encountered = "problema riscontrato",
  action_required = "azione correttiva",

}

export enum expiredFilter {
  SCADUTA = "ExpiredTrue",
  NON_SCADUTA = "ExpiredFalse"
}




export const Date_toJSON = Date.prototype.toJSON;
Date.prototype.toJSON = function () {

  let newDate = Date_toJSON.call(new Date(this.setHours(12)))

  return newDate
}

@Component({
  selector: 'app-dynamic-table',
  templateUrl: './dynamic-table.component.html',
  styleUrls: ['./dynamic-table.component.scss']
})
export class DynamicTable {

  @Input() isAllegato: boolean = false;
  @Input() canAddAttachment: boolean = false;
  @Input() canSearch: boolean = false;
  @Input() tableData: any[] = [];
  @Input() tableCurrentData: any[] = [];
  @Input() tableCurrentDataFinding: any[] = [];
  @Input() isDisabled: boolean;
  @Input() stato: string;
  @Input() size: number;
  @Input() isLoading: boolean = false
  @Input() isFinding: boolean = false;
  @Input() isNotify: boolean = false;
  @Input() makeFaq: boolean = false;
  @Input() isFaq: boolean = false;
  @Input() canFilter: boolean = true
  @Output() attachmentAdded = new EventEmitter<any>()
  @Output() loadingRequest = new EventEmitter<boolean>()
  @Output() removeAttachment = new EventEmitter<number>()
  @Output() deleteFile = new EventEmitter<any>()
  @Output() editAttachmentEvent = new EventEmitter<any>()
  @Output() setFilters = new EventEmitter<any>()



  @Input() isAdmin: boolean = false;
  canRemove: boolean
  @Input() isLazy: boolean = true
  attachmentToModify: Attachment
  currentUser: string;
  filterData: any;
  display: boolean = false;
  postData: DbRequest;
  //tableColumn: string[] = [];
  tableITColumn: { [id: string]: string };
  selectedCategories: any[] = [];
  tableDataToShow: any[] = [];
  page: number = 0;
  pageSize: number = 0;
  filter: Filter;
  attachmentFilter: string = 'all';
  @Input() tableColumn: string[]
  @Input() filters: string[]
  attachmentIndex: number;
  attachmentData: Attachment[] = [{
    document_title: "",
    object_name: "",
    description: "",
    uploaded_by: 0
  }];
  searchMode: boolean = false;
  searchText: string;
  rangeDates: Date[];
  defaultOrder: number = 1;
  @Input() currentUserID: number;
  currentUserUsername: string;
  adminSub: any;
  subscription: Subscription
  role: boolean = false;
  columnWidth: number = 100;
  previusSize: number = 0;

  ambitijson:any
  fieldAmbitoOptions:any
  ambitoselect:any

  ambiti: any
  perimetri: string[] = [];
  fieldAOptions: any
  fieldBOptions: string[] = [];

  fieldRiskOptions: any
  residualrisk:string;
  residualriskjson:any

  intervento:string;
  interventiontype: string;
  interventiontypejson: any;
  fieldInterventionOptions:any
  interventionselect:any;

  priorita: string;
  prioritajson: any;
  prio: string;
  fieldPriorityOptions: any
  prioselect: any



  constructor(private userService: UserService, private initService: InitService, private store: Store, private datePipe: DatePipe, private http: HttpClient, private router: Router) { }

  @ViewChild('calendar') calendar: Calendar;

  public hideCalendar(calendar: Calendar): void {
    calendar.hideOverlay();
  }

  ngOnInit() {
    if (!this.isAllegato) {
      this.interventiontype = localStorage.getItem('InterventionType')
      this.interventiontypejson = JSON.parse(this.interventiontype);
      this.fieldInterventionOptions = this.interventiontypejson.reduce((acc, obj) => {
        acc[obj.id] = obj.name;
        return acc;
      }, {});
  
      this.interventionselect = Object.values(this.fieldInterventionOptions)

      this.priorita = localStorage.getItem('Priority')
      console.log(this.priorita)
      this.prioritajson = JSON.parse(this.priorita);
      console.log(this.prioritajson)
      this.fieldPriorityOptions = this.prioritajson.reduce((acc, obj) => {
        acc[obj.id] = obj.name;
        return acc;
      }, {});
  
      this.prioselect = Object.values(this.fieldPriorityOptions)

      this.ambiti = localStorage.getItem('Ambiti')
      this.ambitijson = JSON.parse(this.ambiti);
  
      this.fieldAOptions = Object.keys(this.ambitijson).sort()
      console.log(this.fieldAOptions);


      // this.ambiti = jsonambiti
      // this.fieldAOptions = Object.keys(this.ambiti).sort()
      // if (this.fieldAOptions.indexOf("default") > -1) {
      //   this.fieldAOptions.splice(this.fieldAOptions.indexOf("default"), 1)
      // }
      this.fieldAOptions.forEach(ambito => {
      //   this.perimetri = [...new Set([...this.perimetri, ...this.ambiti[ambito]])].sort()
      this.perimetri = [...new Set([...this.perimetri, ...this.ambitijson[ambito]])].sort()
      })
    }
console.log(this.tableDataToShow)
    console.log(this.tableColumn)
  }

  ngOnChanges() {

    this.tableColumn.forEach(column => {
      this.tableITColumn = { ...this.tableITColumn, [column]: ITcolumn[column] }

      //qui mi servirà
      if (!this.isAllegato) {
        if (!this.selectedCategories[column]) {
          this.selectedCategories[column] = []
        }
      }
    })
    this.tableDataToShow = this.tableData


  }

  messageCheck(data: DbRequest) {
    const dataRow: any[] = data.comments
    const unseenComment = (dataRow.filter(comment => comment.seen == false)).filter(comment => comment.user !== this.currentUserID)
    return unseenComment.length > 0 ? "Si" : "No"
  }

  attachmentFiltered(filter) {
    this.tableDataToShow = this.tableData
    this.attachmentFilter = filter
    if (filter == "mine") {
      this.tableDataToShow = this.tableData.filter(value => value['uploaded_by'] == this.currentUserID)
      if (this.tableDataToShow.length == 0) {
        this.tableDataToShow = [{
          description: "",
          document_title: "",
          object_name: "",
          uploaded_by: 0,
        }]
      }
    } else if (filter == "admin") {
      this.tableDataToShow = this.tableData.filter(value => value['uploaded_by'] !== this.currentUserID)
      if (this.tableDataToShow.length == 0) {
        this.tableDataToShow = [{
          description: "",
          document_title: "",
          object_name: "",
          uploaded_by: 0,
        }]
      }
    }
  }



  getColName(column: string) {
    return column
  }

  getColumnName(column: string): string {
    return column.replace(/_/g, ' ').toUpperCase();
  }



  getColorClass(stringaBadge) {
    if (!this.isAdmin && !this.isFinding) {
      if (stringaBadge == 'NEW' || stringaBadge == 'IN_CHARGE') {
        stringaBadge = 'COMPLIANCE'
      }
    }
    let string = "badge-status";
    string += " " + string + "-" + stringaBadge.replace(/ /g, "-")
    return string
  }



  getGroupItems(column) {
    switch (column.toLowerCase()) {
      case "scope":
        this.filterData = this.fieldAOptions;
        break;
      case "intervention_type":
        // this.filterData = defOperation;
        this.filterData = this.interventionselect;
        break;

      case "perimeter":
        // if (this.filter?.['scope']?.length > 0) {
        //   this.filterData = [];
        //   this.filter?.['scope'].forEach(ambito => {
        //     this.filterData = [...this.filterData, ...this.ambiti[ambito]]
        //   })
        // } else {
          this.filterData = this.perimetri;
        // }
        break;

      case "priority":
        // this.filterData = defPriorita;
        this.filterData = this.prioselect;
        break;

      case "status":
        if (this.isFinding) {
          this.filterData = this.isAdmin ? defStatusFindingComp : defStatusFinding;
        } else if (this.isFaq) {
          this.filterData = this.isAdmin ? defStatoFaq : '';
        } else {
          this.filterData = this.isAdmin ? defStato : defStatoComp;
        }
        break;
      case "expired":
        this.filterData = [expiredFilter.SCADUTA, expiredFilter.NON_SCADUTA]
        break;
      case "requested_legal":
        this.filterData = ["true", "false"]
        break;
      case "requested_privacy":
        this.filterData = ["true", "false"]
        break;
      case "legal_checkbox":
        this.filterData = ["true", "false"]
        break;
      case "privacy_checkbox":
        this.filterData = ["true", "false"]
        break;
      case "user_assigned":
        this.filterData = []

      // case "flag_expiry_date":
      //   this.filterData = ["True", "False"]
      //   break;
      // case "filterAttachment":
      //   this.filterData = ["compliance", "buisness"]
      //   break;
    }
    return this.filterData

  }

  getSelectedCategories(column) {
    if (column == "due_date" || column == 'due_date_planned') {
      return this.datePipe.transform(this.selectedCategories[column][0], 'dd-MM-yyyy') + " - " + this.datePipe.transform(this.selectedCategories[column][1], 'dd-MM-yyyy')
    } else {
      return this.selectedCategories[column].length > 2 ? this.selectedCategories[column][0] + " e altri " + (this.selectedCategories[column].length - 1) : this.selectedCategories[column]
    }
  }

  filterTableData(column) {

    if (column == "search") {
      this.searchMode = true
      this.selectedCategories = { ...this.selectedCategories, [column]: [this.searchText] }
    }

    if (column == "due_date" || column == 'due_date_planned') {


      let gte = this.rangeDates[0].toJSON().slice(0, this.rangeDates[0].toJSON().indexOf("T"))
      let lte;
      if (this.rangeDates[1]) {
        lte = this.rangeDates[1].toJSON().slice(0, this.rangeDates[1].toJSON().indexOf("T"))
        this.selectedCategories = { ...this.selectedCategories, [column]: [gte, lte] }
      } else {
        lte = gte
        this.selectedCategories = { ...this.selectedCategories, [column]: [gte, lte] }
      }
    }
    this.filter = { ...this.filter, [column]: this.selectedCategories[column] }
    this.updateRequests(this.filter)
  }

  OpenRequest(id) {
  }

  makingFaq(id) {
    let requestToShowCurrent: any = this.tableCurrentData.find(row => row.id == id)
    if (requestToShowCurrent['status'] == 'COMPLETED') {
      this.router.navigate([ROUTES_STRINGS.COMPLIANCE_OPINION, ROUTES_STRINGS.FAQ, id], { queryParams: { par: "req" } })
    }
  }

  formatDate(date) {

    if (date) {
      return this.datePipe.transform(new Date(date), 'dd-MM-yyyy');
    } else {
      return this.datePipe.transform(date, 'dd-MM-yyyy');
    }
  }

  clearFilter(columnFilter) {
    if (columnFilter == "due_date" || columnFilter == 'due_date_planned') {
      this.rangeDates = []
    }
    this.selectedCategories[columnFilter] = []
    this.filter = { ...this.filter, [columnFilter]: [] }
    delete this.filter[columnFilter];
    this.updateRequests(this.filter)
  }

  updateRequests(filters) {
    this.setFilters.emit(filters)
    if (this.isFaq) {
      this.store.dispatch(setFaqs({ page: 1, pageSize: this.pageSize, filters: this.filter }))
    } else if (this.isFinding) {
      this.store.dispatch(setFindings({ page: 1, pageSize: this.pageSize, filters: this.filter }))
    } else {
      this.store.dispatch(setRequests({ page: 1, pageSize: this.pageSize, filters: this.filter }))
    }
  }

  addAttachment(event) {
    this.attachmentAdded.emit(event)
    this.attachmentToModify = undefined
  }

  rmAttachment(event) {
    this.removeAttachment.emit(event)
  }

  loadRequest(event) {
    event.filters = { ...this.filter }
    this.pageSize = event.rows
    this.page = (event.first + event.rows) / event.rows
    this.loadingRequest.emit(event)
  }

  editAttachment(event) {
    if (event.isEditingFromDb) {
      this.editAttachmentEvent.emit(({ index: event.index, attachment: event.attachment }))
    } else {
      this.removeAttachment.emit(event.index)
      this.attachmentAdded.emit(event.attachment)
    }
    //this.tableData[event.index] = event.attachment
  }

  deleteFileFromDb(event) {
    this.deleteFile.emit(event)
  }

  showDialog(index?: number) {
    if (index !== undefined) {
      this.attachmentToModify = this.tableData[index]
      this.attachmentIndex = index
    }
    this.display = true
  }

  downloadFile(attachmentUrl: string, docTitle: string) {
    let url = (environment.apiUrl + "/document/" + attachmentUrl + "/download/")
    this.http.get((url), { responseType: 'blob', observe: 'response' }).subscribe((response: HttpResponse<Blob>) => {
      const contentDisposition = response.headers.get('Content-Disposition');
      const a = document.createElement('a')
      const objectUrl = URL.createObjectURL(response.body)
      a.href = objectUrl
      a.download = docTitle;
      a.click();
      URL.revokeObjectURL(objectUrl);
    })
  }

  search() {
    this.searchText

  }

  showSize(event) {
    if (this.previusSize == 0) {
      this.previusSize = event.element.offsetWidth
    }
    let s: string = event.element.innerText
    if (s.includes('ID') || s.includes('RICHIESTA')) {
      this.columnWidth = this.previusSize + event.delta
      this.previusSize = this.columnWidth
    }
  }

  getFilenameFromUrl(url: string): string {
    const parts = url.split('/');
    return parts[parts.length - 1];
  }

  getFindingColId() {
    return this.isAdmin ? 'ID Finding' : 'ID Finding e descrizione'
  }

  getRequestTitle(id) {
    let currData = this.tableCurrentData.find(rowData => rowData.id == id)
    return currData['request_title']
  }

 

}

