import { Attachment } from "./Attachment";
import { Comment } from 'src/app/data/models/Comment';

export class DbFaq {
  id?: number;
  request_title?: string;
  priority?: string;
  due_date?: string;
  reason?: string;
  scope?: string;
  perimeter?: string;
  structure_request?: string;
  description?: string | { title: string, description: string };
  status?: string;
  creation_date?: string;
  last_update?: string;
  requested_user?: number;
  document_attached?: number[] | Attachment[];
  is_faq?: boolean;
  comments?: Comment[] | number[];
  expired?: boolean

}


export class TableFaq {
  richiesta: {
    titolo: string;
    descrizione: string;
  }
  priorita: string;
  due_date: string;
  ambito: string;
  perimetro: string;
  struttura_richiedente: string;
  status: string;
  creation_date?: string;
  last_update?: string;
  requested_user?: number;
  document_attached?: number[] | Attachment[];
  is_faq?: boolean;
}



export const defAmbito = [
  'Trasparenza',
  'Tutela dei fondi',
  'Antitrust',
  'Conflitti d interesse e parti collegate',
  'Politiche retributive e incentivanti',
  'Conflitti d interesse e parti correlate',
  'Altro'
];
export enum Scope {
  trasparenza = 'Trasparenza',
  tutela = 'Tutela dei fondi',
  antitrust = 'Antitrust',
  conflittiCollegate = 'Conflitti di interesse e parti collegate',
  politiche = 'Politiche retributive e incentivanti',
  conflittiCorrelate = 'Conflitti di interesse e parti correlate',
  altro = 'Altro'
};



export const defPriorita = [
  'Alta',
  'Media',
  'Bassa'
];
export enum Priority {
  HIGH = 'Alta',
  MEDIUM = 'Media',
  LOW = 'Bassa'
}

export const defStato = [
  'COMPLETED',
  'NEW',
  'EXPIRED',
  'DRAFT',
  'SENT',
  'REFUSED',
  'IN_CHARGE',
];
export enum Status {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  IN_CHARGE = 'IN_CHARGE',
  REFUSED = 'REFUSED'
}

export const defPerimetro = [
  'Modifica foglio informativo',
  'Approvazione nuovo prodotto',
  'Approvazione campagna pubblicitaria',
  'Approvazione messaggio promozionale',
  'Altro'
]
export enum Perimeter {
  modifica = 'Modifica foglio informativo',
  approvazioneProdotto = 'Approvazione nuovo prodotto',
  approvazioneCampagna = 'Approvazione campagna pubblicitaria',
  approvazioneMessaggio = 'Approvazione messaggio promozionale',
  altro = 'Altro'
}
