<div class="p-3 flex layout justify-content-stretch flex-column">
    <div class="border bg-white flex flex-column w-full">
        <div class=" flex flex-column w-full  p-3 pb-0  h-10">
            <div class="flex flex-row  justify-content-between">
                <div class="p-2">
                    <h1 class="title">Importazione</h1>
                </div>
                <!-- <div class="pl-2">
                    <button (click)="downloadFile()" pButton pRipple class="button-add" [disabled]="loading">Scarica Template</button>
                </div> -->
            </div>
            <hr>
        </div>
        <div class="dragdrop p-3 gap-2 h-full ">
            <form [formGroup]="formGroup" class="w-full" enctype="x-www-urlencoded">
                <div class="col-12 flex h-100 flex-column ">
                    <div class="col-12 flex h-full justify-content-between">
                        <div id="dropzone-box" appDragDrop (files)="dropfile($event)" style="overflow-y: auto;"
                            class="border-round-lg h-25rem border-dashed border-gray-400 border-1 col-12 flex w-full flex-column align-items-center justify-content-center">
                            <div *ngIf="!currentFile;else showFile">
                                <div
                                    class="col-12  flex flex-row text-center text-lg text-dialog justify-content-center align-items-center ">
                                    <i class="fa-solid fa-upload fa-2xl m-3 "></i>
                                </div>
                                <div id="content"
                                    class="col-12  flex flex-row text-center text-lg text-dialog justify-content-center align-items-center ">
                                    <button class="button-upload border-none " style="background-color:transparent"
                                        type="submit" (click)="fileInput.click()">
                                        <a class="no-underline font-bold ">
                                            <span class=" ">Carica un documento</span>
                                        </a>
                                        <input formControlName="file" #fileInput type="file" (change)="dropfile($event)"
                                            style="display: none" />
                                    </button>

                                </div>
                                <div class=" col-12 text-center ">
                                    <span class="text-base text-dialog font-normal">Accetta solo Template.</span>
                                    <div class=" col-12 text-center ">
                                        <button (click)="downloadFile()" pButton pRipple class="button-add" [disabled]="loading">Scarica Template</button>
                                    </div>
                                </div>
                                <div class="w-full">
                                    <div class="w-full"
                                        *ngIf="formGroup.controls['file'].invalid && formGroup.controls['file'].touched ">
                                        <p class="text-center" style="color:#e24c4c;font-size:small;left:30%">
                                            Inserisci il documento</p>
                                    </div>
                                </div>

                            </div>
                            <ng-template #showFile>
                                <div
                                    class="flex flex-row w-full h-full  py-2  justify-content-center align-content-center">
                                    <div
                                        class=" flex flex-row w-full h-full align-items-center justify-content-center ">
                                        <div class=" flex flex-row align-items-baseline ">
                                            <i class="fa fa-file pl-3" aria-hidden="true"></i>
                                            <p class="pl-3">
                                                {{currentFile.name}}
                                            </p>
                                            <i class="fa fa-close pl-2" (click)="removeFile()" aria-hidden="true"></i>
                                        </div>
                                    </div>
                                </div>
                            </ng-template>
                        </div>
                    </div>

                </div>
            </form>
        </div>
        <div class="flex justify-content-end">
            <div class="flex justify-content-end">
                <div class="flex justify-content-end h-4rem align-items-center p-3">
                    <button pButton pRipple (click)="saveImport()" class="button-add" [disabled]="loading">Conferma Importazione</button>

                </div>
            </div>
        </div>
    </div>
</div>