<div class="container-notify">
    <div class="triangle"></div>
    <div class="notify-home px-3 mt-2">
        <div class="h-full" *ngIf="notifies.length == 0">
            <div class="flex ml-1 flex-row text-center align-items-center justify-content-center mt-2 notnotify">
                <div class="h-full flex ">
                    <p>Non hai nuove notifiche.</p>
                </div>
            </div>
        </div>
        <div *ngFor="let notify of notifies; let i = index" class="my-2">
            <div style="overflow-x: hidden;" (click)="openRequest(notify.payload['id'],notify.payload['type']);ackMessage(notify.messageId)" class="flex flex-row justify-content-between align-items-center  notify-hover">
                <div class="flex flex-row">
                    <!-- <div class="flex  mt-3 ">
                        <i class="fa-regular fa-envelope mr-2 h-full fa-xl"></i>
                    </div> -->
                    <div  class=" flex  notify flex-column justify-content-center ">
                        <div style="overflow: hidden;text-overflow: ellipsis;max-width: 16.5em;">
                            <b style="overflow: hidden;text-overflow: ellipsis;white-space: nowrap">{{notify.payload['title']}}</b>
                        </div>
                        <p style="overflow-x: hidden;text-overflow: ellipsis;white-space: nowrap">{{notify.payload['msg']}}</p>
                    </div>
                </div>
                <div>
                    <i class="fa-solid  fa-xl fa-circle-xmark decline" (click)="ackMessage(notify.messageId)"></i>
                </div>
            </div>
        </div>
    </div>
    <div class="flex w-full justify-content-center buttoncustom align-items-baseline">
        <div class="flex  justify-content-end w-75  pr-6">
            <button class="customButton" [routerLink]="['/notify']">Mostra tutto</button>
        </div>
        <div class="flex justify-content-end w-25  pr-4">
            <i (click)="clear()" class="fa-solid fa-trash icontrash"></i>
        </div>
    </div>
</div>