import { HttpClient, HttpResponse } from '@angular/common/http';
import { Injectable, inject } from '@angular/core';
import { async } from '@angular/core/testing';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import { Observable, Observer, Subject, map, of } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ExportActions } from '../store/export/export.action';

@Injectable({
  providedIn: 'root'
})
export class AdminService {
  scopes: Subject<string[]>;
  activeScope: string;
  perimeters: string[];
  constructor(private http: HttpClient, private keycloakService: KeycloakService, private store: Store) {
    // this.scopes = new Subject<string[]> 
    // this.setScopes().subscribe(ambiti=>{
    //   ambiti.then(res => {
    //     console.log("adminService constructor ", this.scopes);
    //     this.scopes.next(res)
    //     })
    //   })
  }

  setScopes() {
    let url = (environment.apiUrl + '/config/ambiti.json/')
    let ambiti: string[] = [];
    return this.http.get((url), { responseType: 'blob', observe: 'response' }).pipe(
      map(async (response: HttpResponse<Blob>) => {
        const contentDisposition = response.headers.get('Content-Disposition');
        const amb = Object.keys(JSON.parse(await response.body.text()));
        console.log(ambiti)
        ambiti = amb
        return ambiti
      })
    )
  }

  getUsersList(start: number, end: number, searchParams?) {
    console.log("GET")
    searchParams = searchParams ? "search=" + searchParams + "&" : ""
    return this.http.get<any>(`${environment.apiUrl}/admin-panel/users/?${searchParams}start=${start}&end=${end}`, {
      headers: {
        'Authorization': `Bearer ${this.keycloakService.getToken()}`,
      }
    });
  }


  getRoles() {
    return this.http.get<string[]>(`${environment.apiUrl}/admin-panel/roles/`)
  }

  patchUser(temprole: string, id: string) {

    let postData = {
      role: temprole,
    }
    console.log(postData)
    console.log(id)

    return this.http.post(`${environment.apiUrl}/admin-panel/user/${id}/role/`, postData)
  }

  //aggiustare l'api deve essere una put o patch perchè deve aggiornare il dato corrente
  patchUserGroup(oldgroup:any,tempgroup: string, id: string) {

    console.log(oldgroup)
    let postData = {
      old_group: oldgroup[0],
      new_group:tempgroup
    }
    console.log(postData)
    console.log(id)


    return this.http.post(`${environment.apiUrl}/admin-panel/user/${id}/change_user_group/`, postData)
  }

  addUserGroup(tempgroup: string, id: string) {

    let postData = {
      group: tempgroup
    }
    console.log(postData)
    console.log(id)


    return this.http.post(`${environment.apiUrl}/admin-panel/user/${id}/group/`, postData)
  }

  getUserGroup(id: string) {

    return this.http.get(`${environment.apiUrl}/admin-panel/user/${id}/`)
  }



  patchUserEnable(tempenable: boolean, id: string) {

    let field: string;

    if (tempenable == true) {

      field = 'enable'

    } else if (tempenable == false) {

      field = 'disable'
    }

    return this.http.post(`${environment.apiUrl}/admin-panel/user/${id}/${field}/`, id);


    // let postData = {
    //   enable: tempenable
    // }
    // console.log(postData)
    // console.log(id)

  }

  deleteGroup(value: string) {
    let postData = {
      group: value,
    }
    console.log(value)
    return this.http.post(`${environment.apiUrl}/admin-panel/delete-group/`, postData);

  }

  downloadFile() {
    // let value = 'finding'
    // return this.http.post(`${environment.apiUrl}/download-excel/`, {filters:{}});
    // console.log(this.http.post(`${environment.apiUrl}/download-excel/`,value ))
    this.store.dispatch({ type: ExportActions.exportFile, payload: { responses: { request: false } } })

  }

  disconnectGroup(id: string, namegroup: string) {
    let postData = {
      group: namegroup,
    }
    console.log(postData)
    return this.http.post(`${environment.apiUrl}/admin-panel/user/${id}/removegroup/`, postData);


  }



}
