<div class="customContainer">
  <div [formGroup]="formGroup">
    <div class=" form_new_faq border-round-sm ">
      <div class=" header_form border-round-top-sm font-bold flex justify-content-start">
        <p class="m-1 ml-3 ">{{title}}</p>
      </div>
      <div class="p-3">
        <div class="flex flex-row justify-content-between gap-5">
          <div class="w-full mt-1 mb-1">
            <p class="font-bold text1 mb-1">Titolo Richiesta</p>
            <input type="text" pInputText class="p-input text-sm w-full  " formControlName="title"
              placeholder="Inserisci valore">
            <div class="relative w-full" *ngIf="formGroup.controls['title'].invalid && formGroup.controls['title'].touched || formGroup.get('title')?.value.length >= 100 ">
              <p class="absolute" style="color:#e24c4c; font-size: small;">Inserisci il Titolo ( massimo 100 caratteri ).</p>
            </div>
          </div>
          <div class="w-full mt-1 mb-1">
            <p class="font-bold text1 mb-1">Priorità</p>
            <p-dropdown formControlName="priorita" [styleClass]="styleDropDownPrio" [options]="prioselect"
              [(ngModel)]="prio" placeholder="Seleziona Priorità" [showClear]="true"></p-dropdown>
            <div class="relative" *ngIf="formGroup.controls['priorita'].invalid && formGroup.controls['priorita'].touched ">
              <p class="absolute" style="color:#e24c4c; font-size: small;">Inserisci la Priorità</p>
            </div>
          </div>
          <!-- <div class="w-3 mt-1">
            <p class="font-bold text1 mb-1">Due date e motivazione</p>
            <p-calendar [(ngModel)]="date" dateFormat="{{'dd-mm-yy'}}" inputId="time" formControlName="dueDate"
              placeholder="Seleziona una data">
              <ng-template pTemplate="header">
                <p class="font-bold text1 mb-1">Motivazione</p>
                <input type="text" [(ngModel)]="reason" pInputText formControlName="motivazione"
                  class="p-input text-sm w-full" placeholder="Scrivi qui la motivazione">
              </ng-template>
            </p-calendar> -->
          <!-- <p-calendar inputId="basic" [(ngModel)]="date" formControlName="dueDate"  placeholder="--/--/----"></p-calendar> -->
          <!-- <input type="text" pInputText class="p-input text-sm w-100  " formControlName="dueDate"
              placeholder="--/--/----"> -->
          <!-- </div> -->
        </div>
        <div class="flex flex-row justify-content-between gap-5">
          <div class="w-full mt-3 mb-1">
            <p class="font-bold  text1 mb-1">Ambito</p>
            <!-- <p-dropdown  formControlName="ambito" [styleClass]="styleDropDownScope" [options]="scope"
              [(ngModel)]="selectedScope" placeholder="Seleziona Ambito"  [showClear]="true"></p-dropdown> -->
          <p-dropdown [options]="fieldAOptions" [(ngModel)]="ambito" (onChange)="filterFieldBOptions()" [styleClass]="'dropdown w-full'" [placeholder]="'Seleziona Ambito'" formControlName="ambito"></p-dropdown>

              <div class="relative m-0 p-0" *ngIf="formGroup.controls['ambito'].invalid && formGroup.controls['ambito'].touched ">
                <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci l' Ambito</p>
              </div>

          </div>
          <div class="w-full mt-3 mb-1">
            <p class="font-bold  text1 mb-1">Perimetro</p>
            <!-- <p-dropdown  formControlName="perimetro" [styleClass]="styleDropDownPerimeter" [options]="perimeter"
              [(ngModel)]="selectedPerimeter" placeholder="Seleziona Perimetro" [showClear]="true"></p-dropdown> -->
              <p-dropdown [options]="fieldBOptions" [(ngModel)]="perimetro" [styleClass]="'dropdown w-full'" [placeholder]="'Seleziona Perimetro'" emptyMessage="Nessun ambito selezionato" formControlName="perimetro"></p-dropdown>

              <div class="relative m-0 p-0" *ngIf="formGroup.controls['perimetro'].invalid && formGroup.controls['perimetro'].touched ">
                <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il Perimetro</p>
              </div>
          </div>
          <!-- <div class="w-3 mt-3">
            <p class="font-bold text1 mb-1">Struttura organizzata</p>
            <input formControlName="struttura" type="text" pInputText class="p-input text-sm w-100  " placeholder="...">
          </div> -->
        </div>
        <div class="mt-3 mb-3">
          <p class="font-bold text1 mb-1">Descrivi la tua richiesta</p>
          <textarea class="" formControlName="description" pInputTextarea [autoResize]="false" class="w-full">
          </textarea>
          <div class="relative" *ngIf="formGroup.controls['description'].invalid && formGroup.controls['description'].touched || formGroup.get('description')?.value.length >= 65530 ">
            <p class="absolute" style="color:#e24c4c; font-size: small;">Inserisci la Descrizione ( massimo 65535 caratteri ).</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
