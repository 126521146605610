import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { Observable, map, take } from 'rxjs'
import { environment } from 'src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { MessageService } from 'primeng/api';
import { ExportFilter, Filter } from 'src/app/data/models/Filter';
import { FormControl, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectRequestDataLoading } from 'src/app/utils/store/request/request.selector';
import { selectFindingDataLoading } from 'src/app/utils/store/finding/finding.selector';
import { RequestActions } from 'src/app/utils/store/request/request.action';
import { selectExportDataLoading } from 'src/app/utils/store/export/export.selector';
import { ExportActions } from 'src/app/utils/store/export/export.action';


@Component({
  selector: 'app-header-tab',
  templateUrl: './header-tab.component.html',
  styleUrls: ['./header-tab.component.scss']
})
export class HeaderTabComponent {

  @Input() isAllegato: boolean;
  @Input() hasButton: boolean;
  @Input() canExport: boolean;
  @Input() canAddRequest: boolean;
  @Input() canFaq: boolean;
  @Input() canFilter: boolean = true;
  @Input() canChat: boolean;
  @Input() title: string;
  @Input() isAdmin: boolean;

  // @Input() isLegal: boolean;
  // @Input() isPrivacy: boolean;

  @Input() addAllegato: boolean;
  @Input() seeRequestCompliance: boolean;
  @Input() seeFindingCompliance: boolean;
  @Input() createFinding: boolean = false;
  @Input() canRemedation: boolean;
  @Input() hasAction: boolean = true;
  displayDecline: boolean;
  displayConfirmation: boolean;
  displayAcept: boolean;
  displayCompleted: boolean;
  @Input() stato: string;
  @Input() isFinding: boolean = false;
  @Input() filters: ExportFilter;
  refused_reason: string;
  visibleSidebar5;
  setExportBool: boolean = false;
  loading: boolean = false;
  exportFile: boolean = false;

  loadingSub: Observable<boolean> = this.store.select(selectExportDataLoading).pipe(map(value => this.exportFile = value))

  @Output() response = new EventEmitter<any>()


  display: boolean;
  refused = new FormControl('', [Validators.required, Validators.maxLength(65530)]);

  constructor(private http: HttpClient, private keycloackservice: KeycloakService, private messageService: MessageService, private store: Store) { }

  ngOnInit() {


    if (this.isFinding == false) {
      this.store.select(selectRequestDataLoading).pipe(map(value => this.loading = value)).subscribe()
    } else {
      this.store.select(selectFindingDataLoading).pipe(map(value => this.loading = value)).subscribe()

    }
  }

  setExport() {
    this.setExportBool = true;
  }


  showDeclineModalDialog() {
    this.displayDecline = true;
  }
  showAceptModalDialog() {
    this.displayAcept = true;
  }
  showCompleteModalDialog() {
    this.displayCompleted = true;
  }

  showConfirmationDialog() {
    this.displayConfirmation = true;
  }

  declinedRequest() {
    if (this.refused.valid) {
      this.response.emit({ status: "REFUSED", reason: this.refused_reason })
      // this.displayDecline = false;
    } else {
      this.refused.markAsTouched()
    }
  }

  completedRequest() {
    if (this.isFinding) {
      this.response.emit("CLOSED")
    } else {
      this.response.emit("COMPLETED")
    }
    // this.displayDecline = false;
  }

  acceptedRequest() {
    this.response.emit("IN_CHARGE")
    // this.displayDecline = false;
  }

  sendApprovation() {
    this.response.emit("TO_CHECK")
    // this.displayConfirmation = false;
  }

  exportRequest() {
    const data = { ...this.filters, filters: this.filters['filters'] !== undefined ? this.filters['filters'] : {} };

    this.store.dispatch({ type: ExportActions.exportFile, payload: { responses: data } })

    console.log(this.exportFile)

    // this.http.post(`${environment.apiUrl}/download-excel/`, data, {
    //   headers: {
    //     'Authorization': `Bearer ${this.keycloackservice.getToken()}`
    //   },
    //   responseType: 'blob'
    // }).subscribe((response: Blob) => {
    //   const filename = 'file.xlsx';
    //   const blob = new Blob([response], { type: 'application/vnd.ms-excel' });
    //   const url = window.URL.createObjectURL(blob);
    //   const a = document.createElement('a');
    //   document.body.appendChild(a);
    //   a.style.display = 'none';
    //   a.href = url;
    //   a.download = filename;
    //   a.click();
    //   window.URL.revokeObjectURL(url);
    // }, (error) => {
    //   console.error('Download failed:', error);
    // });
  }
}
