import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from '@angular/forms';
import { Store } from '@ngrx/store';
import { MessageService } from 'primeng/api';
import { Observable, Subscription, catchError, forkJoin, map, take } from 'rxjs';
import { Status } from 'src/app/data/models/Request';
import { DbFinding } from 'src/app/data/models/Finding';
import { Attachment } from 'src/app/data/models/Attachment';
import { RequestService } from 'src/app/utils/services/request.service';
import { UserService } from 'src/app/utils/services/user.service';
import { FindingActions, setFindings } from 'src/app/utils/store/finding/finding.action';
import { selectFindingDataLoading, selectFindingsData } from 'src/app/utils/store/finding/finding.selector';
import { selectCurrentID, selectCurrentIDandRole } from 'src/app/utils/store/user/user.selector';
import { initUser } from 'src/app/utils/store/user/user.actions';
import { InitService } from 'src/app/utils/services/init.service';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-new-finding',
  templateUrl: './new-finding.component.html',
  styleUrls: ['./new-finding.component.scss']
})
export class NewFindingComponent implements OnInit {
  @Input() titleForm: string = 'Crea nuovo finding';
  @Input() isDisabled: boolean = false;
  formValue: FormGroup;
  findingData = [];
  attachmentData: Attachment[] = [{
    document_title: "",
    object_name: "",
    description: "",
    uploaded_by: 0
  }]
  tabella: any[] = [];
  isAdmin: Boolean;
  isFilter: { isFilter: boolean, filter: string }
  postData: DbFinding;
  currentUserId;
  @Input() display: boolean = false;
  stato: string;
  canChatFinding: boolean;
  addingAttachment: boolean = false;
  tableColumn: string[] = []
  canAddAttachment: boolean = true;
  size: number = 0;
  sub: Subscription;
  dataToSend: string
  invalidBoolean: boolean = false
  loading: boolean = false;

  @Output() newItemEvent = new EventEmitter<string>();

  adminSub$: Observable<any> = this.store.select(selectCurrentIDandRole).pipe(
    take(1),
    map(detail => {
      let roles: string[] = detail['roles']
      let userRole = roles.find(role => role == "audit_user")
      this.currentUserId = detail['id'];
      return userRole !== undefined ? true : false;
    }),
    catchError(err => {
      //this.isAdmin = false;
      return forkJoin(
        this.initService.initLookups().pipe(
          take(1),
          map((data) => {
            this.store.dispatch(initUser({ currentUser: data['user'] }))
            if (this.sub) {
              this.sub.unsubscribe()
            }
            this.sub = this.adminSub$.subscribe()
          })
        )
      )
    })
  )


  constructor(private initService: InitService, private fb: FormBuilder, private messageService: MessageService, private store: Store) { }
  ngOnInit() {
    this.store.select(selectFindingDataLoading).pipe(map(value => this.loading = value)).subscribe()


    this.sub = this.adminSub$.subscribe(result => {
      this.isAdmin = result
    })

    this.tableColumn = Object.keys(this.attachmentData[0])


    this.formValue = this.fb.group({
      newFinding: {
        // idFinding: '',
        utenteAssegnatario: '',
        idScenario: '',
        ambito: '',
        scenarioResiduo: '',
        perimetro: '',
        tipologiaIntervento: '',
        dataVincolante: '',
        problemaRiscontrato: '',
        azioneNecessaria: '',
        flag_reminder: '',
        reminder_days: '',
        flag_expiry_date: '',
       
      },
    })


  }

  sendFinding() {

    let newValue = this.formValue.controls['newFinding'].value
    if (newValue['dataVincolante']) {
      let data = new Date(newValue['dataVincolante'])
      this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
    }

    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
      this.invalidBoolean = true
    } else {
      this.invalidBoolean = false
      this.isDisabled = true;
      this.canAddAttachment = false;


      this.postData = {
        scope: newValue['ambito'],
        perimeter: newValue['perimetro'],
        intervention_type: newValue['tipologiaIntervento'],
        flag_expiry_date: newValue['flag_expiry_date'],
        due_date: this.dataToSend,
        problem_encountered: newValue['problemaRiscontrato'],
        action_required: newValue['azioneNecessaria'],
        solution_description: "",
        status: Status.NEW,
        flag_reminder: newValue['flag_reminder'],
        reminder_days: newValue['reminder_days'],
        fdocument_attached: [],
        risk_id: newValue['idScenario'],
        residual_risk: newValue['scenarioResiduo'],
        group_assigned: newValue['utenteAssegnatario'],

        // user_assigned: newValue['utenteAssegnatario'].id,
        comments: []
      };

      if (this.attachmentData.length == 1 && this.attachmentData[0].description == "") {
        this.attachmentData = []
      }

      this.store.dispatch({
        type: FindingActions.addNewFinding,
        finding: this.postData,
        attachments: this.attachmentData
      });
    }

  }


  disableForm() {
    this.formValue.get("newFinding").disable()
  }

  save() {

    let newValue = this.formValue.controls['newFinding'].value
    if (newValue['dataVincolante']) {
      let data = new Date(newValue['dataVincolante'])
      this.dataToSend = data.toJSON().slice(0, data.toJSON().indexOf("T"));
    }
    if (this.formValue.invalid) {
      this.messageService.add({ severity: 'error', summary: 'Attenzione!', detail: 'Inserisci i campi corretti' });
      this.invalidBoolean = true
    } else {
      this.invalidBoolean = false
      this.isDisabled = true;
      this.canAddAttachment = false;



      this.postData = {
        scope: newValue['ambito'],
        perimeter: newValue['perimetro'],
        intervention_type: newValue['tipologiaIntervento'],
        flag_expiry_date: newValue['flag_expiry_date'],
        due_date: this.dataToSend,
        problem_encountered: newValue['problemaRiscontrato'],
        action_required: newValue['azioneNecessaria'],
        solution_description: null,
        status: Status.DRAFT,
        flag_reminder: newValue['flag_reminder'],
        reminder_days: newValue['reminder_days'],
        fdocument_attached: [],
        risk_id: newValue['idScenario'],
        residual_risk: newValue['scenarioResiduo'],
        group_assigned: newValue['utenteAssegnatario'],
        // user_assigned: newValue['utenteAssegnatario'].id,
        comments: []
      };
      if (this.attachmentData.length == 1 && this.attachmentData[0].description == "") {
        this.attachmentData = []
      }
      this.store.dispatch({
        type: FindingActions.addNewFinding,
        finding: this.postData,
        attachments: this.attachmentData
      });
    }
  }

  setFilter(data) {
    this.isFilter = data
  }

  addAttachment(attachment) {
    if (this.attachmentData.length > 0 && this.attachmentData[0].description == "") {
      this.attachmentData = []
    }
    this.addingAttachment = true
    const attToShow: any = (({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }) => ({
      attachment,
      request_attached,
      description,
      object_name,
      document_title,
      uploaded_by,
    }))(attachment);
    if (!this.attachmentData) {
      this.attachmentData = { ...attToShow }
    } else {
      this.attachmentData = [...this.attachmentData, attToShow]
    }

    this.size = this.attachmentData.length
  }

  removeAttachment(event) {

    this.attachmentData.splice(event, 1)
    if (this.attachmentData.length == 0) {
      this.attachmentData = [{
        document_title: "",
        object_name: "",
        description: "",
        uploaded_by: 0
      }]
    }

  }

  editAttachment(event) {
    this.removeAttachment(event.index);
    this.addAttachment(event.attachment)
  }

  ngOnDestroy() {
    if (this.sub) {
      this.sub.unsubscribe()
    }
  }

}
