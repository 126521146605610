import { Component, OnInit } from '@angular/core';
import { Form, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { logoAnimation, formaAnimation, formAnimation } from 'src/app/animations/loginAnimation';
import { ChangeDetectorRef } from '@angular/core';
import { UserService } from 'src/app/utils/services/user.service';
import { RequestService } from 'src/app/utils/services/request.service';
import { Store } from '@ngrx/store';
import { take } from 'rxjs';
import { tick } from '@angular/core/testing';
import { Attachment } from 'src/app/data/models/Attachment';
import { setRequests } from 'src/app/utils/store/request/request.action';
import { MessageService } from 'primeng/api';
import { UserClass } from 'src/app/data/models/User';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { FindingService } from 'src/app/utils/services/findings.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
  animations: [logoAnimation, formaAnimation, formAnimation]
})
export class LoginComponent implements OnInit {
  state = 'normal';
  checked: boolean = false;
  public loginForm: FormGroup;
  public registerForm: FormGroup;
  currentUser: UserClass


  constructor(
    private fb: FormBuilder) { }


  ngOnInit() {
    this.currentUser = null

    this.loginForm = this.fb.group({
      usernameFormControl: ['', Validators.required],
      passwordFormControl: ['', Validators.required],
    })

    this.registerForm = this.fb.group({
      usernameFormControl: ['', Validators.required],
      nameFormControl: ['', Validators.required],
      surnameFormControl: ['', Validators.required],
      mailFormControl: ['', Validators.required],
      passwordFormControl: ['', Validators.required],
      confirmPasswordFormControl: ['', Validators.required],
    })


    // this.loginForm = new FormGroup({
    //   usernameFormControl: new FormControl("", Validators.required),
    //   passwordFormControl: new FormControl("", Validators.required),
    // })
    this.state = 'animated';
  }

  ngAfterContentChecked() {
    //this.state == 'normal' ? this.state = 'animated' : this.state = 'normal';
    //this.cdref.detectChanges();
  }

  ngOnDestroy() {
    this.state = 'normal';

  }

  // setUserLogin() {
  //   let loginData = this.loginForm.controls;
  //   // this.currentUser = this.userService.getUser(loginData['usernameFormControl'].value);
  //   if (this.currentUser != null) {
  //     this.router.navigate(['/home'])
  //   } else {
  //     this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'I dati non esistono' });
  //   }
  // }

  // setUserRegistered() {
  //   let registerData = this.registerForm.controls;
  //   // this.currentUser = this.userService.getUser(registerData['usernameFormControl'].value);
  //   if (this.currentUser != null) {
  //     this.store.dispatch(setUsers({ user: this.currentUser }));
  //     this.router.navigate(['/home'])
  //   } else {
  //     this.messageService.add({ severity: 'error', summary: 'ERROR', detail: 'I dati non esistono' });
  //   }
  //   // this.store.dispatch(loginUser({ email: loginData['usernameFormControl'].value, password: loginData['passwordFormControl'].value }))
  //   this.userService.getAdmin();
  // }






}
