import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { KeycloakService } from 'keycloak-angular';
import {UserActions, updateUserSuccess} from './user.actions';
import { UserState } from './user.state';
import { Observable, concatMap, map, mergeMap, of, switchMap } from 'rxjs';
import { UserService } from '../../services/user.service';
import { AdminActions } from '../admin/admin.actions';


@Injectable()
export class UserEffects {
  userArray: any[] = [];
  isAdmin: string;

  // updateUser$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(UserActions.updateUser),
  //     switchMap(({ form }) => {
  //       return this.userService.updateUserDetail(form)
  //         .pipe(
  //           map((res) => {

  //             return updateUserSuccess({currentUser:res})
  //           })
  //         )
  //     })
  //   )
  // })


  constructor(
    private keycloakService: KeycloakService,
    private actions$: Actions,
    private userService: UserService,
    private store: Store
  ) { }
}
