<div class="p-3 flex layout justify-content-stretch flex-column">
  <div class="border bg-white p-2">
    <div class="flex  w-full  p-3 pb-0 justify-content-between ">
        <h1 class="title">Gestione Utenti</h1>
    </div>
      <app-dynamic-list 
      [(selectedDropDown)]="selectedUserOptions"
      [seeAll]="seeAll"
      [manageGroup]="true"
      [values]="virtualItems"
      [canRemoveItems]="false"
      [canEditItems]="true"
      [dropDownOptions]="dropDownOptions"
      [itemSize]="75"
      [isLoading]="isLoading"
      [title]="dropdownOptionManageUser"
      (loadEvent)="loadUsers($event)"
      (changeDropdownTitle)="changeDropDownTitle($event)"
      (editUser)="showDialogEdit($event)"
      (editUser)="showDialogEdit($event)"
      [isDropdownManage]="true"
      
      />
  </div>
</div>
<p-dialog [closable]="true"  [(visible)]="DialogEdit" [modal]="true"
  [style]="{'width': 'fit-content', 'min-width': '40rem', 'height': '70vh' }" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
   <h1 class="title"> Modifica Utente</h1>
  </ng-template>
  <ng-template pTemplate="content ">
    <div class=" flex w-100 h-100 ">
      <app-user-detail class="w-full " [title]="selectedUserOptions" [first]="prevFirst" [last]="prevLast" [selectDropdown]="selectedUserOptions" [user]="userToShow"  (selectedEnableChange)="handleSelectedEnableChange($event)" (selectedRoleChange)="handleSelectedRoleChange($event)" (selectedGroupChange)="handleSelectedGroupChange($event)"></app-user-detail>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between w-full">
      <button pButton pRipple type="button" class="customButton   w-full " [disabled]="loading"
        (click)="DialogEdit=false" label="Annulla">
      </button>
      <button pButton pRipple type="button" class="customButtonActive  m-0 w-full " [disabled]="loading"
        (click)="setChange()" label="Conferma">
      </button>
    </div>
  </ng-template>
</p-dialog>

