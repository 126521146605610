
<div class="flex  p-3 flex-column w-full h-full ">
    <div class="flex flex-column border h-full p-2  bg-white">
        <div class="p-3 pb-0 h-10">
            <h1 class="title pb-2">
                Crea Nuovo Utente
            </h1>
            <hr>
        </div>
        <div class="flex flex-column h-full justify-content-between">
            <form class="px-2 relative h-full" action="" [formGroup]="profileForm">
                <div class="flex flex-row p-3  w-full justify-content-between gap-6">
                    <div class="flex flex-column gap-2 w-full">
                        <label class="text1" htmlFor="username">Nome</label>
                        <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help"
                            formControlName="name" />
                        <div class="relative m-0 p-0"
                            *ngIf="profileForm.controls['name'].invalid && profileForm.controls['name'].touched || profileForm.get('name')?.value.length >= 150">
                            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il nome ( max
                                150 caratteri ).</p>
                        </div>
                    </div>
                    <div class="flex flex-column gap-2 w-full">
                        <label class="text1" htmlFor="username">Cognome</label>
                        <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help"
                            formControlName="surname" />
                        <div class="relative m-0 p-0"
                            *ngIf="profileForm.controls['surname'].invalid && profileForm.controls['surname'].touched || profileForm.get('surname')?.value.length >= 150">
                            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci il cognome (
                                max 150 caratteri ).</p>
                        </div>
                    </div>
                    <div class="flex flex-column gap-2 w-full">
                        <label class="text1" htmlFor="username">Email</label>
                        <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help"
                            formControlName="email" />
                        <div class="relative m-0 p-0"
                            *ngIf="profileForm.controls['email'].invalid && profileForm.controls['email'].touched || profileForm.get('email')?.value.length >= 254">
                            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">{{
                                profileForm.get('email').errors['emailExist'] !== undefined? 'Email già esistente' :
                                "Inserisci l' email ( max 245 caratteri ). "}}</p>
                        </div>
                    </div>
                </div>
                <div class="flex flex-row p-3 w-full justify-content-between gap-6">
                    <div class="flex flex-column gap-2 w-full">
                        <label class="text1" htmlFor="username">Username</label>
                        <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help"
                            formControlName="username" />
                        <div class="relative m-0 p-0"
                            *ngIf="profileForm.controls['username'].invalid && profileForm.controls['username'].touched || profileForm.get('username')?.value.length >= 20">
                            <p class="absolute ml-3" style="color:#e24c4c;font-size:small"> {{
                                profileForm.get('username').errors['usernameExist'] ? 'Username già esistente' :
                                "Inserisci l' username ( max 20 caratteri ). "}} </p>
                        </div>

                    </div>
                    <div class="flex flex-column gap-2 w-full">
                        <label class="text1" htmlFor="username">Password</label>
                        <input pInputText class="p-inputtext-sm" id="username" aria-describedby="username-help"
                            formControlName="password" />
                        <div class="relative m-0 p-0"
                            *ngIf="profileForm.controls['password'].invalid && profileForm.controls['password'].touched || profileForm.get('password')?.value.length >= 128">
                            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Inserisci la password (
                                max
                                128 caratteri ).</p>
                        </div>
                    </div>
                    <div class="flex flex-column gap-2 w-full">
                        <label class="text1" htmlFor="username">Ruolo</label>
                        <p-dropdown [style]="{'width':'100%','height':'2.9rem'}" [options]="roles"
                            [(ngModel)]="selectedRole" formControlName="role"
                            placeholder="Seleziona ruolo"></p-dropdown>
                        <div class="relative m-0 p-0"
                            *ngIf="profileForm.controls['role'].invalid && profileForm.controls['role'].touched ">
                            <p class="absolute ml-3" style="color:#e24c4c;font-size:small">Seleziona un ruolo</p>
                        </div>
                    </div>
                </div>
                    <div class="flex justify-content-end button-absolute">
                        <div class="flex justify-content-end h-4rem align-items-center p-3">
                            <button pButton pRipple type="button" class="button-decline p-fontMedium " [routerLink]="['../']"
                                [disabled]="loading">Torna Indietro
                            </button>
                            <button pButton pRipple type="button" class="button-add " (click)="addUser() " [disabled]="loading"><i
                                class="fa-solid fa-check mr-2"></i>Crea
                            Utente
                        </button>
                        </div>
                    </div>
            </form>
            <!-- <div class="flex justify-content-end align-items-end h-full  pr-2  mb-5 ">
                <div class="   flex  align-items-end">
                    <button pButton pRipple type="button" class="button-decline " [routerLink]="['../']"
                        [disabled]="loading">Torna Indietro
                    </button>
                    <button pButton pRipple type="button" class="button-add " (click)="addUser() " [disabled]="loading"><i
                            class="fa-solid fa-check mr-2"></i>Crea
                        Utente
                    </button>
                </div>
            </div> -->
        </div>
    </div>
</div>
