<div class="align-item-center ">
  <div class="justify-content-between customheader flex inline mt-3 mb-1 flex-end"
    style=" margin: 0 0.5%;  padding:0 0.5%;">
    <div class="flex justify-content-start  w-50 ">
      <p class="title  h-full mb-0 ">{{title}}</p>
    </div>
    <div *ngIf="hasAction">
      <div *ngIf="seeRequestCompliance" class="w-full flex justify-content-end">
        <button pButton pRipple *ngIf="stato !== 'NEW'" type="button" class="export " (click)="display=true"><i
            class="fa-regular fa-comment-dots mr-2"></i>{{ isAdmin ? "Scrivi all'utente" : "Commenti
          Compliance"}}</button>
        <button pButton pRipple *ngIf="stato == 'NEW'" type="button" class="decline"
          (click)="showDeclineModalDialog()"><i class="fa-solid fa-ban mr-2"></i>Rifiuta in carico</button>
        <button pButton pRipple *ngIf="stato == 'NEW'" type="button" class="accept " (click)="showAceptModalDialog()"><i
            class="fa-solid fa-file-import mr-2"></i>Prendi in carico</button>
        <button pButton pRipple *ngIf="stato == 'IN_CHARGE' || stato == 'DRAFT'" type="button" class="exportGreen "
          (click)="showCompleteModalDialog()"><i class="fa-sharp fa-solid fa-file-circle-check mr-2"></i>Finalizza
          Risposta
        </button>
      </div>
      <div *ngIf="seeFindingCompliance" class="w-full flex justify-content-end">

        <button *ngIf="stato!=='DRAFT'" pButton pRipple type="button" class="export " (click)="display=true"><i
            class="fa-regular fa-comment-dots mr-2"></i>{{isAdmin ? "Scrivi all'utente" : "Commenti
          Compliance"}}</button>

        <button pButton pRipple *ngIf="stato == 'NEW' && !isAdmin" type="button" class="accept "
          (click)="showAceptModalDialog()"><i class="fa-solid fa-file-import mr-2"></i>Prendi in carico</button>

        <button pButton pRipple *ngIf="stato == 'TO_CHECK' && isAdmin" type="button" class="decline"
          (click)="showDeclineModalDialog()"><i class="fa-solid fa-ban mr-2"></i>Rifiuta chiusura</button>

        <button pButton pRipple type="button" *ngIf="stato == 'TO_CHECK' && isAdmin" class="accept "
          (click)="showCompleteModalDialog()"><i class="fa-solid fa-file-import mr-2"></i>Chiudi/Invia Finding</button>

        <button *ngIf="!isAdmin && (stato=='IN_CHARGE' || stato == 'REFUSED')" pButton pRipple type="button"
          class="exportGreen" (click)="showConfirmationDialog()"><i
            class="fa-sharp fa-solid fa-circle-check mr-2"></i>Invio per Approvazione</button>

        <!-- *ngIf="stato == 'IN_CHARGE' || stato == 'DRAFT'" -->
        <!-- <button pButton pRipple type="button" class="exportGreen "
          (click)="showCompleteModalDialog()"><i class="fa-sharp fa-solid fa-file-circle-check mr-2"></i>Finalizza
          Risposta
        </button> -->
      </div>
      <div class="flex flex-row  ">
        <div *ngIf="hasButton" class="flex flex-row">
          <button pButton pRipple type="button" class="flex  customButton border-noround-right "
            routerLinkActive="active" [routerLink]="['../', 'requests']">
            {{canAddRequest? 'Le mie Richieste' : 'Le mie Richieste'}}
          </button>
          <button pButton pRipple type="button" class="flex customButton  border-noround-left" routerLinkActive="active"
            [routerLink]="['../', 'faq']">FAQ</button>
        </div>
        <div class="flex ">
          <button pButton pRipple type="button" class="flex export " *ngIf="canExport" (click)="exportRequest()"
            [loading]="loadingSub|async"><i *ngIf="!exportFile"
              class="fa-solid fa-up-right-from-square mr-2"></i>Esporta</button>
        </div>
        <div *ngIf="canAddRequest">
          <button pButton pRipple type="button" class="customButton flex add_request"
            [routerLink]="createFinding ?'../new-finding':'../new-request'"><i
              class="fa-solid fa-circle-plus mr-2"></i>{{createFinding ? 'Crea nuovo finding' :'Crea Nuova Richiesta'}}
          </button>
        </div>
        <div *ngIf="canFaq">
          <button pButton pRipple type="button" class="flex add_request " [routerLink]="'../new-faq'"><i
              class="fa-solid fa-circle-plus mr-2"></i>Crea Nuova FAQ
          </button>
        </div>
        <button pButton pRipple type="button" class="export" [routerLink]="" *ngIf="canChat && stato !== 'NEW'"
          (click)="display=true"><i class="fa-regular fa-comment-dots mr-2"></i>Note da compliance</button>
        <!-- <button pButton pRipple type="button" class="export" [routerLink]="" *ngIf="canChat && stato !== 'NEW' && isLegal !== true && isPrivacy !== true"
          (click)="display=true"><i class="fa-regular fa-comment-dots mr-2"></i>Note da compliance</button> -->
        <div *ngIf="canRemedation">
          <button pButton pRipple type="button" class="export" [routerLink]="" (click)="display=true"><i
              class="fa-regular fa-comment-dots mr-2"></i>Commenti Compliance</button>

        </div>
      </div>
    </div>
  </div>
</div>

<p-dialog [closable]="false" [(visible)]="displayDecline" [modal]="true"
  [style]="{'width': '30rem', 'min-width': '25rem', 'height': 'auto'}" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class=" flex justify-content-center  align-item-center  w-full h-full">
      <i class=" text-center  p-3 fa-solid fa-xl fa-circle-exclamation "></i>
    </div>
  </ng-template>
  <ng-template pTemplate="content ">
    <div class=" flex flex-column w-full ">
      <h5 class=" text-center">Sei sicuro di voler rifiutare la richiesta?</h5>
      <span class="p-float-label w-full mt-4"> <textarea [(ngModel)]="refused_reason" class="w-full" id="float-input"
          rows="2" cols="20" pInputTextarea [formControl]="refused"></textarea> <label for="float-input">Indica perchè
          vuoi rifiutare la
          richiesta</label>
      </span>
      <div class="relative" *ngIf="refused.touched && refused.invalid ">
        <p class="absolute" style="color:#e24c4c; font-size: small;">Inserisci la motivazione.
        </p>
      </div>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between ">
      <button pButton pRipple type="button" class="customButton  w-full" (click)="displayDecline=false"
        [disabled]="loading" label="Annulla">
      </button>
      <button pButton pRipple type="button" class="customButtonActive  m-0 w-full" [disabled]="loading"
        (click)="declinedRequest()" label="Rifiuta presa in carico">
      </button>
    </div>
  </ng-template>
</p-dialog>

<p-dialog [closable]="false" [(visible)]="displayCompleted" [modal]="true"
  [style]="{'width': 'auto', 'min-width': '20rem','height': 'auto' }" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class=" flex justify-content-center  align-item-center  w-full h-full">
      <i class=" text-center  p-3 fa-solid fa-xl fa-circle-exclamation "></i>
    </div>
  </ng-template>
  <ng-template pTemplate="content ">
    <div class=" flex w-full ">
      <h5 class=" text-center">Sei sicuro di voler finalizzare la richiesta?</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between w-full ">
      <button pButton pRipple type="button" class="flex customButton   w-full" (click)="displayCompleted=false"
        [disabled]="loading" label="Annulla">
      </button>
      <button pButton pRipple type="button" class="flex  customButtonActive  w-full m-0" [disabled]="loading"
        (click)="completedRequest()" label="Finalizza">
      </button>
    </div>
  </ng-template>
</p-dialog>


<p-dialog [closable]="false" [(visible)]="displayAcept" [modal]="true"
  [style]="{'width': '30vw', 'min-width': '20rem', 'height': 'auto' }" [draggable]="false" [resizable]="false">
  <ng-template pTemplate="header">
    <div class=" flex justify-content-center  align-item-center  w-full h-full">
      <i class=" text-center  p-3 fa-solid fa-xl fa-circle-exclamation "></i>
    </div>
  </ng-template>
  <ng-template pTemplate="content ">
    <div class=" flex w-full  ">
      <h5 class="w-full  text-center">Sei sicuro di voler prendere in carico la richiesta?</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between w-full">
      <button pButton pRipple type="button" class="customButton   w-full" [disabled]="loading"
        (click)="displayAcept=false" label="Annulla">
      </button>
      <button pButton pRipple type="button" class="customButtonActive  m-0 w-full" [disabled]="loading"
        (click)="acceptedRequest()" label="Prendi in carico">
      </button>
    </div>
  </ng-template>
</p-dialog>
<div *ngIf="display">
  <p-sidebar #sb [(visible)]="display" [fullScreen]="true" [baseZIndex]="10000" [showCloseIcon]="false"
    [style]="{'background-color': 'transparent', 'justify-content':'center', 'aligin-items':'center', 'display':'flex'}"
    [blockScroll]="true" [modal]="true">
    <app-chat [isFinding]="isFinding" [stato]="stato" (closeChat)="sb.close()"></app-chat>
  </p-sidebar>
</div>

<p-dialog [closable]="false" [(visible)]="displayConfirmation" [modal]="true"
  [style]="{'width': '40vw', 'min-width': '20rem','max-width': '35rem', 'height': 'auto' }" [draggable]="false"
  [resizable]="false">
  <ng-template pTemplate="header">
    <div class=" flex justify-content-center  align-item-center  w-full h-full">
      <i class=" text-center  p-3 fa-solid fa-xl fa-circle-exclamation "></i>
    </div>
  </ng-template>
  <ng-template pTemplate="content ">
    <div class=" flex w-full  ">
      <h5 class="w-full  text-center">Sei sicuro di voler inviare richiesta di approvazione?</h5>
    </div>
  </ng-template>
  <ng-template pTemplate="footer">
    <div class="flex justify-content-between w-full text-hoverflow-ellipsis">
      <button pButton pRipple type="button" class="customButton   w-full" (click)="displayConfirmation=false"
        [disabled]="loading" label="Annulla">
      </button>
      <button pButton pRipple type="button" class="customButtonActive  m-0 w-full" [disabled]="loading"
        (click)="sendApprovation()" label="Conferma e Invia">
      </button>
    </div>
  </ng-template>
</p-dialog>