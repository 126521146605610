<div class="customContainer">
  <p-table #dt (onColResize)="showSize($event)" [lazy]="isLazy" (onLazyLoad)="loadRequest($event)"
    [value]="tableDataToShow" [columns]="tableColumn" [resizableColumns]="true" [loading]="isLoading"
    [paginator]="isAllegato? false : true" [rows]="5" [totalRecords]="size" [rowHover]="true"
    [showCurrentPageReport]="true" [currentPageReportTemplate]="size !== 0 ?'{first} - {last} / {totalRecords}  ' : ''"
    [rowsPerPageOptions]="[5,25,50,100]" [styleClass]="!isAllegato ? 'tableCustomHeight customTable p-datatable-gridelines': 'customTable p-datatable-gridelines'"
    [globalFilterFields]="tableColumn">
    <ng-template pTemplate="caption">
      <div class="table-header">
        <div class="flex flex-row justify-content-between mb-2" *ngIf="!isAllegato ;else  attachment">
          <div>
            <p-chip *ngFor="let col of filters" styleClass="m-1 p-customChip align-items-baseline white-space-nowrap"
              [style]="{'border': '1px dashed black','font-size':'0.8rem','cursor':'pointer','max-width':'350px'}">
              <i class="filtericon"
                [class]="selectedCategories[col].length == 0 ? 'fa-sharp fa-solid fa-circle-plus mr-2 cursor-pointer' : 'fa-solid fa-circle-xmark mr-2 z-2 cursor-pointer'"
                (click)="(col == 'due_date' || col == 'due_date_planned') && selectedCategories[col].length == 0 ? op2.toggle($event) : selectedCategories[col].length == 0 ? op.toggle($event) : clearFilter(col)"></i>
              <p class="capitalize" (click)="c(col == 'due_date' || col == 'due_date_planned')? op2.toggle($event): op.toggle($event)">{{
                tableITColumn[col] }}</p>
              <p class="text-primary" style="max-width: 250px; overflow: hidden; text-overflow: ellipsis;" (click)="(col == 'due_date' || col == 'due_date_planned')? op2.toggle($event): op.toggle($event)"
                *ngIf="selectedCategories[col].length > 0"> &nbsp;|
                {{getSelectedCategories(col) | filterstatus }}</p>
              <p-overlayPanel [style]="{'width': '300px','height':'fit-content','margin':'0px','padding':'0px'}" #op2>
                <div class="flex flex-column ">
                  <div class=" pl-4 pt-2 pb-0 header align-items-center">
                    <p class="pcustom">Seleziona un rage di <b>DATA:</b> </p>
                  </div>
                  <div class="mt-6 w-full">
                    <p-calendar #calendar [(ngModel)]="rangeDates" selectionMode="range" [readonlyInput]="true"
                      placeholder="Seleziona una data.." [style]="{'height':'2.1rem','width':'100%'}">
                      <ng-template pTemplate="footer">
                        <div class=" w-full mt-2">
                          <p-button styleClass="w-100 justify-content-center p-button-secondary buttoncust" class=""
                            (click)="hideCalendar(calendar)" [style]="{'height':'2.1rem','width':'100%'}">Conferma
                            Data</p-button>
                        </div>
                      </ng-template>
                    </p-calendar>
                  </div>
                  <div class=" w-full mt-2">
                    <p-button styleClass="w-100 justify-content-center p-button-secondary buttoncust" class=""
                      (click)="filterTableData(col); op2.hide()"
                      [style]="{'height':'2.1rem','width':'100%'}">Conferma</p-button>
                  </div>
                </div>
              </p-overlayPanel>
              <p-overlayPanel #op styleClass="myOverlay z-1" [style]="{'max-height':'400px','max-width':'400px'}">
                <p-table #ft [value]="getGroupItems(col)" [columns]="col" styleClass="customTable" [scrollable]="true"
                  scrollHeight="300px" class="border-noround-bottom" [globalFilterFields]="col">
                  <!-- <ng-template pTemplate="footer">

                                    </ng-template> -->

                  <ng-template pTemplate="header" let-columns>
                    <tr>
                      <th pSortableColumn={{col}}>Filtra per
                        <b>{{tableITColumn[col].toUpperCase()}}</b>
                      </th>
                    </tr>
                  </ng-template>
                  <ng-template pTemplate="body" let-value #filter>
                    <tr>
                      <td>
                        <div class=" field-checkbox">
                          <p-checkbox styleClass="customCheck" name="group2" value="filter" [value]="value"
                            [(ngModel)]="selectedCategories[col]" [inputId]="value"></p-checkbox>
                          <label [for]="value">{{value | filterstatus}}</label>
                        </div>
                      </td>
                    </tr>
                  </ng-template>

                  <!-- <ng-template pTemplate="body" >
                                        <ng-template let-value >
                                            <div >
                                                <div class="px-4 field-checkbox">
                                                    <p-checkbox name="group2" value="filter" [value]="value"
                                                    [(ngModel)]="selectedCategories[col]"
                                                    [inputId]="value"></p-checkbox>
                                                    <label [for]="value">{{value}}</label>
                                                </div>
                                            </div>
                                        </ng-template>
                                    </ng-template>   -->
                </p-table>
                <div class=" p-1">
                  <p-button styleClass="w-100 justify-content-center p-button-secondary buttoncust" class=""
                    (click)="filterTableData(col); op.hide()" [style]="{'height':'2.1rem'}">Conferma</p-button>
                </div>
              </p-overlayPanel>
            </p-chip>
          </div>

          <div *ngIf="canSearch" class=""> <!--GLOBAL SEARCHBAR -->
            <span class="p-inputgroup-addon bg-transparent " style="height:2.1rem;padding-right:0.3rem;">
              <span>
                <i class="pi pi-search"></i>
              </span>
              <input class="customInput" [(ngModel)]="searchText" pInputText type="text"
                style="font-family:Arial, FontAwesome;height:1.9rem;border:none" [placeholder]="isFaq ? 'Cerca tra le Faq': isFinding? 'Cerca tra i Finding':'Cerca tra le richieste'"
                aria-label="Search" aria-describedby="search-addon" />
              <span>
                <button *ngIf="!searchMode ;else resetSearch" class="searchButton text-center" pButton
                  [disabled]="!searchText" (click)="filterTableData('search')"> Cerca
                </button>
              </span>
              <ng-template #resetSearch>
                <span>
                  <button class="searchButton flex justify-content-center" pButton><i class="fa fa-refresh "
                      style="font-size:14pt;" (click)="searchText='';filterTableData('search'); searchMode=false"></i>
                  </button>
                </span>
              </ng-template>
              <!-- <button></button> -->
            </span>
          </div>
        </div>
        <ng-template #attachment>
          <div class="flex w-full flex-row  justify-content-between">
            <div class="flex">
              <label class="title">Allegati</label>
            </div>
            <div class="inline flex flex-row ">
              <div class="flex flex-row " *ngIf="canFilter == true">
                <div class="flex">
                  <button #button1 pButton pRipple type="button" [ngClass]="attachmentFilter ==  'all'? 'seeAll' : ''"
                    class="customButton p-fontMedium border-noround-right" (click)="attachmentFiltered('all')">Mostra
                    tutti
                  </button>
                </div>
                <div class="flex" *ngIf="!isAdmin">
                  <button #button2 pButton pRipple type="button" class="customButton border-noround"
                    [ngClass]="attachmentFilter ==  'admin'? 'seeAll' : ''"
                    (click)="attachmentFiltered('admin')">Caricati
                    da Compliance
                  </button>
                </div>
                <div class="flex">
                  <button #button3 pButton pRipple type="button" class=" customButton border-noround-left"
                    [ngClass]="attachmentFilter == 'mine'? 'seeAll' : ''" (click)="attachmentFiltered('mine')">Caricati
                    da me
                  </button>
                </div>
              </div>
              <div class="flex" *ngIf="canAddAttachment">
                <button pButton pRipple type="button" class="add_attachment flex justify-content-end"
                  (click)="showDialog();canRemove=true"><i class="fa-solid fa-circle-plus mr-2"></i>Aggiungi
                  allegato
                </button>
              </div>
            </div>
          </div>
          <p-sidebar #ap [(visible)]="display" [fullScreen]="true" [baseZIndex]="10000" [showCloseIcon]="false"
            [style]="{'background-color': 'transparent', 'justify-content':'center', 'aligin-items':'center', 'display':'flex','margin-top':'5em'}"
            [blockScroll]="true" [modal]="true">
            <app-add-attachment [canRemove]="canRemove" [currentUserId]="currentUserID"
              (deleteFile)="deleteFileFromDb($event)" (editAttachment)="editAttachment($event)"
              [attachmentIndex]="attachmentIndex" [attachmentForm]="attachmentToModify"
              (addAttachment)="addAttachment($event)" (closeAttachment)="ap.close()"></app-add-attachment>
          </p-sidebar>
        </ng-template>
      </div>
    </ng-template>

    <ng-template pTemplate="header" let-columns>
      <tr>

        <th *ngIf="isAllegato && !isNotify" class="w-4rem"></th>
        <th *ngIf="makeFaq" class="w-4rem"></th>
        <th *ngIf="isAllegato && !isDisabled" class="w-4rem"></th>
        <th *ngIf="isAllegato && !isDisabled" class="w-4rem "></th>
        <th *ngIf="!isAllegato && !isNotify" class="w-4rem"></th>
        <th *ngIf="isNotify" class="w-4rem"></th>
        <th *ngIf="isNotify" class="w-4rem"></th>

        <th pSortableColumn={{col}} pResizableColumn *ngFor="let col of columns">
          <div [class]="col == 'last_update' || col == 'description' ? '':''" *ngIf="col !== 'comments';else comments"
            [ngClass]="col == 'scope' || col == 'perimeter'
            || col == 'description' || col == 'structure_request' || col == 'uploaded_by'
            || col == 'object_name' || col == 'document_title' ? 'text-left resize' : 'text-center'">
            {{col =="description" && isAllegato ? "DESCRIZIONE" : col == 'id' ? 'ID FINDING' :
            tableITColumn[col].toUpperCase()}}
            <p-sortIcon
              *ngIf="col !== 'privacy_checkbox' && col !== 'legal_checkbox' && col !== 'expired' && col !== 'document_title' && col !== 'object_name' && col !== 'uploaded_by' && col !== 'description' ">field={{col}}</p-sortIcon>
            <p-sortIcon *ngIf="isAllegato == false && col == 'description' ">field={{col}}</p-sortIcon>
          </div>
        </th>

      </tr>
    </ng-template>
    <ng-template class="p-tablecustom" pTemplate="body" let-rowData let-rowIndex="rowIndex" let-columns="columns"
      *ngIf=" tableDataToShow.length !== 0 ; else empty ">
      <tr
        *ngIf="(rowData['status']!== undefined && rowData?.['status']!=='') || (rowData?.['description']!=='' &&rowData?.['description']!==undefined )  ;else empty">
        <td class="customButton" *ngIf="isAllegato" (click)="downloadFile(rowData.id, rowData.document_title)">
          <a class="link">Download</a>
        </td>
        <td class="customButton" [ngClass]="currentUserID!==rowData['uploaded_by'] ? 'disabled':'' "
          *ngIf="isAllegato && !isDisabled " (click)="showDialog(rowIndex);canRemove=false"><a
            [ngClass]="currentUserID!==rowData['uploaded_by'] ? 'disabled':'link' ">Modifica</a>
        </td>
        <td class="customButton" *ngIf="isAllegato && !isDisabled"
          [ngClass]="currentUserID!==rowData['uploaded_by'] ? 'disabled':'' "
          (click)="rmAttachment((rowData['id']?rowData['id']:rowIndex))">
          <a [ngClass]="currentUserID!==rowData['uploaded_by'] ? 'disabled':'link' ">Cancella</a>
        </td>
        <td class="customButton" *ngIf="!isAllegato && !isNotify" (click)="OpenRequest(rowData.id)"
          [routerLink]="['./', rowData.id]">
          <a class="link">Apri</a>
        </td>
        <td [ngClass]="rowData.status== 'COMPLETED'? 'customButton' : 'customButtonDisabled'" *ngIf="makeFaq"
          (click)="makingFaq(rowData.id)"><a [ngClass]="rowData.status== 'COMPLETED'? 'link' : 'disabled'">Crea FAQ</a>
        </td>

        <td *ngFor="let col of columns" class="tableCell" style="width: auto; max-width: 15%">
          <div *ngIf="!isLoading">
            <div style="white-space:nowrap; text-overflow: ellipsis;overflow: hidden;"
              [ngClass]="col == 'status' ? getColorClass(rowData[col]) : ''">
              <div class="flex flex-column justify-content-center "
                *ngIf="col !== 'comments' && col !== 'expired' && col !== 'legal_checkbox' && col!== 'privacy_checkbox' && col !== 'requested_legal' && col!== 'requested_privacy'; else badge">
                <div *ngIf="col !== 'status'; else status">
                  <div class="capitalize" style="width: 200px;text-overflow: ellipsis;overflow: hidden;" *ngIf="col!=='id' && col !== 'description';else titleSubtitle"
                    [ngClass]="col == 'scope' || col == 'id' || col == 'perimeter' || col == 'description'
                    || col == 'structure_request' || col == 'uploaded_by' || col == 'object_name'
                    || col == 'document_title' || col == 'user_assigned' ? 'text-left' : 'text-center'">
                      {{
                        (col == 'due_date' || col == 'due_date_planned' || col == 'creation_date' || col =='last_update'|| col =='expiry_date' )?
                        formatDate(rowData[col])
                        :
                        col == 'uploaded_by'?
                        rowData['username']
                        :
                        col == 'user_assigned' ?
                        rowData?.['fullname_assigned']
                        :
                        rowData[col]
                        }}
                  </div>
                </div>
                <div *ngIf="col == 'user_assigned'" class="justify-content-baseline  font-bold" >
                    {{rowData.email_assigned}}
                </div>
                <ng-template #titleSubtitle>
                  <div class="text-left flex flex-column">
                    <div *ngIf="!isAllegato">
                      <b>{{isFinding ? ('Finding #'+rowData[col]) : getRequestTitle(rowData.id)}}</b>
                    </div>
                    <div [style]="{'word-wrap': 'break-word', 'max-width': columnWidth+'px'}"
                      style="text-overflow: ellipsis;overflow: hidden;">
                      {{isFinding ? rowData['risk_id'] : rowData[col]}}
                    </div>
                  </div>
                </ng-template>
                <ng-template #status>
                  <div>
                    {{rowData['status'] | statuspipe:isAdmin:isFinding:isFaq}}
                  </div>
                </ng-template>
              </div>
              <ng-template #badge>
                <div class="flex justify-content-center w-full text-center">
                  <div *ngIf="col == 'comments'" class="flex align-items-center text-center justify-content-center"
                    [ngClass]="messageCheck(rowData) == 'No' ? 'badgedecline':'badgeactive' ">
                    <i class="fa fa-circle mr-2" style="font-size: 6pt;"></i>
                    {{ messageCheck(rowData)}}
                  </div>
                  <div *ngIf="col == 'expired'" class="flex align-items-center w-full text-center justify-content-center"
                    [ngClass]=" rowData.expired == true ?  'badgedecline':'badgeactive' ">
                    <i class="fa fa-clock mr-2" style="font-size: 6pt;"></i>
                    {{rowData.expired }}
                  </div>
                  <div *ngIf="col !== 'expired' && col !== 'comments'" class="flex align-items-center text-center justify-content-center"
                    [ngClass]=" rowData[col] == true ?  ' badgeactive':'badgedecline' ">
                    <i class="fa fa-circle mr-2" style="font-size: 6pt;"></i>
                    {{rowData[col] ==true? "Si":"No"}}
                  </div>
                </div>
              </ng-template>
            </div>
          </div>
          <div *ngIf="isLoading" class="skeleton ">
            <p-skeleton height="100%" styleClass=""></p-skeleton>
          </div>
        </td>
      </tr>
    </ng-template>

    <ng-template pTemplate=" emptymessage" #empty>
      <tr class="empty">
        <td class="text-center h-full" colspan="100%">
          <p class="absolute" style="top: 50%; left: 43vw;" [ngClass]="isFaq == false && isAllegato == false && isFinding == false ? 'padding':' '">
            {{isAllegato? "Non ci sono allegati": isFaq? "Non ci sono FAQ" : isFinding? "Non ci sono finding" :"Non ci
            sono richieste."}}
          </p>
        </td>
      </tr>
    </ng-template>
  </p-table>
</div>
<ng-template #comments>
  <div class="titoli text-center"><i class="fa-solid fa-comment-dots"></i></div>
</ng-template>
