<div class="w-full flex flex-column">
  <app-flow></app-flow>
  <app-header-tab [title]="titleForm"></app-header-tab>
  <form [formGroup]="formValue">
    <app-form-new-request formControlName="newRequest" [invalidBoolean]="invalidBoolean"></app-form-new-request>
  </form>
  <app-dynamic-table [currentUserID]="currentUserId" [canFilter]="false" (editAttachment)="editAttachment($event)"
    (attachmentAdded)="addAttachment($event)" [tableColumn]="tableColumn" (removeAttachment)="removeAttachment($event)"
    [tableData]="attachmentData" [canAddAttachment]="canAddAttachment" [isAllegato]="true" [isDisabled]="isDisabled"
    [size]="size"></app-dynamic-table>
  <!-- inserire riga 23,24 in tutti gli allegati sdove stanno i tre button filtri -->
</div>

<div class="flex justify-content-end" style=" margin: 0 0.5%;  padding: 0.5%;">
  <div *ngIf="titleForm=='Ecco la tua richiesta'">
    <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']" [disabled]="loading">Annulla
    </button>
    <button pButton pRipple type="submit" class="save p-fontMedium m-2" [disabled]="loading" (click)="save()">Salva e
      Chiudi
    </button>
    <button pButton pRipple type="button" class="send p-fontMedium m-2 mr-0" [disabled]="loading"
      (click)="sendRequest()">Conferma e Invia
    </button>
  </div>
  <div *ngIf="titleForm=='Crea nuova richiesta'">
    <button pButton pRipple type="button" class="return p-fontMedium m-2" [routerLink]="['../']" [disabled]="loading">Annulla
    </button>
    <button pButton pRipple type="submit" class="send p-fontMedium m-2 mr-0" (click)="sendToCompliance()">Conferma
    </button>
  </div>
</div>
