import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription, map } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';

@Component({
  selector: 'app-fullnotify',
  templateUrl: './fullnotify.component.html',
  styleUrls: ['./fullnotify.component.scss']
})
export class FullnotifyComponent {
  notifies: any[] = [];
  subscription: Subscription
  constructor(private http: HttpClient, private router: Router) { }

  ngOnInit() {

    this.subscription = this.http.get(`${environment.apiUrl}/notifications/`).pipe(
      map((result: any[]) => {
        this.notifies = [...result]
      })).subscribe()
  }

  openRequest(id,flag) {
    if(flag == "REQUEST"){
      this.router.navigateByUrl(ROUTES_STRINGS.COMPLIANCE_OPINION).then(() => {
        this.router.navigate(["../", ROUTES_STRINGS.COMPLIANCE_OPINION, ROUTES_STRINGS.REQUEST, id])
      })
    }else{
      this.router.navigateByUrl(ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN).then(() => {
        this.router.navigate(["../", ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN, ROUTES_STRINGS.FINDING_ASSIGNED, id])
      })
    }
  }
  

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe()
    }
  }
}
