
<div class="p-3  flex layout justify-content-stretch flex-column">
    <div class="border bg-white">
      <div class="flex flex-column w-full  p-3 pb-0 justify-content-between ">
        <h1 class="title">Crea Gruppo</h1>
      </div>

      <app-dynamic-list 
      [itemSize]="50" 
      [values]="listDropdown" 
      [canAddItems]="true"
      (apiAddDropDown)="addItem($event)" 
      (apiRemoveDropDown)="removeItem($event)"
      [lazy]="false" 
      [canRemoveGroup]="true" 
      (loadEvent)="loadEvent($event)"
     

      />

    </div>
  </div>