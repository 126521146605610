import { Component } from '@angular/core';
import { RequestActions, setRequests } from 'src/app/utils/store/request/request.action';
import { Store } from '@ngrx/store';

@Component({
  selector: 'app-requests',
  templateUrl: './requests.component.html',
  styleUrls: ['./requests.component.scss']
})
export class RequestsComponent {


}
