import { Component, inject, Input } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { LazyLoadEvent } from 'primeng/api';
import { debounceTime, distinctUntilChanged, first, forkJoin, map, merge, take } from 'rxjs';
import { User } from 'src/app/data/models/User';
import { UserService } from 'src/app/utils/services/user.service';
import { AdminActions, getRoles } from 'src/app/utils/store/admin/admin.actions';
import { selectBooleanButton, selectRoles, selectUserAndRolesList, selectUserList } from 'src/app/utils/store/admin/admin.selector';
import { UserActions } from 'src/app/utils/store/user/user.actions';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-manage-user',
  templateUrl: './manage-user.component.html',
  styleUrls: ['./manage-user.component.scss']
})
export class ManageUserComponent {
  users: User[] = []
  userService = inject(UserService)
  rows: number = 0;
  virtualItems: any[] = [];
  usersCount: number
  url: string = "/admin-dash/user-manager/user"
  prevFirst: number = 0;
  prevLast: number = 15;
  creating: boolean
  itemSize: string;
  roles: string[] = []
  dropDownOptions: any;
  currentDropDownValue: string;
  isLoading: boolean = false;
  selectedUserOptions: string = '';
  activeDropDownOptions: any;
  searchParams: string | undefined;
  seeAll: boolean = true
  DialogEdit: boolean = false;
  userToShow: User;
  selectedRole: string;
  selectedGroup: string;
  selectedEnable: boolean
  userId: string;
  @Input() editUser: [];
  loading: boolean = false;
  dropdownOptionManageUser: any[];
  prova: any
  booleanGroupPatch: boolean = false



  constructor(private store: Store, private activatedRoute: ActivatedRoute, private router: Router, private http: HttpClient) {
    this.store.dispatch({ type: AdminActions.getRoles })
    this.setUserList(this.prevFirst, this.prevLast)

    this.dropdownOptionManageUser = [
      'Ruolo',
      'Gruppo',
      'Abilitazione'
    ];

  }



  ngOnInit() {
    this.activatedRoute.queryParams.subscribe(param => {
      this.selectedUserOptions = param['id']
      console.log("parametro", this.selectedUserOptions)
    })
    this.prova = this.selectedUserOptions
    console.log(this.prova)
    console.log(this.activatedRoute)
    this.store.select(selectBooleanButton).pipe(map(value => this.loading = value)).subscribe()


    this.store.select(selectUserAndRolesList)
      .subscribe(
        result => {
          this.roles = [...result.roles]
          const usersMapped = result['userList'].users.map(element => {
            return { ...element, roles: element.roles[0] }
          });
          const userList = { ...result['userList'], users: usersMapped }
          this.dropDownOptions = { ...this.dropDownOptions, roles: this.roles, }
          //console.log("risultati ricevuti da map",result,this.dropDownOptions)

          if (userList.users?.length !== 0) {

            this.users = [...userList.users]

            if (this.virtualItems.length == 0 || this.usersCount !== userList.count) {
              this.usersCount = userList.count
              //console.log("Creo Virtual array ...");
              this.virtualItems = Array.from({ length: this.usersCount + 3 })
            } else {
              console.log("virtual array esiste");
              //console.log("Loading items...", this.virtualItems,this.rows)
              let loadedProducts = [...userList.users];
              Array.prototype.splice.apply(this.virtualItems, [...[this.prevFirst, this.rows], ...loadedProducts]);
            }
          }
          this.isLoading = result.isLoadinList
          return result
        }
      )
  }

  setUserList(start: number, end: number, search?: string | undefined): void {

    this.store.dispatch({ type: AdminActions.setUserList, start: start, end: end, filters: search })
  }


  loadUsers(event) {
    if (event.first != 0 || event.last != 0) {
      this.prevFirst = event.first;
      this.prevLast = event.last > this.usersCount ? this.usersCount : event.last;
      this.rows = event.rows;
      if (this.searchParams !== event.search) {
        this.searchParams = event.search
        this.virtualItems = []
      }
      console.log("mando questi dati alla funzione setUserList  -> ", event)
      this.setUserList(this.prevFirst, this.prevLast, event?.search)
    }
  }

  changeDropDownTitle(event) {

    console.log("CHANGE EMITTED", event);
    // if (event == 'users' || !event) {
    //   this.seeAll = true
    // } else {
    //   this.seeAllfhandleSelectedGroupChange = false
    // }



    this.selectedUserOptions = event
    console.log(this.selectedUserOptions)
    //this.dropDownOptions={...this.dropDownOptions, groups : ['GRUPPO 1','GRUPPO2 ','GRUPPO3']}
    //this.activeDropDownOptions = this.dropDownOptions['groups']
    this.router.navigate([this.url, event], { queryParams: { id: event } })

    // this.router.navigate([this.url], { queryParams: { id: event } })
  }

  showDialogEdit(event) {
    this.userToShow = event
    this.DialogEdit = true;
    console.log(this.userToShow)
  }


  onDropdownSelected(value: string) {
    this.selectedRole = value;
  }

  handleSelectedRoleChange(selectedRole: string) {
    this.selectedRole = selectedRole
  }

  handleSelectedEnableChange(selectedEnable: boolean) {
    this.selectedEnable = selectedEnable
  }

  handleSelectedGroupChange(selectedGroup: string) {
    this.selectedGroup = selectedGroup
    console.log(this.selectedGroup)
  }


  setChange() {
    let patchrole: any;
    let patchenable: any;
    let patchgroup: any;

    if (this.selectedUserOptions == 'Ruolo') {
      if (this.selectedRole == undefined) {
        patchrole = this.userToShow.roles
      } else {
        patchrole = this.selectedRole
      }
    } else if (this.selectedUserOptions == 'Gruppo') {
      if (this.selectedGroup == undefined) {
        patchgroup = this.userToShow.group
      } else {
        patchgroup = this.selectedGroup
      }
    } else if (this.selectedUserOptions == 'Abilitazione') {
      if (this.selectedEnable == undefined) {
        patchenable = this.userToShow.enabled
      } else {
        patchenable = this.selectedEnable
      }
    }

    // if (this.selectedEnable == undefined) {
    //   patchenable = this.userToShow.enabled
    // } else {
    //   patchenable = this.selectedEnable

    // }

    if (this.selectedUserOptions == 'Ruolo') {

      this.store.dispatch({ type: AdminActions.patchUser, role: patchrole, id: this.userToShow.id, first: this.prevFirst, last: this.prevLast })
    } else if (this.selectedUserOptions == 'Gruppo') {
      if (this.userToShow['groups'].length == 0) {
        this.booleanGroupPatch = false
        console.log("gruppo 0")
      } else {
        this.booleanGroupPatch = true
        console.log("gruppo 1")

      }
      this.store.dispatch({ type: AdminActions.patchUserGroup, booleangroup: this.booleanGroupPatch,oldgroup:this.userToShow['groups'], group: patchgroup, id: this.userToShow.id, first: this.prevFirst, last: this.prevLast })
      // this.store.dispatch({ type: AdminActions.patchUserGroup, group: patchgroup, id: this.userToShow.id, first: this.prevFirst, last: this.prevLast })

    } else if (this.selectedUserOptions == 'Abilitazione') {

      this.store.dispatch({ type: AdminActions.patchUserEnable, enable: patchenable, id: this.userToShow.id, first: this.prevFirst, last: this.prevLast })
    }




    this.DialogEdit = false
    // this.userToShow = { ...this.userToShow, roles: role, enabled: enable }
    // console.log(this.userToShow)
    // this.http.patch(environment.apiUrl+'/admin-panel/user/${this.userToSh1ow}`/role/',
    // this.http.patch(`${environment.apiUrl}/admin-panel/user/${this.userToShow.id}/change_role_by_user_id_disable/`, 
    // {
    //   role: [this.selectedRole]
    // }
    // ).subscribe()
  }




  //  api/admin-panel/user/<str:id>/change_role_by_user_id_disable/

  // }

  //  disableUser(){
  //   this.http.patch(environment.apiUrl+'/admin-panel/user/1120bfaa-e958-49cb-a2ed-7b6ffdb65832/',
  //   {
  //     enabled: false
  //   }
  //   ).subscribe()
  //  }

}
