import { createFeatureSelector, createSelector } from "@ngrx/store";
import { AdminState } from "./admin.state";

export const selectAdminState = createFeatureSelector<AdminState>('admin');


export const selectRoles = createSelector(
  selectAdminState,
  (state: AdminState) => state.roles
);

export const selectError = createSelector(
  selectAdminState,
  (state: AdminState) => state.error
);

export const selectBooleanButton = createSelector(
  selectAdminState,
  (state: AdminState) => state.booleanbutton
);

export const selectUserList = createSelector(
  selectAdminState,
  (state: AdminState) => state.users_paginated
);


export const selectUserListLoading = createSelector(
  selectAdminState,
  (state: AdminState) => state.loading_users
);
export const selectUserAndRolesList = createSelector(
  selectUserListLoading,
  selectUserList,
  selectRoles,
  (isLoadinList, userList, roles) => ({ isLoadinList,userList, roles })
);

export const selectScopes = createSelector(
  selectAdminState,
  (state: AdminState) => state.scopes
);

export const selectGroup = createSelector(
  selectAdminState,
  (state: AdminState) => state.group
);

// export const selectGroupById = createSelector(
//   selectAdminState,
//   (state: AdminState, id: string) =>
//     state.users_paginated.users.filter(item => item.id === id)
// );

export const selectGroupById = createSelector(
  selectAdminState,
  (state: AdminState, id: string) =>
    state.users_paginated.users.filter(item => item.id.includes(id))
);



export const selectPerimeters = createSelector(
  selectAdminState,
  (state: AdminState) => state.perimeters
);

export const selectResidualRisks = createSelector(
  selectAdminState,
  (state: AdminState) => state.residualRisks
);

export const selectPriority = createSelector(
  selectAdminState,
  (state: AdminState) => state.priority
);

export const selectInterventionType = createSelector(
  selectAdminState,
  (state: AdminState) => state.intervention_type
);
