import { Component, EventEmitter, Input, Output } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Store } from '@ngrx/store';
import { selectCurrentRequestID, selectCurrentRequestStatus } from 'src/app/utils/store/request/request.selector';
import { selectCurrentUserID, selectCurrentUserIDandUsername, selectCurrentUsername } from 'src/app/utils/store/user/user.selector';
import { selectCommentIdUser } from 'src/app/utils/store/request/request.selector';
import { selectComments } from 'src/app/utils/store/request/request.selector';
import { Observable, Subscription, concatMap, distinct, map, merge, pipe, share, switchMap, take, timeout } from 'rxjs';
import { Comment } from 'src/app/data/models/Comment';
import { RequestActions } from 'src/app/utils/store/request/request.action';
import { DatePipe } from '@angular/common';
import { Status } from 'src/app/data/models/Request';
import { OnInit } from '@angular/core';
import { ViewChild, ElementRef } from '@angular/core';
import { selectCommentsFinding, selectCurrentFindingID } from 'src/app/utils/store/finding/finding.selector';
import { FindingActions } from 'src/app/utils/store/finding/finding.action';
import { CommentAction } from 'src/app/utils/store/comment/comment.action';



@Component({
  selector: 'app-chat',
  templateUrl: './chat.component.html',
  styleUrls: ['./chat.component.scss']
})
export class ChatComponent implements OnInit {


  visibleSidebar5;
  display: boolean = true;
  @Output() closeChat = new EventEmitter<any>()
  @ViewChild('chatContainer', { static: true }) chatContainer: ElementRef;
  @Input() stato: string;

  comment: Comment;
  idUserComment: number[] = [];
  userId: number;
  username: string;
  // user: { userId: number, username: string };
  idRequest: number = 0;
  message: string = "";
  comments: Comment[] = []
  @Input() isFinding:boolean;

  obs$ = new Observable()
  disabledButton: boolean = false;
  subscription:Subscription
  commentSub:Observable<Comment[]>;
  requestcomments$ = this.store.select(selectCurrentRequestID).pipe(
    distinct(),
    switchMap((payload) => {
      this.idRequest = payload
      this.store.dispatch({
        type: RequestActions.setComments,
        requestID: payload
      });
      return this.store.select(selectComments).pipe(
        share(),
        map((comments:Comment[]) => {
          console.log(comments)
          if(comments.length > 0 ){
            const unseenComment = (comments.filter(comment => comment.seen == false)).filter(comment=>comment.user !== this.userId)
            console.log(unseenComment)
            if(unseenComment.length > 0){
              this.store.dispatch({type:CommentAction.seeComment, payload: unseenComment })
            } 
          }
          setTimeout(()=>{
            this.scrollToBottom()
          },1)
          return comments
        })
      )
    })
  )

  findingcomments$ = this.store.select(selectCurrentFindingID).pipe(
    share(),
    concatMap((payload) => {
      this.idRequest = payload
      this.store.dispatch({
        type: FindingActions.setComments,
        findingID: payload
      });
            return this.store.select(selectCommentsFinding).pipe(
        share(),
        map((comments:Comment[]) => {
          console.log(comments)
          if(comments.length > 0 ){
            const unseenComment = (comments.filter(comment => comment.seen == false)).filter(comment=>comment.user !== this.userId)
            console.log(unseenComment)
            if(unseenComment.length > 0){
              this.store.dispatch({type:CommentAction.seeComment, payload: unseenComment })
            } 
          }
          setTimeout(()=>{
            this.scrollToBottom()
          },1)
          return comments
        })
      )

      })
  )

  constructor(private http: HttpClient, private store: Store, private datePipe: DatePipe) {
  }

  ngOnInit(): void {
    if(!this.isFinding){
      this.commentSub = this.requestcomments$.pipe(
        map(comments=>{
          this.comments = [...comments]
          this.comments = this.orderCommentById();
          return this.comments
        })
      )
    }else{
      this.commentSub = this.findingcomments$.pipe(
        map(comments=>{
          this.comments = [...comments]
          this.comments = this.orderCommentById();
          return this.comments
        })
      )
    }


    this.subscription = merge(
      this.store.select(selectCurrentUserID).pipe(
        take(1),
        map(user => {
          this.userId = user;
        })
      ),
      this.store.select(selectCurrentUsername).pipe(
        take(1),
        map(user => {
          this.username = user;
        })
      ),
    ).subscribe()

    if(!this.isFinding){
      if (this.stato == 'COMPLETED' || this.stato== 'REFUSED') {
        this.disabledButton = true;
      }
    }else{
      if (this.stato == 'CLOSED' ) {
        this.disabledButton = true;
      }
    }

    // if (this.stato == 'COMPLETED' || this.stato== 'REFUSED') {
    //   this.disabledButton = true;
    // }
  }

  orderCommentById(){
    return this.comments.sort(function (a, b) {
      if (a.id > b.id)
        return 1;
      if (a.id < b.id)
        return -1;
      // a must be equal to b
      return 0;
    })
  }

  close() {
    this.closeChat.emit()
  }

  addComment(message) {
    if (message.length < 255) {
      this.comment = {
        message: this.message,
        user: this.userId,
        username: this.username,
      }


      if(!this.isFinding){
        this.store.dispatch({ type: RequestActions.addComment, payload: { comment: this.comment, id: this.idRequest } })
      }else{
        this.store.dispatch({ type: FindingActions.addComment, payload: { comment: this.comment, id: this.idRequest } })

      }


      this.message = ""
    }

  }

  formatDate(date) {
    return this.datePipe.transform(new Date(date), 'dd-MM-yyyy hh:mm');
  }

  scrollToBottom() {
    this.chatContainer.nativeElement.scrollTop = this.chatContainer.nativeElement.scrollHeight;
  }

  ngOnDestroy(){
    if(this.subscription){
      this.subscription.unsubscribe()
    }
    // if(this.commentSub){
    //   this.commentSub.unsubscribe()
    // }
  }
}
