import { Injectable } from '@angular/core';
import { HttpClient, HttpResponse } from '@angular/common/http';
import { DbFinding } from 'src/app/data/models/Finding';
import { KeycloakService } from 'keycloak-angular';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';


@Injectable({
    providedIn: 'root'
})
export class InitService {

    constructor(private http: HttpClient, private keycloakService: KeycloakService, private store: Store) { }

    initLookups() {
        return this.http.get(`${environment.apiUrl}/lookups/`, {
            headers: {
                'Authorization': `Bearer ${this.keycloakService.getToken()}`,
                'Content-Type': 'application/json'
            }
        })
    }

    getAmbiti() {
        return this.http.get(`${environment.apiUrl}/getscope/`, {
            headers: {
                'Authorization': `Bearer ${this.keycloakService.getToken()}`,
                'Content-Type': 'application/json'
            }
        })
    }

    getResidualRisk() {
        return this.http.get<any>(`${environment.apiUrl}/residualrisk/` ,{
            headers: {
                'Authorization': `Bearer ${this.keycloakService.getToken()}`,
                'Content-Type': 'application/json'
            }
        })
    }

    getPriority() {
        return this.http.get<any>(`${environment.apiUrl}/priority/`, {
            headers: {
                'Authorization': `Bearer ${this.keycloakService.getToken()}`,
                'Content-Type': 'application/json'
            }
        })
    }

    getInterventionType() {
        return this.http.get<any>(`${environment.apiUrl}/interventiontype/`, {
            headers: {
                'Authorization': `Bearer ${this.keycloakService.getToken()}`,
                'Content-Type': 'application/json'
            }
        })
    }
}
