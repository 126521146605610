import { Attachment } from "./Attachment";
import { Comment } from 'src/app/data/models/Comment';


export class DbRequest {
  request_id?: number;
  id?: number;
  request_title?: string;
  priority?: string;
  due_date?: string;
  reason?: string;
  scope?: string;
  perimeter?: string;
  structure_request?: string;
  description?: string | { title: string, description: string };
  status?: string;
  creation_date?: string;
  last_update?: string;
  requested_user?: number;
  document_attached?: number[] | Attachment[];
  is_faq?: boolean;
  comments?: Comment[] | number[];
  trackings?: TrackingRequest[];
  expired?: boolean
  compliance_answer?: string;
  legal_answer?: string;
  privacy_answer?: string;
  note?: string;
  legal_checkbox?: boolean;
  privacy_checkbox?: boolean;
  requested_privacy?: boolean;
  requested_legal?: boolean;

}


export class TrackingRequest {
  id?: number | string;
  id_track?: number;
  status?: string;
  result?: string;
  date_update?: string;
  user?: number
  flagTimeLine?: boolean
  username?: string
}
export class TableRequest {
  richiesta: {
    titolo: string;
    descrizione: string;
  }
  priorita: string;
  due_date: string;
  ambito: string;
  perimetro: string;
  struttura_richiedente: string;
  status: string;
  creation_date?: string;
  last_update?: string;
  requested_user?: number;
  document_attached?: number[] | Attachment[];
  is_faq?: boolean;
}

export const defAmbito = [
  'Trasparenza',
  'Tutela dei fondi',
  'Antitrust',
  'Conflitti d interesse e parti collegate',
  'Politiche retributive e incentivanti',
  'Conflitti d interesse e parti correlate',
  'Altro'
];

export const defPriorita = [
  'Alta',
  'Media',
  'Bassa'
];

export enum Priority {
  HIGH = 'Alta',
  MEDIUM = 'Media',
  LOW = 'Bassa'
}

export const defStato = [
  'COMPLETED',
  'NEW',
  'DRAFT',
  'REFUSED',
  'IN_CHARGE',
];

export const defStatoFaq = [
  'PUBLISHED',
  'DRAFT'
]

export const defStatoComp = [
  'COMPLETED',
  'DRAFT',
  'REFUSED',

];
export enum Status {
  COMPLETED = 'COMPLETED',
  NEW = 'NEW',
  EXPIRED = 'EXPIRED',
  DRAFT = 'DRAFT',
  SENT = 'SENT',
  IN_CHARGE = 'IN_CHARGE',
  REFUSED = 'REFUSED',
  TO_CHECK = "TO_CHECK"
}

export enum ITStatus {
  COMPLETED = 'Completato',
  NEW = 'Nuovo',
  DRAFT = 'Bozza',
  SENT = 'Inviato',
  IN_CHARGE = 'In carico',
  REFUSED = 'Rifiutato',
  TO_CHECK = "Verifica Compliance",
  CLOSED = "Chiuso",
  ExpiredTrue = 'Scaduta',
  ExpiredFalse = 'Non scaduta',
  true = "Si",
  false= "No",
  PUBLISHED = 'Pubblicato'
}
