import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Router } from '@angular/router';
import { Message, PulsarService } from 'src/app/utils/services/pulsar.service';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module'

@Component({
  selector: 'app-notify',
  templateUrl: './notify.component.html',
  styleUrls: ['./notify.component.scss']
})
export class NotifyComponent {
  @Input() notifies: any[];
  @Output() removeNotification: EventEmitter<any> = new EventEmitter<any>()
  variabileCliccata: string;
  message: Message;
  constructor(private pulsarService: PulsarService, private router: Router) { }

  ackMessage(messageId) {
    this.message = {
      messageId: messageId,
    }
    this.removeNotification.emit(messageId)
    this.pulsarService.messages.next(this.message)
  }
  
  openRequest(id,flag) {

    if(flag == "request"){
      this.router.navigateByUrl(ROUTES_STRINGS.COMPLIANCE_OPINION).then(() => {
        this.router.navigate(["../", ROUTES_STRINGS.COMPLIANCE_OPINION, ROUTES_STRINGS.REQUEST, id])
      })
    }else{
      this.router.navigateByUrl(ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN).then(() => {
        this.router.navigate(["../", ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN, ROUTES_STRINGS.FINDING_ASSIGNED, id])
      })
    }
  }
  
  clear() {
    this.notifies.forEach(notify => this.ackMessage(notify.messageId))
    this.removeNotification.emit(" ")
  }

  onItemClick(event: MouseEvent, index: number) {

    this.notifies.splice(index, 1);
  }



}
