import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { CommentAction } from './comment.action';
import { concatMap, map, switchMap } from 'rxjs';
import { CommentService } from '../../services/comment.service';
import { HttpResponse } from '@angular/common/http';

@Injectable()
export class CommentEffects {

    seeComment$= createEffect(()=>{
        return this.actions$.pipe(
            ofType(CommentAction.seeComment), 
            switchMap((action:CommentAction.seeComment) =>{
                console.log(action['payload'])
                return this.commentService.seeComment(action['payload'][0]['id']).pipe(
                        map(res => {
                            return action['payload'].slice(1).length > 0 ?
                            {type:CommentAction.seeComment, payload: action['payload'].slice(1)}
                            :
                            { type: CommentAction.seeCommentSuccess}
                        })
                    )
                    
            })
        )
    })

    constructor(private actions$: Actions,private commentService:CommentService){}

}