import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class AdminGuard implements CanActivate {
  constructor(private keycloakService: KeycloakService, private router: Router) { }

  canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): boolean | UrlTree {
    // Verifica se l'utente ha il ruolo di amministratore
    const isAdmin = this.keycloakService.getUserRoles().includes('mooney_admin');

    // Se l'utente è un amministratore, reindirizza alla pagina principale o a un'altra pagina di tua scelta
    if (isAdmin) {
      // Reindirizza a una pagina principale o a un'altra pagina
      this.router.navigate(['admin-dash']);
    }

    // L'utente non è un amministratore e può accedere alla rotta
    return true;
  }
  
}
