import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
    providedIn: 'root'
})
export class CommentService{
    constructor(private http: HttpClient) { }

    seeComment(commentId: number){
        return this.http.patch(`${environment.apiUrl}/comment/${commentId}/`,{seen:true})
      }    
}