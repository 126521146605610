import { Pipe, PipeTransform } from '@angular/core';
import { ITStatus } from 'src/app/data/models/Request';

@Pipe({
  name: 'filterstatus'
})
export class FilterstatusPipe implements PipeTransform {

  arrayTemp: string[] = [];


  transform(value: string): string {
    // console.log("qui",value)
    // this.arrayTemp = [...value];
    let stringTemp: string = '';
    let status: string = '';

    if (Array.isArray(value)) {
      this.arrayTemp = [...value];
      this.arrayTemp.forEach((element, index) => {

        stringTemp += ITStatus[element] ? ITStatus[element] : element
        if (this.arrayTemp[index + 1] !== undefined) {
          stringTemp += ' , '
        }

      });
    } else {
      let i = value.indexOf(' e altri')
      if (i > 0) {
        status = value.slice(0, value.indexOf(' e altri'));
        stringTemp = ITStatus[status] ? ITStatus[status] + ' e altri' : value
      } else {
        status = value
        stringTemp = ITStatus[status] ? ITStatus[status] : value
      }
    }
    return stringTemp
  }
}
