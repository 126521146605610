import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DbFinding } from 'src/app/data/models/Finding';
import { environment } from 'src/environments/environment';
import { Store } from '@ngrx/store';
import { Attachment } from 'src/app/data/models/Attachment';
import { selectFindingDataLoading } from '../store/finding/finding.selector';
import { Filter, SortField } from 'src/app/data/models/Filter';


@Injectable({
  providedIn: 'root'
})
export class FindingService {
  private serviceData: any[] = [];
  private request: DbFinding;
  private finding: any;


  constructor(private http: HttpClient, private store: Store) { }

  //funzione della select loading
  getLoading() {
    return this.store.select(selectFindingDataLoading)
  }

  addNewFinding(newFinding: DbFinding) {
    return this.http.post(`${environment.apiUrl}/finding/`, newFinding, { observe: 'response' });
  }

  updateFinding(finding: DbFinding, id: number) {
    return this.http.put(`${environment.apiUrl}/finding/${id}/`, finding)
  }

  addNewAttachment(attachment: any, id: number) {
    let tmp = { ...attachment, finding_attached: id }
    const formData: FormData = new FormData();

    formData.append('document_title', tmp.document_title);
    formData.append('object_name', tmp.object_name);
    formData.append('description', tmp.description);
    formData.append('attachment', tmp.attachment, tmp.attachment.name);
    formData.append('uploaded_by', tmp.uploaded_by.toString());
    formData.append('finding_attached', tmp.finding_attached.toString());
    return this.http.post(`${environment.apiUrl}/document/`, formData);
  }

  addComment(findingId: number, comment: Comment) {
    return this.http.post(`${environment.apiUrl}/finding/${findingId}/comment/`, comment)
  }

  getFindings(page: number, pageSize: number, filters?: Filter, order?: SortField) {
    let filtersToApply = ""
    let orderToApply = ""
    page = page == undefined ? 1 : page;
    pageSize = pageSize == undefined ? 5 : pageSize
    if (filters) {
      let keys = Object.keys(filters);
      keys.forEach((key, i) => {
        if (key == 'due_date' || key == 'due_date_planned') {
          filtersToApply += `&${key}__gte=${filters[key][0]}&due_date__lte=${filters[key][1]}`
        } else if (key == "search" || key == "expired") {
          filtersToApply += "&" + key + "="
        } else {
          filtersToApply += "&" + key + "__in="
        }
        if (key !== "due_date" && key !== 'due_date_planned') {
          filters[key].forEach((filter, index) => {
            if (index > 0) {
              filtersToApply += ","
            }
            filtersToApply += filter;
          })
        }
      })
    }
    if (order?.['field'] !== undefined) {
      orderToApply = "&ordering=" + (order.sortOrder > 0 ? '' : '-') + (order.field == 'description' ? 'request_title' : order.field)
    }
    return this.http.get<DbFinding[]>(`${environment.apiUrl}/finding/?page=${page}&page_size=${pageSize}${filtersToApply}${orderToApply}`)
  }

  getFinding(id) {
    return this.http.get<DbFinding>(`${environment.apiUrl}/finding/${id}/`)
  }

  getAttachment() {
    return this.http.get<Attachment>(`${environment.apiUrl}/document/`)
  }

  updateFindingStatus(status, id, answer) {
    let data;
    if (status == "CLOSED"){
       data = {
        status: status,
        problem_encountered: answer['problem_encountered'],
        action_required: answer['action_required'],
        solution_description: answer['solution_description'],
        refused_reasons: answer['refused_reasons'],
        due_date_planned: answer['due_date_planned'],
        group_assigned: answer['group_assigned'],
        closed_date:answer['closed_date']
      }
  
      
      console.log(data)
    }else{
       data = {
        status: status,
        problem_encountered: answer['problem_encountered'],
        action_required: answer['action_required'],
        solution_description: answer['solution_description'],
        refused_reasons: answer['refused_reasons'],
        due_date_planned: answer['due_date_planned'],
        group_assigned: answer['group_assigned']
      }
      console.log(data)

    }

    return this.http.patch(`${environment.apiUrl}/finding/${id}/update-status/`, data)
  }


  updateAttachment(attachment) {
    const id = attachment['id']
    let att = {
      document_title: attachment['document_title'],
      object_name: attachment['object_name'],
      description: attachment['description']
    }
    return this.http.patch(`${environment.apiUrl}/document/${id}/`, att)
  }
}
