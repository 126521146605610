import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { FindingService } from '../../services/findings.service';
import { map, concatMap, switchMap, from, mergeMap, take, of } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { FindingActions, addNewFinding, addNewAttachmentSuccess, setFindings, setFindingsSuccess, updateAttachmentSuccess } from './finding.action';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { environment } from 'src/environments/environment';
import { selectCurrentIDandRole } from '../user/user.selector';
import { PulsarService } from '../../services/pulsar.service';
import { ROUTES_STRINGS } from 'src/app/modules/app-routing.module';

export enum Summary {
  COMPLETED = "Completato",
  IN_CHARGE = "Presa in carico",
  REFUSED = "Azione rifiutata!",
  TO_CHECK = "Approvazione",
  CLOSED = "Chiusura"
};

export enum Detail {
  COMPLETED = "Risoluzione approvata con successo.",
  IN_CHARGE = "Finding preso in carico",
  REFUSED = "Intervento rifiutato.",
  TO_CHECK = "Invio per approvazione",
  CLOSED = "Chiusura finding"
}


@Injectable()
export class FindingEffects {
  userId: number;
  filter: boolean;
  page: number;
  pageSize: number

  setFindings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.setFinding, FindingActions.addNewFindingSuccess, FindingActions.updateFindingSuccess, FindingActions.updateFindingStatusSuccess),
      concatMap((value) => {
        // return this.store.select(selectCurrentIDandRole).pipe(
        //   take(1),
        //   concatMap(userData => {
        //     this.userId = userData['role'] == "admin" ? -1 : userData['id']
        //     this.filter = userData['role'] == "admin" ? false : true
        return this.findingService.getFindings(value['page'], value['pageSize'], value['filters'], value['ordering']).pipe(
          map(data => {
            return setFindingsSuccess({ findings: data['results'], size: data['count'] })
          })
        )
      })

    );
  });


  addFindings$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.addNewFinding),
      concatMap(({ finding, attachments }) => {
        return this.findingService.addNewFinding(finding).pipe(
          map(data => {
            let array: any[] = attachments
            if (array.length > 0) {
              return {
                type: FindingActions.addNewAttachment,
                attachments: attachments,
                newFindingId: (data.body)['id']
              }
            } else {
              this.router.navigate([`../${ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN}`])
              this.messageService.add({ severity: 'success', summary: 'Finding creato con successo!' });
              return {
                type: FindingActions.addNewFindingSuccess
              }
            };
          }))
      }
      )
    )
  });

  updateFinding$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.updateFinding),
      concatMap(({ payload }) => {
        return this.findingService.updateFinding(payload['finding'], payload['id']).pipe(
          mergeMap(() => {
            const attachments: any[] = payload['attachments'];
            const updates = [];
            const adds = [];
            attachments.forEach(att => {
              if (att.id) {
                updates.push(att);
              } else {
                adds.push(att);
              }
            });
            const updateActions = updates.map(att => {
              return { type: FindingActions.updateAttachment, attachment: att };
            })
            const addActions = adds.map(att => {
              return { type: FindingActions.addNewAttachment, attachments: att, newFindingId: payload['id'] };
            })
            if (updateActions.length > 0 || addActions.length > 0) {

              return from([
                ...updateActions,
                ...addActions,
              ])
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN])
              return of({ type: FindingActions.updateFindingSuccess })
            }
          })
        )
      }
      )
    )
  });


  updateFindingStatus$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.updateFindingStatus),
      concatMap(({ payload }) => {
        return this.findingService.updateFindingStatus(payload['status'], payload['id'], payload['responses']).pipe(
          concatMap((data) => {
            const attachments: any[] = payload['attachments'];
            const updates = [];
            const adds = [];
            attachments.forEach(att => {
              if (att.id) {
                updates.push(att);
              } else {
                adds.push(att);
              }
            });
            const updateActions = updates.map(att => {
              return { type: FindingActions.updateAttachment, attachment: att };
            })
            const addActions = adds.map(att => {
              return { type: FindingActions.addNewAttachment, attachments: att, newFindingId: payload['id'] };
            })
            if (updateActions.length > 0 || addActions.length > 0) {
              return from([
                ...updateActions,
                ...addActions,
              ])
            } else {
              this.router.navigate([`../${ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN}`])
              let message = this.getMessageFromStatus(payload['status'])
              this.messageService.add(message);
              return of({ type: FindingActions.updateFindingStatusSuccess })
            }
          })
        )
      })
    )
  })


  addComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.addComment),
      concatMap(({ payload }) => {
        return this.findingService.addComment(payload['id'], payload['comment']).pipe(
          map(data => {
            return { type: FindingActions.addCommentSuccess, findingID: payload['id'] }
          })
        );
      })
    )
  });

  setComment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.addCommentSuccess, FindingActions.setComments),
      concatMap(({ findingID }) => {
        return this.http.get(`${environment.apiUrl}/finding/${findingID}/comments/`).pipe(
          map((data) => {
            return { type: FindingActions.setCommentsSuccess, comments: data }
          })
        )
      })
    )
  })




  // updateFinding$ = createEffect(() => {
  //   return this.actions$.pipe(
  //     ofType(FindingActions.updateFinding),
  //     concatMap(({ payload }) => {
  //       return this.findingService.updateFinding(payload['finding'], payload['id']).pipe(
  //         mergeMap(() => {
  //           const attachments: any[] = payload['attachments'];
  //           const updates = [];
  //           const adds = [];
  //           attachments.forEach(att => {
  //             if (att.id) {
  //               updates.push(att);
  //             } else {
  //               adds.push(att);
  //             }
  //           });
  //           const updateActions = updates.map(att => {
  //             return { type: FindingActions.updateAttachment, attachment: att };
  //           })
  //           const addActions = adds.map(att => {
  //             return { type: FindingActions.addNewAttachment, attachments: att, newFindingId: payload['id'] };
  //           })
  //           return from([
  //             ...updateActions,
  //             ...addActions,
  //           ])
  //         })
  //       )
  //     }
  //     )
  //   )
  // });




  updateAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.updateAttachment),
      concatMap(data => {
        return this.findingService.updateAttachment(data['attachment']).pipe(
          map(dataRes => {

            this.router.navigate(['../', ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN])

            return updateAttachmentSuccess()

          })
        )
      })
    )
  })

  addAttachment$ = createEffect(() => {
    return this.actions$.pipe(
      ofType(FindingActions.addNewAttachment),
      concatMap(({ attachments, newFindingId }) => {
        let att = attachments[0] !== undefined ? attachments[0] : attachments
        return this.findingService.addNewAttachment(att, newFindingId).pipe(
          map(() => {
            let att: any[] = attachments
            if (att.length > 1) {
              return {
                type: FindingActions.addNewAttachment,
                attachments: att.slice(1),
                newFindingId: newFindingId
              }
            } else {
              this.router.navigate(['../', ROUTES_STRINGS.FOLLOW_UP_REMEDATION_PLAN])
              return addNewAttachmentSuccess()
            }
          })
        )
      })
    );
  });

  getMessageFromStatus(status) {
    let summary = Summary[status]
    let detail = Detail[status]
    let message = { severity: (status == 'REFUSED' ? 'error' : 'success'), summary: summary, detail: detail }
    return message
  }

  constructor(
    private actions$: Actions,
    private findingService: FindingService,
    private router: Router,
    private store: Store,
    private pulsarService: PulsarService,
    private http: HttpClient,
    private messageService: MessageService
  ) { }
}



