<div class="flex flex-column" *ngIf="!isAdmin else admin">
  <app-flow></app-flow>
  <div *ngIf="stato=='REFUSED'" class="flex justify-content-center" style="margin: 0 0.5%; padding: 0.5%;">
    <div class="flex flex-column card  p-3 cardRefused ">
      <div class="flex icon justify-content-center ">
        <i class="fa-solid fa-xl fa-circle-exclamation m-1 my-3"></i>
      </div>
      <div>
        <div class="font-bold text-center mb-3">Richiesta Rifiutata da Compliance!</div>
        <div class="Motivazione">
          <div class="text-sm font-bold">Motivazione:</div>
          <div class="text-xs">{{refused_reasons}}</div>
        </div>
      </div>
    </div>
  </div>
  <app-follow-timeline [timeLineStatus]="timelineStatus" *ngIf="stato ;else spinner" [stato]="stato" [size]=5
    [isFinding]="true" class="mb-5"></app-follow-timeline>
  <app-header-tab (response)="respond($event)" [isAdmin]="isAdmin" [seeFindingCompliance]="true" [isFinding]="true"
    [stato]="stato" class="mb-2"></app-header-tab>
  <form [formGroup]="formValue">
    <app-form-attributes [timeLineStatus]="timeLineStatus" formControlName="newFinding" [isAdmin]="isAdmin"
      [stato]="stato"></app-form-attributes>
    <app-answer formControlName="answer" [isUserFinding]="true"></app-answer>
  </form>
  <app-dynamic-table [currentUserID]="currentUserId" [isAdmin]="isAdmin" [canFilter]="canFilter"
    (editAttachmentEvent)="editAttachmentFromDB($event)" (removeAttachment)="removeAttachment($event)"
    (attachmentAdded)="addAttachment($event)" [tableData]="attachmentData" [tableColumn]="tableColumn"
    [isAllegato]="true" [isDisabled]="disabledButton()" [canAddAttachment]="canAddAllegato()">
  </app-dynamic-table>

  <div class="flex justify-content-end mt-2 custombutton gap-2 px-3">
    <button pButton pRipple type="button" class="return p-fontMedium " [routerLink]="['../']" [disabled]="loading">Torna Indietro
    </button>
    <button *ngIf="stato !== 'NEW' && stato !== 'CLOSED' && stato !== 'TO_CHECK'" pButton pRipple type="submit"
      class="save p-fontMedium " (click)="saveRequestCompliance()" [disabled]="loading">Salva e
      Chiudi
    </button>
  </div>
</div>

<ng-template #admin>
  <div class="flex flex-column" *ngIf="isAdmin">
    <app-flow></app-flow>
    <div *ngIf="stato !== 'DRAFT'">
      <app-follow-timeline [timeLineStatus]="timelineStatus" *ngIf="stato;else spinner" [stato]="stato" [size]=5
        [isFinding]="true" class="mb-5"></app-follow-timeline>
      <br>
      <app-tracking *ngIf="trackings" [trackings]="trackings" class="my-2"></app-tracking>
      <app-header-tab *ngIf="stato" (response)="respond($event)" [isAdmin]="isAdmin" [seeFindingCompliance]="true"
        [isFinding]="true" [isAdmin]="isAdmin" [stato]="stato" [title]="'Tracking Finding'"></app-header-tab>
    </div>
    <form [formGroup]="formValue">
      <app-form-attributes formControlName="newFinding" [stato]="stato" [isAdmin]="isAdmin"></app-form-attributes>
      <app-answer formControlName="answer" [isUserFinding]="true"></app-answer>
    </form>

    <app-dynamic-table [currentUserID]="currentUserId" [isAdmin]="isAdmin" [canFilter]="canFilter"
      (editAttachmentEvent)="editAttachmentFromDB($event)" (removeAttachment)="removeAttachment($event)"
      (attachmentAdded)="addAttachment($event)" [tableData]="attachmentData" [tableColumn]="tableColumn"
      [isAllegato]="true" [isDisabled]="disabledButton()" [canAddAttachment]="canAddAllegato()"></app-dynamic-table>



    <div class="flex justify-content-end mt-2 custombutton gap-2 px-3" >
      <div class="flex justify-content-end">
        <button pButton pRipple type="button" class="return p-fontMedium " [routerLink]="['../']" [disabled]="loading">Torna Indietro
        </button>
      </div>    
      <button *ngIf="stato !== 'NEW' && stato !== 'CLOSED'" pButton
        pRipple type="submit" class="save p-fontMedium ml-2" [disabled]="loading" (click)="saveFindingCompliance()">Salva e
        Chiudi
      </button>
      <button *ngIf="stato == 'DRAFT'" pButton pRipple type="button" class="send p-fontMedium  " [disabled]="loading"
        (click)="sendToCompliance()">Conferma e Invia
      </button>
    </div>
  </div>
</ng-template>

<ng-template #spinner>
  <div class=" flex w-full timelineCustom justify-content-center align-items-center flex-column">
    <div class="flex w-50 justify-content-between">
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
      <p-skeleton shape="circle" size="4rem"></p-skeleton>
    </div>
  </div>
</ng-template>
