import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { RequestService } from 'src/app/utils/services/request.service';
import { MessageService } from 'primeng/api';
import { UserService } from 'src/app/utils/services/user.service';
import { FormControl, FormGroup, Validators } from '@angular/forms';
import { Store } from '@ngrx/store';
import { selectCurrentRequestState } from '../utils/store/request/request.selector';
import { take } from 'rxjs';

@Component({
  selector: 'app-view-request-test',
  templateUrl: './view-request-test.component.html',
  styleUrls: ['./view-request-test.component.scss']
})

export class ViewRequestTestComponent implements OnInit {
  @Input() display: boolean = false;
  stato: string;
  formvalue: FormGroup;


  @Output() newItemEvent = new EventEmitter<string>();

  tabella: any[] = [];
  isAdmin: Boolean;

  constructor(private requestService: RequestService, private messageService: MessageService, private userService: UserService, private store: Store) { }

  ngOnInit() {

    this.formvalue = new FormGroup({
      title: new FormControl({ value: '', disabled: this.display }, [Validators.required]),
      priorita: new FormControl({ value: '', disabled: this.display }, [Validators.required]),
      dueDate: new FormControl({ value: '', disabled: this.display }, Validators.required),
      ambito: new FormControl({ value: '', disabled: this.display }, Validators.required),
      perimetro: new FormControl({ value: '', disabled: this.display }, Validators.required),
      struttura: new FormControl({ value: '', disabled: this.display }, Validators.required),
      description: new FormControl({ value: '', disabled: this.display }, Validators.required)
    })

    // this.isAdmin = this.userService.getAdmin();
    this.store.select(selectCurrentRequestState).pipe(take(1)).subscribe(value => this.stato = value)



  }

  saveRequestCompliance() {
    this.messageService.add({ severity: 'success', summary: 'Salvato con successo' });
  }


}
