import { Action, createReducer, on } from '@ngrx/store';
import { state } from '@angular/animations';
import { setFindings, setCurrentFinding, setFindingsSuccess, addNewFinding, initFindings, setComments, updateFinding, setCommentsSuccess, updateFindingStatusSuccess, updateFindingStatus } from './finding.action';
import { initialFindingState } from './finding.state';



export const findingReducer = createReducer(
    initialFindingState,
    on(setFindings, state => ({ ...state, loadingFinding: true })),
    on(setFindingsSuccess, (state, { findings, size }) => ({ ...state, findings: { findings, size }, loadingFinding: false })),
    on(addNewFinding, (state) => ({ ...state, loadingFinding: true })),
    on(setCurrentFinding, (state, { currentFinding }) => ({ ...state, currentFinding })),
    on(updateFinding, (state) => ({ ...state, loadingFinding: true })),
    on(setCommentsSuccess, (state, { comments }) => ({ ...state, currentFinding: { ...state.currentFinding, comments: comments } })),
    on(updateFindingStatus, (state) => ({ ...state, loadingFinding: true })),
    on(updateFindingStatusSuccess, (state) => ({ ...state, loadingFinding: false })),
);
